import { VixoAPI } from './settings';
import axios from 'axios';
import { APIHeader } from './APICallWrapper';



export function CustomerAuthentication(location)
{

    var AuthData={
        CustomerKey:'',
        UserKey:'',

    };
    const params = new URLSearchParams(location.search);
    var CustomerId = params.get("id");
    if(!CustomerId)
    {

      CustomerId=params.get("Id");
    }
    var UserID = params.get("U");
    if (CustomerId) {
        AuthData.CustomerKey=CustomerId;
    }
    if (UserID) {
        AuthData.UserKey=UserID;
    }
   if(UserID!=null && GetLoggedInUser()==null)
   {
         getUserByKey(UserID)
   }
return AuthData;
}
export function GetLoggedInUser()
{
  RemoveLoggedInUser();

    const loggedInUser = localStorage.getItem('User');
    var userObject = JSON.parse(loggedInUser);
    return userObject;

}
export function GetLoggedInUserOrganizationId()
{
    const loggedInUser = localStorage.getItem('Org');
    var userObject = JSON.parse(loggedInUser);
    if(userObject!=null)
    {
        return userObject.organizationId;
    }
}
export function RemoveLoggedInUser() {
  // Remove the user from localStorage
  localStorage.removeItem('User');
}
export function GetLoggedInCustomer()
{
    const loggedInCustomer = localStorage.getItem('Customer');
    var customerObject = JSON.parse(loggedInCustomer);
    return customerObject;

}
function getUserByKey(UserKey) {
 
  var config=APIHeader();
  if(config && config.headers)
    {
    try {
      var encodedCustomer=encodeURIComponent(UserKey);
      const OrgAPIPath = `${VixoAPI.UserAPI}getUserByKey?Key=${encodedCustomer}`;
      axios
        .get(OrgAPIPath, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            localStorage.setItem("User", JSON.stringify(response.data));
            getOrganizationById(response.data.organizationId);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
        });

      } catch (error) {
          throw error;
          }
  
        }

  }

  function getOrganizationById(OrganizationID) {
    var config=APIHeader();
    if(config && config.headers)
      {
    try {
     
      const OrgAPIPath = `${VixoAPI.OrganizationAPI}get?ID=${OrganizationID}`;
      axios
        .get(OrgAPIPath, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            localStorage.setItem("Org", JSON.stringify(response.data));
          }
        })
        .catch((error) => {
            throw error;
        })
        .finally(() => {
          });
    } catch (error) {
        throw error;
    }
  }
  }