import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import axios from "axios";

import { Link, useNavigate, json, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CustomerAuthentication } from "../../customerAuthentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faFilePen,
  faCloudUpload,
} from "@fortawesome/free-solid-svg-icons";
import Fileopen from "../Files/Fileopen";
import "./UploadFiles.css";
import DateComponent from "../../VixoFormats/Date";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { config } from "@fortawesome/fontawesome-svg-core";

const CustomerStatementsDocumentsUpload = ({Customer}) => {
  const [SelectedCustomer, setSelectedCustomer] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [documentData, setDocumentData] = useState(null);

  const documentInputRef = useRef(null);
  const [formData, setFormData] = useState({
    DocumentTitle: "",
    Files: null,
    UploadedDate: "",
    Location: "",
  });
  const [OrgName, setOrgName] = useState(null);
  const [hasLogo, setHasLogo] = useState(null);

  const [loading, setLoading] = useState(false);
  const [statementData, setStatementData] = useState(null);
  const [Data, setData] = useState({
    DocumentTitle: "",
    Files: null,
    UploadedDate: "",
    isChecked: false,
  });

  useEffect(() => {
    if (SelectedCustomer && SelectedCustomer.key) {
      LoadDocByCustomerKey();
      LoadStatementByCustomerKey();
    }
  }, [SelectedCustomer]);

  useEffect(() => {
    if(Customer && Customer!=null)
    {
          setSelectedCustomer(Customer);
    }
  }, [Customer]);

  /// upload statement and get statement ////

  const handleStatement = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    const selectedFiles = e.target.files; // Get all selected files

    const data = new FormData(); // Create a FormData object
    data.append("StatementTitle", "ali");
    data.append("UploadedDate", currentDate);
    data.append("Location", formData.Location);
    data.append("isCheckedList", false);

    // Append each selected file to FormData
    for (let i = 0; i < selectedFiles.length; i++) {
      data.append("Files", selectedFiles[i]); // Use "Files" to indicate it's an array of files
    }
    

    try {
      const StatementAPIPath =
        VixoAPI.StatmentAPI + "uploadStatementCustomer?Key=" + SelectedCustomer.key; // Replace with your actual API URL
      setLoading(true);
      axios
        .post(StatementAPIPath, data, {headers: config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            LoadStatementByCustomerKey();
          } else {
            toast(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
     
    }
 
  };

  function LoadStatementByCustomerKey() {
 

    try {
      const SetDataById = `${VixoAPI.StatmentAPI}getAnyStatementByCustomerKey?Key=${SelectedCustomer.key}&OcrStatus=${false}`;
     
      axios
        .get(SetDataById,{headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setstatementData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      
    }
 
  }

  function setstatementData(statementData) {
    try {
      setLoading(true);
      var statementdata = JSON.parse(JSON.stringify(statementData));
      setStatementData(statementdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  ///upload statement and get statement ////

  ///upload document and get document ////
  const [UserKey, setUserKey] = useState("");
  const handleDocument = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    const selectedFiles = e.target.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select one or more files.");
      return;
    }

    const data = new FormData();
    data.append("DocumentTitle", formData.DocumentTitle);
    data.append("UploadedDate", currentDate);
    data.append("Location", formData.Location);
    data.append("UserKey", formData.UserKey);

    for (let i = 0; i < selectedFiles.length; i++) {
      data.append("Files", selectedFiles[i]);
    }
    

    try {
      const DocumentAPIPath = VixoAPI.DocumentAPI + "upload?Key=" + SelectedCustomer.key;
      setLoading(true);
      axios
        .post(DocumentAPIPath, data)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            LoadDocByCustomerKey();
            documentInputRef.current.value = "";
          } else {
            toast(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  
  };

  function LoadDocByCustomerKey() {
  

    try {
      if (SelectedCustomer && SelectedCustomer.key != null) {
     

        const DocDataById = `${VixoAPI.DocumentAPI}getAnyDocumentByCustomerKey?Key=${SelectedCustomer.key}`;
      
        axios
          .get(DocDataById, {headers: config.headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setBankData(response.data);
            } else {
              //setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
           
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
     
    }
  
}

  function setBankData(docData) {
    try {
      setLoading(true);
      if (docData != null) {
        var Docmentdata = JSON.parse(JSON.stringify(docData));
        setDocumentData(Docmentdata);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const [selectedFiledata, setselectedFiledata] = useState({});
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);
  function handleViewClick(fileName) {
    const fileview = fileName;
    setFileView(fileview);
    setShowViewPopup(true);
  }

  const handleDocumentClick = () => {
    documentInputRef.current.click();
  };

  function getOrganizationById(OrganizationID) {

    try {
      const OrgAPIPath = `${VixoAPI.OrganizationAPI}getAny?ID=${OrganizationID}`;
      axios
          .get(OrgAPIPath,)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var OrgData = JSON.parse(JSON.stringify(response.data));
            setHasLogo(OrgData.logoUrl);
            setOrgName(OrgData.name);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
 
  }
  const handleStatementClick = () => {
    const fileInput = document.getElementById("formFileLg");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDocClick = () => {
    // Trigger a click on the document input when the fieldset is clicked
    if (documentInputRef.current) {
      documentInputRef.current.click();
    }
  };

  return (
    <>
      <Fileopen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        title={selectedFiledata?.name}
        pdfSrc={fileView}
      />
      <ToastContainer />
      <Spinner show={loading} Title="Customer Statement Document Upload" />
      <div className="row">
        <div className="col-md-2"></div>
        <div
          className="col-md-8 files"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-8 fileindexing">
            <div className="">
              <div className=" py-3">
                <h4
                  className="mb-0 text-start"
                  style={{
                    color: "#00AADB",
                    fontWeight: 600,
                    fontSize: "25px",
                  }}
                >
                  Upload Files
                </h4>
              </div>
              <div className="card-body">
                <h6
                  className="text-start"
                  style={{
                    fontSize: "18px",
                    color: "#004265",
                    fontWeight: "400",
                  }}
                >
                  Upload Statement
                </h6>

                <fieldset
                  className="upload_dropZone text-center mb-3 p-4"
                  // onClick={handleStatementClick}
                  style={{ cursor: "pointer", height: "12pc" }}
                >
                  <legend className="visually-hidden">Document uploader</legend>
                  <label
                    // className="mb-3"
                    htmlFor="formFileLg"
                    style={{
                      marginTop: "-2px",
                      color: "#004265",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                      display: "inline-block",
                      width: "-webkit-fill-available",
                      height: "-webkit-fill-available",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        zIndex: 1,
                      }}
                    >
                      <input
                        id="formFileLg"
                        className="form-control"
                        type="file"
                        multiple
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                        onChange={handleStatement}
                      />
                    </div>
                    <img
                      src="assets/images/fileuploader.svg"
                      alt="Document Uploader"
                    />
                    <br />
                    <h2
                      className="mt-3"
                      style={{
                        fontSize: "20px",
                        color: "#004265",
                        fontWeight: "600",
                      }}
                    >
                      Upload Statement
                    </h2>

                    <p
                      className="mt-3"
                      style={{
                        color: "#004265",
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: "300",
                      }}
                    >
                      Drag and drop files here
                    </p>
                  </label>
                </fieldset>

                {/* <div>
                  <label htmlFor="formFileLg" className="form-label">
                    Upload Statement
                  </label>
                  <input
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                    onChange={handleStatement}
                  />
                </div> */}
                <br />
                <h3 className="text-center" style={{ color: "#004265" }}>
                  Do you have any additional documents you'd like to upload for{" "}
                  {OrgName ? OrgName : "Vixo Login"} to review? (i.e. - articles
                  or organizations, state documents, tax returns, etc...)
                </h3>
                <br />
                <h6
                  className="text-start"
                  style={{
                    fontSize: "18px",
                    color: "#004265",
                    fontWeight: "400",
                  }}
                >
                  Upload Document
                </h6>

                <fieldset
                  className="upload_dropZone text-center mb-3 p-4"
                  //onClick={handleDocClick}
                  style={{ cursor: "pointer", height: "12pc" }}
                >
                  <legend className="visually-hidden">Document uploader</legend>
                  <label
                    // className="mb-3"
                    htmlFor="formFileLg"
                    style={{
                      marginTop: "-2px",
                      color: "#004265",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                      display: "inline-block",
                      width: "-webkit-fill-available",
                      height: "-webkit-fill-available",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        zIndex: 1,
                      }}
                    >
                      <input
                        id="formFileLg"
                        className="form-control"
                        ref={documentInputRef}
                        type="file"
                        multiple
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                        onChange={handleDocument}
                      />
                    </div>
                    <img
                      src="assets/images/fileuploader.svg"
                      alt="Document Uploader"
                    />
                    <br />
                    <h2
                      className="mt-3"
                      style={{
                        fontSize: "20px",
                        color: "#004265",
                        fontWeight: "600",
                      }}
                    >
                      Upload Document
                    </h2>

                    <p
                      className="mt-3"
                      style={{
                        color: "#004265",
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: "300",
                      }}
                    >
                      Drag and drop files here
                    </p>
                  </label>
                </fieldset>

                {/* <div className="documentinput">
                  <label htmlFor="formFileLg" className="form-label">
                    Upload Document
                  </label>
                  <input
                    ref={documentInputRef}
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                    onChange={handleDocument}
                    multiple
                  />
                </div>  */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>

      <div className="containerFluid">
        <div className="row">
          <div className="col-10 mx-auto mt-4  ">
            <div className="">
              <div className="py-3">
                <h5
                  className="mb-0 text-start"
                  style={{
                    fontWeight: "600",
                    color: "rgb(0, 170, 219)",
                    fontSize: "25px",
                  }}
                >
                  Uploaded Statements
                </h5>
              </div>
              <div className="card-body">
                {statementData != null ? (
                  <>
                    {/* Desktop View */}
                    <div className="table-responsive-statement  d-none d-md-block">
                      <table
                        id="example"
                        className="table"
                        style={{ borderBottom: "transparent" }}
                      >
                        <thead>
                          <tr>
                            <th className="text-start">Date</th>
                            <th className="text-start">Statements Name</th>
                            <th className="text-start">Error</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            background: "#EAF0F6",
                            borderRadius: "50px",
                            borderBottom: "1px solid",
                          }}
                        >
                          {statementData.map((statement, index) => (
                            <tr key={index}>
                              <td className="text-start">
                                {statement
                                  ? 
                                  <DateComponent value={statement.uploadedDate} />
                                  : ""}
                              </td>
                              <td className="text-start">
                                {statement.statementTitle}
                              </td>
                              <td className="text-start">No</td>
                              <td>
                                <FontAwesomeIcon
                                  style={{ color: "green", marginLeft: "2px" }}
                                  onClick={() =>
                                    handleViewClick(statement.fileSha1Hash)
                                  }
                                  icon={faEye}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Mobile View */}
                    <div className="card-deck d-md-none">
                      {statementData.map((statement, index) => (
                        <div
                          key={index}
                          className="card mb-3"
                          style={{ border: "1px solid black" }}
                        >
                          <div className="card-body">
                            <h5 className="card-title">
                              {statement.statementTitle}
                            </h5>
                            <p className="card-text">
                              Date:{" "}
                              {statement.uploadedDate
                                ? new Date(
                                    statement.uploadedDate
                                  ).toLocaleDateString("en-US")
                                : ""}
                            </p>
                            <p className="card-text">Error: No</p>
                            {/* <FontAwesomeIcon
                              style={{ color: "green", marginLeft: "2px" }}
                              onClick={() =>
                                handleViewClick(statement.fileSha1Hash)
                              }
                              icon={faEye}
                            /> */}

                            <div
                              onClick={() =>
                                handleViewClick(statement.fileSha1Hash)
                              }
                            >
                              <embed
                                src={statement.fileSha1Hash}
                                type="application/pdf"
                                width="200"
                                height="200"
                                style={{ overflow: "hidden" }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p>No statements available.</p>
                )}
              </div>
            </div>
            <br />

            <div className="">
              <div className="py-3">
                <h5
                  className="mb-0 text-start"
                  style={{
                    fontWeight: "600",
                    color: "#00AADB",
                    fontSize: "25px",
                  }}
                >
                  Uploaded Documents
                </h5>
              </div>
              <div className="card-body">
                {documentData != null ? (
                  <>
                    {/* Desktop View */}
                    <div className="table-responsive table-responsive-doucment d-none d-md-block">
                      <table id="example" className="table">
                        <thead>
                          <tr>
                            <th className="text-start">Date</th>
                            <th className="text-start">Document Name</th>
                            <th className="text-start">Location</th>
                            <th className="text-start">Error</th>
                            <th className="text-start">Actions</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            background: "#EAF0F6",
                            borderRadius: "50px",
                            borderBottom: "1px solid",
                          }}
                        >
                          {documentData.map((document, index) => (
                            <tr key={index}>
                              <td className="text-start">
                                {document
                                  ? <DateComponent value={document.uploadedDate} / >
                                  : ""}
                              </td>
                              <td className="text-start">
                                {document.fileName}
                              </td>
                              <td className="text-start">
                                {document.location}
                              </td>
                              <td className="text-start">No</td>
                              <td className="text-start">
                                <FontAwesomeIcon
                                  style={{ color: "green", marginLeft: "2px" }}
                                  onClick={() =>
                                    handleViewClick(document.fileSha1Hash)
                                  }
                                  icon={faEye}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Mobile View  */}
                    <div className="card-deck-doucment d-md-none">
                      {documentData.map((document, index) => (
                        <div
                          key={index}
                          className="card mb-3"
                          style={{ border: "1px solid black" }}
                        >
                          <div className="card-body">
                            <h5 className="card-title">{document.fileName}</h5>
                            <p className="card-text">{document.location}</p>
                            <p className="card-text">
                              Date:{" "}
                              {document.uploadedDate
                                ? new Date(
                                    document.uploadedDate
                                  ).toLocaleDateString("en-US")
                                : ""}
                            </p>
                            <p className="card-text">Error: No</p>

                            <div
                              onClick={() =>
                                handleViewClick(document.fileSha1Hash)
                              }
                            >
                              <embed
                                src={document.fileSha1Hash}
                                type="application/pdf"
                                width="200"
                                height="200"
                                style={{ overflow: "hidden" }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p>No documents available.</p>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerStatementsDocumentsUpload;
