
export function APIHeader()
{
  
  const loggedInUser = localStorage.getItem("AuthUser");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    var accesstoken=  userObject.authToken;
    const config = {
        headers: {
        Authorization: `Bearer ${accesstoken}`
        }
    };
    return config;
}

}
export function HasLoggedInUser(UserID)
{
  const loggedInUser = localStorage.getItem("AuthUser");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    return true;
  }
  return false;
}
export function  ExpireToken(navigate,Customerkey)
{
  
  const User = localStorage.getItem("AuthUser");
  var userObject = JSON.parse(User);
  if(userObject && userObject!=null && Customerkey!=null )
    {
      var CustomerKey = Customerkey;
      var UserKey = userObject.key;
      var RedirectURL= `/vixohome?Id=${CustomerKey}&U=${UserKey}`;
      localStorage.removeItem("AuthUser");
      navigate(RedirectURL);
    }else
    {
      navigate("/Home");
    }
 
}