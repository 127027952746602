import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import addNotification from "./Slices/sendNotificationSlice";
import * as signalR from "@microsoft/signalr";
import { VixoAPI } from "../../settings";
import CustomerNavbar from "../Header/CustomerNavbar";

const NotificationsHome = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState({});
  // useEffect(() => {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
  //     .build();

  //   connection
  //     .start()
  //     .catch((error) => console.error("SignalR Connection Error:", error));

  //   connection.on("ReceiveNotification", (receivedNotification) => {
  //     alert("ReceiveNotification", receivedNotification);
  //     // sendNotificationToRedux(receivedNotification);
  //     const { organiationID, cutomerId, notificationType, referenceID } =
  //       receivedNotification;
  //       setNotifications(sendNotificationToRedux(receivedNotification));

  //     // Handle the notification data as needed in your React application
  //   });

  //   function sendNotificationToRedux(receivedNotification) {
  //     dispatch(addNotification(receivedNotification));
  //   }

  //   return () => {
  //     if (connection.state === signalR.HubConnectionState.Connected) {
  //       connection.stop();
  //     }
  //   };
  // }, [dispatch]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`)
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      handleNotification(receivedNotification);
    });

    function handleNotification(receivedNotification) {
      dispatch(addNotification(receivedNotification));
    }

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);

  return (
    <>
      <h1> notification: </h1>
    </>
  );
};

export default NotificationsHome;
