
import React, { useState, useEffect,useCallback} from "react";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import { usePlaidLink } from 'react-plaid-link';
import axios from "axios";
import { APIHeader } from "../../APICallWrapper";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";

const PlaidIdentityVerfication = ({Customer}) => {
    const [loading, setLoading] = useState(false);
    const [SelectedCustomer, setSelectedCustomer] = useState(null);
    const [customerToken, setCustomerToken] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorType, setErrorType] = useState(null);
    const [linkToken, setLinkToken] = useState(null);
    
    const [publicToken, setPublicToken] = useState(null);
    const [isPublicTokenSaved, setPublicTokenSave] = useState(null);
    const [metaData, setMetaData] = useState([]);
    const onSuccess = React.useCallback((public_token, metadata) => {
      try {
            
             setPublicToken(public_token);
             setMetaData(metadata);

          

        // Handle response ...
      } catch (error) {
        setErrorType(error);
       
      }
    }, []);

   

    const config = {
      token: linkToken,
      onSuccess,
    };

    const { open, ready } = usePlaidLink(config);
    function setError(message) {
        setErrorType(message);
      }

    useEffect(() => {
      if(Customer)
      {
        setSelectedCustomer(Customer);
       
      }
    },[Customer]);

    useEffect(() => {
      if(SelectedCustomer!=null)
      {
      
          GetPlaidLinkToken();
        
      }
    },[SelectedCustomer]);

   
    
   
    function GetPlaidLinkToken() {
      try {
        setLoading(true);
        const LoadPlaidCustomerLinkTokenAPI = `${VixoAPI.FinicityAPI}getCustomerIdentityVerficiationLink?CustomerKey=${SelectedCustomer.key}`;
        setLoading(true);
        axios
          .get(LoadPlaidCustomerLinkTokenAPI, {headers:APIHeader().headers})
          .then((response) => {
            var response = response.data;
            console.log(response);
            if (response.statusCode === 200) {
              var linkTokenResponse = response.data;
              setLinkToken(linkTokenResponse.link_token);
              
            } else {
              setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    
      
    useEffect(() => { 
      if (linkToken!=null && SelectedCustomer!=null&& SelectedCustomer.key!=null) {
        open();
      }
    }, [linkToken,SelectedCustomer,open,onSuccess]);

    useEffect(() => {
      
      if (publicToken!=null &&SelectedCustomer!=null&& SelectedCustomer.key!=null) {
        
        SavePublicToken();
      }
    }, [publicToken,SelectedCustomer]);

      // useEffect(() => {
        
      //   if (metaData!=null && customerToken!=null && isPublicTokenSaved!=null&& isPublicTokenSaved===true) {
         
      //     SavePlaidBankAccounts();
      //   }
      // }, [CustomerKey,metaData,isPublicTokenSaved]);
      
      function SavePublicToken()
      {
        setLoading(true);
        if(customerToken!=null && publicToken!=null)
        {
                const APIPath =`${VixoAPI.FinicityAPI}updateCustomerIdentityVerificationPublicToken?CustomerKey=${SelectedCustomer.key}&PublicToken=${publicToken}`;
                axios.get(APIPath, {headers:APIHeader().headers}) 
                .then(response => {
                  var apiResponse=response.data;

                  if(apiResponse.statusCode==200)
                  {
                    setPublicTokenSave(true);
                  }else {
                      setError(apiResponse.message);
                    }
                })
                .catch(error => {
                  // Handle any errors that occurred during the request
                  setLoading(false);
                });
        }
        setLoading(false);

      }


    return (
        <>
        <Spinner show={loading} Title="Plaid Identity Verification"/>
      
      <br/>
      <span>{errorType ? errorType : ""}</span>
      {successMessage ? (
                      <span style={{ color: "green" }}>{successMessage}</span>
                    ):''



      }
      </>
    );
  };
  
  export default PlaidIdentityVerfication;
  
  
  
  
  