import React from "react";
import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CommunicationTimeline.css";
import AccountStatus from "../../VixoFormats/AccountStatus";

const CommunicationTimelineAccount = ({ communicationTimelineData }) => {
  return (
    <>
      <ToastContainer />

      <main className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {communicationTimelineData.map((accounts, index) => (
                  <React.Fragment key={index}>
                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{
                          borderRadius: "13px",
                          backgroundColor: "#f0f0f0",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        }}
                      >
                        <img
                          className="card-img col-md-12 bg-light text-center img-fluid mt-1"
                          src={accounts.instituteLogoUrl}
                          alt="Bank Image"
                          style={{ alignSelf: "center" }}
                        />

                        <div
                          className="d-flex align-items-center justify-content-between p-2 rounded card-title h5 mt-2"
                          style={{
                            backgroundColor: "#00AADB",
                            color: "#fff",
                            borderTopLeftRadius: "13px",
                            borderTopRightRadius: "13px",
                          }}
                        >
                          <div style={{ fontSize: "12px" }}>
                            {accounts.lastUpdatedAgo}
                          </div>

                          <div style={{ fontSize: "12px" }}>
                            {" "}
                            <AccountStatus value={accounts.connectionId} />{" "}
                          </div>
                        </div>
                        <div style={{ borderTop: "1px solid #00AADB" }}>
                          <p
                            style={{
                              color: "#333",
                              fontSize: "12px",
                              marginTop: "4px",
                              marginLeft: "8px",
                            }}
                          >
                            {accounts.accountTitle} <b>{accounts.realAccountNumber.slice(-4)}</b>
                          </p>
                          <div className="col-md-12 mt-3"></div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CommunicationTimelineAccount;
