import React from "react";

function ErrorPage() {
  return (
    <>
    <div className="container-fluid mt-5">
      <div className="row mt-5">
        <div className="col-12 text-center mt-5">
              <img
                src="assets/images/LogoVixo.png"
                alt="logo icon"
                className="logoIcon mt-5"
                style={{
                 // display: "flex",
                  width: "200px",
                  //marginLeft:"12pc",
                  //justifyContent: "center",
                  //alignItems: "center",
                }}
              />
              <p style={{fontSize:'20px'}}>
              URL is not Correct, Please contact support at <a href="mailto:support@vixolink.com">support@vixolink.com</a>
              </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default ErrorPage;
