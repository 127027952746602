import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../../APICallWrapper";
import {   ValidatePhoneGlobal, ValidateEmailGlobal } from "../../Architecture/Validation";
import ErrorComponent from "../../../ErrorComponent";
import Spinner from "../../../Spinner";
import { VixoAPI } from "../../../settings";

import "react-toastify/dist/ReactToastify.css";
import { OpenBankingType } from "./lookup";
import { useSelector } from "react-redux";

import { selectNotification } from "../../Notification/Slices/sendNotificationSlice";
import { useNavigate } from "react-router-dom";
const CustomerAddModal = ({ showModal, handleModalClose }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [OpenBankingId, setOpenBankingId] = useState();
  const navigate=useNavigate()


  const notification = useSelector(selectNotification)

  const [errorType, setErrorType] = useState(null);

  const [selectedRadio, setSelectedRadio] = useState("invitePhone");
  const [CreateSms, setCreateSms] = useState();
  const [customerInfo, setCustomerInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    OpenBankingType: "0",
    Identity: "",
    Massage: CreateSms && CreateSms  
  });
  const [orgID, setorgID] =useState();
  const LoadData = () => {
    const loggedInOrg = localStorage.getItem("Org");
    var orgObject = JSON.parse(loggedInOrg);
    if (orgObject != null) {
      setCreateSms(orgObject.bankLinkInvitationText);
      setorgID(orgObject.id);
    } else {
      // localStorage.clear();
      // navigate("/");
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");
    if (loggedInUser) {
      try {
        setUser(JSON.parse(loggedInUser));
        setAuthenticated(true);
      } catch (exception) {
        setAuthenticated(false);
      }
    }
  }, []);
  useEffect(() => {
    LoadLookups();
    LoadData();
  }, []);

  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);
  async function LoadLookups() {
    try {
      OpenBankingType()
        .then((result) => {
          setOpenBankingRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(
        ex + " setOpenBankingRoles Role Lookup Call is not successfull"
      );
    }
  }

  useEffect(() => {
    // Check if all required fields are filled
    const isFilled =
      customerInfo.firstName &&
      customerInfo.lastName &&
      customerInfo.email &&
      customerInfo.phone &&
      customerInfo.Identity;
      
      
    // Check if all fields are valid
    const emailIsValid = ValidateEmailGlobal(customerInfo.email);
    const phoneIsValid = ValidatePhoneGlobal(customerInfo.phone);

    setIsFormFilled(isFilled && emailIsValid && phoneIsValid);
  }, [customerInfo]);

  const resetForm = () => {
    setCustomerInfo({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      OpenBankingType: "0",
    });
    setErrorType(null); // Clear any previous error message
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();

    const emailIsValid = ValidateEmailGlobal(customerInfo.email);
    const phoneIsValid = ValidatePhoneGlobal(customerInfo.phone);

    if (!emailIsValid) {
      setErrorType("Enter a valid email address.");
      return;
    }

    if (!phoneIsValid) {
      setErrorType("Enter a valid phone number exactly 10 digits.");
      return;
    }

    const data = {
      Id: 0,
      organizationId: User.organizationId,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName,
      email: customerInfo.email,
      Identity: customerInfo.Identity,
      phone: customerInfo.phone,
      InvitePhone: selectedRadio === "invitePhone",
      InviteEmail: selectedRadio === "inviteEmail",
      contactMethod: selectedRadio,
      UserId: User.id,
      OpenBankingType: customerInfo.OpenBankingType,
      Massage: CreateSms && CreateSms,
    };

    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
       const CustomerPIPath = VixoAPI.CustomerAPI + "create";
      setLoading(true);

      const response = await axios.post(CustomerPIPath, data, {headers:config.headers});

      if (response.data.statusCode === 200) {
        clearData();
        setData(response.data.data);
        if (selectedRadio === "invitePhone") {
          // customerInfo.CreateSms; // Phone number to send the SMS
          // CreateSms; 
        }
      } else {
        setErrorType(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
      {
         ExpireToken(navigate);
        }
      } finally {
        setLoading(false);
      }
      
    }
    else{
    ExpireToken(navigate);
  }

    if (window.newrelic) {
      console.log('Adding page action with custom attributes... on add customer');
      window.newrelic.addPageAction('AddCustomer', {
        // Name: companyInfo.companyName,
        Email: customerInfo.email,
        // Add more attributes as needed
      });
    }    

  };

  const clearData = () => {
    resetForm();
    handleModalClose();
  };

  const setData = (data) => {
    handleModalClose(data);
  };

  const [isFormFilled, setIsFormFilled] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    // Implement your desired formatting logic here
    // e.g., (123)456-7890
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
  };

  const unformatPhoneNumber = (formattedPhoneNumber) => {
    // Remove formatting characters to get the plain number
    return formattedPhoneNumber.replace(/\D/g, "");
  };

  
function GotNewAlert(notification) { 
   if (
  notification != null  && 
  parseInt(notification.organiationID) == orgID  &&
  notification.notificationType == 22
) {



}
}

// useEffect(() => {
// if(notification)
// {
//  GotNewAlert(notification)
// }

// }, [notification]);

// function ReloadCustomerAccount(AccountID) {
//   if (!AccountID) {

//     AccountID = this.bankAccount.id;
//   }
//   try {
//     setLoading(true);
//     const AccountAPI = `${VixoAPI.AccountAPI}get?ID=${AccountID}`;
//     setLoading(true);
//     axios
//       .get(AccountAPI)
//       .then((response) => {
//         var response = response.data;
//          if (response.statusCode === 200) {

//           var account = JSON.parse(JSON.stringify(response.data));
        
//           setBankAccount(account);
//           accountUpdated(account);
//         }
//       })
//       .catch((error) => {
//         setLoading(false);
//         throw error;
//       })
//       .finally(() => {
//         setLoading(false);
//       });

//     setLoading(false);

//   } catch (error) {
//     setLoading(false);
//   }
// }

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} Title="Customer Add" />
      <Modal show={showModal} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-left">
                  <span className="m-2">Create Customer</span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div className="modal-body">
            <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="FirstName"
                  name="firstName"
                  placeholder="Customer Identity"
                  value={customerInfo.Identity}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      Identity: e.target.value,
                    })
                  }
                  style={{ borderColor: "inherit" }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="FirstName"
                  name="firstName"
                  placeholder="First Name"
                  value={customerInfo.firstName}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      firstName: e.target.value,
                    })
                  }
                  style={{ borderColor: "inherit" }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={customerInfo.lastName}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      lastName: e.target.value,
                    })
                  }
                  style={{ borderColor: "inherit" }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="example@vixolink.com"
                  value={customerInfo.email}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      email: e.target.value,
                    })
                  }
                  style={{
                    borderColor:
                      errorType === "Enter a valid email address."
                        ? "red"
                        : "initial",
                  }}
                />
                {errorType === "Enter a valid email address." && (
                  <span className="error">Enter a valid email address.</span>
                )}
              </div>

              <div className="">
                <input
                  type="text" // Change type to text
                  name="phone"
                  className={`form-control ${
                    errorType ===
                    "Enter a valid phone number exactly 10 digits."
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="(123) 456-7890"
                  maxLength="13" // Set maxLength to allow for the formatted phone number (e.g., (123)456-7890)
                  value={formatPhoneNumber(customerInfo.phone)} // Use a formatting function
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      phone: unformatPhoneNumber(e.target.value), // Remove formatting before setting state
                    })
                  }
                  onInput={(e) => {
                    const unformattedValue = unformatPhoneNumber(
                      e.target.value
                    );
                    if (unformattedValue.length > 10) {
                      e.target.value = formatPhoneNumber(
                        unformattedValue
                      ).slice(0, 14);
                    }
                  }}
                  style={{
                    borderColor:
                      errorType ===
                      "Enter a valid phone number exactly 10 digits."
                        ? "red"
                        : "initial",
                  }}
                />

                {errorType ===
                  "Enter a valid phone number exactly 10 digits." && (
                  <span className="error">
                    Enter a valid phone number exactly 10 digits.
                  </span>
                )}
              </div>
              <div
                className="card-footer py-3 bg-transparent error-container"
                style={{ color: "red" }}
              >
                {errorType && (
                  <div className="" role="alert">
                    {errorType}
                  </div>
                )}
              </div>
              <div className="col-md-12 mb-3 mt-0">
                <select
                  className="form-select"
                  name="OpenBankingType"
                  value={customerInfo.OpenBankingType}
                  onChange={(e) =>
                    setCustomerInfo({
                      ...customerInfo,
                      OpenBankingType: e.target.value, // Remove formatting before setting state
                    })
                  }
                >
                  {OpenBankingRoles != null &&
                    OpenBankingRoles.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.displayValue}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-12">
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByEmail"
                    name="inviteMethod"
                    value="inviteEmail"
                    // checked={selectedRadio === "inviteEmail"}
                    onChange={() => handleRadioChange("inviteEmail")}
                  />
                  <label className="form-check-label" htmlFor="inviteByEmail">
                    Invite by Email
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByPhone"
                    name="inviteMethod"
                    value="invitePhone"
                    checked={selectedRadio === "invitePhone"}
                    onChange={() => handleRadioChange("invitePhone")}
                  />
                  <label className="form-check-label" htmlFor="inviteByPhone">
                    Invite by Phone Number
                  </label>
                </div>
              </div>

              <div
                className="flex-row justify-content-between"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={clearData}
                  style={{borderRadius:"20px",width:"7pc",fontWeight:"500"}}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-info mx-2 custom-button"
                  onClick={handleCustomerSubmit}
                  disabled={!isFormFilled}
                  style={{borderRadius:"20px", fontWeight:"500",color:"white"}}

                >
                  Add Customer
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerAddModal;
