// sendNotificationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: {},
};

const sendNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification(state, action) {
      state.notification = action.payload;
    },
  },
});

export const { addNotification } = sendNotificationSlice.actions;
export const selectNotification = (state) => state.notification.notification;

export default sendNotificationSlice.reducer;
