import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VixoAPI } from "../../settings";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { useLocation } from "react-router-dom";

const DownloadHome = () => {
  const navigate = useNavigate();
  const [loading ,setLoading] =useState();
  const [collapsed, setCollapsed] = useState(false);
  const [customerHome, setCustomerHome] = useState(null);
  const [authenticated, setauthenticated] = useState(null);
  const [User, setUser] = useState(null);
  const [CurrentCustomer, setCurrentCustomer] = useState(null);
  const [Userkey, setUserkey] = useState(null);

  useEffect(() => {
    CustomerSelected();
  }, []);

  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("U");

    if (keyFromParams) {
      setUserkey(keyFromParams);
     
    }
    
  }, [location]);

  function LoadUser() {
    if(HasLoggedInUser()){
      const config= APIHeader();
    try {
      setLoading(true);
      var Key = encodeURIComponent(Userkey);
      const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
      setLoading(true);
      axios
        .get(GetUserByKeyAPI, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            
            setUser(response.data);
            
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate)
  }
  }

   useEffect(()=>{
    
    LoadUser();

   })


  function CustomerSelected() {
    const loggedInUser = Userkey;
    const currentCustomerLocal = localStorage.getItem("SelectedCustomer");
    var currentCustomerObject = JSON.parse(currentCustomerLocal);
    var userObject = JSON.parse(loggedInUser);
    if (loggedInUser != null && currentCustomerLocal != null) {
      setUser(userObject);
      setCurrentCustomer(currentCustomerLocal);
      try {
        if (
          loggedInUser &&
          currentCustomerObject &&
          currentCustomerObject.key &&
          userObject.key
        ) {
          var customerHomeLink =
          VixoAPI.CustomeDashboard +
            "downloadindex?Id=" +
            encodeURIComponent(currentCustomerObject.key) +
            "&U=" +
            encodeURIComponent(userObject.key);
          setCustomerHome(customerHomeLink);
        }
      } catch (Exception) {}
    }
  }
  const handleCustomerSelected = () => {
    CustomerSelected();
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    width: collapsed ? "100%" : "100%",

    marginLeft: collapsed ? "" : "39px", // Add a margin-left of 30% when the width is 70%
    // Add other styles if needed
  };

  return (
    <>
      <div>
        <iframe
          title="DownloadNow"
          src={customerHome}
          height="800px" 
          width="100%"
          style={sidebarStyle}
        />
      </div>
    </>
  );
};

export default DownloadHome;