import CustomerStatementsDocumentsUpload from "../CustomerFiles/CustomerStatementsDocumentsUpload";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { VixoAPI } from "../../settings";
import axios from "axios";

import Spinner from "../../Spinner";
import "./AccountDetailSpinners.css";
import PlaidComponent from "./PlaidComponent";
import { useRef } from "react";
import "../../UploadFiles.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AccountStatus from "../../VixoFormats/AccountStatus";
import { CustomerAuthentication } from "../../customerAuthentication";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { ApiProvider } from "@reduxjs/toolkit/query/react";


const CustomerBanking = ({ Customer }) => {
  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [OpenBankingData, setOpenBankingData] = useState(null);
  const [OrgName, setOrgName] = useState(null);
  const buttonRef = useRef();
  const location = useLocation();
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);
  const [FinicityLinkToken, setFinicityLinkToken] = useState(null);
  const [CustomerToken, setCustomerToken] = useState(null);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [banks, setBanks] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [openBankingCustomerID, setOpenBankingCustomerID] = useState(null);
  const [openBankingType, setOpenBankingType] = useState(null);
  const [currentOpenBankingType, setCurrentOpenBankingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nullData, setNullData] = useState(false);
  const [desktopModalVisible, setDesktopModalVisible] = useState(false);
  const [mobileModalVisible, setMobileModalVisible] = useState(false);
  const [showFilesContent, setShowFilesContent] = useState(false);
  const [showBankingContent, setShowBankingContent] = useState(true);
  const bankingTabColor = showBankingContent ? "white" : "#EAF0F6";
  const filesTabColor = showFilesContent ? "white" : "#EAF0F6";
  const [identityTabVisible, setIdentityTabVisible] = useState(true);
  const [CustomerKey, setCustomerKey] = useState(null);
  const [OrganizationMessage, setOrganizationMessage] = useState(null);

  const notification = useSelector(selectNotification);
  function GotNewAlert(notification) {
    if (
      notification != null &&
      notification.customerKey == CustomerToken &&
      notification.notificationType == 25 // Customer is Edited
    ) {
      GetCustomerByKey(notification.customerKey).then((customFromAPI) => {
        if (customFromAPI) {
          setSelectedCustomer(customFromAPI);
        }
      });
    }
  }
  async function GetCustomerByKey(CustomerKey) {
  
    try {
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getAnyCustomerBykey?key=${Key}`;

      const response = await axios.get(GetCustomerId);
      var apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        const data = apiResponse.data;
        var customersdata = JSON.parse(JSON.stringify(data));
        return customersdata;
      }
    } catch (error) {
      //setloading(false);
     
        }
     
  }

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function UpdateBankAccount(customeraccount) {
    console.log("customeraccount:", customeraccount);
    const updatedArray = bankdata.map((obj) =>
      obj.id === customeraccount.id ? { ...obj, ...customeraccount } : obj
    );

    setBankdata(updatedArray);
  }

  const ResetCustomerOpenBankingEvent = () => {
    ResetCustomerOpenBanking();
  };

  function LoadBankByCustomerKey(customerKey) {

       
    try {
      //setloading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getAnyMinimalAccountsCustomerKey?Key=${customerKey}`;
      //setloading(true);
      axios
        .get(GetCustomerBankId)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            BankData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
         
          
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
 
  }
  function BankData(cusbankdata) {
    try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  function getOrganizationById(OrganizationID) {
    
      
    try {
      const OrgAPIPath = `${VixoAPI.OrganizationAPI}getAny?ID=${OrganizationID}`;
      axios
        .get(OrgAPIPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var OrgData = JSON.parse(JSON.stringify(response.data));
            setOrgName(OrgData.name);
            setOrganizationMessage(OrgData.bankLinkInvitationText);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
  
  }

  function LoadCustomer(customerKey) {
 
      
    try {
      //setloading(true);
      const LoadCustomerAPI =
        VixoAPI.CustomerAPI + "getAnyCustomerBykey?Key=" + customerKey;
      //setloading(true);
      axios
        .get(LoadCustomerAPI)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            getOrganizationById(response.data.organizationId);

            if (response.data.identityRequired) {
              setIdentityTabVisible(true);
            } else {
              setIdentityTabVisible(false);
            }
          } else {
            navigate(`/?id=${customerKey}`);
          }
        })
        .catch((error) => {
          //setloading(false);
        
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  
  }

  function setData(Customer) {
    if (Customer != null) {
      var cachedCustomer = JSON.stringify(Customer);
      setSelectedCustomer(JSON.parse(cachedCustomer));
    }
  }

  function ReLoadCustomer() {
    var authData = CustomerAuthentication(location);
    var encodedCustomer = encodeURIComponent(authData.CustomerKey);
    setCustomerToken(authData.CustomerKey);
    setCustomerKey(encodedCustomer);
    LoadCustomer(encodedCustomer);
  }
  useEffect(() => {
    var authData = CustomerAuthentication(location);
    if (authData.CustomerKey === "") {
      navigate("/");
    } else {
      ReLoadCustomer();
    }
  }, []);
  function setError(message) {
    setErrorType(message);
  }

  var width = 400;
  var height = 650;
  var left = window.innerWidth / 2 - width / 2;
  var top = window.innerHeight / 2 - height / 2;

  async function GetFincityAccessToken() {

    try {
      setloading(true);
      const LoadFinicityAuthAPI = `${VixoAPI.FinicityAPI}getCustomerAuthLink?CustomerKey=${SelectedCustomer.key}`;
   
      axios
        .get(LoadFinicityAuthAPI)
        .then((response) => {
          var response = response.data;
          if (
            response.statusCode === 200 &&
            response.data != null &&
            response.data.oAuthLink
          ) {
            setFinicityLinkToken(response.data.oAuthLink);

            var AuthWindow =
              window.finicityConnect &&
              window.finicityConnect.launch(response.data.oAuthLink, {
                selector: "#connectContainer",
                overlay: "rgba(255,255,255, 0)",

                success: function (event) {
                  SyncCustomerBankingData();
                },
                cancel: function (event) {
                  ReturnCustomerBankingData().then((result)=>
                  {

                    var APIResponse = result.data;
                    if (APIResponse.statusCode === 200) {
                      var responseData = APIResponse.data;
                      setloading(false);
                      if (
                        responseData != null) {
                          var CustomerAccountsLength=0;
                          if(responseData.accounts)
                          {

                            CustomerAccountsLength=responseData.accounts.length;
                          }
                          var CustomerOpenBankingTypeChoice=responseData.customer.customerOpenBankingTypeChoice;
                          if(CustomerAccountsLength< 1 && (CustomerOpenBankingTypeChoice==null || CustomerOpenBankingTypeChoice==0))
                          {
                            var localPLaidOpen = plaidOpen + 1;
                            setPlaidOpen(localPLaidOpen);
                           
                          } 
                          
                      }
                 
                  }
                  }).catch((error) => {


                    alert(error);
                    console.error("Error fetching data:", error);
                  })  .finally(() => {
                    
                  });
                  
                         
                     
                },
                error: function (error) {},
                loaded: function () {},
                route: function (event) {},
                user: function (event) {},
              });

            setSuccessMessage(response.message);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setloading(false);
          throw error;
        })
        .finally(() => {
          setloading(false);
        });

      // window.addEventListener("beforeunload", function (event) {
      //   SyncCustomerBankingData();
      //   event.preventDefault();
      //   event.returnValue = "";
      // });
    } catch (error) {
      setloading(false);
      throw error;
    }
 
  }

  async function ResetCustomerOpenBanking() {

    try {
      //setloading(true);
      const LoadFinicityAuthAPI = `${VixoAPI.FinicityAPI}resetOpenBanking?CustomerKey=${SelectedCustomer.key}`;
      //setloading(true);
      axios
        .get(LoadFinicityAuthAPI)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setOpenBankingCustomerID(null);
            setOpenBankingType(null);
            setSuccessMessage(response.message);
            setCurrentOpenBankingType(null);
            setPlaidOpen(0);
            ReLoadCustomer();
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
       
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  
  }
  async function GetPlaidLinkToken() {

    try {
      setloading(true);
      const LoadPlaidCustomerLinkTokenAPI = `${VixoAPI.FinicityAPI}getAccessToken?CustomerKey=${SelectedCustomer.key}&OpenBankingType=2`;
     
      axios
        .get(LoadPlaidCustomerLinkTokenAPI)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var linkToken = response.data.link_token;
            setPlaidLinkToken(linkToken);
            setSuccessMessage(response.message);
            setOpenBankingCustomerID(response.data.request_id);
            
            LoadBankByCustomerId();
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate, SelectedCustomer.key);
            }
        })
        .finally(() => {
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      throw error;
    }
 
  }

  function ConnectCustomerToFinicity(FinicityAccessToken) {
    alert(FinicityAccessToken);
  }

  const handleContinue = async (e) => {
    e.preventDefault();
    if (
      openBankingType == null ||
      openBankingType == 0 ||
      openBankingType == 1
    ) {
      GetFincityAccessToken();
    } else {
      GetPlaidLinkToken();
    }
  };

  const handleRestart = async (e) => {
    e.preventDefault();
    ResetCustomerOpenBanking();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNullData(false);
  };

  const [plaidOpen, setPlaidOpen] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

 

  async function ReturnCustomerBankingData(){
   
    try {
      //setloading(true);
      const data = {
        BankAccounts: null,
        Insititute: null,
        CustomerKey: SelectedCustomer.key,
      };
      const LoadFinicityAuthAPI = `${VixoAPI.FinicityAPI}syncCustomerOpenBankingData`;
      //setloading(true);
      const response = axios.post(LoadFinicityAuthAPI, data);
      
        return response;
       
    } catch (error) {
      //setloading(false);
      throw error;
    }
  

  };
  const SyncCustomerBankingData = () => {

    try {
      //setloading(true);
      const data = {
        BankAccounts: null,
        Insititute: null,
        CustomerKey: SelectedCustomer.key,
      };
      const LoadFinicityAuthAPI = `${VixoAPI.FinicityAPI}syncCustomerOpenBankingData`;
      //setloading(true);
      axios
        .post(LoadFinicityAuthAPI, data)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var responseData = response.data;
            if (
              responseData != null &&
              (response.data.accounts.length > 0 ||
                response.data.customer.customerOpenBankingTypeChoice == 1)
            ) {
              setOpenBankingData(response.data);
              setPlaidOpen(0);
              setSuccessMessage(response.message);
            }
            LoadBankByCustomerId();
            setOpenBankingCustomerID(
              response.data.customer.openBankingCustomerId
            );
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          //setloading(false);
          throw error;
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  
  };

  const [bankdata, setBankdata] = useState(null);

  function LoadBankByCustomerId() {
   
     
    try {
      //setloading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getCustomerAppAccbyKey?Key=${SelectedCustomer.key}`;
      //setloading(true);
      axios
        .get(GetCustomerBankId)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setBankData(response.data);
          }
        })
        .catch((error) => {
         
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
 
  }

  function setBankData(cusbankdata) {
    try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  useEffect(() => {
    if (SelectedCustomer) {
      LoadBankByCustomerId();
    }
  }, [SelectedCustomer]);

  useEffect(() => {
    if (plaidOpen > 0) {
      setCurrentOpenBankingType(2);
    }
  }, [plaidOpen]);

  useEffect(() => {
    if (Customer && Customer != null) {
      setSelectedCustomer(Customer);
    }
  }, [Customer]);

  const handleBankSyncedFromPlaid = (dataFromChild) => {
    var authData = CustomerAuthentication(location);
    console.log("Event received from child:", dataFromChild);
    var encodedCustomer = encodeURIComponent(authData.CustomerKey);
    LoadBankByCustomerId();
  };

  useEffect(() => {
    if (currentOpenBankingType == 2 && SelectedCustomer != null) {
      GetPlaidLinkToken();
    }
  }, [currentOpenBankingType, plaidOpen]);

  const [activeTab, setActiveTab] = useState("banking");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseDesktopModal = () => {
    setDesktopModalVisible(false);
    setNullData(false);
  };

  const handleCloseMobileModal = () => {
    setMobileModalVisible(false);
    setNullData(false);
  };

  useEffect(() => {
    if (SelectedCustomer && SelectedCustomer !== null) {
      setCustomerToken(SelectedCustomer.key);
      setOpenBankingType(SelectedCustomer.openBankingType);
      setOpenBankingCustomerID(SelectedCustomer.openBankingCustomerId);
      LoadBankByCustomerId();
    }
  }, [SelectedCustomer]);

  return (
    <>
      <Spinner show={loading} Title="Customer Banking" />

      <div
        className="col-md-12 linkstext"
        style={{
          background: " linear-gradient(0deg, #fff 10%, #EAF0F6 50%)",
          padding: "45px",
          borderRadius: "50px 50px 0px 0px",
          marginTop: "-3px",
        }}
      >
        <div className="row">
          <h4
            className="linkaccountmobile"
            style={{
              fontSize: "50px",
              fontWeight: "600",
              color: "#004265",
            }}
          >
            Link Your Bank Account
          </h4>
          <p
            className="bank_accountlink_text"
            style={{
              fontSize: "28px",
              fontWeight: "400",
              color: "#004265",
            }}
          >
            <b>{OrgName ? OrgName : "Vixo Login"}</b> {OrganizationMessage}
          </p>
        </div>

        <div className="col-md-12 buttonupdate" style={{ padding: "13px" }}>
          {openBankingCustomerID != null ? (
            <>
              <button
                className="btn btn-lg"
                style={{
                  fontSize: "20px",
                  borderRadius: "20px",
                  color: "white",
                  width: "10pc",
                  background: "#00AADB",
                }}
                ref={buttonRef}
                onClick={handleContinue}
              >
                Update
              </button>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="btn btn-lg btn-restart"
                style={{
                  fontSize: "20px",
                  borderRadius: "100px",
                  color: "white",
                  backgroundColor: "#A13A7D",
                }}
                ref={buttonRef}
                onClick={handleRestart}
              >
                Restart the Process
              </button>
            </>
          ) : (
            <button
              className="btn btn-lg"
              style={{
                fontSize: "20px",
                borderRadius: "100px",
                color: "white",
                width: "10pc",
                background: "#00AADB",
              }}
              ref={buttonRef}
              onClick={handleContinue}
            >
              Continue
            </button>
          )}
        </div>

        <PlaidComponent
          linkToken={plaidLinkToken}
          CustomerKey={SelectedCustomer != null && SelectedCustomer.key}
          ReloadBanks={handleBankSyncedFromPlaid}
          SelectedCustomer={SelectedCustomer}
          ResetCustomerOpenBankingEvent={ResetCustomerOpenBankingEvent}
        />
        <div className="col-md-12 m-0 text-center">
          {successMessage ? (
            <span style={{ color: "green", fontSize: "18px" }}>
              {successMessage}
            </span>
          ) : (
            <>
              <span>{errorType ? errorType : ""}</span>
              {openBankingCustomerID == null ? (
                <span
                  style={{
                    color: "#F00",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  * Status Bank Link incomplete, Please Click{" "}
                  <a
                    href="#"
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    Continue
                  </a>
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>

      <p
        className=" w-100 fs-0"
        style={{
          marginTop: "2.5rem",
          marginBottom: "2rem",
          color: "#004265",
          fontSize: "22px",
          fontWeight: "400",
        }}
      >
        {" "}
        Your Following Bank Accounts are connected with {OrgName}
      </p>

      <div className="containerFluid cardResponsive">
        <div className="row">
          <div className="col-md-1"></div>
          <div
            className="col-md-10 d-flex"
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              placeContent: "center",
            }}
          >
            {bankdata != null &&
              bankdata.map((bank, index) => {
                return (
                  <div key={index} className="col-md-3 col-sm-12">
                    <div
                      className="card p-2 m-2"
                      style={{
                        // width: "60%",
                        backgroundColor: "#EAF0F6",
                        borderRadius: "15px",
                        border: "1px solid rgb(0, 66, 101 , 1)",
                        height: "9pc",
                      }}
                    >
                      {bank.instituteLogoUrl ? (
                        <img
                          src={bank.instituteLogoUrl}
                          alt={bank.instituteName}
                          className="card-img-top"
                          style={{ width: "35%" }}
                        />
                      ) : (
                        <h3 className="text-center">{bank.instituteName}</h3>
                      )}
                      <div className="col-md-12">
                        <AccountStatus value={bank.connectionId} />
                      </div>
                      <hr style={{ border: "1px solid #004265" }}></hr>
                      <div
                        className="card-body"
                        style={{
                          // backgroundColor: "#EAF0F6",
                          boxShadow: "none",
                          border: "none",
                          borderRadius: "15px",
                          transform: "scale(1)",
                          transition: "opacity 0.2s ease-in-out 0s",
                          padding: "0px",
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <p
                              className="card-text text-start"
                              style={{
                                color: "#004265",
                                fontSize: "18px",
                              }}
                            >
                              {bank.accountType}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p
                              className="text-end"
                              style={{
                                color: "#004265",
                                fontSize: "18px",
                              }}
                            >
                              {bank.accountNumberlastFourDigits &&
                              bank.accountNumberlastFourDigits.length === 4
                                ? `(${bank.accountNumberlastFourDigits})`
                                : bank.accountNumberlastFourDigits}
                            </p>
                          </div>
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>

      <br />

      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <p
            className="text-center mt-2 mb-3 fs-0 bankingmobile"
            style={{ fontSize: "22px", color: "#004265" }}
          >
            Already have your bank statements? Let our statement OCR feature
            makes it easy for you to get started with our software. With this
            powerful feature, you can upload your existing bank statements and
            let our OCR technology do the rest.
          </p>
        </div>
        <div className="col-md-2"></div>
      </div>

      <CustomerStatementsDocumentsUpload Customer={SelectedCustomer} />

      <div>
        <Modal show={desktopModalVisible} onHide={handleCloseDesktopModal}>
          <Modal.Header closeButton>
            <Modal.Title>Popup blocked</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Your Browser has blocked Popups. Please enable using following
              steps.
            </h5>
            <br />
            <img src="assets/images/desktop.png" style={{ width: "100%" }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDesktopModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* Mobile Modal */}
      <Modal show={mobileModalVisible} onHide={handleCloseMobileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Popup blocked</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Your Browser has blocked Popups. Please enable using following
            steps.
          </h5>
          <br />
          <img src="assets/images/mobile.webp" style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMobileModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerBanking;
