import React from 'react';
import { toast } from 'react-toastify';
import errorMessages from './ErrorMessages';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

function ErrorComponent({ ErrorMessage,ErrorType=0 }) {
  if(ErrorMessage=="")
  {

    ErrorMessage="An error occurred.";
  }
  var errorMessage = ErrorMessage; // Default message if not found
  if(ErrorType==0)
  {
  toast.error(errorMessage); // Use toast.error to show error message
  }else if(ErrorType==1)
  {

    toast.warning(errorMessage);
  }
  else if(ErrorType==2)
  {

    toast.info(errorMessage);
  }
  else if(ErrorType>2)
  {

    toast.success(errorMessage);
  }
  
  return null; // You can render an empty element since the message will be shown using toast
}

export default ErrorComponent;
