import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Components/Notification/store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
// createRoot lets you create a root to display React components inside a browser DOM node.
// Returns a reference to the first object with the specified value of the ID attribute.
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
