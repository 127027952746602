// import React, { useState, useEffect } from "react";
// import Bankcheckbox from "./Bankcheckbox";
// import ReactApexChart from "react-apexcharts";
// import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import axios from "axios";
// import { VixoAPI } from "../../settings";
// const Balance = ({ custDownload }) => {
//   const [loading, setLoading] = useState(false);
//   const [balanceData, setBalanceData] = useState(null);
//   const [showInCustomer, setShowInCustomer] = useState(true);
//   const [chartCategories, setChartCategories] = useState([
//     "Jan 23",
//     "Feb 23",
//     "Mar 23",
//     "Apr 23",
//     "May 23",
//     "Jun 23",
//     "Jul 23",
//     "Aug 23",
//     "Sep 23",
//     "Oct 23",
//     "Nov 23",
//     "Dec 23",
//   ]);

//   const [barchartData, setBarChartData] = useState({
//     series: [],
//     options: {
//       chart: {
//         type: "bar", // Change the chart type to bar
//         height: 380,
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: "50%",
//           endingShape: "rounded",
//         },
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       stroke: {
//         show: true,
//         width: 1,
//         colors: [],

//         text: "Balance",
//       },
//       xaxis: {
//         categories: chartCategories, // Add your categories if needed
//       },
//       legend: {
//         show: true,
//         position: "right", // Adjust position as needed
//         offsetY: 0,
//         height: 150,
//         onItemClick: {
//           toggleDataSeries: false,
//         },
//         onItemHover: {
//           highlightDataSeries: false,
//         },
//         formatter: function (val, opts) {
//           return opts.w.globals.seriesNames[opts.seriesIndex]; // Show series names in legend
//         },
//       },
//       yaxis: {
//         title: {
//           text: "Balance",
//         },
//       },
//       fill: {
//         opacity: 1,
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return "$" + val.toFixed(2); // Format tooltip values as needed
//           },
//         },
//       },
//     },
//   });

//   useEffect(() => {
//     if (custDownload === false) {
//       setShowInCustomer(false);
//     }
//   }, []);

//   const [totalBalance, setTotalBalance] = useState(null);
//   const [names, setNames] = useState([]);
//   const [refreshid, setreFreshId] = useState([]);
//   const [accounttitles, setAccountTitles] = useState([]);

//   const handleAccountClick = (accountIds, name) => {
//     if (accountIds != null && accountIds.length >= 0) {
//     } else {
//       clearBalance();
//       return;
//     }

//     const accountIdArray = Array.isArray(accountIds)
//       ? accountIds
//       : [accountIds];

//     try {
//       setLoading(true);
//       const GetBalance = `${VixoAPI.AccountAPI}getAccountById`;
//       setLoading(true);
//       axios
//         .post(GetBalance, accountIdArray)
//         .then((response) => {
//           var responseData = response.data;
//           if (responseData.statusCode === 200) {
//             const BalanceData = responseData.data;
//             const balancendata = JSON.parse(JSON.stringify(BalanceData));
//             setBalanceData(balancendata);
//             setreFreshId(accountIds)
//             setNames(name);
//             if (balancendata != null) {
//               const totalBalance = balancendata.reduce(
//                 (total, balance) => total + balance.balance,
//                 0
//               );
//               const formattedTotalBalance = totalBalance.toFixed(2);
//               setTotalBalance(formattedTotalBalance);
//             } else {
//               setTotalBalance(null);
//               setNames([]);
//             }

//             const chartData = [];
//             const nameData = [];
//             const accountTitlesData = [];
//             const CategoriesFromAPIs = [];
//             let colors = [];
//             if (BalanceData != null) {
//               const uniqueMonths = new Set(
//                 BalanceData.map((data) => data.month)
//               );
//               colors = Array.from(
//                 { length: uniqueMonths.size },
//                 () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
//               );
//               for (const details of BalanceData) {
//                 chartData.push(details.balance);
//                 nameData.push(details.accountTitle);
//                 accountTitlesData.push({
//                   accountTitle: details.accountTitle,
//                 });
//               }
//             }
//             setChartData({
//               series: chartData,
//               options: {
//                 chart: {
//                   width: 380,
//                   type: "bar",
//                 },
//                 labels: chartCategories,
//                 colors: colors,
//                 legend: {
//                   show: true,
//                   position: "right",
//                   offsetY: 0,
//                   height: 150,
//                   labels: {
//                     colors: "#333", // Adjust color of legend labels
//                   },
//                   markers: {
//                     width: 12,
//                     height: 12,
//                   },
//                   itemMargin: {
//                     horizontal: 5,
//                     vertical: 10,
//                   },
//                   formatter: function (val, opts) {
//                     return opts.w.globals.seriesNames[opts.seriesIndex]; // Show series names in legend
//                   },
//                 },
//                 xaxis: {
//                   categories: chartCategories,
//                   title: {
//                     text: nameData,
//                   },
//                 },
//                 yaxis: {
//                   title: {
//                     text: "Balance",
//                   },
//                 },
//                 plotOptions: {
//                   bar: {
//                     horizontal: false,
//                     columnWidth: "50%",
//                     endingShape: "rounded",
//                   },
//                 },
//                 dataLabels: {
//                   enabled: false,
//                 },
//                 stroke: {
//                   show: true,
//                   width: 1,
//                   colors: [],
//                   text: "Balance",
//                 },
//                 fill: {
//                   opacity: 1,
//                 },
//                 tooltip: {
//                   y: {
//                     formatter: function (val) {
//                       return "$" + val.toFixed(2);
//                     },
//                   },
//                 },
//               },
//             });

//             setAccountTitles(accountTitlesData);
//           } else {
//             //Toast("no Transaction of this Account");
//           }
//         })
//         .catch((error) => {
//           setLoading(false);
//           throw error;
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } catch (error) {
//       setLoading(false);
//       throw error;
//     }
//   };
//   function clearBalance() {
//     //Clear All Data Function
//     setBalanceData(null);
//     setTotalBalance(null);
//     setNames([]);
//     setChartData(null);
//   }
//   const handleRefreshBalance = () => {
//     handleAccountClick(refreshid, names);
//   };
//   return (
//     <>
//       <div className="row">
//         {showInCustomer && (
//           <Bankcheckbox handleAccountClick={handleAccountClick} />
//         )}
//       </div>
//       <div
//         className="row"
//         style={{ display: "flex", justifyContent: "flex-end" }}
//       >
//         <div className="col-md-3 mb-2">
//           <button
//             className="bg-info"
//             onClick={handleRefreshBalance}
//             style={{ fontWeight: "500", borderRadius: "20px", width: "7pc" }}
//           >
//             <FontAwesomeIcon icon={faArrowRotateRight} /> Refresh
//           </button>
//         </div>
//       </div>
//       {showInCustomer && (
//         <div class="row">
//           <br />
//         </div>
//       )}
//       <br />
//       <div class="row">Total Balance: ${totalBalance}</div>
//       <br />
//       <div class="row">
//         <div className="col-md-12">
//           <div id="chart" className="expense">
//             {balanceData && balanceData.length > 0 ? (
//               <ReactApexChart
//                 options={chartData.options}
//                 series={[{ data: chartData.series }]}
//                 type="bar"
//                 height={400}
//               />
//             ) : (
//               <h3 className="text-center fs-3" style={{ marginLeft: "3pc" }}>
//                 No data
//               </h3>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="row mt-5">
//         <div className="card">
//           <div className="card-body">
//             <div className="table-responsive">
//               <table
//                 id="example"
//                 className="table table-striped"
//                 style={{ width: "100%" }}
//               >
//                 <thead>
//                   <tr>
//                     <th>Account Type</th>
//                     <th>Balance</th>
//                     <th>Date</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {balanceData != null &&
//                     balanceData.map((balance, index) => (
//                       <tr key={index}>
//                         <td title={`Account Number: ${balance.accountNumber}`}>
//                           {" "}
//                           {names[index]} {balance.accountType}
//                         </td>
//                         <td>${Math.abs(balance.balance).toFixed(2)}</td>
//                         <td>{balance.createdDate}</td>
//                       </tr>
//                     ))}
//                   <tr style={{ fontFamily: "auto" }}>
//                     <td colSpan="1">
//                       <h3>Total Balance:</h3>
//                     </td>
//                     <td>
//                       <h3>${Math.abs(totalBalance).toFixed(2)}</h3>
//                       {/* {balanceData != null
//                   ? balanceData.reduce((total, balance) => total + balance.balance, 0)
//                   : 0} */}
//                     </td>
//                     <td> </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Balance;

import React, { useState, lazy, Suspense } from "react";
const LazyBalanceTransaction = lazy(
  () => import("../IncomeExpenseTransactions/IncomeTransaction")
);

const Balance = ({ custDownload, IsOpenedFromDownload, tabClicked }) => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyBalanceTransaction
          TransactionType={3}
          tabClicked={tabClicked}
          trngrpah={custDownload}
          IsOpenedFromDownload={IsOpenedFromDownload}
        />
      </Suspense>
    </>
  );
};

export default Balance;
