import React,{useState, useEffect} from 'react'
import Tab from './Tab'
import { Link, useNavigate } from "react-router-dom";
import { VixoAPI } from "../../settings";
import { useLocation } from 'react-router-dom';
import axios from "axios";

import { CustomerAuthentication } from '../../customerAuthentication';
import CustomerNavbar from '../Header/CustomerNavbar';
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import Spinner from "../../Spinner";
const Home = () => {
  const navigate = useNavigate();
 
const location = useLocation();
const [loading, setLoading] = useState(false);

const [customerKey, setCustomerKey] = useState();
const [userKey, setUserKey] = useState();
const [customer, setCustomer] = useState({});
const [customerBanks, setCustomerBanks] = useState([]);
const hasBanks = customerBanks!=null && customerBanks.length > 0;
const [AnyUser,SetAnyUser]=useState(null);
const[password, setPassword] = useState()
const [showPassword, setShowPassword]=useState(false);
const [userPassword,setUserPassword]=useState(null);
const [error, setError] = useState(null);
const [hasLoggedInUser, setHasLoggedInUser] = useState(false);

useEffect(()=>
{
  const params = new URLSearchParams(location.search);
  var UserID = params.get("U");
  if(!hasLoggedInUser)
  {

    getUserByKeyAny(UserID);
  }
},[hasLoggedInUser]);


  useEffect(() => {
    console.log("useEffect called");
    const params = new URLSearchParams(location.search);
    var CustomerKey = params.get("Id");
    var UserID = params.get("U");
    var HasLoggedInUserLocal=HasLoggedInUser(encodeURIComponent(UserID))
    setHasLoggedInUser(HasLoggedInUserLocal);
    if(HasLoggedInUserLocal)
    {
    if(CustomerKey!=null && UserID !=null){
      setCustomerKey(CustomerKey);
      setUserKey(UserID);
    }else{
      navigate("/");
    }

    try{
      setLoading(true);
      var AuthData= CustomerAuthentication(location);
    }catch(exception)
    {
      setError(exception);
      setLoading(false);

    }finally
    {

      setLoading(false);
    }

    if (CustomerKey != null && UserID !=null) {
      LoadBankByCustomerKey(CustomerKey);
      LoadCustomer(CustomerKey);
    }
  }
  }, [location]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function LoadBankByCustomerKey(customerKey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getMinimalAccountsCustomerKey?Key=${customerKey}`;
      setLoading(true);
      axios
        .get(GetCustomerBankId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            SetBankData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,customerKey);
  }
  }
  async function LoginUser(e)
  {
    
    try {
      const SigninAPIPath = VixoAPI.AuthenticationAPI + "login";
      setLoading(true);
      const data = {
        UserName: AnyUser.email,
        Password: password,
      };

      const response = await axios.post(SigninAPIPath, data,);
      const responseData = response.data;

      if (responseData.statusCode === 200) {
        if (responseData.data) {
          // getorganizationId(responseData.data.organizationId);
          localStorage.setItem("AuthUser", JSON.stringify(responseData.data));
          // dispatch(login(responseData.data));
          window.dispatchEvent(new Event("User"));
          const params = new URLSearchParams(location.search);
          var CustomerKey = params.get("Id");
          var UserKey = params.get("U");
          var RedirectURL= `/vixohome?Id=${CustomerKey}&U=${UserKey}`;
          navigate(RedirectURL);
          
        } else {
          setError("User could not be Loaded. Vixo Team is Working on it.");
        }
      } else {
        setError(responseData.message);
        // setIsErrorModalOpen(true);
      }
    } catch (error) {
      setError(error.message);
      // setIsErrorModalOpen(true);
    } finally {
      setLoading(false);
      // setFormSubmitted(true);
    }
 
  }

  function getUserByKeyAny(UserKey) {
    try {
      var encodedCustomer=encodeURIComponent(UserKey);
      const OrgAPIPath = `${VixoAPI.UserAPI}getAnyUserByKey?Key=${encodedCustomer}`;
      axios
        .get(OrgAPIPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            SetAnyUser(response.data);
            console.log("Userdata:", response.data)
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
        });
    } catch (error) {
      throw error;
    }
  

  }

  function LoadCustomer(CustomerKey) {
  if(HasLoggedInUser())
    {
    const config=APIHeader();
    try {

      
      setLoading(true);
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
      setLoading(true);
      axios
        .get(GetCustomerId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            SetCustomerData(response.data);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }
  function SetCustomerData(data) {
    try {
      setLoading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setCustomer(customersdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  function SetBankData(cusbankdata) {
    try {
      setLoading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setCustomerBanks(cusBankdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      LoginUser();
    }
  };
  return (
  <>
  
    {
      hasLoggedInUser==false ? (
        <>
          <Spinner show={loading}/>
          <span>{error ? error : ""}</span>
          <div className='container' style={{ marginTop: "10pc" }}>
            <div className='row'>
              <div className='col-md-2'></div>
              <div className='col-md-8 shadow p-5'>
                <h1 className="fw-bold" style={{ fontSize: "25px" }}>Login for User</h1>
                <br />
                <label className="fw-bold mb-2 mt-2">{AnyUser && AnyUser.email}</label>
                <br />
                <label className="fw-bold mb-2 mt-2">Password</label>
                <div className="d-flex align-items-center border border-black rounded">
                  <input
                    style={{
                      width: "85%",
                      padding: "4px 3px",
                      outline: "none",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    value={userPassword}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className="input-group-text text-end"
                    style={{ cursor: "pointer", marginLeft: "4pc" }}
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <span role="img" aria-label="Visible">
                        👁️
                      </span>
                    ) : (
                      <span role="img" aria-label="Hidden">
                        🙈
                      </span>
                    )}
                  </span>
                </div>
                <p className="fw-bold mb-3 text-danger text-center">
                  {error}
                </p>
                <button
                  className="btn btn-primary form-control mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    LoginUser();
                  }}
                >
                  Login
                </button>
              </div>
              <div className='col-md-2'></div>
            </div>
          </div>
        </>
      ) : (
        <Tab/>
      )
    }

  </>
  )
}

export default Home