import React from 'react';
const AccountStatus = ({ value }) => {
    // Assuming amount is a number
    
    if(value==1)
    {
      return "Pending"

    }
    else if(value==2)
    {
      return "Connected"

    }else if (value==3)
    {
      return "Disconnected"

    }
    else if (value==4)
    {
      return "Manual"

    }
    else if (value==5)
    {
      return "OCR"

    }
    else if (value==6)
    {
          return "Connection Removed"

    }
      return "";
      
  };
  
  export default AccountStatus;