import React, { useEffect, useState } from "react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTipList from "../../ToolTipsList";
const TooltipComponent = ({ Constant, Status }) => {
  const [constantName, setConstantName] = useState("");

 useEffect(() => {
   if (Status !== undefined && Status !== null) {
     setConstantName(`${Constant}_${Status}`);
   } else {
     setConstantName(Constant);
   }
 }, [Constant, Status]);

  
  return (
    <div className="tooltip-container d-flex justify-content-end">
      {constantName && (
        <OverlayTrigger
          placement="top"
          overlay={
            <BootstrapTooltip id="tooltip" >
              <center><span style={{fontSize:"13px"}}>{ToolTipList[constantName]}</span></center>
            </BootstrapTooltip>
          }
        >
          <span style={{opacity: "0.5",marginBottom: "-4px"}}> 
            <FontAwesomeIcon icon={faCircleInfo} />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default TooltipComponent;
