// store.js
import { configureStore } from '@reduxjs/toolkit';
import sendNotificationSlice from '../Slices/sendNotificationSlice';

const store = configureStore({
  reducer: {
    notification: sendNotificationSlice
    // Add other reducers here if needed
  },
});

export default store;