import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./TermsConditions.css";
import { VixoAPI } from "../../settings";
import axios from "axios";
import Spinner from "../../Spinner";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { useNavigate } from "react-router-dom";


const TermsConditions = ({ Customer, SingedContract }) => {
  const [signature, setSignature] = useState("");
  const [organizationConsent, setOrganziationConsent] = useState("");
  const [IsSubmitButtonDisable, setIsSubmitButtonDisable] = useState(false);
  const [SignatureDidNotMatchMessage, setSignatureDidNotMatchMessage] =
    useState("");
  const [contractId, setContractId] = useState(false);
  const [orgranizationConsent, setOrganizationConsent] = useState(false);
  const [isCloseBtnDisabled, setIsCloseBtnDisabled] = useState(true);
  const [IsSignatureButtonEnable, SetSignatureButtonEnable] = useState(false);
  const [showmodal, setShowModal] = useState(true);
  const [ip, setIp] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate= useNavigate();
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);
  function fetchContractData() {
    if (!Customer) return;
    const OrgAPIPath = `${VixoAPI.OrganizationAPI}getOrganizationContracts?ID=${Customer.organizationId}`;
    // setLoading(true);
    axios
      .get(OrgAPIPath)
      .then((response) => {
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setOrganizationConsent(responseData.data.contract);
          setContractId(responseData.data.id);
          // toast.success(responseData.message);
        } else {
          // toast.error(responseData.message);
        }
      })
      .catch((error) => {
        // toast.error(error.message);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  useEffect(() => {
    if (Customer) {
      GetOrganizationContract(Customer.organizationId);
      fetchContractData();
    }
  }, [Customer]);

  function GetOrganizationContract(OrgranizationID) {
    // Call GetOrganizationContract and set it to the following state.
    // organizationConsent
  }

  function CustomerSignatureName() {
    return Customer && Customer.firstName + " " + Customer.lastName;
  }
  const handleSignature = (e) => {
    const newSignature = e.target.value;
    setSignature(newSignature);
    if (
      newSignature &&
      newSignature.length > 0 &&
      newSignature !== CustomerSignatureName()
    ) {
      setSignatureDidNotMatchMessage(
        "Your Signature does not Match with Your Name"
      );
    } else {
      setSignatureDidNotMatchMessage("");
      SetSignatureButtonEnable(true);
    }
  };
  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };
 
  const handleSignatureChange = async() => {
    if (signature !== CustomerSignatureName()) {
      setSignatureDidNotMatchMessage("");
      SetSignatureButtonEnable(false);
    } else {
      setSignatureDidNotMatchMessage("");
      SetSignatureButtonEnable(true);
      
      
      const data = {
        ipAddress: ip,
        signedName: signature,
        customerKey: Customer.key,
      };

      if(HasLoggedInUser())
      {
        const config= APIHeader();
      const OrgAPIPath = `${VixoAPI.CustomerAPI}CustomerSignedContract`;
      // setLoading(true);
      axios
        .post(OrgAPIPath, data,{headers:config.headers})
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            setShowModal(false);
            SingedContract(true);
          } else {
            setSignatureDidNotMatchMessage(responseData.message);
          }
        })
        .catch((error) => {
          // toast.error(error.message);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
              }
            })
            .finally(() => {
              // setLoading(false);
            });
          }
      else{
      ExpireToken(navigate);
    }
  }
  };

  return (
    <>
      <Spinner show={loading} className="spinner-mobile" />
      {showmodal&& (
      <Modal
        size="lg"
        show={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Consent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row w-100">
              <div className="col-md-12">
                <div className="">
                  <div className="card-body text-start">
                    <div
                      dangerouslySetInnerHTML={renderHTML(orgranizationConsent)}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      textAlign: "justify",
                    }}
                  >
                    By signing my eSignature below, I affirm that I am
                    authorized to enter into this contract of Work on behalf of
                    The Company. Furthermore, I acknowledge that I have read,
                    understand, and agree to Vixo Link Terms and Conditions. I
                    also acknowledge that my eSignature is the electronic
                    equivalent of a handwritten signature.
                  </p>
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="signature"
                      className="form-label"
                      style={{ fontWeight: "bold" }}
                    >
                      Signature: {CustomerSignatureName()}
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="signature-field form-control"
                        id="signature"
                        placeholder="Signature Here"
                        value={signature}
                        onChange={handleSignature}
                        style={{
                          width: "200px",
                          borderRadius: "5px",
                          marginLeft: "10px",
                        }} // Adjust width as needed
                      />
                      <button
                        disabled={!IsSignatureButtonEnable}
                        type="button"
                        className="btn btn-primary"
                        style={{
                          marginLeft: "10px",
                          padding: "5px 15px",
                          borderRadius: "5px",
                        }}
                        onClick={handleSignatureChange}
                        // disabled={IsSubmitButtonDisable}
                      >
                        Signature
                      </button>
                    </div>
                    <div
                      className="form-text"
                      style={{ marginTop: "10px", textAlign: "justify" }}
                    >
                      To agree, please electronically sign this document by
                      typing your name exactly as it appears below and click
                      "Confirm Signature"
                    </div>
                    {SignatureDidNotMatchMessage && (
                      <div className="alert alert-danger mt-2">
                        {SignatureDidNotMatchMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      )}
    </>
  );
};

export default TermsConditions;
