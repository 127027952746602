import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import ErrorComponent from "../../ErrorComponent";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings"; 
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentFund from "./PaymentFund";
import "./Payment2.css";
import PhoneNumber from "../../VixoFormats/Phone";
import DateComponent from "../../VixoFormats/Date";
import Money from "../../VixoFormats/Money";
import DollarSign from "../../VixoFormats/DollarSign";
const PaymentTabTwo = ({
  custDownload,
  customer,
  selectedAccount,
  AccountID,
  forDownload,
  accountUpdated,
  selectedAccountDetails,
}) => {
  const [loanPurpose, setloanPurpose] = useState("");

  const [connected, setConnected] = useState(false);
  const [loanTerm, setLoanTerm] = useState(0);
  const [cusAccount, setAccountNumber] = useState(0);

  const [interestRate, setInterestRate] = useState(0);
  const [debtCancellation, setDebtCancellation] = useState(0);

  const [showPaymentFundModal, setShowPaymentFundModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [customerFundingDetail, setCustomerFundingDetail] = useState("");
  const [requestamount, setRequestAmount] = useState("");
  const [PaymentRequestDate, setPaymentRequestDate] = useState("");

  const [showInCustomer, setShowInCustomer] = useState(true);
  const [CustomerKey, setCustomerKey] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [bankdata, setBankdata] = useState(null);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [lastFundedAccountNumber, setLastFundedAccountNumber] = useState(null);
  const navigate =useNavigate();

  const handlePaymentFundModal = () => {
    setShowPaymentFundModal(!showPaymentFundModal);
  };
  const handleRangeClick = (newValue, setValue) => {
    const parsedValue = parseFloat(newValue);
    setValue(parsedValue);
  };

  const paymentFundProps = {
    loanPurpose,
  };
  const location = useLocation();
  useEffect(() => {
    if (custDownload != null) {
      setShowInCustomer(false);
    }

    const params = new URLSearchParams(location.search);
    var CustomerId = params.get("Id");
    var UserID = params.get("U");

    if (CustomerId) {
      setCustomerKey(JSON.stringify(CustomerId));
    }
    if (UserID) {
      setUserKey(JSON.stringify(UserID));
    }
    if (CustomerId != null && UserID != null) {
      LoadBankByCustomerKey(CustomerId);
      LoadCustomer(CustomerId);
      LoadCustomerFundingDetail(CustomerId);
    }
    if (bankdata != null) {
      // Automatically select the first bank if available
      if (bankdata.length > 0) {
        handleAccountClick(bankdata[0]);
        setClickedCardIndex(0);
      }
    }
  }, [location]);

  function LoadBankByCustomerKey(customerKey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      //setloading(true);
      //var Id = encodeURIComponent(25);

      const GetCustomerBankId = `${VixoAPI.AccountAPI}getbyCustomerKey?Key=${customerKey}`;
      //setloading(true);
      axios
        .get(GetCustomerBankId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            BankData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          //setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }
  function BankData(cusbankdata) {
    try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  function LoadCustomer(CustomerKey) {
    if(HasLoggedInUser()){
      const config= APIHeader();
    try {
      //setloading(true);
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
      //setloading(true);
      axios
        .get(GetCustomerId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            CusData(response.data);
          } else {
            // setError(response.message);
          }
        })
        .catch((error) => {
          //setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }
  function CusData(data) {
    try {
      //setloading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setCustomers(customersdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  const [customer2, setCustomers] = useState({});
  function LoadBankByCustomerId(CustomerKey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      //setloading(true);
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
      //setloading(true);
      axios
        .get(GetCustomerId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setCusData(response.data);
          } else {
            // setError(response.message);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  function setCusData(data) {
    try {
      //setloading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setCustomers(customersdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  async function LoadCustomerFundingDetail(customerKey) {
    if(HasLoggedInUser()){
      var config=APIHeader();
    try {
      var encodedKey = encodeURIComponent(customerKey);
      const GetCustomerFundingAPIPath =
        VixoAPI.FundingAPI + "GetCustomerFunding?CustomerKey=" + encodedKey;
      //setloading(true);
      const response = await axios.get(GetCustomerFundingAPIPath, {headers:config.headers});
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        setCustomerFundingData(responseData.data);
        setLastFundedAccountNumber(responseData.data.accountNumber);
      } else {
        setErrorType(responseData.message);
      }
    } catch (error) {
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate,encodedKey);
        }
    } finally {
      //setloading(false);
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }
  const handleLoanPurposeChange = (e) => {
    setloanPurpose(e.target.value); 
  };

  const handleLoanPurposeSubmit = (e) => {
    e.preventDefault(); 
    setShowPaymentFundModal(true); 
  };

  function setCustomerFundingData(data) {
    try {
      //setloading(true);
      var customerFundingRequest = JSON.parse(JSON.stringify(data));
      setCustomerFundingDetail(customerFundingRequest);
      setRequestAmount(
        customerFundingRequest.customerFundingRequest.toFixed(2)
      );
      if (customerFundingRequest.amount != null) {
        setLoanTerm(customerFundingRequest.amount.toFixed(2));
      } else {
        setLoanTerm(0);
      }
      if (customerFundingRequest.paymentRequestDate != null) {
        setPaymentRequestDate(customerFundingRequest?.paymentRequestDate);
      } else {
        setPaymentRequestDate(0);
      }
      if (customerFundingRequest.interest != null) {
        setInterestRate(customerFundingRequest.interest.toFixed(2));
      } else {
        setInterestRate(0);
      }
      if (customerFundingRequest.terms != null) {
        setDebtCancellation(customerFundingRequest.terms);
      } else {
        setDebtCancellation(0);
      }
      if (customerFundingRequest.purpose != null) {
        setloanPurpose(customerFundingRequest.purpose);
      } else {
        setloanPurpose("");
      }

      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  const handleButtonClick = () => {
    setConnected(!connected);
  };
  
  const handleButtonRefresh = () => {
    var Key = CustomerKey;
    Key  = Key.replace(/"/g,  "");
    LoadCustomerFundingDetail(Key);
  };

  const handleInputChange = (e, setValue) => {
    const inputValue = e.target.value;
    const numbersOnly = inputValue.replace(/[^0-9.]/g, ""); 
    setValue(numbersOnly);
    
  };
  const handleInterestInputChange = (e) => {
    const inputValue = e.target.value;
    const numbersOnly = inputValue.replace(/[^0-9.]/g, ""); 
    setInterestRate(numbersOnly);
    if (parseInt(inputValue) > 100) {
      setInterestRate(100);
    } else {
      setInterestRate(inputValue);
    }
  };
  const [selectedAccount2, setSelectedAccount2] = useState(null);

  const handleAccountClick = (bankdata) => {
    // Handle the selected account logic here
    var accountNumber = bankdata.accountNumber;
    setSelectedAccount2(accountNumber);
    // You can make an API call or perform any other necessary actions with the accountId here.
  };

  function formatAmount(amount) {
    return amount.toFixed(2); // Format to two decimal places
  }

  return (
    <>
        <Spinner show={loading} Title="Customer Payment 2" />

      <div className="containerFluid AccountInfoMediaContainer ">
        <div className="row AccountInfoMediaRow">
          {/* <div className="col-md-3">
            <Sidebar2
              connected={selectedAccount ? selectedAccount.connected : false}
              handleAccountClick={setSelectedAccount}
            />
          </div> */}
          <div
            className="col-md-4 col-sm-4 shadow AccountInfoMediaOne paymentcard"
            style={{
              border: "1px solid #DFD1D1",
              borderRight: "none",
              backgroundColor: "white",
              marginLeft: "-5px",
              height: "35pc",
            }}
          >
            <div className="row MediaOneRow">
              <div
                className="col-md-12"
                style={{
                  border: "0.5px solid rgb(190, 205, 219)",
                  background: "rgb(217, 230, 243)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "2pc",
                  color: "rgb(0, 66, 101 , 1)",
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlignLast: "center",
                }}
              >
                Customer
              </div>
            </div>
            <br />
            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "rgb(0, 66, 101 , 1)" }}
                >
                  Name
                </span>
                <br />
                <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                  {customer ? customer.name : ""}
                </span>
              </div>
            </div>
            <div className="row MediaOneRow mt-2">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "rgb(0, 66, 101 , 1)" }}
                >
                  Email
                </span>
                <br />
                <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                  {customer ? customer.email : ""}
                </span>
              </div>
            </div>
            <div className="row MediaOneRow mt-2"></div>

            <div className="row MediaOneRow ">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "rgb(0, 66, 101 , 1)" }}
                >
                  Phone
                </span>
                <br />
                <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                  {customer ? (
                    // customer.phone
                    <PhoneNumber number={customer.phone} />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="row MediaOneRow mt-2">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "rgb(0, 66, 101 , 1)" }}
                >
                  Created Date
                </span>
                <br />
                <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                  {customer ? (
                    <DateComponent value={customer.createDate} />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            {lastFundedAccountNumber && (
              <div className="row MediaOneRow mt-2">
                <div className="col-md-12">
                  <span
                    className="fw-bold"
                    style={{ color: "rgb(0, 66, 101 , 1)" }}
                  >
                    Last Funded Account Number
                  </span>
                  <br />
                  <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                    {lastFundedAccountNumber}
                  </span>
                </div>
              </div>
            )}

            {selectedAccountDetails && (
              <div className="row MediaOneRow mt-2">
                <div className="col-md-12">
                  <span
                    className="fw-bold"
                    style={{ color: "rgb(0, 66, 101 , 1)" }}
                  >
                    Account Number
                  </span>
                  <br />
                  <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                    {selectedAccountDetails.accountNumber}
                  </span>
                </div>
                <div className="col-md-12 mt-2">
                  <span
                    className="fw-bold"
                    style={{ color: "rgb(0, 66, 101 , 1)" }}
                  >
                    Account Type
                  </span>
                  <br />
                  <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                    {selectedAccountDetails &&
                      selectedAccountDetails.accountTitle}
                  </span>
                </div>
                <div className="col-md-12 mt-2">
                  <span
                    className="fw-bold"
                    style={{ color: "rgb(0, 66, 101 , 1)" }}
                  >
                    Balance
                  </span>
                  <br />
                  <span style={{ color: "rgb(0, 66, 101 , 1)" }}>
                    {" "}
                    {selectedAccountDetails &&
                    selectedAccountDetails.balance != null ? (
                      <Money value={selectedAccountDetails.balance} />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                {/* Add more details of the selected account here as needed */}
              </div>
            )}
          </div>

          <div
            className="col-md-8 col-sm-8 shadow AccountInfoMediaTwo paymentcard"
            style={{
              border: "1px solid #DFD1D1",
              backgroundColor: "white",
              marginLeft: "5px",
            }}
          >
            <div className="bank-section">
              <div className="account-info">
                <div className="row">
                  <div
                    className="col-md-12 d-flex"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      border: "0.5px solid rgb(190, 205, 219)",
                      background: "rgb(217, 230, 244)",
                      height: "6pc",
                    }}
                  >
                    <div className="col-md-6">
                      <div>
                        <h5
                          className="mt-2"
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "rgb(0, 66, 101 , 1)",
                            padding: "25px",
                          }}
                        >
                          Funding
                        </h5>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ textAlign: "end" }}>
                      <Button
                        variant="primary"
                        className="mt-4"
                        onClick={handleButtonRefresh}
                        style={{ borderRadius: "20px", width: "7pc" }}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faSync}
                          style={{ marginRight: "5px" }}
                        />
                        Refresh
                      </Button>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="container">
                      <br />

                      <p
                        className="fw-bold"
                        style={{ color: "rgb(0, 66, 101 , 1)" }}
                      >
                        {" "}
                        Amount Requested
                      </p>
                      <div className="row">
                        <div className="col-md-12" style={{ display: "flex" }}>
                          <div className="input-group">
                            <span className=" input-group-text fs-5">
                              <DollarSign />
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={requestamount}
                              placeholder="Amount Requested"
                              onChange={(e) =>
                                handleInputChange(e, setRequestAmount)
                              }
                              required
                            />
                          </div>
                        </div>

                        <span hidden>
                          {selectedAccountDetails
                            ? selectedAccountDetails.accountNumber
                            : ""}
                        </span>
                      </div>
                      <p
                        className="fw-bold mt-2"
                        style={{ color: "rgb(0, 66, 101 , 1)" }}
                      >
                        Amount Granted
                      </p>
                      <div className="row">
                        <div className="col-md-8" style={{ display: "flex" }}>
                          <div className="input-group">
                            <span className=" input-group-text fs-5">
                              <DollarSign />
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              value={loanTerm}
                              min="0"
                              max="1000000"
                              onChange={(e) =>
                                handleInputChange(e, setLoanTerm)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="range"
                            min="0"
                            max="10000"
                            step="1"
                            value={loanTerm || 0}
                            onChange={(e) =>
                              setLoanTerm(parseFloat(e.target.value))
                            }

                            // onClick={() => handleRangeClick(loanTerm + 1, setLoanTerm)}
                          />
                          {/* <input
                            type="range"
                            value={selectedAccount?selectedAccount.accountNumber:''}
                            onChange={(e) =>
                              setAccountNumber(e.target.value)
                            }
                            /> */}
                        </div>
                      </div>
                      <p
                        className="fw-bold mt-2 mb-2"
                        style={{ color: "rgb(0, 66, 101 , 1)" }}
                      >
                        Interest Rate
                      </p>
                      <div className="row">
                        <div className="col-md-8" style={{ display: "flex" }}>
                          <div className="input-group">
                            <span className=" input-group-text fs-5">%</span>
                            <input
                              type="number"
                              className="form-control"
                              value={interestRate}
                              onChange={handleInterestInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="range"
                            value={interestRate || 0}
                            min="0"
                            max="100"
                            step="1"
                            onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                          />
                        </div>
                      </div>
                      <p
                        className="fw-bold mt-2"
                        style={{ color: "rgb(0, 66, 101 , 1)" }}
                      >
                        Loan Term{" "}
                      </p>
                      <div className="row">
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            value={debtCancellation}
                            onChange={(e) =>
                              handleInputChange(e, setDebtCancellation)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="range"
                            value={debtCancellation || 0}
                            min="0"
                            max="10000"
                            step="1"
                            onChange={(e) =>
                              setDebtCancellation(parseFloat(e.target.value))
                            }
                          />
                        </div>
                      </div>
                      <p
                        className="fw-bold mt-2"
                        style={{ color: "rgb(0, 66, 101 , 1)" }}
                      >
                        Loan Purpose
                      </p>
                      <div className="row">
                        <div className="col-md-12">
                          <textarea
                            rows={3}
                            type="text"
                            name="loanPurpose"
                            value={loanPurpose}
                            className="form-control"
                            placeholder="Loan Purpose"
                            onChange={handleLoanPurposeChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-center mb-2">
                  <div className="col-md-12 col-sm-12 ">
                    <button
                      className="btn btn-info w-25 mt-2 payment-fund"
                      id="paymentFundBtn"
                      onClick={handlePaymentFundModal}
                      style={{
                        borderRadius: "20px",
                        color: "white",
                        fontWeight: "500",
                      }}
                      disabled={!customer || !selectedAccountDetails}
                    >
                      FUND
                    </button>
                  </div>
                  <PaymentFund
                    showModal={showPaymentFundModal}
                    handleModalClose={handlePaymentFundModal}
                    loanTerm={loanTerm}
                    cusAccount={cusAccount}
                    interestRate={interestRate}
                    debtCancellation={debtCancellation}
                    loanPurpose={loanPurpose}
                    customerFundingDetail={customerFundingDetail.id}
                    customer={customer}
                    selectedAccount={selectedAccount}
                    selectedAccountDetails={selectedAccountDetails}
                    PaymentRequestDate={PaymentRequestDate}
                    requestamount={requestamount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentTabTwo;
