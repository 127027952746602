import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import TopnavBar2 from "../Header/CustomerNavbar";
import { CustomerAuthentication } from "../../customerAuthentication";
import { VixoAPI } from "../../settings";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./Customer_Identity.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../Spinner";
import PhoneNumber from "../../VixoFormats/Phone";
import DateComponent from "../../VixoFormats/Date";
import APIErrorComponent from "../../APIErrorComponent";

const CustomerIdentityVerfication = () => {
  const location = useLocation();
  const [APIResponse, setAPIResponse] = useState(null);

  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [CustomerKey, setCustomerKey] = useState(null);
  const [loginCustomerKey, setLoginCustomerKey] = useState("");
  const [isIdentityVerified, setIsIdentityVerified] = useState([]);
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [identityRequired, setIdentityRequired] = useState(true);
  const [kyc, setKyc] = useState(true);
  const [
    customerIdentityResponseData,
    setCustomerIdentityResponseData,
  ] = useState(null);
  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    inviteEmail: "",
    invitePhone: "",
  });

  useEffect(() => {
    if (userId) {
      LoadUser();
    }
  }, [userId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var userKey = params.get("U");

    if (userKey != null) {
      setUserId(userKey);
    } else {
    }
  }, []);

  function setUserDataFromAPI(data) {
    try {
      // setLoading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  function LoadUser() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(userId);
        const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
        // setLoading(true);
        axios
          .get(GetUserByKeyAPI, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setUserDataFromAPI(response.data);
            } else {
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var CustomerKey = params.get("Id");
    if (CustomerKey) {
      LoadCustomer(CustomerKey);
    }
  }, [location]);

  const [buttonState, setButtonState] = useState(-1);

  useEffect(() => {
    if (SelectedCustomer != null) {
      const result = ShouldShowButtons();
      setButtonState(result);
    }
  }, [SelectedCustomer, buttonState]);

  useEffect(() => {
    // TODO Adnan Test Refresh
    if (
      SelectedCustomer !== null &&
      SelectedCustomer.identityRequired == true &&
      customerIdentityResponseData == null
    ) {
      PlaidIdentityVerifiedCustomer(CustomerKey);
    }
  }, [SelectedCustomer]);

  function ShouldShowButtons() {
    if (SelectedCustomer == null) {
      return -1;
    } else if (
      (SelectedCustomer.identityRequired == true &&
        SelectedCustomer.idVerificationProcessStatus == 1) ||
      SelectedCustomer.idVerificationProcessStatus == 2
    ) {
      return 2;
    } else if (
      SelectedCustomer.identityRequired == null ||
      (SelectedCustomer.identityRequired == false &&
        SelectedCustomer.idVerificationProcessStatus == 3)
    ) {
      return 3;
    } else if (
      SelectedCustomer.identityRequired == true &&
      SelectedCustomer.customerIdentityId !== null &&
      SelectedCustomer.idVerificationProcessStatus == 4
    ) {
      return 4;
    } else {
    }
  }

  function LoadCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${CustomerKey}`;
        // setLoading(true);
        axios
          .get(GetCustomerId, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var customersdata = JSON.parse(JSON.stringify(response.data));
              setSelectedCustomer(customersdata);
            } else {
              // setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  function IdentityVerificationRequest() {
    if (HasLoggedInUser()) {
      try {
        // setLoading(true);
        const request = {
          customerKey: SelectedCustomer.key,
          userKey: User.key,
        };

        const PlaidIdentificationRequest = `${VixoAPI.FinicityAPI}createIdentityVerificationRequest`;
        setLoading(true);
        axios
          .post(PlaidIdentificationRequest, request, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              setCustomerIdentityResponseData(response.data);
              //error remove after Identity verification successed..
              setError("");
            } else if (response) {
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  function PlaidIdentityVerifiedCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        const PlaidIdentifiedCustomerData = `${VixoAPI.FinicityAPI}getCustomerIdentityDetail?CustomerKey=${SelectedCustomer.key}`;
        // setLoading(true);
        axios
          .get(PlaidIdentifiedCustomerData, { headers: APIHeader().headers })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setCustomerIdentityResponseData(responseData.data);
              console.log(responseData); // Use responseData directly here
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  function PlaidIdentityVerifiedCustomerOnRefresh() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        const PlaidIdentifiedCustomerDataOnRefresh = `${VixoAPI.FinicityAPI}getCustomerIdentityVerificationDetailFromPlaid?CustomerKey=${SelectedCustomer.key}`;
        setLoading(true);
        axios
          .get(PlaidIdentifiedCustomerDataOnRefresh, {
            headers: config.headers,
          })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setCustomerIdentityResponseData(responseData.data);
              setError("");
              console.log(responseData); // Use responseData directly here
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <Spinner show={loading} Title="Customer Identity Verfication" />

        <ToastContainer />
        <TopnavBar2 />
        <APIErrorComponent APIResponse={APIResponse} />

        <div className="card" style={{ marginTop: "1rem" }}>
          <div className="text-end ">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}
            >
              <button
                className="btn btn-info mt-3"
                style={{
                  borderRadius: "20px",
                  fontWeight: "500",
                  width: "7pc",
                  color: "white",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  PlaidIdentityVerifiedCustomerOnRefresh();
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faArrowRotateRight} /> Refresh
              </button>
            </OverlayTrigger>
          </div>
          {error && (
            <div className="alert alert-danger" style={{ color: "red" }}>
              {error}
            </div>
          )}
          <div className="row" style={{ marginTop: "1rem" }}>
            {error && <h3 style={{ textAlign: "center" }}>{error}</h3>}
            <div className="col-lg-2"></div>
            {
              <>
                {buttonState === 3 && (
                  <div className="col-lg-8 d-flex-col align-items-center mt-3">
                    <p
                      style={{
                        marginRight: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Please press below button to Start Customer's
                      Identification Process with Plaid
                    </p>
                    <center>
                      <button
                        id="myButton"
                        type="button"
                        className="btn btn-lg"
                        onClick={IdentityVerificationRequest}
                        style={{
                          borderRadius: "20px",
                          fontWeight: "500",
                          color: "white",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                          backgroundColor: "rgb(0, 170, 219)",
                        }}
                      >
                        Request Customer Identity Verification
                      </button>
                    </center>
                  </div>
                )}

                {buttonState === 2 && (
                  <div className="col-lg-8 d-flex-col align-items-center">
                    <p
                      style={{
                        marginRight: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Identity Verification Request was already Sent to
                      Customer.
                    </p>
                    <center>
                      <button
                        id="myButton"
                        type="button"
                        className="btn btn-info custom-button"
                        onClick={IdentityVerificationRequest}
                        style={{
                          borderRadius: "20px",
                          fontWeight: "500",
                          color: "white",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                        }}
                      >
                        Restart Customer Identification Process
                      </button>
                    </center>
                  </div>
                )}

                {buttonState === 4 && (
                  <div className="col-lg-8 d-flex-col align-items-center">
                    <p
                      style={{
                        marginRight: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Identity Verification Request was completed.
                    </p>
                    <center>
                      <button
                        id="myButton"
                        type="button"
                        className="btn btn-info custom-button"
                        onClick={IdentityVerificationRequest}
                        style={{
                          borderRadius: "20px",
                          fontWeight: "500",
                          color: "white",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                        }}
                        disabled
                      >
                        Completed Customer Identification Process
                      </button>
                    </center>
                  </div>
                )}
              </>
            }

            {/* <div className="row mt-10">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-6 align-items-center" style={{marginTop: "2rem"}}>
                                <h1 style={{ marginRight: '10px', fontSize: '20px', lineHeight: '3rem'}}>Is Name Verified</h1>
                                <h1 style={{ marginRight: '10px', fontSize: '20px', lineHeight: '3rem' }}>Is Phone Number Verified</h1>
                                <h1 style={{ marginRight: '10px', fontSize: '20px', lineHeight: '3rem' }}>Is Email Address Verified</h1>
                                <h1 style={{ marginRight: '10px', fontSize: '20px', lineHeight: '3rem' }}>Is Phone Address Verified</h1>
                                <h1 style={{ marginRight: '10px', fontSize: '20px', lineHeight: '3rem' }}>Is ID Verified</h1>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {customerIdentityResponseData && (
        <>
          <div className="containerFluid p-3">
            <div className="card">
              <div className="card-header  table-header">
                <div class="">
                  <div>
                    <h4
                      className="table_header_heading"
                      style={{ color: "white !important" }}
                    >
                      Customer Detail
                    </h4>
                  </div>
                </div>
              </div>

              <div className=" mt-20">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="table_Customer_heading">
                          Customer Name
                        </th>
                        <th className="table_Customer_heading">Email</th>
                        <th className="table_Customer_heading">DOB</th>
                        <th className="table_Customer_heading">ID Number</th>
                        <th className="table_Customer_heading">ID Type</th>
                        <th className="table_Customer_heading">Phone Number</th>
                        <th className="table_Customer_heading">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_Customer_list">
                          {/* {customerIdentityResponseData?.vrfIdentityUsers?.map(user => (
                                                        
                                                      user.givenName!=null? user.givenName:"" + " " + user.familyName!=null ? user.familyName: ""
                                                    ))} */}
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) =>
                              `${
                                user.givenName != null ? user.givenName : ""
                              } ${
                                user.familyName != null ? user.familyName : ""
                              }`
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) => user.emailAddress
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) => (
                              <DateComponent value={user.dateOfBirth} />
                            )
                          )}{" "}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.rfIdentityUsers?.map(
                            (user) => user.idNumberValue
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) => user.idNumberType
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {/* {customerIdentityResponseData?.vrfIdentityUsers?.map(user => (
                                                      <PhoneNumber number={ user.phoneNumber} />
                                                      
                                                     


                                                    ))}{" "} */}
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) => (
                              <PhoneNumber number={user.phoneNumber} />
                            )
                          )}{" "}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) =>
                              (user.street != null ? user.street : "") +
                              (user.street2 != null ? " " + user.street2 : "") +
                              (user.city != null ? " " + user.city : "") +
                              (user.country != null ? " " + user.country : "") +
                              (user.postalCode != null
                                ? " " + user.postalCode
                                : "")
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="containerFluid p-3">
            <div className="card">
              <div className="card-header table-header">
                <div class="">
                  <div>
                    <h4 className="table_header_heading">
                      Document Verification
                    </h4>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="table_Customer_heading">Category</th>
                        <th className="table_Customer_heading">DOB</th>
                        <th className="table_Customer_heading">ID Number</th>
                        <th className="table_Customer_heading">
                          Expiration Date
                        </th>
                        <th className="table_Customer_heading">
                          Issuing Country
                        </th>
                        <th className="table_Customer_heading">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfDocuVerifications?.map(
                            (doc) =>
                              doc.category != null
                                ? doc.category?.toUpperCase().replace("_", " ")
                                : " "
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfDocuVerifications?.map(
                            (doc) => (
                              <DateComponent value={doc.dateOfBirth} />
                            )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfDocuVerifications?.map(
                            (doc) => doc.idNumber
                          )}{" "}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfDocuVerifications?.map(
                            (doc) => (
                              <DateComponent value={doc.expirationDate} />
                            )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfDocuVerifications?.map(
                            (doc) => doc.issuingCountry
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentityUsers?.map(
                            (user) =>
                              (user.street != null ? user.street : "") +
                              (user.street2 != null ? " " + user.street2 : "") +
                              (user.city != null ? " " + user.city : "") +
                              (user.country != null ? " " + user.country : "") +
                              (user.postalCode != null
                                ? " " + user.postalCode
                                : "")
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-body py-3 bg-transparent">
                <div className="row">
                  {customerIdentityResponseData?.vrfDocuVerifications?.map(
                    (doc, index) => (
                      <div key={index} className="col-md-4 mb-3 card shadow">
                        <img
                          src={
                            doc.croppedFront != null
                              ? doc.croppedFront
                              : doc.originalFront
                          }
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "center",
                            padding: "1pc",
                          }}
                          alt={`Customer-frontside-Document-${index}`}
                        />
                      </div>
                    )
                  )}
                  {customerIdentityResponseData?.vrfDocuVerifications?.map(
                    (doc, index) => (
                      <div key={index} className="col-md-4 mb-3 card shadow">
                        <img
                          src={
                            doc.croppedBack != null
                              ? doc.croppedBack
                              : doc.originalBack
                          }
                          style={{
                            width: "100%",
                            height: "auto",
                            padding: "1pc",
                          }}
                          alt={`Customer-backside-Document-${index}`}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="containerFluid p-3">
            <div className="card">
              <div className="card-header table-header">
                <div class="">
                  <div>
                    <h4 className="table_header_heading">Steps Verification</h4>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="table_Customer_heading">Accept TOS</th>
                        <th className="table_Customer_heading">Verify SMS</th>
                        <th className="table_Customer_heading">KYC Check</th>
                        <th className="table_Customer_heading">
                          Document Verification
                        </th>
                        <th className="table_Customer_heading">Selfie Check</th>
                        <th className="table_Customer_heading">Risk Check</th>
                        <th className="table_Customer_heading">
                          Watch List Screening
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) =>
                              step.acceptTos != null
                                ? step.acceptTos.toUpperCase()
                                : ""
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) =>
                              step.verifySms != null
                                ? step.verifySms.toUpperCase()
                                : ""
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) =>
                              step.kyccheck != null
                                ? step.kyccheck.toUpperCase()
                                : ""
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) =>
                              step.documentaryVerification != null
                                ? step.documentaryVerification.toUpperCase()
                                : ""
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) =>
                              step.selfieCheck != null
                                ? step.selfieCheck.toUpperCase()
                                : ""
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) => step.riskCheck.toUpperCase()
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfIdentitySteps?.map(
                            (step) => step.watchlistScreening.toUpperCase()
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="containerFluid p-3">
            <div className="card">
              <div className="card-header table-header">
                <div class="">
                  <div>
                    <h4 className="table_header_heading">KYC</h4>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="table_Customer_heading">Status</th>
                        <th className="table_Customer_heading">Name</th>
                        <th className="table_Customer_heading">DOB</th>
                        <th className="table_Customer_heading">ID Number</th>
                        <th className="table_Customer_heading">Phone Number</th>
                        <th className="table_Customer_heading">Address</th>
                        <th className="table_Customer_heading">Address Type</th>
                        <th className="table_Customer_heading">Po Box</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) => kyc.status.toUpperCase()
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.nameSummary == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>

                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.dobsummary == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.idNumber == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.phoneSummary == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.address == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.addressType == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfKycchecks?.map(
                            (kyc) =>
                              kyc.poBox == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="containerFluid p-3">
            <div className="card">
              <div className="card-header table-header">
                <div class="">
                  <div>
                    <h4 className="table_header_heading">Selfie Check</h4>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="table_Customer_heading">Status</th>
                        <th className="table_Customer_heading">
                          Selfie Status
                        </th>
                        <th className="table_Customer_heading">Attempt</th>
                        <th className="table_Customer_heading">
                          Document Comparison
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfSelfieChecks?.map(
                            (selfie) => selfie.status.toUpperCase()
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfSelfieChecks?.map(
                            (selfie) => selfie.selfieStatus.toUpperCase()
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfSelfieChecks?.map(
                            (selfie) => selfie.attempt
                          )}
                        </td>
                        <td className="table_Customer_list">
                          {customerIdentityResponseData?.vrfSelfieChecks?.map(
                            (selfie) =>
                              selfie.documentComparison == "match" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: "#198754" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: "#bb2124" }}
                                  />
                                </>
                              )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-body py-3 bg-transparent">
                <div className="">
                  {/* {customerIdentityResponseData?.vrfSelfieChecks?.map((selfie, index) => (
                                        <div key={index} className="col-md-6 mb-3 card shadow">
                                            <img
                                                src={selfie.imageUrl}
                                                style={{ width: "100%", height: "auto", objectFit: "cover", objectPosition: "center", padding: "1pc" }}
                                                alt={`selfie-${index}`}
                                            />
                                        </div>
                                    ))} */}
                  {customerIdentityResponseData?.vrfSelfieChecks?.map(
                    (selfie, index) => (
                      <div key={index} className="col-md-6 mb-3 card shadow">
                        <video
                          controls
                          style={{
                            width: "100%",
                            height: "auto",
                            paddingBottom: "40px",
                            padding: "1pc",
                          }}
                        >
                          <source
                            src={selfie.videoUrl}
                            type="video/mp4"
                            alt={`selfie-video-${index}`}
                          />
                        </video>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerIdentityVerfication;
