import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  APIHeader,
  ExpireToken,
  HasLoggedInUser,
} from "../../../APICallWrapper";
import {
  ValidateNameGlobal,
  ValidateEmailGlobal,
  ValidatePhoneGlobal,
} from "../../Architecture/Validation";
import ErrorComponent from "../../../ErrorComponent";
import { OpenBankingType } from "./lookup";
import Spinner from "../../../Spinner";
import { VixoAPI } from "../../../settings";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";

const EditCustomer = ({
  ShowPopup,
  SelectedCustomer,
  onActionCancelled,
  onActionCompleted,
}) => {
  // Disable Button

  // USESTATES START
  const [editedPhone, setEditedPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [email, setEmail] = useState("");
  const [initialCustomerData, setInitialCustomerData] = useState(null);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [firstnameIsValid, setFirstNameIsValid] = useState(true);
  const [lastnameIsValid, setLastNameIsValid] = useState(true);
  const [phoneerror, setPhoneError] = useState("");
  const [fnameerror, setFNameError] = useState("");
  const [lnameerror, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("invitePhone");
  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({
    phone: "",
  });
  const [currentCustomer, setCurrentCustomer] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    identity: "",
    IsEmail: false,
    IsPhone: false,
    OpenBankingType: null,
    customerInvitation: null,
  });
  const [customerInfo, setCustomerInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    identity: "",
  });
  // USESTATES END

  // Handle  and function  section
  const clearData = () => {
    setCustomerInfo({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      identity: "",
      IsEmail: false,
      IsPhone: false,
    });
    setEmailIsValid(true);
    setPhoneIsValid(true);
    setErrorType(null);
  };
  const unformatPhoneNumber = (formattedPhoneNumber) => {
    return formattedPhoneNumber.replace(/\D/g, "");
  };
  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    return isValid;
  };
  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;

    // Update the companyInfo state when input changes
    setCompanyInfo({
      ...companyInfo,
      [name]: value,
    });

    // Check if the phone number is valid
    if (name === "phone") {
      const unformattedPhone = unformatPhoneNumber(value); // Assuming you have an unformatting function
      setEditedPhone(unformattedPhone);

      if (unformattedPhone.length === 10) {
        setPhoneIsValid(true); // Set phoneIsValid to true if it has exactly 10 digits
      } else {
        setPhoneIsValid(false); // Set phoneIsValid to false otherwise
      }
    }
  };

  const validateFirstName = (name) => {
    const isValid = ValidateNameGlobal(name);
    if (!isValid) {
      setFNameError("Enter a valid First Name.");
    } else {
      setFNameError(null); // Clear error message if validation passes
    }
    return isValid;
  };

  const validateLastName = (name) => {
    const isValid = ValidateNameGlobal(name);
    if (!isValid) {
      setLNameError("Enter a valid Last Name.");
    } else {
      setLNameError(null); // Clear error message if validation passes
    }
    return isValid;
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    const camelCaseValue = toCamelCase(value);
    if (name === "firstName") {
      const isValid = validateFirstName(camelCaseValue);

      setFirstNameIsValid(isValid);
    } else if (name === "lastName") {
      const isValid = validateLastName(camelCaseValue);
      setLastNameIsValid(isValid);
    }
    setEditCustomer({ target: { name, value: camelCaseValue } });
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    if (!isValid) {
      setPhoneError("Enter a valid phone number exactly 10 digits.");
    } else {
      setPhoneError(null); // Clear error message if validation passes
    }
    return isValid;
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setEditedPhone(value);
    setPhoneIsValid(validatePhone(value));
    setCurrentCustomer({
      ...currentCustomer,
      phone: value, // Update the phone number in the currentCustomer state
    });
    setHasChanges(true);
  };
  const isButtonDisabled =
    !phoneIsValid ||
    !emailIsValid ||
    !hasChanges ||
    !currentCustomer.firstName ||
    !currentCustomer.lastName ||
    !firstnameIsValid ||
    !lastnameIsValid ||
    currentCustomer.OpenBankingType === null ||
    currentCustomer.phone ===
      (initialCustomerData ? initialCustomerData.phone : "");

  //  Load lookups
  useEffect(() => {
    LoadLookups();
  }, []);

  useEffect(() => {
    if (onActionCompleted && SelectedCustomer) {
      setCurrentCustomer({
        id: SelectedCustomer.id,
        firstName: SelectedCustomer.firstName,
        lastName: SelectedCustomer.lastName,
        email: SelectedCustomer.email,
        phone: SelectedCustomer.phone,
        IsEmail: SelectedCustomer.inviteEmail,
        IsPhone: SelectedCustomer.invitePhone,
        identity: SelectedCustomer.identity,
        IsEmail: false,
        IsPhone: false,
        OpenBankingType: SelectedCustomer.openBankingType,
        customerInvitation: SelectedCustomer.customerInvitation,
      });
      // Set the edited phone number initially
      setEditedPhone(SelectedCustomer.phone);
      if (SelectedCustomer.invitePhone) {
        setSelectedRadio("invitePhone");
      } else {
        setSelectedRadio("inviteEmail");
      }
    } else {
      setCurrentCustomer({
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        identity: "",
        IsEmail: false,
        IsPhone: false,
        OpenBankingType: null,
        customerInvitation: null,
      });
      // Reset the edited phone number when the modal is closed
      setEditedPhone("");
    }
    LoadLookups();
  }, [onActionCompleted, SelectedCustomer]);

  async function LoadLookups() {
    try {
      OpenBankingType()
        .then((result) => {
          setOpenBankingRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(
        ex + " setOpenBankingRoles Role Lookup Call is not successfull"
      );
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailIsValid(validateEmail(value));
  };
  const setEditCustomer = (e) => {
    setCurrentCustomer({
      ...currentCustomer,
      [e.target.name]: e.target.value,
    });
    setHasChanges(true);
  };

  const handleModalCancel = async () => {
    setPhoneError("");
    setFNameError("");
    setLNameError("");
    setEmailError("");
    onActionCancelled(SelectedCustomer, 2); //Cancel Deleting
  };
  const handleUpdateCustomer = async () => {
    // e.preventDefault();

    if (
      !phoneIsValid ||
      !emailIsValid ||
      !firstnameIsValid ||
      !lastnameIsValid ||
      !currentCustomer.OpenBankingType == null
    ) {
      return;
    }

    const updatedIdentity = `${currentCustomer.firstName}  ${currentCustomer.lastName}`;

    // Determine the value of customerInvitation based on the selectedRadio
    const customerInvitation = selectedRadio === "invitePhone" ? 0 : 1;

    const data = {
      id: SelectedCustomer.id,
      organizationId: SelectedCustomer.organizationId,
      firstName: currentCustomer.firstName,
      lastName: currentCustomer.lastName,
      email: currentCustomer.email,
      phone: currentCustomer.phone,
      identity: updatedIdentity,
      InvitePhone: selectedRadio == "invitePhone" ? true : false,
      InviteEmail: selectedRadio == "inviteEmail" ? true : false,
      key: "",
      OpenBankingType: currentCustomer.OpenBankingType,
      customerInvitation: customerInvitation,
    };

    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const CustomerUpdate = VixoAPI.CustomerAPI + "updateMinimalCustomer";
        setLoading(true);

        await axios
          .put(CustomerUpdate, data, { headers: config.headers })
          .then((response) => {
            const responseData = response.data;
            if (responseData.statusCode === 200) {
              clearData();
              setData(responseData.data);
            } else {
              setErrorType(response.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  };

  function setData(data) {
    onActionCompleted(data, 2);
  }
  const handEditCancel = async () => {
    setErrorType("");
    setPhoneError("");
    setFNameError("");
    setLNameError("");
    setEmailError("");
    onActionCancelled(SelectedCustomer, 2); //Cancel Edit
  };
  function setError(message) {
    setErrorType(message);
  }
  const handleCheckboxChange = (checkboxName) => {
    setHasChanges(true);
    setSelectedRadio(checkboxName);
  };

  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} Title="Edit Customer 1" />
      <Modal show={ShowPopup} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="m-2 fs-4">Edit Customer</span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorType && <ErrorComponent errorType={errorType} />}
          <Spinner show={loading} Title="Edit Customer 2" />
          <p>
            <div className="modal-body">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="FirstName"
                  name="firstName"
                  placeholder="First Name"
                  value={toCamelCase(currentCustomer.firstName)}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleNameChange(e);
                  }}
                />
                {!firstnameIsValid && (
                  <span className="error" style={{ color: "red" }}>
                    {fnameerror}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={currentCustomer.lastName}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleNameChange(e);
                  }}
                />
                {!lastnameIsValid && (
                  <span className="error" style={{ color: "red" }}>
                    {lnameerror}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="example@vixolink.com"
                  value={currentCustomer.email}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleEmailChange(e);
                  }}
                  // style={{ borderColor: emailIsValid ? "" : "red" }}
                />
                {!emailIsValid && (
                  <span className="error" style={{ color: "red" }}>
                    {emailError}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <InputMask
                  mask="(999) 999-9999"
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="1234567890"
                  value={editedPhone}
                  onChange={handlePhoneChange}
                />
                {!phoneIsValid && (
                  <span className="error" style={{ color: "red" }}>
                    {phoneerror}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  name="id"
                  placeholder="Number"
                  value={currentCustomer.phone}
                  onChange={setEditCustomer}
                  hidden
                />
              </div>

              <select
                className="form-select"
                name="OpenBankingType"
                value={currentCustomer.OpenBankingType}
                onChange={setEditCustomer}
              >
                {OpenBankingRoles != null &&
                  OpenBankingRoles.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.displayValue}
                    </option>
                  ))}
              </select>

              <div className="col-md-12">
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByEmail"
                    name="inviteMethod"
                    // value="email"
                    value={currentCustomer.IsEmail}
                    checked={selectedRadio === "inviteEmail"}
                    onChange={() => handleCheckboxChange("inviteEmail")}
                  />
                  <label className="form-check-label" htmlFor="inviteByEmail">
                    Invite by Email
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByPhone"
                    name="inviteMethod"
                    value={currentCustomer.IsPhone}
                    checked={selectedRadio === "invitePhone"}
                    onChange={() => handleCheckboxChange("invitePhone")}
                  />
                  <label className="form-check-label" htmlFor="inviteByPhone">
                    Invite by Phone Number
                  </label>
                </div>
                <div
                  className="card-footer py-3 bg-transparent error-container"
                  style={{ color: "red" }}
                >
                  {errorType && (
                    <div className="" role="alert">
                      {errorType}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-row d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={handEditCancel}
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    fontWeight: "500",
                    width: "7pc",
                  }}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-info mx-2"
                  onClick={() => handleUpdateCustomer()}
                  disabled={isButtonDisabled}
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  Update Customer
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCustomer;
