import React from "react";
import { json, useLocation } from "react-router-dom";
import DocuSign from "./DocuSign"
const Contractindex = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Customerkey = params.get("Id")?.replace(/^"|"$/g, "");
  const Userkey = params.get("U")?.replace(/^"|"$/g, "");

  return (
    <>
      <main className="page-content">
        <DocuSign customerkey={Customerkey} userkey={Userkey} />
      </main>
    </>
  );
};

export default Contractindex;
