import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  APIHeader,
  ExpireToken,
  HasLoggedInUser,
} from "../../../APICallWrapper.js";
import { OpenBankingType } from "./lookup.js";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorComponent from "../../../ErrorComponent";
import Spinner from "../../../Spinner";
import { VixoAPI } from "../../../settings";
import PhoneNumber from "../../../VixoFormats/Phone.js";
import CustomerNavbar from "../CustomerNavbar.js";
import { useSelector } from "react-redux";
import { selectNotification } from "../../Notification/Slices/sendNotificationSlice.js";
import "./CustomerResendInvite.css";
import {
  faCommentSms,
  faEnvelope,
  faBuildingColumns,
  faCheck,
  faFileContract,
  faExclamationTriangle,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import APIErrorComponent from "../../../APIErrorComponent.js";
function CustomerResendInvite({ ShowNavbar = true }) {
  const location = useLocation();

  const notification = useSelector(selectNotification);
  const navigate = useNavigate();
  const [Organization, setOrganization] = useState({});
  const [
    editedBankLinkInvitationText,
    setEditedBankLinkInvitationText,
  ] = useState(Organization.bankLinkInvitationSms);
  const [User, setUser] = useState(null);
  const [APIResponse, setAPIResponse] = useState(null);

  const [userKey, setUserKey] = useState();

  const [CustomerKey, setCustomerKey] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("invitePhone");
  const [collapsed, setCollapsed] = useState(false);
  const [userId, setUserId] = useState(null);
  const [ReloadResendCustomer, setReloadResendCustomer] = useState(0);
  const [customerLastRefreshedOn, setCustomerLastRefreshOn] = useState(null);
  // const [notification, setNotification] = useState(null);
  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [invitePhone, setInvitePhone] = useState(true);
  const [inviteEmail, setInviteEmail] = useState(true);
  const [CustomerAccountCount, setCustomerAccountCount] = useState(null);
  const [identityRequired, setIdentityRequired] = useState(true);
  const [getcontracts, setGetContracts] = useState("");
  const [createdate, setCreateDate] = useState("");
  const [kyc, setKyc] = useState(true);
  const [
    customerIdentityResponseData,
    setCustomerIdentityResponseData,
  ] = useState();

  useEffect(() => {
    LoadLookups();
    const params = new URLSearchParams(location.search);
    const CustomerId = params.get("Id");
    if (CustomerId) {
      setCustomerKey(CustomerId);
    }
    var UserID = params.get("U");
    setUserKey(UserID);
    getUserByKey(UserID);
    //setUser(userObject);
  }, [location]);

  function getUserByKey(UserKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var encodedCustomer = encodeURIComponent(UserKey);
        const OrgAPIPath = `${VixoAPI.UserAPI}getUserByKey?Key=${encodedCustomer}`;
        axios
          .get(OrgAPIPath, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              localStorage.setItem("User", JSON.stringify(response.data));
              setUserId(response?.data?.id);
              LoadOrganization(response?.data?.organizationId);
              return response.data;
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  }

  function LoadOrganization(OrganizationID) {
    if (HasLoggedInUser()) {
      try {
        // setLoading(true);
        const OrgnizationUpdateAPIPath =
          VixoAPI.OrganizationAPI + "get?Id=" + OrganizationID;
        axios
          .get(OrgnizationUpdateAPIPath, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            // setLoading(false);
            if (response.statusCode === 200) {
              var Organization = JSON.parse(JSON.stringify(response.data));
              if (Organization != null) {
                setOrganization(Organization);
                setEditedBankLinkInvitationText(
                  Organization.bankLinkInvitationSms
                );
              }
            } else {
              setError(response.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            throw error;
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      navigate("/Home");
    }
  }
  function setError(message) {
    setErrorType(message);
  }
  useEffect(() => {
    if (CustomerKey != null) {
      LoadCustomer();
    }
  }, [CustomerKey]);

  useEffect(() => {
    if (ReloadResendCustomer > 0) {
      LoadCustomer();
    }
  }, [ReloadResendCustomer]);

  async function LoadLookups() {
    try {
      const result = await OpenBankingType();
      setOpenBankingRoles(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const [progress, setProgress] = useState(60);
  const [customerData, setCustomerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    inviteEmail: "",
    invitePhone: "",
    openBankingType: "",
    IsEmail: SelectedCustomer.inviteEmail,
    IsPhone: SelectedCustomer.invitePhone,
  });

  async function LoadCustomer() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        const response = await axios.get(
          `${VixoAPI.CustomerAPI}getCustomerBykey?key=${encodeURIComponent(
            CustomerKey
          )}`,
          { headers: config.headers }
        );
        if (response.data.statusCode === 200) {
          setReloadResendCustomer(0);
          setSelectedCustomer(response.data.data);
          CustomerData(response.data.data);
        } else {
          setErrorType(response.data.message);
        }
      } catch (error) {
        setErrorType(error.message);
      } finally {
        // setLoading(false);
      }
    } else {
      ExpireToken(navigate);
    }
  }

  function CustomerData(data) {
    try {
      // setLoading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setSelectedCustomer(customersdata);
      if (customersdata.invitePhone) {
        setSelectedRadio("invitePhone");
      } else {
        setSelectedRadio("inviteEmail");
      }

      // setLoading(false);
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function GotNewAlert(notification) {
    if (
      notification != null &&
      parseInt(notification.organiationID) ===
        SelectedCustomer.organizationId &&
      notification.customerKey === CustomerKey &&
      notification.notificationType === 25
    ) {
      GetCustomerByKey(notification.customerKey).then((customer) => {
        if (customer) {
          var sendinvite =
            "/resendinvite?Id=" + SelectedCustomer.key + "&U=" + userKey;
          window.location.href = sendinvite;
          const hourDiff = Date.now - customerLastRefreshedOn;
          const minDiff = hourDiff / 60 / 1000;
          if (minDiff < 5) {
            setCustomerLastRefreshOn(Date.now);
            const lReloadResendCustomer = ReloadResendCustomer + 1;
            setReloadResendCustomer(lReloadResendCustomer);
          }
        }
      });
    } else if (
      notification != null &&
      parseInt(notification.organiationID) ===
        SelectedCustomer.organizationId &&
      notification.customerKey === CustomerKey &&
      notification.notificationType === 26
    ) {
      const hourDiff = Date.now - customerLastRefreshedOn;
      const minDiff = hourDiff / 60 / 1000;
      if (minDiff < 5) {
        setCustomerLastRefreshOn(Date.now);
        const lReloadResendCustomer = ReloadResendCustomer + 1;
        setReloadResendCustomer(lReloadResendCustomer);
      }
    } else if (
      notification != null &&
      parseInt(notification.organiationID) ===
        SelectedCustomer.organizationId &&
      notification.customerKey === CustomerKey &&
      notification.notificationType === 1
    ) {
      // const customerdashboardLink = `${VixoAPI.DashBoard}Home?Id=${CustomerKey}&U=${userKey}`;
      // console.log("customerdashboardLink" , url);
      const url = `/tabs?Id=${CustomerKey}&U=${userKey}#accountdetail`;
      window.location.href = url;
    }
  }

  async function GetCustomerByKey(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const Key = encodeURIComponent(CustomerKey);
        const response = await axios.get(
          `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`,
          { headers: config.headers }
        );
        if (response.data.statusCode === 200) {
          return response.data.data;
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate);
        }
      }
    } else {
      ExpireToken(navigate);
    }
  }

  const handleCompanyInfoChange = (e) => {
    setOrganization({
      ...Organization,
      bankLinkInvitationSms: e.target.value,
    });
    setEditedBankLinkInvitationText(e.target.value);
  };

  useEffect(() => {
    if (SelectedCustomer !== null) {
      GetAccountsCountByCustomer();
    }
  }, [SelectedCustomer]);

  function GetAccountsCountByCustomer() {
    if (!CustomerKey) return;
    if (HasLoggedInUser()) {
      try {
        //  setLoading(true);

        const GetCustomerId = `${VixoAPI.AccountAPI}getAccountCountByCustomer?CustomerKey=${CustomerKey}`;
        //  setLoading(true);
        axios
          .get(GetCustomerId, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var customersdata = JSON.parse(JSON.stringify(response.data));
              setCustomerAccountCount(customersdata);
            } else {
              // setError(response.message);
            }
          })
          .catch((error) => {
            //  setLoading(false);
            throw error;
          })
          .finally(() => {
            //  setLoading(false);
          });
      } catch (error) {
        //  setLoading(false);
        throw error;
      }
    } else {
      navigate("/Home");
    }
  }

  const sendInvite = async (e) => {
    e.preventDefault();
    const updatedInvitePhone = selectedRadio === "invitePhone";
    const updatedInviteEmail = selectedRadio === "inviteEmail";
    const data = {
      eventType: 0,
      invitePhone: updatedInvitePhone,
      inviteEmail: updatedInviteEmail,
      customerID: SelectedCustomer.id,
      organizationID: SelectedCustomer.organizationId,
      userId: userId,
      massage: editedBankLinkInvitationText,
      openBankingType: SelectedCustomer.openBankingType,
      contactMethod: selectedRadio,
    };
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        setLoading(true);
        const APIURL = `${VixoAPI.CustomerAPI}resendInvite`;
        const response = await axios.post(APIURL, data, {
          headers: config.headers,
        });
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
            setSelectedCustomer({
              ...SelectedCustomer,
              openBankingType: SelectedCustomer.openBankingType,
            });
            clearData();
          } else {
            toast.error(responseData.message);
          }
        }
      } catch (error) {
        console.error("Error sending invite:", error);
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };

  const clearData = () => {
    //setEditedBankLinkInvitationText("");
  };
  const handleRadioChange = (e) => {
    const value = e;
    if (value === "inviteEmail") {
      setInvitePhone(false);
      setInviteEmail(true);
    } else if (value === "invitePhone") {
      setInvitePhone(true);
      setInviteEmail(false);
    }
    setSelectedRadio(value);
  };

  const [buttonState, setButtonState] = useState(-1);

  function setUserDataFromAPI(data) {
    try {
      setLoading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  useEffect(() => {
    if (SelectedCustomer != null) {
      const result = ShouldShowButtons();
      setButtonState(result);
    }
  }, [SelectedCustomer, buttonState]);

  useEffect(() => {
    if (
      SelectedCustomer !== null &&
      SelectedCustomer.identityRequired == true
    ) {
      PlaidIdentityVerifiedCustomer(CustomerKey);
    }
  }, [SelectedCustomer]);

  function ShouldShowButtons() {
    if (SelectedCustomer == null) {
      return -1;
    } else if (
      (SelectedCustomer.identityRequired == true &&
        SelectedCustomer.idVerificationProcessStatus == 1) ||
      SelectedCustomer.idVerificationProcessStatus == 2
    ) {
      return 2;
    } else if (
      SelectedCustomer.identityRequired == null ||
      SelectedCustomer.identityRequired == false
    ) {
      return 3;
    } else if (
      SelectedCustomer.identityRequired == true &&
      SelectedCustomer.customerIdentityId !== null
    ) {
      return 4;
    } else {
    }
  }

  function LoadUser() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(userId);
        const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
        // setLoading(true);
        axios
          .get(GetUserByKeyAPI, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setUserDataFromAPI(response.data);
            } else {
            }
          })
          .catch((error) => {
            // setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate);
            }
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  }
  useEffect(() => {
    if (userId) {
      LoadUser();
    }
  }, [userId]);

  function IdentityVerificationRequest() {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        const request = {
          customerKey: CustomerKey,
          userKey: userKey,
        };

        const PlaidIdentificationRequest = `${VixoAPI.FinicityAPI}createIdentityVerificationRequest`;
        setLoading(true);
        axios
          .post(PlaidIdentificationRequest, request, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              setCustomerIdentityResponseData(response.data);
            } else if (response) {
              //setAPIResponse(response);
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      navigate("/Home");
    }
  }

  function PlaidIdentityVerifiedCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      try {
        // setLoading(true);
        const PlaidIdentifiedCustomerData = `${VixoAPI.FinicityAPI}getCustomerIdentityDetail?CustomerKey=${SelectedCustomer.key}`;
        // setLoading(true);
        axios
          .get(PlaidIdentifiedCustomerData, { headers: APIHeader().headers })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setCustomerIdentityResponseData(responseData.data);
              console.log(responseData); // Use responseData directly here
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            throw error;
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      navigate("/Home");
    }
  }

  function PlaidIdentityVerifiedCustomerOnRefresh() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        const PlaidIdentifiedCustomerDataOnRefresh = `${VixoAPI.FinicityAPI}getCustomerIdentityVerificationDetailFromPlaid?CustomerKey=${SelectedCustomer.key}`;
        // setLoading(true);
        axios
          .get(PlaidIdentifiedCustomerDataOnRefresh, {
            headers: config.headers,
          })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setCustomerIdentityResponseData(responseData.data);
              console.log(responseData); // Use responseData directly here
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }
  ///////////////////////
  const handleSendContracts = (e) => {
    e.preventDefault();
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const sendcontactsAPIPath =
          VixoAPI.CustomerAPI +
          "SendContractToCustomer?CustomerKey=" +
          SelectedCustomer.key;
        axios
          .get(sendcontactsAPIPath, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setCreateDate(response.data.createdDate);
              toast.success(response.message)
            } else {
              toast.error(response.message);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  };

  useEffect(() => {
    if (CustomerKey) {
      handlGetContracts();
    }
  }, [CustomerKey]);

  const handlGetContracts = () => {
    if (!CustomerKey) return;
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const getcontactsAPIPath =
          VixoAPI.CustomerAPI +
          "GetContractSignedDetailByKey?CustomerKey=" +
          CustomerKey;
        axios
          .post(getcontactsAPIPath)
          .then((response) => {
            var responsedata = response.data;
            if (responsedata.statusCode === 200) {
              setGetContracts(responsedata.data.strSignature);
            } else {
              setError(responsedata.message);
            }
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <APIErrorComponent APIResponse={APIResponse} />
        {errorType && <ErrorComponent errorType={errorType} />}
        <Spinner show={loading} Title="Customer Resend Invite" />
        <ToastContainer />
        {ShowNavbar && ShowNavbar == true ? (
          <CustomerNavbar customerkey={CustomerKey} userkey={userKey} />
        ) : (
          ""
        )}

        {SelectedCustomer != null && SelectedCustomer.id ? (
          <div className="shadow p-3 bg-white col-lg-12 col-sm-12 mt-5">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="card">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 ">
                          <div
                            className="d-flex align-items-center justify-content-end px-5 my-5 "
                            style={{ gap: "50px" }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <BootstrapTooltip id="tooltip">
                                  <center>
                                    <span style={{ fontSize: "13px" }}>
                                      Consent successfully Delivered{" "}
                                    </span>
                                  </center>
                                </BootstrapTooltip>
                              }
                            >
                              <div className="position-relative">
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    icon={faFileContract}
                                    fontSize="25px"
                                  />
                                </span>
                                {getcontracts ? (
                                  <>
                                    <span
                                      className=""
                                      style={{
                                        position: "absolute",
                                        top: "-20px",
                                        left: "3px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        fontSize="20px"
                                        icon={faCheckDouble}
                                        style={{ color: "green" }}
                                      />
                                    </span>
                                  </>
                                ) : createdate ? (
                                  <span
                                    className=""
                                    style={{
                                      position: "absolute",
                                      top: "-23px",
                                      left: "2px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      fontSize="25px"
                                      icon={faCheck}
                                      style={{ color: "green" }}
                                    />
                                  </span>
                                ) : null}
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <BootstrapTooltip id="tooltip">
                                  <center>
                                    <span style={{ fontSize: "13px" }}>
                                      Customer Attached Accounts{" "}
                                      {CustomerAccountCount}
                                    </span>
                                  </center>
                                </BootstrapTooltip>
                              }
                            >
                              <div className="position-relative">
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    icon={faBuildingColumns}
                                    fontSize="25px"
                                  />
                                </span>
                                <span
                                  class="badge rounded-pill badge-notification bg-danger  "
                                  style={{
                                    position: "absolute",
                                    top: "-4px",
                                    left: "15px",
                                  }}
                                >
                                  {CustomerAccountCount}
                                </span>
                              </div>
                            </OverlayTrigger>
                            {SelectedCustomer &&
                            SelectedCustomer.customerInvitation === 1 ? (
                              <div className="d-flex align-items-center justify-content-end  fs-3">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <BootstrapTooltip id="tooltip">
                                      <center>
                                        <span style={{ fontSize: "13px" }}>
                                          Invitation successfully sent via Email
                                        </span>
                                      </center>
                                    </BootstrapTooltip>
                                  }
                                >
                                  <span className="text-secondary position-relative">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-end fs-3">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <BootstrapTooltip id="tooltip">
                                      <center>
                                        <span style={{ fontSize: "13px" }}>
                                          Invitation successfully sent via SMS
                                        </span>
                                      </center>
                                    </BootstrapTooltip>
                                  }
                                >
                                  <div className="position-relative">
                                    <span className="text-secondary">
                                      <FontAwesomeIcon
                                        icon={faCommentSms}
                                        fontSize="30px"
                                      />
                                    </span>
                                    {SelectedCustomer &&
                                      SelectedCustomer.customerReceivedSms ===
                                        true && (
                                        <span
                                          className=""
                                          style={{
                                            position: "absolute",
                                            top: "-20px",
                                            left: "08px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            fontSize="25px"
                                            icon={faCheck}
                                            style={{ color: "green" }}
                                          />
                                        </span>
                                      )}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )}
                          </div>
                          <h3 className="m-3 fw-bold"> Customer Details</h3>
                        </div>
                      </div>
                      <div className="row m-1">
                        <div className="col-md-6">
                          <span className="fw-bold ">First Name</span>
                          <br />
                          <span>{SelectedCustomer.firstName}</span>
                        </div>
                        <div className="col-md-6 ">
                          <span className="fw-bold">Last Name</span>
                          <br />
                          <span>{SelectedCustomer.lastName}</span>
                        </div>
                      </div>
                      <div className="row m-2">
                        <div className="col-md-6">
                          <span className="fw-bold">Email</span>
                          <br />
                          <span>{SelectedCustomer.email}</span>
                        </div>
                        <div className="col-md-6">
                          <span className="fw-bold">Phone</span>
                          <br />
                          <span>
                            {SelectedCustomer && (
                              <PhoneNumber number={SelectedCustomer.phone} />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="modal-body">
                        <form className="m-3">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              rows="4"
                              style={{ height: "100px" }}
                              placeholder="Enter your comment..."
                              value={editedBankLinkInvitationText}
                              onChange={handleCompanyInfoChange}
                            ></textarea>
                          </div>
                          <div className="col-md-2 mb-4 mt-3">
                            <select
                              className="form-select"
                              name="OpenBankingType"
                              value={SelectedCustomer.openBankingType}
                              onChange={(e) =>
                                setSelectedCustomer({
                                  ...SelectedCustomer,
                                  openBankingType: e.target.value,
                                })
                              }
                            >
                              {OpenBankingRoles.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.displayValue}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-12 mb-2 mt-2">
                            <div className="form-check d-flex gap-1">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="inviteEmail"
                                name="inviteMethod"
                                checked={selectedRadio === "inviteEmail"}
                                onChange={() =>
                                  handleRadioChange("inviteEmail")
                                }
                              />

                              <label
                                className="form-check-label"
                                htmlFor="inviteEmail"
                              >
                                Invite by Email
                              </label>
                              {selectedRadio === "inviteEmail" ? (
                                <span className="text-success">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    fontSize="20px"
                                  />
                                </span>
                              ) : null}
                            </div>
                            <div className="form-check d-flex gap-1">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="invitePhone"
                                name="inviteMethod"
                                checked={selectedRadio === "invitePhone"}
                                onChange={() =>
                                  handleRadioChange("invitePhone")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invitePhone"
                              >
                                Invite by Phone Number
                              </label>
                              {selectedRadio === "invitePhone" ? (
                                <span className="text-success">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    fontSize="20px"
                                  />
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            {buttonState === 4 && (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                Identity Verification Request was completed.
                              </p>
                            )}
                            {buttonState === 2 && (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                Identity Verification Request was already Sent
                                to Customer
                              </p>
                            )}
                          </div>

                          <div className="d-flex">
                            <button
                              className="btn"
                              onClick={sendInvite}
                              style={{
                                borderRadius: "20px",
                                color: "white",
                                fontWeight: "500",
                                width: "9pc",
                                backgroundColor: "#94ba33",
                              }}
                            >
                              Resend Invite
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {buttonState === 3 && (
                              <div className="d-flex align-items-center">
                                <button
                                  id="myButton"
                                  type="button"
                                  className="btn btn-info"
                                  onClick={IdentityVerificationRequest}
                                  style={{
                                    borderRadius: "20px",
                                    color: "white",
                                    fontWeight: "500",
                                    width: "23pc",
                                  }}
                                >
                                  Request Customer Identity Verification
                                </button>
                              </div>
                            )}
                            {buttonState === 2 && (
                              <>
                                <div className="d-flex align-items-center">
                                  <button
                                    id="myButton"
                                    type="button"
                                    className="btn btn-info custom-button"
                                    onClick={IdentityVerificationRequest}
                                    style={{
                                      borderRadius: "20px",
                                      color: "white",
                                      fontWeight: "500",
                                      width: "23pc",
                                    }}
                                  >
                                    Restart Customer Identification Process
                                  </button>
                                </div>
                              </>
                            )}
                            {buttonState === 4 && (
                              <>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    <button
                                      id="myButton"
                                      type="button"
                                      className="btn btn-info custom-button"
                                      onClick={IdentityVerificationRequest}
                                      style={{
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: "500",
                                        maxWidth: "23pc",
                                      }}
                                    >
                                      Completed Customer Identification Process
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                              id="myButton"
                              type="button"
                              className="btn"
                              onClick={handleSendContracts}
                              style={{
                                borderRadius: "20px",
                                color: "white",
                                fontWeight: "500",
                                backgroundColor: "#a13a7d",
                              }}
                            >
                              Send Consent
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CustomerResendInvite;
