import React, { useState } from "react";
import { Spinner, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./CommunicationTimeline.css";

const CommunicationTimelineDocument = ({ communicationTimelineData }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ToastContainer />

      <main className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {communicationTimelineData.map((documents, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="col-md-4 text-center animated mt-2"
                      style={{
                        borderRadius: "10px",
                        background: "white",
                        border: "1px solid",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: "rgb(13, 110, 253)",
                          color: "white",
                        }}
                      >
                        Document
                      </p>

                      <div>
                        <p
                          className="text-center"
                          style={{ marginTop: "17px", cursor: "pointer" }}
                        >
                          <span
                            style={{ display: "block", overflow: "hidden" }}
                          >
                            <embed
                              src={documents.fileSha1Hash}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                              style={{ overflow: "hidden" }}
                            />
                          </span>
                        </p>
                        <div className="card-body m-auto">
                          <h6 className="card-title">{documents.fileName}</h6>
                          <div className="mt-2">
                            {/* <img
                                  src="https://prod-carpintero-branding.s3.us-west-2.amazonaws.com/170758/logo.svg"
                                  alt="Logo 2"
                                  className="timeline-logo"
                                  style={{
                                    width: "185px",
                                    height: "67px",
                                    marginTop: "-15px",
                                  }}
                                /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CommunicationTimelineDocument;
