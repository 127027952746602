import { useState, useEffect } from "react";
import axios from "axios";
import { APIHeader } from "../../APICallWrapper";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";

const UpdateAccount = ({showModal, updateBank, handleModalClose}) => {
    const [loading, setLoading] = useState(false);
    const [addAccount, setBankAccount] = React.useState(false);
    const [instituteName, setInstituteName] = useState("");

    useEffect(() => {
        // Set initial form data when modal is opened
        if(updateBank!=null){
            LoadBank(updateBank.id);
            // setFormData({
            //   instituteName: updateBank.institutionId,
            //   accountTitle: updateBank.accountTitle,
            //   accountType: updateBank.accountType,
            //   currency: "$",
            //   ownerName: updateBank.ownerName,
            //   ownerAddress: updateBank.ownerAddress,
            //   routingNumber: updateBank.routingNumber,
            //   accountNumber: updateBank.accountNumber,
            // });
        }
      }, [updateBank]);

    const [formData, setFormData] = useState({
        institutionId: 0,
        accountTitle: "",
        accountType: "",
        currency: "",
        ownerName: "",
        ownerAddress: "",
        routingNumber: "",
        accountNumber: "",
        customerId:0,
        connectionId:0,
        ocrtypeId:0,
        StatementUrl:"",
    });
    const handleChange = (e) => {
      const { name, value } = e.target;
      const parsedValue = name === "instituteName" ? parseInt(value, 10) : value;
  
      setFormData({
        ...formData,
        [name]: parsedValue,
      });
    };
    const handleUpdateAccount = async (e) => {
      e.preventDefault();
      const data = {
        InstitutionId: parseInt(formData.institutionId, 10),
        accountTitle: formData.accountTitle,
        accountType: formData.accountType,
        currency: formData.currency,
        ownerName: formData.ownerName,
        ownerAddress: formData.ownerAddress,
        routingNumber: formData.routingNumber,
        accountNumber: formData.accountNumber,
        OcrtypeId: formData.ocrtypeId,
        ConnectionId: formData.connectionId,
        InstituteName:instituteName,
        CustomerId:formData.customerId,
        StatementUrl: formData.StatementUrl,
      };
      try {
          const CreateAccountAPIPath = VixoAPI.AccountAPI + "update?Id=" + updateBank.id;
        setLoading(true);
        axios
          .put(CreateAccountAPIPath, data, {headers:APIHeader().headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              handleModalClose(response.data);
            } else {
              toast(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    };
    


    function LoadBank(id) {
      try {
        setLoading(true);
        const GetCustomerBankId = `${VixoAPI.AccountAPI}get?ID=${id}`;
        setLoading(true);
        axios
          .get(GetCustomerBankId, {headers:APIHeader().headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var Bankdetail = JSON.parse(JSON.stringify(response.data));
              setFormData(Bankdetail);
              setInstituteName(Bankdetail.instituteName);
            } else {
              //setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }



  return (
    <>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-left">
                  <span className="m-2">Account</span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {errorType && <ErrorComponent errorType={errorType} />} */}
          <Spinner show={loading} Title="Update Account"/>
          <p>
            <div className="modal-body">
            <div className="mb-3">
            <label for="">Institute Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Institute Name"
                name="accountTitle"
                value={instituteName?instituteName:''}
                onChange={handleChange}
                disabled
              />
              </div>
              <div className="mb-3">
                <lablefor>AccountTitle</lablefor>
              <input
             
                type="text"
                className="form-control"
                placeholder="Institute Number"
                name="accountTitle"
                value={formData.institutionId}
                onChange={handleChange}
                hidden
              />
              </div>
              <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Account title"
                name="accountTitle"
                value={formData.accountTitle}
                onChange={handleChange}
              />
              {formData.accountTitle?"":
              <span className="text-danger">Account title is not found</span>
              }
              </div>
              <div className="mb-3">
              <label for="">Account type</label>
              <input
                type="text"
                class="form-control"
                placeholder="Account type"
                name="accountType"
                value={formData.accountType}
                onChange={handleChange}
              />
               {formData.accountType?"":
              <span className="text-danger">Account type is not found</span>
              }
              </div>
              <div className="mb-3">
              <label for="">Currency</label>
              <input
                type="text"
                class="form-control"
                placeholder="Currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              />
              </div>
              <div className="mb-3">
              <label for="">Owner Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Owner Name"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
              />
              </div>

              <div className="mb-3">
              <label for="">Owner Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Owner Address"
                name="ownerAddress"
                value={formData.ownerAddress}
                onChange={handleChange}
              />
              </div>

              <div className="mb-3">

              <label for="">Routing Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Routing Number"
                name="routingNumber"
                value={formData.routingNumber}
                onChange={handleChange}
              />
              </div>
              <div className="mb-3">
              <label for="">Account Number</label>
              <input
                    type="text"
                    class="form-control"
                    placeholder="Account Number"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                  />
                  {formData.accountNumber?"":
              <span className="text-danger">Account number is not found</span>
              }
              </div>
              <div
                className="flex-row d-flex justify-content-between align-items-center"
              >
                <button
                    type="button"
                    className="btn btn-secondary mx-2"
                    onClick={handleModalClose}
                    style={{borderRadius:"20px",fontWeight:"500",width:"7pc"}}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleUpdateAccount}
                  style={{borderRadius:"20px",fontWeight:"500",width:"7pc"}}

                >
                  Update
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateAccount