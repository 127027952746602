import React,{useState} from 'react'
import axios from 'axios';
import { APIHeader, ExpireToken, HasLoggedInUser } from '../../APICallWrapper';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';


const OcrStatementDelete = ({ show, onHide, id, onDeleteCancel, onDeleteSuccess }) => {
    const [loading, setLoading] = useState(false);
    const navigate=useNavigate();

    const handleDeleteConfirm = () => {
      if(HasLoggedInUser())
        {
          const config=APIHeader();
      try {
        setLoading(true);
        const statementCustomerAPIPath = `${VixoAPI.StatmentAPI}deletestatementwithtransaction?id=${id}`;
        axios
          .delete(statementCustomerAPIPath, {
            headers:config.headers
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              onDeleteSuccess();
              onHide();
            } else {
              toast(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        if(error.response && error.response.status && error.response.status==401)
          {
             ExpireToken(navigate);
          }
      }
    }
    else{
      ExpireToken(navigate)
    }
  }
  
  return (
  <>
  <Spinner />
  <ToastContainer />
       <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Delete File</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    This will remove it from the OCR list, and if you want to perform OCR on this file again, you will be charged again.
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onDeleteCancel}>
        Cancel
      </Button>
      <Button variant="danger" onClick={handleDeleteConfirm}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
  </>
  )
}

export default OcrStatementDelete