import React from 'react';
const DateComponent = ({ value }) => {
    // Assuming amount is a number
    
        if (value) {
          const date = new Date(value);
          const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date
            .getDate()
            .toString()
            .padStart(2, "0")}-${date.getFullYear()}`;
          return formattedDate;
        }
        return "";
      
  };
  
  export default DateComponent;