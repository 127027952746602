import { Link, useNavigate, json, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { VixoAPI } from "../../settings";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import "./AccountDetailSpinners.css";
import { useRef } from "react";

import CustomerBanking from "./CustomerBanking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { CustomerAuthentication } from "../../customerAuthentication";
import IdentityVerificationRequired from "../IdentityVerification/IdentityVerificationRequired";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import TermsConditions from "./TermsConditions";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { config } from "@fortawesome/fontawesome-svg-core";

const AttachBank = (customerkey) => {
  const [hasLogo, setHasLogo] = useState(null);
  const [OrgName, setOrgName] = useState(null);
  const buttonRef = useRef();
  const location = useLocation();
  const [CustomerKey, setCustomerKey] = useState(null);
  const [CustomerToken, setCustomerToken] = useState(null);
  const [Customer, setCustomer] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [banks, setBanks] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [ShowIdentityContent, setShowIdentityContent] = useState(false);
  const [showBankingContent, setShowBankingContent] = useState(true);
  const bankingTabColor = showBankingContent ? "#EAF0F6" : "white";
  const filesTabColor = ShowIdentityContent ? "#EAF0F6" : "white";
  const [CustomerIdentityResponseData, setCustomerIdentityResponseData] =
    useState("");
  const [identityTabVisible, setIdentityTabVisible] = useState(true);
  const [showConsent, setShowConsent] = useState(false);

  const notification = useSelector(selectNotification);
  function GotNewAlert(notification) {
    if (
      notification != null &&
      notification.customerKey == CustomerToken &&
      notification.notificationType == 25 // Customer is Edited
    ) {
      GetCustomerByKey(notification.customerKey).then((customFromAPI) => {
        if (customFromAPI) {
          setCustomer(customFromAPI);
        }
      });
    }
  }
  async function GetCustomerByKey(CustomerKey) {

    try {
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getAnyCustomerBykey?key=${Key}`;

      const response = await axios.get(GetCustomerId);
      var apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        const data = apiResponse.data;
        var customersdata = JSON.parse(JSON.stringify(data));
        return customersdata;
      }
    } catch (error) {
      setLoading(false);
    
    }

  }
  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);
  useEffect(() => {
    ReloadComponent();
  }, []);

  useEffect(() => {
    ReloadComponent();
  }, [showConsent]);

  function ReloadComponent()
  {
    var authData = CustomerAuthentication(location);
    if (authData.CustomerKey === "") {
      navigate("/");
    } else {
      var encodedCustomer = encodeURIComponent(authData.CustomerKey);
      setCustomerToken(authData.CustomerKey);
      setCustomerKey(encodedCustomer);
      LoadCustomer(encodedCustomer);
    }

  }
  function LoadCustomer(customerKey) {
 
    try {
      setLoading(true);
      const LoadCustomerAPI =
        VixoAPI.CustomerAPI + "getAnyCustomerBykey?Key=" + customerKey;
      setLoading(true);
      axios
        .get(LoadCustomerAPI)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            getOrganizationById(response.data.organizationId);

            if (response.data.identityRequired) {
              setIdentityTabVisible(true);
            } else {
              setIdentityTabVisible(false);
            }
          } else {
            navigate(`/?id=${customerKey}`);
          }
        })
        .catch((error) => {
          setLoading(false);
       
            })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
 
  }

  function setData(Customer) {
    if (Customer != null) {
      var cachedCustomer = JSON.stringify(Customer);
      var Customer = JSON.parse(cachedCustomer);
      if (
        Customer.signContract &&
        Customer.signContract != null &&
        Customer.signContract == true &&
        (Customer.customerSignedContract == null ||
          Customer.customerSignedContract == false)
      ) {
        setShowConsent(true);
      }
      setCustomer(Customer);
    }
  }

  function getOrganizationById(OrganizationID) {
    try {
      const OrgAPIPath = `${VixoAPI.OrganizationAPI}getAny?ID=${OrganizationID}`;
      axios
        .get(OrgAPIPath)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var OrgData = JSON.parse(JSON.stringify(response.data));
            setHasLogo(OrgData.logoUrl);
            setOrgName(OrgData.name);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
  }

  function setError(message) {
    setErrorType(message);
  }

  const [activeTab, setActiveTab] = useState("banking");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleIdentityClick = () => {
    handleTabClick("identity");
    setShowIdentityContent(true);
    setShowBankingContent(false);
    PlaidIdentityVerifiedCustomerOnRefresh(CustomerKey);
  };

  const handleBankingClick = () => {
    handleTabClick("banking");
    setShowBankingContent(true);
    setShowIdentityContent(false);
  };

  const ConsentSigned = (IsTermSigned) => {
    setShowConsent(!IsTermSigned);
  };

  //this function update the customer portal after identity verification response and pass as Props in IdentityVerificationRequired
  function PlaidIdentityVerifiedCustomerOnRefresh(Customer) {
    
    try {
      setLoading(true);
      const PlaidIdentifiedCustomerDataOnRefresh = `${VixoAPI.FinicityAPI}getCustomerIdentityVerificationDetailFromPlaid?CustomerKey=${Customer}`;
      setLoading(true);
      axios
        .get(PlaidIdentifiedCustomerDataOnRefresh, {headers:config.headers})
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            setCustomerIdentityResponseData(responseData.data);
            // Use responseData directly here
          } else {
            setError(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate, customerkey);
              }
            })
            .finally(() => {
              setLoading(false);
            });
          } catch (error) {
      setLoading(false);
      throw error;
    }

  }
  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} className="spinner-mobile" />

      <main
        className="bankattach"
        style={{
          backgroundColor: "#DCE6F1",
          paddingLeft: "2pc",
          paddingRight: "2pc",
        }}
      >
        <div className="containerFluid">
          <div className="row text-center">
            <p
              className="text-center"
              style={{
                color: "#004265",
                fontSize: "20px",
                marginTop: "9px",
                marginBottom: "9px",
                fontWeight: "normal",
                fontWeight: "400",
              }}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="34"
                viewBox="0 0 34 39"
                fill="none"
              >
                <path
                  d="M33.4977 10.901L33.3518 7.98198L33.2772 6.48678L32.236 7.56034L17.8062 22.4734L12.9314 17.0018L12.5 16.5186L12.0168 16.9499L10.1259 18.6365L9.6426 19.0679L10.074 19.5511L17.2354 27.585L17.6992 28.1072L18.1857 27.6045L29.703 15.698C29.5603 18.1143 29.0835 20.4106 28.2824 22.5416C27.4391 24.786 26.239 26.8423 24.7211 28.6489C23.4302 30.1862 21.8961 31.5355 20.2939 32.5539C19.0971 33.3128 17.8613 33.8837 16.7878 34.1691C15.7175 33.8804 14.4785 33.3128 13.2817 32.5539C11.6762 31.5355 10.1453 30.1862 8.85445 28.6489C7.33655 26.8423 6.13649 24.786 5.29321 22.5416C4.3202 19.9533 3.8272 17.1186 3.8272 14.1185C3.8272 12.7173 3.87585 11.4913 3.93099 10.5897C6.2792 10.3853 8.65336 9.80801 10.8362 8.90635C12.0038 8.42308 13.1422 7.83927 14.2158 7.17438C15.1239 6.61003 15.9867 5.98405 16.791 5.30943C17.9197 6.25974 19.1619 7.10627 20.4885 7.82954C22.347 8.84148 24.3384 9.60043 26.4109 10.0805L27.0434 10.2264L27.1893 9.59394L27.7602 7.12573L27.9061 6.49326L27.2737 6.34731C25.5384 5.94513 23.8746 5.31267 22.321 4.46615C20.7577 3.61314 19.3533 2.56876 18.1468 1.35898L17.2516 0.460561L16.7943 0L16.3337 0.460561L15.4385 1.35898C13.7649 3.03257 11.6697 4.4175 9.37664 5.36457C7.07707 6.31488 4.57318 6.82409 2.13739 6.84031L1.00545 6.84679L0.424883 6.85004L0.36326 7.42736L0.246497 8.55606C0.236767 8.65336 0 10.94 0 14.1185C0 22.3405 3.22393 27.8996 5.92567 31.1138C6.76571 32.1128 7.68683 33.0436 8.65661 33.8772C9.54205 34.6362 10.4761 35.3237 11.4362 35.914C13.113 36.9487 14.8774 37.6947 16.4018 38.0158L16.6613 38.0709L16.7943 38.1001L16.9272 38.0709L17.1867 38.0158C18.7111 37.6947 20.4723 36.9487 22.1523 35.914C23.1091 35.3237 24.0465 34.6394 24.9319 33.8772C25.9017 33.0436 26.8228 32.1128 27.6629 31.1138C30.3679 27.8964 33.5885 22.3372 33.5885 14.1185C33.5788 13.0514 33.5529 11.9681 33.4977 10.901Z"
                  fill="url(#paint0_linear_103_6)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_103_6"
                    x1="16.7845"
                    y1="0.202131"
                    x2="16.7845"
                    y2="38.0203"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#30C1FF" />
                    <stop offset="1" stop-color="#4741BF" />
                  </linearGradient>
                </defs>
              </svg>
              &nbsp;{" "}
              <span
                style={{
                  color: "#004265",
                  fontSize: "20px",
                  fontWeight: "normal",
                  fontWeight: "400",
                }}
              >
                Secure Vault for{" "}
              </span>
              <span style={{ color: "rgb(35 92 122)", fontWeight: "700" }}>
                {Customer != null ? Customer.identity : "Customer"}
              </span>
            </p>

            <div
              className="col-12"
              style={{
                backgroundColor: "white",
                borderRadius: "25px",
              }}
            >
              <br />

              <div className="row">
                <div className="col-md-4">
                  <img
                    src="assets/images/homelogoleft.png"
                    style={{ width: "65%" }}
                    className="hidemobilelogosone"
                  />
                </div>
                <div className="col-md-4 mt-3 imageattachbank">
                  {hasLogo ? (
                    <img
                      src={hasLogo}
                      className="logo-icon"
                      alt="logo icon"
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "3pc",
                      }}
                    />
                  ) : (
                    <img
                      src="assets/images/logoVixo.png"
                      className="logo-icon"
                      alt="logo icon"
                      style={{
                        width: "185px",
                        height: "136px",
                        marginTop: "-2.3rem",
                      }}
                    />
                  )}
                </div>

                <div className="col-md-4 ">
                  <img
                    src="assets/images/homelogoright.png"
                    style={{ width: "65%" }}
                    className="hidemobilelogostwo"
                  />
                </div>
              </div>
              <br />

              <div className="containerFluid organizationtextmobile">
                <div
                  className="row"
                  style={{ backgroundColor: "white", borderRadius: "25px " }}
                >
                  <div className="col-md-2"></div>
                  <div className="col-md-8 mt-3 mb-3">
                    <p
                      className=" mt-lg-auto"
                      style={{
                        fontWeight: 500,
                        fontSize: "22px",
                        color: "#004265",
                      }}
                    >
                      {" "}
                      {OrgName ? OrgName : "Vixo Login"} we take the security of
                      our Customer Portal seriously. That's why we've partnered
                      with Vixo Link to provide our clients with an instant
                      verification process for banking, payroll, financials,
                      payments, and ID verification.
                    </p>
                    <br />
                  </div>
                  <div className="col-md-2"></div>

                  <div className="row organizationlogo">
                    <div className="col-0 col-md-3 d-none d-md-block"></div>
                    <div className="col-4 col-md-2">
                      <img
                        src="assets/images/FincityMasterLogo.png"
                        className="logo-icon logoforvixo"
                        alt="logo icon"
                        style={{ width: "70%", height: "auto" }}
                      />
                    </div>
                    <div className="col-4 col-md-2 logosformobile">
                      <img
                        src="assets/images/OcrolusLogo.png"
                        className="logo-icon logosfororoclous"
                        alt="logo icon"
                        style={{
                          width: "90%",
                          // marginTop: "-1.9rem",
                        }}
                      />
                    </div>
                    <div className="col-4 col-md-2 logosformobile2">
                      <img
                        src="assets/images/PlaidLogo.png"
                        className="logo-icon logoforplaid"
                        alt="logo icon"
                        style={{ width: "70%", height: "auto" }}
                      />
                    </div>
                    <div className="col-0 col-md-3 d-none d-md-block"></div>
                  </div>
                </div>

                <div
                  className="container-fluid mt-4 p-4 bankaccount_Tab"
                  style={{ width: "auto" }}
                >
                  <div className="tabbuttonformobile">
                    {/* Dekstop View  */}
                    <ul
                      className="navbar-nav align-items-center desktop_view_button"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        flexwrap: "nowrap",
                      }}
                    >
                      {identityTabVisible && (
                        <li className={showConsent ? 'disabled' : ''}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="186"
                            height="41"
                            viewBox="0 0 186 41"
                            onClick={handleBankingClick}
                          >
                            <path
                              d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                              fill={bankingTabColor}
                              stroke="#BECDDB"
                            />
                            <text
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="#000"
                            >
                              Banking
                            </text>
                          </svg>
                        </li>
                      )}
                      {identityTabVisible && (
                        <li className={showConsent ? 'disabled' : ''}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="186"
                            height="41"
                            viewBox="0 0 186 41"
                            style={{ marginLeft: "-28px" }}
                            onClick={handleIdentityClick}
                          >
                            <path
                              d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                              fill={filesTabColor}
                              stroke="#BECDDB"
                            />

                            <text
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="#000"
                            >
                              ID
                            </text>
                          </svg>
                        </li>
                      )}
                    </ul>
                    {/* Desktop View End  */}

                    {/* Mobile View  */}
                    {identityTabVisible && (
                      <div className="mobile-view_for_Tab_buttons">
                        <FontAwesomeIcon
                          icon={faBuilding}
                          onClick={handleBankingClick}
                          className={activeTab === "banking" ? "active" : ""}
                        />

                        <FontAwesomeIcon
                          icon={faUser}
                          onClick={handleIdentityClick}
                          className={activeTab === "identity" ? "active" : ""}
                        />
                      </div>
                    )}
                    {/* Mobile View End  */}
                  </div>

                  {/* {showBankingContent && (
                    <CustomerBanking Customer={Customer} />
                  )}
                  {ShowIdentityContent && (
                    <IdentityVerificationRequired
                      Customer={Customer}
                      CustomerIdentityResponseData={
                        CustomerIdentityResponseData
                      }
                    />
                  )} */}

                  {/* <TermsConditions
                    Customer={Customer}
                    SingedContract={TermsSigned}
                  /> */}
                  {showBankingContent && (
                    <CustomerBanking Customer={Customer} IsDisabled={showConsent} />
                  )}
                  {ShowIdentityContent  && (
                    <div disabled={showConsent}>
                    <IdentityVerificationRequired
                      Customer={Customer}
                      CustomerIdentityResponseData={
                        CustomerIdentityResponseData
                      }
                    />
                    </div>

                  )}
                  {showConsent == true ? (
                    <TermsConditions
                      Customer={Customer}
                      SingedContract={ConsentSigned}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center", padding: "5px", bottom: "0" }}>
          I Acknowledge and Agree to abide by the{" "}
          <Link to="/terms" target="_blank" style={{ textDecoration: "none" }}>
            {" "}
            Terms and Conditions{" "}
          </Link>{" "}
        </div>
        <div className="copyright"></div>
      </main>
    </>
  );
};

export default AttachBank;
