import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import PaymentTab from "./Payment";
import { json, useLocation } from "react-router-dom";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import "./Payment.css";
import { data } from "./Transaction";
import { useSelector } from "react-redux";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
const PaymentDetails = ({ showModal, handleModalClose }) => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    websiteURL: "",
    phoneNumber: "",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const handleSendClick = () => {
    setShowSuccessModal(true);
    handleModalClose();
  };

  /////get user////
  const [CustomerKey, setCustomerKey] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [OrganizationPaymentRequestDetail, setOrganizationPaymentRequestDetail] = useState(null);
  const [OrganizationPaymentRequestDetailMessage, setOrganizationPaymentRequestDetailMessage] = useState(null);
  const [showRequestButton, setShowRequestButton] = useState(true);
  const [FundingRequestResponseMessage, setFundingRequestResponseMessage] = useState(null);

  const [User, setUser] = useState(null);
  const [error, setError] = useState();

  const notification = useSelector(selectNotification);

  function GotNewAlert(notification) {
    if (
      notification != null &&
      notification.referenceID != null &&
      notification.notificationType == 16 &&
      notification.organiationID ==  User.organizationId
    ) {
      // setAlertCount((prevCount) => prevCount + 1);
    //  ReloadCustomerAccount(selectedAccount);
    GetOrganizationPaymentRequestDetail(User.organizationId);
    }
  }

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function GetOrganizationPaymentRequestDetail(OrganizationID) {
    if(HasLoggedInUser()){
    try {
      const OrgAPIPath = `${VixoAPI.FundingAPI}GetOrganizationPaymentRequestDetail?OrganizationID=${OrganizationID}`;
      setLoading(true);
      var config=APIHeader();
      axios
        .get(OrgAPIPath, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setOrganizationPaymentRequestDetail(response.data);
          } else {
            setOrganizationPaymentRequestDetail(null);
            ShowOrganizationRequestDoesNotExist(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(false);
    } catch (error) {
      //setErrorType(error.message);
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate,CustomerKey);
        }
    }
  }else
  {
     ExpireToken(navigate,CustomerKey);
  }
  }

  function ShowOrganizationRequestDoesNotExist(message) {
    setOrganizationPaymentRequestDetailMessage(message);
  }


  const CreatePaymentApprovalRequest = async (e) => {
    const data = {
      UserKey: UserKey,
    };
    try {
      const FundAPIPath = VixoAPI.FundingAPI + "CreatePaymentApprovalRequest";
      setLoading(true);
      axios
        .post(FundAPIPath, data, {headers:APIHeader().headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setFundRequestSuccess(response.message);
            setShowRequestButton(false);
          } else {
            setError(response.message);
            //showModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      //setErrorType(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lUserKey = params.get("U");

    if (lUserKey) {
      setUserKey(lUserKey);
    }
   
  }, [location]);


  useEffect(() => {
    if (UserKey) {
      LoadUser();
    }
  }, [UserKey]);

  useEffect(() => {
    if (User && User.organizationId) {
      GetOrganizationPaymentRequestDetail(User.organizationId);
    }
  }, [User]);

  function LoadUser() {
    if(HasLoggedInUser()){
      const config= APIHeader();
    try {
      setLoading(true);
      var Key = encodeURIComponent(UserKey);
      const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
      setLoading(true);
      axios
        .get(GetUserByKeyAPI, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setUserDataFromAPI(response.data);
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate,CustomerKey);
        }
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  function setUserDataFromAPI(data) {

    try {
      setLoading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }


  function setFundRequestSuccess(successMessage) {
    try {
      setLoading(true);
      setFundingRequestResponseMessage(successMessage);
      setShowRequestButton(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  return (
    <>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">Request Payments to Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <p>
              {" "}
              <img
                src="assets/images/logoVixo.png "
                style={{
                  width: "170px",
                  height: "136px",
                  justifyContent: "center",
                  textAlign: "center ",
                  marginLeft: "7rem",
                }}
              />
            </p>
            <br />
            { OrganizationPaymentRequestDetail!=null && (OrganizationPaymentRequestDetail.status!=3)? (<p style={{textAlign:"center"}}>{OrganizationPaymentRequestDetail.requestStatusMessage}</p>): (
             <>
             {showRequestButton==true?(<>
                 {OrganizationPaymentRequestDetail!=null?(<p style={{textAlign:"center"}}>{OrganizationPaymentRequestDetail.requestStatusMessage}</p>) :(<p style={{textAlign:"center"}}>{OrganizationPaymentRequestDetailMessage}</p>)}
             </>):("")}
            <p style={{textAlign:"center"}}>{FundingRequestResponseMessage}</p>
            <p style={{ marginLeft: "30px" }}>{error}</p>
            <p className="justify-content-center text-center">
             
             {showRequestButton?(
                <button
                  className="form-control btn btn-primary w-25 end-0 mt-4 mb-4 animated-button1"
                  onClick={CreatePaymentApprovalRequest} // Handle Send button click
                >
                  {" "}
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Request
                </button>
                ):("")}
             
            </p></> ) }
          </div>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="animate-charcter fw-bold">{FundingRequestResponseMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="animated-button1"
            onClick={() => setShowSuccessModal(false)}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentDetails;
