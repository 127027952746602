import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import File from "./File";
import { useNavigate } from "react-router-dom";

const StatementUploadPopup = ({
  show,
  handleModalClose,
  files,
  customerkey,
  NewStatementUploaded,
}) => {
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const [UserKey, setUserKey] = useState("");
  const [RefreshChildComponent, setRefreshChildComponent] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate =useNavigate()

  useEffect(() => {
    LoadBankByCustomerKey();
  }, []);
  ////get bankdata using customerID

  const [bankdata, setBankdata] = useState(null);

  function LoadBankByCustomerKey() {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      // setLoading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getbyCustomerKey?Key=${customerkey}`;
      // setLoading(true);
      axios
        .get(GetCustomerBankId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setBankData(response.data);
          } else {
            // Handle error if needed
          }
        })
        .catch((error) => {
          // setLoading(false);
          throw error;
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      // setLoading(false);
      throw error;
    }
  }
  else{
    navigate('/Home')
  }
  }

  function setBankData(cusbankdata) {
    try {
      setLoading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileCheckboxes, setFileCheckboxes] = useState({});

  // Function to handle file checkbox changes
  const handleFileCheckboxChange = (e, file) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((selectedFile) => selectedFile !== file)
      );
    }
    setFileCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [file.name]: isChecked,
    }));
  };

  const UploadFile = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();

    const data = new FormData(); // Create a FormData object
    data.append("AccountId", selectedAccountNumber);
    data.append("UploadedDate", currentDate);
    data.append("StatementTitle", "Ali");
    data.append("UserKey", data.UserKey);

    // Append checkbox values for each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append("Files", file); // Append the file itself
      data.append("isCheckedList[]", false); // Append the checkbox value
      data.append("UserKey", data.UserKey);
    }

    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      const UploadStatmentAPI =
        VixoAPI.StatmentAPI + "uploadStatementCustomer?Key=" + customerkey;
      setLoading(true);
      axios
        .post(UploadStatmentAPI, data, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            handleModalClose(response.data);
            NewStatementUploaded();
          } else {
            toast(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
  else{
    ExpireToken(navigate);
  }
  };
 


  return (
    <>
      <ToastContainer />
      <Spinner show={loading} Title="Statement Upload Popup" />
      <Modal className="filesbank" show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select or Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* ////// get all bank  */}

          <div className="row">
            <select
              className="form-control-lg"
              value={selectedAccountNumber}
              onChange={(e) => setSelectedAccountNumber(e.target.value)}
            >
              <option value="">Create new Account</option>
              {bankdata != null &&
                bankdata.map((bank, index) => (
                  <option key={index} value={bank.id}>
                    {bank.accountTitle} {bank.accountNumberlastFourDigits}
                  </option>
                ))}
            </select>
          </div>
          {/* /////get all bank end  */}
          <br />
          {files.map((file) => (
            <div key={file.name}>
              <label>{file.name}</label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary "
            onClick={handleModalClose}
            style={{ borderRadius: "20px", width: "7pc", color: "white" }}
          >
            Cancel
          </button>
          <button
            className="btn btn-info"
            onClick={UploadFile}
            style={{ borderRadius: "20px", width: "7pc", color: "white" }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatementUploadPopup;
