export const Environment = {
  ENV: process.env.REACT_APP_ENV, // 'DEV' ,'PRODUCTION' ,LOCAL
};

export const VixoAPILocal = {
  CustomeDashboard: "http://localhost:3001/",
  DashBoard: "http://localhost:3000/", 
  OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
  FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity/",
};

export const VixoAPIProductionLocal = {
  CustomeDashboard: "http://localhost:3001/",
  DashBoard: "http://localhost:3000/",
  OrganizationAPI: "https://vixoapimanagement2.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement2.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement2.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement2.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement2.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement2.azure-api.net/vixodoc/",
  StatmentAPI: "https://localhost:7007/",
  OCRAPI: "https://localhost:7029/",
  AlertsAPI: "https://vixoapimanagement2.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement2.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement2.azure-api.net/vixolookup/",
  FinicityAPI: "https://vixoapimanagement2.azure-api.net/vixofinicity/",
};

export const VixoAPIDev = {
  CustomeDashboard: "https://devhome.vixolink.com/",
 DashBoard: "https://devdashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
  FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity/",
};

export const VixoAPIStaging = {
  CustomeDashboard: "https://staginghome.vixolink.com/",
  DashBoard: "https://stagingdashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement4.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement4.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement4.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement4.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement4.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement4.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement4.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement4.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement4.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement4.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement4.azure-api.net/vixolookup/",
  FinicityAPI: "https://vixoapimanagement4.azure-api.net/vixofinicity/",
};

export const VixoAPIProduction = {
  CustomeDashboard: "https://home.vixolink.com/",
  DashBoard: "https://dashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement2.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement2.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement2.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement2.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement2.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement2.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement2.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement2.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement2.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement2.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement2.azure-api.net/vixolookup/",
  FinicityAPI: "https://vixoapimanagement2.azure-api.net/vixofinicity/",
};

export const VixoAPI = {
  CustomeDashboard: GetEnvironmentBasedAPI().CustomeDashboard,
  DashBoard: GetEnvironmentBasedAPI().DashBoard,
  OrganizationAPI: GetEnvironmentBasedAPI().OrganizationAPI,
  NotificationAPI: GetEnvironmentBasedAPI().NotificationAPI,
  AuthenticationAPI: GetEnvironmentBasedAPI().AuthenticationAPI,
  CustomerAPI: GetEnvironmentBasedAPI().CustomerAPI,
  UserAPI: GetEnvironmentBasedAPI().UserAPI,
  AccountAPI: GetEnvironmentBasedAPI().AccountAPI,
  DocumentAPI: GetEnvironmentBasedAPI().DocumentAPI,
  StatmentAPI: GetEnvironmentBasedAPI().StatmentAPI,
  OCRAPI: GetEnvironmentBasedAPI().OCRAPI,
  AlertsAPI: GetEnvironmentBasedAPI().AlertsAPI,
  FundingAPI: GetEnvironmentBasedAPI().FundingAPI,
  LookupAPI: GetEnvironmentBasedAPI().LookupAPI,
  FinicityAPI: GetEnvironmentBasedAPI().FinicityAPI,
};

export function GetEnvironmentBasedAPI() {
  if (Environment.ENV.trim().toUpperCase() == "LOCAL") {
    return VixoAPILocal;
  } else if (Environment.ENV.trim().toUpperCase() == "PRODUCTIONLOCAL") {
    return VixoAPIProductionLocal;
  } else if (Environment.ENV.trim().toUpperCase() == "STAGING") {
    return VixoAPIStaging;
  } else if (
    Environment.ENV.trim().toUpperCase() == "DEV" ||
    Environment.ENV.trim().toUpperCase() == "DEVELOPMENT"
  ) {
    return VixoAPIDev;
  } else {
    return VixoAPIProduction;
  }
}
