import React, { useState } from "react";
import { Spinner, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./CommunicationTimeline.css";

const CommunicationTimelineFiles = ({ communicationTimelineData }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <ToastContainer />

      <main className="page-content">
        <div className="container-fluid">
          <div className="row">
            {communicationTimelineData.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  className="col-md-4 text-center animated mt-2"
                  style={{
                    borderRadius: "10px",
                    background: "white",
                    border: "1px solid",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "rgb(13, 110, 253)",
                      color: "white",
                    }}
                  >
                    Statement
                  </p>
                  <div>
                    <p
                      className="text-center"
                      style={{ marginTop: "17px", cursor: "pointer" }}
                    >
                      <span style={{ display: "block", overflow: "hidden" }}>
                        <embed
                          src={item.fileSha1Hash}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                          style={{ overflow: "hidden" }}
                        />
                      </span>
                    </p>
                    <div className="card-body m-auto">
                      <h6 className="card-title">{item.statementTitle}</h6>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default CommunicationTimelineFiles;
