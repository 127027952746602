const ToolTipList = {
  AccountOwner_2: "ACCOUNT OWNER retrieves the owner name and address",
  AccountOwner_3:
    "ACCOUNT OWNER won't retrieve data because the account is disconnected",
  AccountOwner_5:
    "ACCOUNT OWNER is disabled because the bank account was established using OCR on Vixolink.com",
  AccountOwner_6:
    "ACCOUNT OWNER is disabled due to the removed connection on Vixolink.com ",
  ACHDetail_2: "ACH DETAILS retrieves the routing number and account number",
  ACHDetail_3:
    "ACH DETAILS won't retrieve data because the account is disconnected",
  ACHDetail_5:
    "ACH DETAILS is disabled because the bank account was established using OCR on Vixolink.com",
  ACHDetail_6:
    "ACH DETAILS was disabled due to a removed connection on Vixolink.com ",
  Days90_2: "PAST 90 DAYS retrieves transactions and the latest transaction",
  Days90_3:
    "PAST 90 DAYS won't retrieve data because the account is disconnected",
  Days90_5:
    "PAST 90 DAYS is disabled because the bank account was established using OCR on Vixolink.com",
  Days90_6:
    "PAST 90 DAYS was disabled due to a removed connection on Vixolink.com ",
  LongHistory_2: "LONG HISTORY retrieves data from the last three months",
  LongHistory_3:
    "LONG HISTORY won't retrieve data because the account is disconnected",
  LongHistory_5:
    "LONG HISTORY is disabled because the bank account was established using OCR on Vixolink.com",
  LongHistory_6:
    "LONG HISTORY was disabled due to a removed connection on Vixolink.com",
  ResendInvite_6: "Click to resend invitation",
  Docusign: "You can Send A Document to Customer for Signature",
  VerifiedProfile: "Customer Profile is not Verified",
  UnverifiedProfile: "Customer Profile is Unverified",
};
export default ToolTipList;
