import React from 'react';

const Money = ({ value }) => {
  // Assuming amount is a number
  var formattedAmount ="";
  if(value)
  { 
    formattedAmount=value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD', // Change this according to your currency
    minimumFractionDigits: 2,
  });

}
  return <div>{formattedAmount}</div>;
};

export default Money;

