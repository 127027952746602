import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import "./file.css";
import { useNavigate } from "react-router-dom";

const CustomerUploadDocument = ({ customerkey, NewDocumentUploaded }) => {
  const [loading, setLoading] = useState(false);
  const documentInputRef = useRef(null);
  const childRef = useRef(null);
  const navigate = useNavigate();

  const [alertCount, setAlertCount] = useState(0);
  const [RefreshChildComponent, setRefreshChildComponent] = useState(1);

  const [selectAllOuter, setSelectAllOuter] = useState(false);
  const handleSelectAllCheckboxChange = () => {
    setSelectAllOuter(!selectAllOuter);
  };

  const [formData, setFormData] = useState({
    DocumentTitle: "dummy title",
    Files: null,
    UploadedDate: "",
    Location: "USA",
  });

  const handleDocument = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    const selectedFiles = e.target.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select one or more files.");
      return;
    }

    const data = new FormData();
    data.append("DocumentTitle", formData.DocumentTitle);
    data.append("UploadedDate", currentDate);
    data.append("Location", formData.Location);
    data.append("UserKey", FormData.UserKey);

    for (let i = 0; i < selectedFiles.length; i++) {
      data.append("Files", selectedFiles[i]);
    
    }
      if(HasLoggedInUser())
        {
          const config=APIHeader();
        
        try {
          const DocumentAPIPath = VixoAPI.DocumentAPI + "upload?Key=" + customerkey;
          setLoading(true);
          axios
          .post(DocumentAPIPath, data, {headers:config.headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              documentInputRef.current.value = "";
              NewDocumentUploaded();
            } else {
              toast(response.message);
            }
          })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
      } catch (error) {
        if(error.response && error.response.status && error.response.status==401)
          {
             ExpireToken(navigate, customerkey);
          }
      }
    }
    else{
      ExpireToken(navigate,customerkey);
    }
  
    };
    


  const handleDocClick = () => {
    if (documentInputRef.current) {
      documentInputRef.current.click();
    }
  };
  return (
    <>
      <ToastContainer />
      {/* <Spinner show={loading}  Title="Customer Upload Document"/> */}

      <h6
        className="text-start"
        style={{ fontSize: "18px", color: "#004265", fontWeight: "400" }}
      >
        Upload Document
      </h6>
      <fieldset
        className="upload_dropZone text-center mb-3 p-4"
        // onClick={handleDocClick}
        style={{ cursor: "pointer" }}
      >
        <legend className="visually-hidden">Document uploader</legend>
        <label
          // className="mb-3"
          htmlFor="formFileLg"
          style={{
            marginTop: "-2px",
            color: "#004265",
            fontSize: "20px",
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
            display: "inline-block",
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            fontWeight: "600",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            <input
              id="formFileLg"
              className="form-control"
              ref={documentInputRef}
              type="file"
              multiple
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "600",
              }}
              onChange={handleDocument}
            />
          </div>
          <img src="assets/images/fileuploader.svg" alt="Document Uploader" />
          <br />
          <h2
            className="mt-3"
            style={{ fontSize: "20px", color: "#004265", fontWeight: "600" }}
          >
            Upload Document
          </h2>

          <p
            className="mt-3"
            style={{
              color: "#004265",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "300",
            }}
          >
            Drag and drop files here
          </p>
        </label>
      </fieldset>
    </>
  );
};

export default CustomerUploadDocument;
