// import React, { useState, useEffect } from "react";
// import Bankcheckbox from "./Bankcheckbox";
// import ReactApexChart from "react-apexcharts";
// import axios from "axios";
// import Spinner from "../../Spinner";
// import { VixoAPI } from "../../settings";

// const Income = () => {
//   const [TransactionType, setTransactionType] = useState(0);
//   const [chartData, setChartData] = useState([]);
// const [chartOptions, setChartOptions] = useState({
//   chart: {
//     id: 'multi-series-line-chart',
//   },
//   xaxis: {
//     categories: [], // We'll populate this with account names
//   },
// });
// useEffect(() => {
//   setTransactionType(1);
// }, []);

//   const [loading, setLoading] = useState(false);
//   const [incomdata, setIncomdata] = useState([]);

//   const clearIncome = () => {
//     setIncomdata(null);

//   };

//   const [selectedValue, setSelectedValue] = useState();
//   const [accountIds, setAccountIds] = useState([]);
//   const handleDropdownChange = (value) => {
//     const val = parseInt(value, 10);
//     setSelectedValue(value);
//     const name = '';
//     handleAccountClick(accountIds,name, val);
//     // You can update the state or perform other actions related to the dropdown change here.
//   }

//   const handleAccountClick = (accountIds, name, value) => {
//     if (accountIds == null || accountIds.length === 0) {
//       clearIncome();
//       return;
//     }
//     var Period = 0;

//     if(value!=null){
//       Period = value;
//     }
//     setAccountIds(accountIds);
//     const accountIdArray = Array.isArray(accountIds)
//       ? accountIds
//       : [accountIds];
//     const data = {
//       AccountsIDs: accountIdArray,
//       DatePeriod: Period,
//       TransactionTypes:TransactionType

//     };

//     setLoading(true);

//     const GetIncome = `${VixoAPI.AccountAPI}getAccountTransactions`;

//     axios
//       .post(GetIncome, data)
//       .then((response) => {
//         var responseData = response.data;
//         if (responseData.statusCode === 200) {
//           const combinedData = responseData.data.flatMap((item) => {
//             const accountDetails = item.account;
//             const transactions = item.transactions;

//             return {
//               accountDetails,
//               transactions,
//             };
//           });

//           setIncomdata(combinedData);
//           setChartDataSeries(responseData.data);
//         } else {
//           clearIncome();
//         }
//       })
//       .catch((error) => {
//         setLoading(false);
//         throw error;
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   const [GroupValue, setGroupValue] = useState(0);

//   const ChangeChartXAxis = (event) => {
//     const newValue = event.target.value;
//     setGroupValue(newValue);

//     // Depending on the selected option, update the X-axis categories
//     if (newValue === '1') { // Monthly
//       const newCategories = generateMonthlyLabels(); // Implement your logic to generate monthly labels
//       setChartData({ ...chartData, xaxis: { categories: newCategories } });
//     } else { // Weekly or other options
//       // Set X-axis categories for other options
//       // Implement your logic here
//     }
//   };

//   function setChartDataSeries(GraphData) {
//     if (Array.isArray(GraphData) && GraphData.length > 0) {
//       // Create an object to store transaction counts per month for each account
//       const accountData = {};

//       GraphData.forEach((item) => {
//         const accountDetails = item.account;
//         const transactions = Array.isArray(item.transactions) ? item.transactions : [];

//         transactions.forEach((transaction) => {
//           const transactionDate = new Date(transaction.transactionDate);
//           const month = transactionDate.getMonth(); // Get the month (0-11)

//           // Initialize the account data if it doesn't exist for this account
//           if (!accountData[accountDetails.accountTitle]) {
//             accountData[accountDetails.accountTitle] = {
//               name: accountDetails.accountTitle,  // Account title
//               data: Array(12).fill(0),           // Initialize an array for 12 months
//             };
//           }

//           // Increment the count for this month
//           accountData[accountDetails.accountTitle].data[month]++;
//         });
//       });

//       // Extract the month names for the X-axis
//       const monthNames = [
//         'January', 'February', 'March', 'April', 'May', 'June',
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];

//       // Extract the account data for the Y-axis
//       const accountSeries = Object.values(accountData);

//       // Update the X-axis categories with month names and the Y-axis with account data
//       setChartOptions((prevOptions) => ({
//         ...prevOptions,
//         xaxis: {
//           categories: monthNames,
//         },
//       }));

//       setChartData(accountSeries);
//     } else {
//       // Handle the case where GraphData is not an array or is empty
//       // You can set some default values or show an error message here.
//     }
//   }

//   function formatTransactionDate(date) {
//     const months = [
//       'January', 'February', 'March', 'April', 'May', 'June',
//       'July', 'August', 'September', 'October', 'November', 'December'
//     ];

//     const formattedDate = date ? new Date(date) : null;
//     if (!formattedDate) return ''; // Handle undefined or null dates

//     const year = formattedDate.getFullYear();
//     const month = formattedDate.getMonth();

//     return `${months[month]} ${year}`;
//   }

//   function generateMonthlyLabels(startDate, endDate) {
//     const labels = [];
//     let currentDate = new Date(startDate);

//     while (currentDate <= endDate) {
//       const month = currentDate.toLocaleString('default', { month: 'long' });
//       const year = currentDate.getFullYear();
//       labels.push(`${month} ${year}`);
//       currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
//     }

//     return labels;
//   }

//   ////filter
//   const [searchQuery, setSearchQuery] = useState("");

//   const handleSearchInputChange = (e) => {
//     const query = e.target.value;
//     setSearchQuery(query);

//     // Filter the data for the grid
//     const filteredGridData = incomdata.filter((trn) =>
//       trn.description.toLowerCase().includes(query.toLowerCase())
//     );

//   };

//   return (
//     <>
//       <Spinner show={loading} />
//       <div className="row">
//         <Bankcheckbox handleAccountClick={handleAccountClick} />
//       </div>
//       <div className="row">
//         <div className="col-4">
//           <input
//             className="form-control w-50 searchbartabbmob"
//             placeholder="Seacrh"
//             value={searchQuery}
//             onChange={handleSearchInputChange}
//           />
//         </div>
//         <div className="col-4 text-center">
//         <select
//             style={{ alignContent: "right", border: "20%" }}
//             className="dropdowntab dropdowntabmob w-50 h-100 rounded"
//             value={GroupValue}
//         onChange={ChangeChartXAxis}
//           >
//             <option value={0}>Weekly</option>
//             <option value={1}>Monthly</option>

//           </select>
//         </div>
//         <div className="col-4 text-end ">
//           <select
//             style={{ alignContent: "right", border: "20%" }}
//             className="dropdowntab dropdowntabmob w-50 h-100 rounded"
//         onChange={(e) => handleDropdownChange(e.target.value)}
//           >
//             <option value='0'>All Time</option>
//             <option value='1'>This Month</option>
//             <option value='2'>Last Month</option>
//             <option value='3'>Last Three Month</option>
//             <option value='4'>Last Six Month</option>
//             <option value='5'>Last Nine Month</option>
//             <option value='6'>This Year</option>
//             <option value='7'>Last Year</option>
//             <option value='8'>Custom Date</option>
//           </select>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-12">
//         <div id="multi-series-line-chart">
//         <ReactApexChart options={chartOptions} series={chartData} type="line" height={300} />

//     </div>
//         </div>
//       </div>
//       <div className="row mt-5">
//         <div className="card">
//           <div className="card-body">
//             <div className="table-responsive">
//               <table
//                 id="example"
//                 className="table table-striped"
//                 style={{ width: "100%" }}
//               >
//                 <thead>
//                   <tr>
//                   <th>Account details</th>
//                     <th>Date</th>
//                     <th>Description</th>
//                     <th>Amount</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {incomdata &&
//                     incomdata.map((accountItem, outerIndex) => {
//                       let firstRowInGroup = true; // Track the first row in each group
//                       return accountItem.transactions && accountItem.transactions.map((trn, innerIndex) => (
//                         <tr key={innerIndex}>
//                           {/* Only render the Date and Account Details for the first row in each group */}
//                           {firstRowInGroup && (
//                             <>
//                               <td
//                                 rowSpan={accountItem.transactions.length}
//                                 title={`Account Number: ${accountItem.accountDetails.accountNumber}`}
//                               >
//                                 {accountItem.accountDetails.accountType}{" ,"}
//                                 {accountItem.accountDetails.accountTitle}{" ,"}
//                                 {accountItem.accountDetails.instituteName}
//                               </td>
//                             </>
//                           )}
//                           <td>{trn.transactionDate}</td>
//                           <td>{trn.description}</td>
//                           <td>{trn.amount}</td>
//                           {firstRowInGroup && (firstRowInGroup = false)}
//                           {/* Mark that we've rendered the details */}
//                         </tr>
//                       ));
//                     })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Income;

// import React, { useState } from "react";
// import IncomeTransaction from "../IncomeExpenseTransactions/IncomeTransaction";

// const Income = ({custDownload}) => {
//   const [TransactionType, setTransactionType] = useState(1); //1- Mean Income
//   return (
//     <>
//       <IncomeTransaction TransactionType={TransactionType} trngrpah={custDownload} />
//     </>
//   );
// };

// export default Income;

import React, { useState, lazy, Suspense } from "react";

// Use React.lazy to lazily load the IncomeTransaction component
const LazyIncomeTransaction = lazy(() => import("../IncomeExpenseTransactions/IncomeTransaction"));

const Income = ({custDownload,IsOpenedFromDownload,tabClicked}) => {
  // const [TransactionType, setTransactionType] = useState(1); // 1- Mean Income

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {/* Use LazyIncomeTransaction here */}
        <LazyIncomeTransaction TransactionType={1} tabClicked={tabClicked} trngrpah={custDownload} IsOpenedFromDownload={IsOpenedFromDownload} />
      </Suspense>
    </>
  );
};

export default Income;

