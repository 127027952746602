import React, { useState, useEffect, useRef } from "react";
import { Card, Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "react-bootstrap";

import {
  faEye,
  faPaperPlane,
  faFileSignature,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomerNavbar from "../Header/CustomerNavbar";
import "../../UploadFiles.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import APIErrorComponent from "../../APIErrorComponent";
import { ClimbingBoxLoader } from "react-spinners";
const DocuSign = ({ customerkey, userkey }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [APIResponse, setAPIResponse] = useState(null);
  const [OrganizationDocuments, setOrganizationDocuments] = useState([]);
  const [CustomerKey, setCustomerKey] = useState(null);
  const [DocusignCallback, setDocusignCallBack] = useState(null);
  const [Userkey, setUserkey] = useState(null);
  const [User, setUser] = useState(null);
  const location = useLocation();
  const [isAllDocuSignFilesSelected, setIsAllDocuSignFilesSelected] = useState(
    false
  );
  const [docuSignFiles, setDocuSignFiles] = useState([]);
  const [
    tempCustomerUploadedDocuments,
    setTempCustomerUploadedDocuments,
  ] = useState([]);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState({});
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState({});
  const [Doucmentid, setDoucmentid] = useState({});
  const [OrganizationId, setOrganizationId] = useState("");
  const [errorType, setErrorType] = useState("");
  const [sentDocuments, setSentDocuments] = useState([]);
  const [documentsToSend, setDocumentsToSend] = useState([]);
  const [
    isOrganizationCheckboxChecked,
    setisOrganizationCheckboxChecked,
  ] = useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [isSendButtonVisible, setIsSendButtonVisible] = useState(false);
  const [isCustomCheckboxChecked, setIsCustomCheckboxChecked] = useState();
  const [sentDocumentsCount, setSentDocumentsCount] = useState(0);
  const [editDocument, setEditDocument] = useState();
  const navigate = useNavigate();

  function LoadCustomer(customerKey) {
    // setLoading(true);
    if (HasLoggedInUser()) {
      const config = APIHeader();
      const LoadCustomerAPI =
        VixoAPI.CustomerAPI + "getCustomerBykey?Key=" + customerKey;

      return axios
        .get(LoadCustomerAPI, { headers: config.headers })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            return responseData.data.organizationId;
          } else {
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.error("Error loading customer:", error);
          throw error;
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      ExpireToken(navigate);
    }
  }

  function GetDocumentType(DocumentTitle) {
    if (DocumentTitle.toLowerCase().includes("pdf")) {
      return "application/pdf";
    } else {
      return "application/msword";
    }
  }

  function GetOrganizationDocument(OrganizationID) {
    if (HasLoggedInUser()) {
      try {
        const contractAPIPath =
          VixoAPI.FinicityAPI +
          "GetOrganizationDocuments?OrganizationID=" +
          OrganizationID;
        setLoading(true);

        axios
          .post(contractAPIPath, { headers: APIHeader().headers })
          .then((response) => {
            const responseData = response.data;
            if (responseData.statusCode === 200) {
              var CompanyDocuments = [];
              responseData.data.forEach((element) => {
                var companyDocument = {
                  Title: element.title,
                  IsSelected: false,
                  DocumentUrl: element.documentUrl,
                  Type: GetDocumentType(element.title),
                  DocumentID: element.id,
                  AlreadySent: false,
                };
                CompanyDocuments.push(companyDocument);
              });

              setOrganizationDocuments(CompanyDocuments);
            } else {
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error("Error in GetOrganizationDocumentByID:", error);
        setLoading(false);
      }
    } else {
      navigate("/Home");
    }
  }

  function LoadDocusignDocuments() {
    if (HasLoggedInUser()) {
      // setLoading(true);
      const config = APIHeader();
      const contractAPIPath =
        VixoAPI.FinicityAPI + "getDocusignDocuments?CustomerKey=" + customerkey;

      axios
        .get(contractAPIPath, { headers: config.headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            var totalSentDocumentsCount = 0;
            if (
              responseData.data &&
              responseData.data != null &&
              responseData.data.length > 0
            ) {
              totalSentDocumentsCount = responseData.data.length;
            }
            setSentDocumentsCount(totalSentDocumentsCount);
            // setLoading(false);
            AssignDocuSignFiles(responseData.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      ExpireToken(navigate);
    }
  }

  async function EditDocument(envelopeId) {
    // setLoading(true);
    if (HasLoggedInUser()) {
      try {
        const requestData = { envelopeId };

        const contractAPIPath = VixoAPI.FinicityAPI + "docusignUi";

        const response = await axios.post(contractAPIPath, requestData, {
          headers: APIHeader().headers,
        });

        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setEditDocument(responseData.data.returnUrl);
          window.open(responseData.data.returnUrl, "_blank");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setLoading(false);
      }
    } else {
      navigate("/Home");
    }
  }

  function MarkOrganizationDocumentSent(DocusignFile) {
    var OrganizationDocument = OrganizationDocuments.find(
      (p) => p.DocumentID === DocusignFile.organizationDocId
    );

    if (OrganizationDocument) {
      OrganizationDocument.AlreadySent = true;

      const updatedTempDocuments = OrganizationDocuments.map((tempdoc) =>
        tempdoc.DocumentID === OrganizationDocument.DocumentID
          ? { ...tempdoc, ...OrganizationDocument }
          : tempdoc
      );

      setOrganizationDocuments(updatedTempDocuments);
    }
  }
  function AssignDocuSignFiles(docusignAPIData) {
    if (docusignAPIData) {
      const DocusignFilesSource = [];
      var array = docusignAPIData;
      array.forEach((docusignFile) => {
        var docusignFileRequest = {
          Id: docusignFile.id,
          IsActive: docusignFile.isActive,
          Title: docusignFile.documentTitle,
          DocumentURL: docusignFile.documentUrl,
          Status: docusignFile.status,
          organizationDocId: docusignFile.organizationDocId,
          EnvelopId: docusignFile.envelopeID,
        };

        DocusignFilesSource.push(docusignFileRequest);
      });

      setDocuSignFiles(DocusignFilesSource);
    }
  }

  function setError(message) {
    setErrorType(message);
  }

  function LoadUser() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(Userkey);
        const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
        // setLoading(true);
        axios
          .get(GetUserByKeyAPI, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setUserDataFromAPI(response.data);
            } else {
            }
          })
          .catch((error) => {
            // setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, customerkey);
            }
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, customerkey);
    }
  }

  function setUserDataFromAPI(data) {
    try {
      setLoading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
      if (data && data.organizationId) {
        setOrganizationId(data.organizationId);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const handleCreateEnvelope = async () => {
    setLoading(true);

    const Files = [];
    const organizationDocumentIDs = [];

    OrganizationDocuments.forEach((element) => {
      if (element.IsSelected) {
        organizationDocumentIDs.push(element.DocumentID);
      }
    });

    tempCustomerUploadedDocuments.forEach((element) => {
      if (element.IsSelected) {
        Files.push({
          Title: element.Title,
          File: element.File,
        });
      }
    });

    const formData = new FormData();
    formData.append("CustomerKey", CustomerKey);
    formData.append("UserKey", Userkey);
    formData.append("InvitationMessage", "Please sign attached documents.");
    organizationDocumentIDs.forEach((id) => {
      formData.append("OrganizationDocuments", id);
    });

    Files.forEach((file) => {
      formData.append("Files", file.File, file.Title);
    });
    setAPIResponse(null);
    if (HasLoggedInUser()) {
      try {
        const contractAPIPath =
          VixoAPI.FinicityAPI + "uploadDocumentToCustomer4SignWithDocuUI";
        const response = await axios.post(contractAPIPath, formData, {
          headers: APIHeader().headers,
        });
        var APIResponse = response.data;
        if (APIResponse.statusCode === 200) {
          toast.success(APIResponse.message);
          setDocusignCallBack(APIResponse.data);

          LoadDocusignDocuments();
          setTempCustomerUploadedDocuments([]);

          setOrganizationDocuments((prevState) =>
            prevState.map((doc) => ({ ...doc, IsSelected: false }))
          );

          setisOrganizationCheckboxChecked(false);
          setIsCustomCheckboxChecked(false);
          window.open(APIResponse.data, "_blank");
        } else {
          setAPIResponse(APIResponse);
        }
      } catch (error) {
        console.error("Error while creating envelope:", error);
      } finally {
        setIsAllDocuSignFilesSelected(false);
        setLoading(false);
      }
    } else {
      navigate("/Home");
    }
  };

  function selectedDecuments(lTempDocuments) {
    setIsCustomCheckboxChecked(true);
  }

  const handleFileChange = async (event) => {
     setLoading(true);

    try {
      const uploadedFiles = event.target.files;
      var lTempDocuments = tempCustomerUploadedDocuments;
      //  const lTempDocuments = [...tempCustomerUploadedDocuments];
      const validFiles = [];
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];

        const fileType = file.name.split(".").pop();
        if (fileType === "docx" || fileType === "pdf") {
          validFiles.push(file);
        } else {
          toast.error(
            `Invalid file type: ${file.name}. Only .docx and .pdf files are allowed.`
          );
        }
      }

      validFiles.forEach((file) => {
        const tempDocument = {
          File: file,
          Title: file.name,
          IsSelected: true,
        };

        lTempDocuments.push(tempDocument);
      });
      setIsSendButtonVisible(true);
      setTimeout(async () => {
        setLoading(true);
        setTempCustomerUploadedDocuments(lTempDocuments);

        // Inner setTimeout to set loading to false after 1 second
        setTimeout(() => {
          CheckDocumentsToUpload();
          setLoading(false);
        }, 1000);
      }, 500);
      event.target.value = "";
    } catch (error) {
      toast.error("Failed to upload documents.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function CheckDocumentsToUpload() {
    // setIsCheckboxChecked(false);
    // var IsSelectedTempDocuents = tempCustomerUploadedDocuments.filter(
    //   (p) => p.IsSelected == true
    // );
    // if (IsSelectedTempDocuents != null && IsSelectedTempDocuents.length > 0) {
    //   setIsCheckboxChecked(true);
    // }

    const IsSelectedTempDocuents =
      tempCustomerUploadedDocuments.some((doc) => doc.IsSelected) ||
      OrganizationDocuments.some((doc) => doc.IsSelected);

    setSelectedDocumentIds(
      IsSelectedTempDocuents
        ? OrganizationDocuments.filter((doc) => doc.IsSelected).map(
            (doc) => doc.DocumentID
          )
        : []
    );

    setisOrganizationCheckboxChecked(IsSelectedTempDocuents);

    var IsSelectedOrgDocuents = OrganizationDocuments.filter(
      (p) => p.IsSelected == true
    );
    if (IsSelectedOrgDocuents != null && IsSelectedOrgDocuents.length > 0) {
      setisOrganizationCheckboxChecked(true);
    }
  }

  const handleOrganizationTopCheckboxChange = (event) => {
    const isChecked = event.target.checked;

    const updatedOrganizationDocuments = OrganizationDocuments.map((doc) => ({
      ...doc,
      IsSelected: isChecked,
    }));

    setSelectedDocumentIds(
      isChecked ? updatedOrganizationDocuments.map((doc) => doc.DocumentID) : []
    );
    setOrganizationDocuments(updatedOrganizationDocuments);

    const allDocumentsSelected =
      isChecked && updatedOrganizationDocuments.every((doc) => doc.IsSelected);

    setisOrganizationCheckboxChecked(allDocumentsSelected);
  };

  const handleOrganizationDocumentCheckboxChange = (event, doc) => {
    const isChecked = event.target.checked;
    doc.IsSelected = isChecked;
    const updatedTempDocyments = OrganizationDocuments.map((tempdoc) =>
      tempdoc.DocumentID == doc.DocumentID ? { ...tempdoc, ...doc } : tempdoc
    );
    setOrganizationDocuments(updatedTempDocyments);

    const allSelected = updatedTempDocyments.every((doc) => doc.IsSelected);
    setisOrganizationCheckboxChecked(allSelected);
    const anyDocumentSelected = updatedTempDocyments.some(
      (doc) => doc.IsSelected
    );

    setSelectedDocumentIds(
      anyDocumentSelected
        ? updatedTempDocyments
            .filter((doc) => doc.IsSelected)
            .map((doc) => doc.DocumentID)
        : []
    );
    setIsCustomCheckboxChecked(false);
  };

  const handleTempTopCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const UpdatedtempCustomerUploadedDocumentsdocument = tempCustomerUploadedDocuments.map(
      (doc) => ({ ...doc, IsSelected: isChecked })
    );
    setTempCustomerUploadedDocuments(
      UpdatedtempCustomerUploadedDocumentsdocument
    );
    setIsCustomCheckboxChecked(isChecked);
    // setIsSendButtonVisible(isChecked);
  };

  const handleTempDocumentCheckboxChange = (event, doc) => {
    const isChecked = event.target.checked;
    doc.IsSelected = isChecked;

    const updatedTempDocyments = tempCustomerUploadedDocuments.map((tempdoc) =>
      tempdoc.Title === doc.Title ? { ...tempdoc, ...doc } : tempdoc
    );
    setTempCustomerUploadedDocuments(updatedTempDocyments);

    // Check if any checkbox is unchecked, if so, uncheck the main checkbox
    const anyUnchecked = updatedTempDocyments.some((doc) => !doc.IsSelected);
    setIsCustomCheckboxChecked(!anyUnchecked);

    CheckDocumentsToUpload();
  };

  const handleModalToggle = (DocumentURL, title, id) => {
    setSelectedDocumentUrl(DocumentURL);
    setSelectedDocumentTitle(title);
    setDoucmentid(id);
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (showModal) {
      setLoading(true); // Set loading to true when modal is opened
    }
  }, [showModal, setSelectedDocumentUrl]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lUserKey = params.get("U");

    if (lUserKey) {
      setUserkey(lUserKey);
    }

    var lCustomerKey = params.get("Id");
    if (lCustomerKey) {
      setCustomerKey(lCustomerKey);
    }
  }, [location]);

  useEffect(() => {
    if (Userkey) {
      LoadUser();
    }
  }, [Userkey]);

  useEffect(() => {
    if (CustomerKey && CustomerKey != null) {
      LoadCustomer(CustomerKey);
      LoadDocusignDocuments();
    }
  }, [CustomerKey]);

  useEffect(() => {
    if (
      OrganizationDocuments &&
      OrganizationDocuments != null &&
      docuSignFiles &&
      docuSignFiles != null
    ) {
      FindOrganizationDocumentsFromDosuFiles();
    }
  }, [OrganizationDocuments, docuSignFiles]);

  useEffect(() => {
    if (OrganizationId) {
      GetOrganizationDocument(OrganizationId);
    }
  }, [OrganizationId]);

  const handleRefresh = () => {
    LoadDocusignDocuments();
  };

  useEffect(() => {
    const anyDocumentSelected =
      tempCustomerUploadedDocuments.some((doc) => doc.IsSelected) ||
      OrganizationDocuments.some((doc) => doc.IsSelected);
    setIsSendButtonVisible(anyDocumentSelected);
  }, [tempCustomerUploadedDocuments, OrganizationDocuments]);

  function FindOrganizationDocumentsFromDosuFiles() {
    docuSignFiles.forEach((element) => {
      if (element.organizationDocId && element.organizationDocId != null) {
        MarkOrganizationDocumentSent(element);
      }
    });
  }
  function checkIfAnyDocumentSelected() {
    return (
      tempCustomerUploadedDocuments.some((doc) => doc.IsSelected) ||
      OrganizationDocuments.some((doc) => doc.IsSelected)
    );
  }

  return (
    <div className="container-fluid">
      <Spinner show={loading} Title="Customer Upload Docsign" />
      <ToastContainer />
      <CustomerNavbar />
      {/* ------------------------ Start for Refresh Button  ------------------------- */}
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="text-end " style={{ marginRight: "39px" }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}
              >
                <button
                  className="btn btn-info mt-3"
                  style={{
                    borderRadius: "20px",
                    fontWeight: "500",
                    width: "7pc",
                    color: "white",
                  }}
                  onClick={handleRefresh}
                >
                  {" "}
                  <FontAwesomeIcon icon={faArrowRotateRight} /> Refresh
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Documents Sent to Customer  List ------------------------- */}
      <div style={{ padding: "1pc" }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card">
              <div className="card-header py-3 bg-transparent">
                <h5 className="mb-0" style={{ marginLeft: "3pc" }}>
                  Documents Sent to Customer
                </h5>
                <h5
                  style={{ float: "right", position: "relative", top: "-2pc" }}
                >
                  {sentDocumentsCount !== null ? sentDocumentsCount : 0}
                </h5>
              </div>
              <div style={{ textAlign: "center", float: "right" }}></div>
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Status</th>
                      <th></th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docuSignFiles.map((item) => (
                      <tr key={item.Id}>
                        <td>{item.Title}</td>
                        <td>{item.Status}</td>
                        <td>
                          {item.organizationDocId &&
                          item.organizationDocId != null
                            ? "Organization Document"
                            : ""}
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "#36B37E",
                                cursor: "pointer",
                              }}
                              icon={faEye}
                              onClick={() =>
                                handleModalToggle(
                                  item.DocumentURL,
                                  item.Title,
                                  item.Id
                                )
                              }
                            />
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="view-tooltip">Sign</Tooltip>}
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "#36B37E",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              icon={faFileSignature}
                              onClick={() => EditDocument(item.EnvelopId)}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {docuSignFiles.length === 0 && (
                  <div className="text-center">No documents available.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Organization Documents Checkbox ------------------------- */}
      <APIErrorComponent APIResponse={APIResponse} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <input
              type="checkbox"
              checked={isOrganizationCheckboxChecked}
              onChange={handleOrganizationTopCheckboxChange}
              style={{ marginRight: "0.5rem" }}
              className="checkbox-lg"
            />
            Select All Documents
          </div>
          <div className="col-md-6">
            {isSendButtonVisible && (
              <button
                className="btn btn-primary"
                onClick={handleCreateEnvelope}
                style={{ float: "right", borderRadius: "20px" }}
                disabled={!checkIfAnyDocumentSelected()}
              >
                Send All Selected Documents
              </button>
            )}
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Organization Doucment  ------------------------- */}
      <div className="container-fluid" style={{ padding: "1pc" }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card">
              <div className="card-header py-3 bg-transparent">
                <h5 className="mb-0 ">Organization Documents</h5>

                <div style={{ textAlign: "center", float: "right" }}></div>
              </div>
              <Row>
                {OrganizationDocuments &&
                  OrganizationDocuments.map((document, index) => (
                    <Col md={6} lg={4} xl={4} className="mb-4" key={index}>
                      <Card
                        className="shadow-lg"
                        style={{
                          cursor: "pointer",
                          padding: "5px",
                          height: "26pc",
                        }}
                      >
                        <Card.Header className="d-flex align-items-center justify-content-between">
                          <div className="col-8">
                            <Card.Title className="mb-0">
                              <div
                                onClick={() =>
                                  handleModalToggle(
                                    document.documentUrl,
                                    document.Title,
                                    document.DocumentID
                                  )
                                }
                              >
                                <h6 style={{ fontSize: "20px" }}>
                                  {document.Title}
                                </h6>
                              </div>
                            </Card.Title>
                          </div>

                          <div className="col-4">
                            <span>
                              {" "}
                              <input
                                type="checkbox"
                                checked={document.IsSelected}
                                onChange={(event) =>
                                  handleOrganizationDocumentCheckboxChange(
                                    event,
                                    document
                                  )
                                }
                              />
                            </span>
                            <span
                              style={{ fontSize: "16px" }}
                              className="font-weight-bold"
                            >
                              {document.AlreadySent &&
                              document.AlreadySent == true
                                ? "Already Sent"
                                : ""}
                            </span>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <div className="position-relative">
                            <div>
                              {document &&
                              document.Type === "application/pdf" ? (
                                <embed
                                  type={document.type}
                                  src={document.DocumentUrl}
                                  className="border-0"
                                  style={{
                                    overflow: "hidden",
                                    height: "300px",
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                <>
                                  {document &&
                                  document.Title.toLowerCase().includes(
                                    "docx"
                                  ) ? (
                                    <div
                                      style={{
                                        margin: "100px",
                                      }}
                                    >
                                      This is a DOCX file. Preview is not
                                      available.{" "}
                                      <a href={document.DocumentUrl} download>
                                        Download
                                      </a>
                                    </div>
                                  ) : (
                                    <iframe
                                      title={document.Title}
                                      src={document.DocumentUrl}
                                      className="border-0"
                                      style={{
                                        overflow: "hidden",
                                        height: "300px",
                                        cursor: "pointer",
                                        width: "100%",
                                      }}
                                      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Custom Doucment Checkbox  ------------------------- */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <input
              type="checkbox"
              checked={isCustomCheckboxChecked}
              onChange={handleTempTopCheckboxChange}
              style={{ marginRight: "0.5rem" }}
              className="checkbox-lg"
            />
            Select All Documents
          </div>
          <div className="col-md-6">
            {isSendButtonVisible && (
              <button
                className="btn btn-primary"
                onClick={handleCreateEnvelope}
                style={{ borderRadius: "20px", float: "right" }}
                disabled={!checkIfAnyDocumentSelected()}
              >
                Send All Selected Documents
              </button>
            )}
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Custom  Doucments  ------------------------- */}
      <APIErrorComponent APIResponse={APIResponse} />
      <div className="container-fluid" style={{ padding: "1pc" }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            {errorType && (
              <div className="alert alert-danger" style={{ color: "red" }}>
                {errorType}
              </div>
            )}

            <div className="card">
              <div
                class="card-header py-3 bg-transparent"
                style={{ padding: "1pc" }}
              >
                <h5 class="mb-0">Custom Document</h5>
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th> &nbsp; &nbsp;</th>
                    <th>Document Title</th>
                  </tr>
                </thead>
                <tbody>
                  {tempCustomerUploadedDocuments.map((doc) => (
                    <tr key={doc.DocumentID}>
                      <td>
                        <input
                          type="checkbox"
                          checked={doc.IsSelected}
                          onChange={(event) =>
                            handleTempDocumentCheckboxChange(event, doc)
                          }
                        />
                      </td>
                      <td>{doc.Title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------ Start for Upload Doucment  ------------------------- */}
      <div className="container-fluid" style={{ padding: "1pc" }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card">
              <div class="card-header py-3 bg-transparent">
                <h5 class="mb-0">
                  Upload Custom Documents to get Signed from Customer
                </h5>
              </div>
              <div className="card-body" style={{ padding: "13px" }}>
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-md-8">
                      <fieldset
                        className="upload_dropZone text-center mb-3 mt-3 p-4"
                        style={{ height: "10pc" }}
                      >
                        <legend className="visually-hidden">
                          Image uploader
                        </legend>

                        <label
                          htmlFor="formFileLg"
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="assets/images/fileuploader.svg"
                            alt="File uploader"
                          />
                          <p className="my-2" style={{ fontSize: "20px" }}>
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: "600",
                                color: "#004265",
                              }}
                            >
                              Upload a File
                            </p>
                          </p>

                          <input
                            id="formFileLg"
                            data-post-name="image_background"
                            className="position-absolute invisible"
                            type="file"
                            onChange={handleFileChange}
                            multiple
                          />

                          <span
                            className="mb-3"
                            style={{
                              marginTop: "-11px",
                              color: "#004265",
                              fontSize: "16px",
                              cursor: "pointer",
                              fontWeight: "300",
                            }}
                          >
                            Drag and drop files here
                          </span>
                        </label>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-lg"
        show={showModal}
        onHide={() => {
          handleModalToggle("");
          setLoading(false); // Set loading state to false when modal is closed
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedDocumentTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Spinner animation="border" variant="primary" /> */}
          {loading && <Spinner animation="border" variant="primary" />}
          <iframe
            title="Document Viewer"
            src={selectedDocumentUrl}
            width="100%"
            height="240px"
            onLoad={() => setLoading(false)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleModalToggle("");
              setLoading(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      ;
    </div>
  );
};

export default DocuSign;
