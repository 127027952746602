// import React, { useState, useEffect } from "react";
// import Bankcheckbox from "./Bankcheckbox";
// import ReactApexChart from "react-apexcharts";
// import axios from "axios";
// import Spinner from "../../Spinner";
// import { VixoAPI } from "../../settings";
// import { Toast } from "react-bootstrap";
// const Expense = () => {
 
//   const [chartData, setChartData] = useState({
//     series: [],
//     options: {
//       chart: {
//         width: 380,
//         type: "pie",
//       },
//       labels: [],
//     },
//   });
//   const [loading, setLoading] = useState(false);
//   const [expensedata, setExpensedata] = useState(null);
//   useEffect(() => {}, []);


//   function clearTransactions() {
//     setExpensedata(null);
//   }
//   const handleAccountClick = (accountIds, name) => {
//     if (accountIds != null && accountIds.length >= 0) {
//     } else {
//       clearTransactions();
//       return;
//     }

//     const accountIdArray = Array.isArray(accountIds)
//       ? accountIds
//       : [accountIds];
   
//     try {
//       setLoading(true);
//       const GetExpense = `${VixoAPI.AccountAPI}getExpenseDetailsByAccountId`;
//       setLoading(true);
//       axios
//         .post(GetExpense, accountIdArray)
//         .then((response) => {
//           var responseData = response.data;
//           if (responseData.statusCode === 200) {
//             const transactionsData = responseData.data.transactions;
//             const transactionsdata = JSON.parse(
//               JSON.stringify(transactionsData)
//             );
//           setExpensedata(transactionsdata);

//           const GraphData = responseData.data.chartSeriesInfo;

//           const Seriessdata = JSON.parse(
//             JSON.stringify(GraphData)
//           );

//           const chartData = [];
//           const nameData = [];
//           if(GraphData!=null){
//             for (const data of GraphData) {
//               chartData.push(data.amount);
//               nameData.push(data.title);
//             }
//           }
          
        
//           setChartData({
//             series: chartData,
//             options: {
//               chart: {
//                 width: 380,
//                 type: 'pie',
//               },
//               labels: nameData,
//             },
//           });
           
//           } else {
//             Toast("no Transaction of this Account");
//           }
//         })
//         .catch((error) => {
//           setLoading(false);
//           throw error;
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } catch (error) {
//       setLoading(false);
//       throw error;
//     }
//   };

//   return (
//     <>
//       <Spinner show={loading} />
//       <div className="row">
//         <Bankcheckbox handleAccountClick={handleAccountClick} />
//       </div>
//       <div class="row">
//         <div class="col-6">
//           <input
//             class="form-control w-50 searchbartabbmob"
//             placeholder="Search"
//           />
//         </div>
//         <div class="col-6 text-end">
//           <select
//             value="{DaysOfHistory}"
//             style={{ alignContent: "right" }}
//             className="dropdowntab dropdowntabmob w-50 h-100 rounded"
//             onchange="{SelectDaysOfHistory}"
//           >
//             <option disabled>Total By</option>
//             <option value="365">Annually</option>
//             <option value="90">Quarterly</option>
//             <option value="30">Monthly</option>
//             <option value="7">Weekly</option>
//             <option value="1">Daily</option>
//           </select>
//         </div>
//       </div>
//       <div class="row">
//         <div className="col-md-6 offset-md-3 d-flex justify-content-center">
//           <div id="chart" className="expense">
//             <ReactApexChart
//               options={chartData.options}
//               series={chartData.series}
//               type="pie"
//             />
//           </div>
//         </div>
//       </div>
//       <div className="row mt-5">
//         <div className="card">
//           <div className="card-body">
//             <div className="table-responsive">
//               <table
//                 id="example"
//                 className="table table-striped"
//                 style={{ width: "100%" }}
//               >
//                 <thead>
//                   <tr>
//                     <th>Date</th>
//                     <th>Description</th>
//                     {/* <th>RunningBalance</th> */}
//                     <th>Amount</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {expensedata != null &&
//                     expensedata.map((expense, index) => (
//                       <tr>
//                         <td>{expense.transactionDate}</td>
//                         <td>{expense.description}</td>
//                         <td>{expense.amount}</td>
//                         {/* <td>$100</td> */}
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Expense;

// import React, { useEffect, useState } from 'react'
// import IncomeTransaction from '../IncomeExpenseTransactions/IncomeTransaction'

// const Expense = ({custDownload}) => {
//   const [TransactionType, setTransactionType] = useState(2);
//   useEffect(()=>{

//   },[])
//   return (
//     <>
//       <IncomeTransaction TransactionType={TransactionType} trngrpah={custDownload} />
//     </>
//   )
// }

// export default Expense

import React, { useEffect, useState, lazy, Suspense } from 'react';

// Use React.lazy to lazily load the IncomeTransaction component
const LazyIncomeTransaction = lazy(() => import('../IncomeExpenseTransactions/IncomeTransaction'));

const Expense = ({ custDownload,IsOpenedFromDownload,tabClicked}) => {
  // const [TransactionType, setTransactionType] = useState(2);   //2- Mean Expense

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {/* Use LazyIncomeTransaction here */}
        <LazyIncomeTransaction TransactionType={2} tabClicked={tabClicked}  trngrpah={custDownload} IsOpenedFromDownload={IsOpenedFromDownload}/>
      </Suspense>
    </>
  );
};

export default Expense;

