import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fileopen from "./Fileopen";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import "./file.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faFilePen } from "@fortawesome/free-solid-svg-icons";
import StatementDelete from "./StatementDelete";
import StatementUploadPopup from "./StatementUploadPopup";
import DateComponent from "../../VixoFormats/Date";
import { useLocation, useNavigate } from "react-router-dom";

import APIErrorComponent from "../../APIErrorComponent";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";

const CustomerUploadStatementList = ({
  customerkey,
  userkey,
  IsNewStatementUploaded,
}) => {
  const [loading, setLoading] = useState(false);
  const documentInputRef = useRef(null);
  const childRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [showOcrPopup, setShowOcrPopup] = useState(false);
  const [showDeleteStatement, setShowDeleteStatement] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [showOcrLoader, setShowOcrLoader] = useState(false);
  const [RefreshChildComponent, setRefreshChildComponent] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [statementCount, setStatementCount] = useState(0);
  const [errorType, setErrorType] = useState(null);
  const [APIResponse , setAPIResponse] =useState(null);
  const [showDeleteDoc, setShowDeleteDoc] = useState(false);
  const navigate=useNavigate();

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setShowOcrPopup(true);
    setFiles(Array.from(selectedFiles));
    setSelectedFiles([]);
    e.target.value = "";
  };

  useEffect(() => {
    LoadFreshData();
  }, []);

  useEffect(() => {
    if(IsNewStatementUploaded>0 && IsNewStatementUploaded>statementCount)
    {
      setStatementCount(IsNewStatementUploaded);
      LoadFreshData();
    }
  }, [IsNewStatementUploaded]);

  const [statementData, setStatementData] = useState(null);



  function LoadFreshData() {
    LoadMinimalStatementByCustomerKey();
   
  }


  function LoadMinimalStatementByCustomerKey() {
    if(HasLoggedInUser())
      {
        const config= APIHeader();
    try {
      const SetDataById = `${VixoAPI.StatmentAPI}getMinimalStatementByCustomerKey?Key=${customerkey}&OcrStatus=${false}`;
      axios
        .get(SetDataById,{headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setstatementData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate, customerkey);
        }
    }
  }
  else{
    ExpireToken(navigate, customerkey);
  }
}

  useEffect(() => {
    // LoadMinimalStatementByCustomerKey();
  }, [statementData]);

  function setstatementData(StatementData) {
    try {
      setLoading(true);
      var statementArray = JSON.parse(JSON.stringify(StatementData));
      setStatementData(statementArray);
      setLoading(false);
   
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const [documentData, setDocumentData] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);

  function setData(docData) {
    try {
      setLoading(true);
      var Docmentdata = JSON.parse(JSON.stringify(docData));
      setDocumentData(Docmentdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  function UpdateStatementInList(statementReponse) {
    if (statementReponse) {
      var updatedSatatement = JSON.parse(JSON.stringify(statementReponse));
      const updatedArray = statementData.map((obj) =>
        obj.id === statementReponse.id ? { ...obj, ...updatedSatatement } : obj
      );

      setStatementData(updatedArray);
  
    }
  }
  const StatemenDeleteSuccess = () => {
    setLoading(true)
    LoadFreshData();
  };
  const handleModalClose = () => {
    // LoadStatementByCustomerKey();
    LoadMinimalStatementByCustomerKey();

    setShowOcrPopup(false);
  };
  const [Userkey, setUserkey] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("U");
    if (keyFromParams) {
      setUserkey(keyFromParams);
    }
  }, [location]);
  const [fileUploaded, setFileUploaded] = useState(false);
const [disabledButtons, setDisabledButtons] = useState({});
  const StatementUploaded = () => {
    setFileUploaded(true);
  };
  function setError(message) {
    setErrorType(message);
  }
  function DoOCR(id) {
    const Data ={
      UserKey: Userkey,
      StatementID: id
    }
    setAPIResponse(null);
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setErrorType(null);
      setLoading(true);
      const ocr = `${VixoAPI.StatmentAPI}doOCR`; 
      setLoading(true);
      axios
        .post(ocr,Data, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          setIdDoc(response.id);
          if (response.statusCode === 200) {
            toast.success(response.message);
            var udatedStatement = response.data;
            UpdateStatementInList(udatedStatement); // setProgressBar =50;

                    setDisabledButtons((prevDisabledButtons) => ({
                      ...prevDisabledButtons,
                      [id]: true, // Disable the button after successful OCR
                    }));

          } 
          else if(response)
          {
           
            setAPIResponse(response);
    
          }
          else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate, customerkey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,customerkey)
  }
  }

  const handleOcrClick = (file) => {
    setselectedFiledata(file);
    setShowOcrLoader(true);
    setTimeout(() => {
      setShowOcrLoader(false);
      alert("OCR completed for file:", file.name);
    }, 2000);
  };

  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);

  const [selectedFiledata, setselectedFiledata] = useState({});

  function handleViewClick(fileName) {
    const fileview = fileName;
    setFileView(fileview);
    setShowViewPopup(true);
  }
  const handleDeleteClick = (Id, type) => {
    if (type === "statement") {
      setselectedFiledata(Id, type);
      setShowDeleteStatement(true);
      
    } else {
      setselectedFiledata(Id, type);
      setShowDeleteDoc(true);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteStatement(false);

  };

  return (
    <>
      <StatementUploadPopup
        show={showOcrPopup}
        handleModalClose={handleModalClose}
        files={files}
        selectedFiles={selectedFiles}
        customerkey={customerkey}
        NewStatementUploaded={IsNewStatementUploaded}
      />
      <Fileopen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        title={selectedFiledata?.name}
        pdfSrc={fileView}
      />
      <StatementDelete
        show={showDeleteStatement}
        onHide={handleDeleteCancel}
        id={selectedFiledata}
        onDeleteCancel={handleDeleteCancel}
        onDeleteSuccess={StatemenDeleteSuccess}
      />
      {/* end selected file */}

      <ToastContainer />
      <APIErrorComponent APIResponse={APIResponse} />

      <Spinner show={loading} Title="Customer Upload Statement List"/>
      {errorType && (
        <div className="alert alert-danger" style={{ color: "red" }}>
          {errorType}
        </div>
      )}
      <div className="card">
        <div className="card-header py-3 bg-transparent">
          <h5 className="mb-0">Uploaded Statements</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table id="example" className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Statements Name</th>

                  <th>Ocr</th>
                  <th>Error</th>
                  <th>Actions</th>
                  <th>Upload Progress</th>
                </tr>
              </thead>
              <tbody>
                {statementData != null &&
                  statementData.map((statement, index) => (
                    <tr key={index}>
                      <td>
                        {statement ? (
                          <DateComponent value={statement.uploadedDate} />
                        ) : (
                          ""
                        )}{" "}
                      </td>
                      <td className="statement-title">
                        {statement.statementTitle}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="reload-tooltip">Do OCR</Tooltip>
                          }
                        >
                          <button
                            className="btn btn-info"
                            style={{
                              borderRadius: "20px",
                              fontWeight: "500",
                              width: "7pc",
                              color: "white",
                              marginLeft: "-20px",
                            }}
                            disabled={disabledButtons[statement.id]}
                            onClick={() => DoOCR(statement.id)}
                          >
                            Start OCR
                          </button>
                        </OverlayTrigger>
                      </td>
                      <td>No</td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="reload-tooltip">
                              View Statement
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ color: "green", marginLeft: "2px" }}
                            onClick={() =>
                              handleViewClick(statement.fileSha1Hash)
                            }
                            icon={faEye}
                          />
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="reload-tooltip">
                              Delete Statement
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ color: "red", marginLeft: "2px" }}
                            onClick={() =>
                              handleDeleteClick(statement.id, "statement")
                            }
                            icon={faTrash}
                          />
                        </OverlayTrigger>
                      </td>
                      <td style={{ width: "10%" }}>
                        <td style={{ width: "10%" }}>
                          {statement.progress == "100" ? (
                            " "
                          ) : (
                            <ProgressBar
                              completed={statement.progress}
                              bgColor="green"
                              animateOnRender={true}
                            />
                          )}
                        </td>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerUploadStatementList;
