import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Margin, usePDF } from "react-to-pdf";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { VixoAPI } from "../../settings";
import Transaction from "../Home/Transaction";
import Income from "../Home/Income";
import Expense from "../Home/Expense";
import Balance from "../Home/Balance";
import Statment from "../Home/Statment";
import "./Download.css";
import Spinner from "../../Spinner";
import CustomerAccount from "../Home/CustomerAccount";
import PhoneNumber from "../../VixoFormats/Phone";
import DateComponent from "../../VixoFormats/Date";
import { PDFDocument, rgb,degrees } from "pdf-lib";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// const html2canvas = require('html2canvas');

const DownloadNow = ({ handlePdfUrl }) => {
  const componentRef = useRef(null);
  const [User, setUser] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [dateDownloaded, setDateDownloaded] = useState("");
  const [loading, setLoading] = useState(false);
  const [custDownload, setCusDownload] = useState(false);
  const [customer, setCustomers] = useState({});
  const location = useLocation();
  const [urlsForMerge, setUrlsForMerge] = useState([]);
  const [pdfbytes, setPdfBtyes] = useState([]);
  const [statementbytes, setStatementBytes] = useState([]);
  const [ResultantPDF, setResultantPDF] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [getOrganizationById, setgetOrganizationById] = useState(null);
  const [margeurldata, setMargeUrlData] = useState(null);
  const [urldata, setUrlData] = useState(null);
  const navigate= useNavigate()

  ////// /////////////////

  // const [mergedPdfBytes, setMergedPdfBytes] = useState(null);
  const globalSelectedAccountHandler = (selectedCustomerAccount) => {
    setSelectedAccountId(selectedCustomerAccount);
  };
  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    const params = new URLSearchParams(window.location.search);
    const loggedInUser = localStorage.getItem("User");
    const lUserKey = params.get("U");

    if (lUserKey) {
      setUserKey(lUserKey);
    }
    var userObject = JSON.parse(loggedInUser);
    var CustomerKey = params.get("Id");
    const currentDateTime = new Date().toLocaleString();
    setDateDownloaded(currentDateTime);
    if (userObject != null) {
      // setUser(userObject.name);
    }
    if (CustomerKey != null) {
      LoadCustomer(CustomerKey);
    }
  }, [location]);

  function LoadCustomer(CustomerKey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
      setLoading(true);
      axios
        .get(GetCustomerId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setCustomers(response.data);
            setgetOrganizationById(response.data.organizationId);
          } else {
            // setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
              }
            })
            .finally(() => {
              setLoading(false);
            });
          } catch (error) {
            setLoading(false);
            throw error;
          }
        }
        else{
          ExpireToken(navigate,CustomerKey);
  }
  }

  function getUserNameByKey() {
    if (HasLoggedInUser()) {
      const config=APIHeader();
    try {
      setLoading(true);
      var Key = encodeURIComponent(UserKey);
      const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
      setLoading(true);
      axios
        .get(GetUserByKeyAPI, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setUser(response.data);
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate);
  }
  }

  useEffect(() => {
    getUserNameByKey();
  }, [UserKey]);

  const generatePdf = async () => {
    try {
      // Get the HTML string of the React component
      const htmlString = componentRef.current.outerHTML;
      const pdfDoc = await PDFDocument.create();
      // Add a new page to the PDF
      const page = pdfDoc.addPage([400, 200]);
      const { width, height } = page.getSize();
      const fontSize = 10;

      const font = await pdfDoc.embedFont("Helvetica");

      page.drawText(htmlString, {
        x: 50,
        y: height - 50, // Adjust the y-coordinate as needed
        size: fontSize,
        font,
      });
      // Save the PDF to bytes
      const pdfBytes = await pdfDoc.save();
      console.log("PDF Bytes:", pdfBytes);
      setPdfBtyes(pdfBytes)
      // return pdfBytes;
      // handlePdfUrl(pdfBytes);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  const [pdfBytes, setPdfBytes] = useState(null);
  const { toPDF, targetRef } = usePDF({
    filename: `${User && User.name}.pdf`,
    page: { margin: Margin.MEDIUM },
    scale: 1,
    style: { backgroundColor: "white" },
    overrides: {
      pdf: {
         compress: true,
         mimeType: "application/pdf"
      },
   },
  });


  const pdfHeight=0;
  const pdfWidth=0;
  
  async function capturePageAsPdf() {
    // Capture the current page as an image
    const canvas = await html2canvas(document.body);

    // Convert the image data URL to a Uint8Array
    const imgData = canvas.toDataURL('image/png');
    const imgBytes = Uint8Array.from(atob(imgData.split(',')[1]), c => c.charCodeAt(0));

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Add a new page
    const page = pdfDoc.addPage([canvas.width, canvas.height]);

    // Embed the captured image onto the page
    const pngImage = await pdfDoc.embedPng(imgBytes);
    const { width, height } = pngImage.scale(1);
    // pdfWidth=width;
    // pdfHeight=height;
    page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: width,
        height: height,
        rotate: degrees(0),
    });

    // Save the PDF to a file or get its data as a buffer
    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
}
  const handleDownloadPDF = async () => {
    try {
      setLoading(true);
      //await toPDF();
      //generatePdf();
      var bytesFromPage=await capturePageAsPdf();
      mergePdfsBytes(bytesFromPage , urlsForMerge)
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  ////////////////call back for get statements/////////////////

  const handleDownloadStatements = (data) => {
    if (data && data.length > 0) {
      const urlsForMerge = data.map((statement) => statement.fileSha1Hash);
      setUrlsForMerge(urlsForMerge);
      mergePdfPages(urlsForMerge);
      generatePdf();
      if(pdfbytes){
        mergePdfsBytes(pdfbytes , urlsForMerge)
      }
    } else {
      console.error("No data to download statements.");
    }
  };

  //////////////////merge statements//////////////////
  const mergePdfPages = async (urlsForMerge) => {
    try {
      if (urlsForMerge && urlsForMerge.length > 0) {
        setLoading(true);
        const pdfDoc = await PDFDocument.create();
        for (const url of urlsForMerge) {
          const pdfBytes = await fetch(url).then((res) => res.arrayBuffer());
          const donorPdfDoc = await PDFDocument.load(pdfBytes, {
            ignoreEncryption: true, // Ignore encryption
          });
          for (let i = 0; i < donorPdfDoc.getPageCount(); i++) {
            const [page] = await pdfDoc.copyPages(donorPdfDoc, [i]);
          //   const scaleFactor = Math.min(pdfHeight, pdfWidth);
          //   const scaledPage = page.scale(scaleFactor);
          // page.drawPage(scaledPage, {
          //     x: 0,
          //     y: 0,
          // });
            pdfDoc.addPage(page);
          }
        }
        const mergedPdfBytes = await pdfDoc.save();
        console.log(mergedPdfBytes);
        setStatementBytes(mergedPdfBytes);
      } else {
        console.error("No URLs for merging.");
      }
    } catch (error) {
      console.error("Error merging PDFs:", error);
    } finally {
      setLoading(false);
    }
  };
  //////////////////current page pdf and statments /////
  const mergePdfsBytes = async (pdfbytes , urlsForMerge) => {
    try {
      if (!urlsForMerge && !pdfbytes) return;
      const secondDonorPdfBytesArray = await Promise.all(
        urlsForMerge.map(async (url) => {
          const response = await fetch(url);
          return response.arrayBuffer();
        })
      );

      const pdfDoc = await PDFDocument.create();
      const firstDonorPdfDoc = await PDFDocument.load(pdfbytes, {
        ignoreEncryption: true,
      });
        // Add pages from the first donor document
        for (let i = 0; i < firstDonorPdfDoc.getPageCount(); i++) {
          const [page] = await pdfDoc.copyPages(firstDonorPdfDoc, [i]);
          pdfDoc.addPage(page);
        }
      const secondDonorPdfDocs = await Promise.all(
        secondDonorPdfBytesArray.map((bytes) => PDFDocument.load(bytes))
      );
      
      // Add pages from the second donor documents
      for (const secondDonorPdfDoc of secondDonorPdfDocs) {
        for (let i = 0; i < secondDonorPdfDoc.getPageCount(); i++) {
          const [page] = await pdfDoc.copyPages(secondDonorPdfDoc, [i]);
          // const scaleFactor = Math.min(pdfHeight, pdfWidth);
          //   const scaledPage = page.scale(scaleFactor);
          // page.drawPage(scaledPage, {
          //     x: 0,
          //     y: 0,
          // });
          pdfDoc.addPage(page);
        }
      }
      const mergedPdfBytes = await pdfDoc.save();
      // setPdfUrlForMarge(mergedPdfBytes);
     // Do something with the PDF data, e.g., download it
     const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
     const url = URL.createObjectURL(blob);
     setUrlData(url);
     const a = document.createElement('a');
       a.href = url;
      //  a.download = 'page.pdf';
       a.download =`${customer && customer.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      
    } catch (error) {
      console.error("Error merging PDFs:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Spinner show={loading} Title="Download Now"/>

      <div className="DonwloadOutsideContainer">
        <div className="text-end m-4">
          <Button className="Btn btn-success" onClick={handleDownloadPDF}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Download PDF"
            )}
          </Button>
        </div>
        <div className="container" id="targetDiv"ref={targetRef} >
        <div ref={componentRef}>
            <div className="row">
              <div className="m-2">
                <div className="col-md-12">
                  <div className="">
                    <span className="fw-bold">Customer Full Name: </span>{" "}
                    {customer ? customer.name : ""}
                    <br />
                    <span className="fw-bold">Customer Email: </span>{" "}
                    {customer ? customer.email : ""}
                    <br />
                    <>
                      <div className="d-flex">
                        <p className="fw-bold">Customer Phone: </p>
                        {customer ? (
                          <PhoneNumber number={customer.phone} />
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                    <span className="fw-bold">Vixo Id: </span>{" "}
                    {customer ? customer.id : ""}
                    <br />
                    <span span className="fw-bold">
                      CreateDate:{" "}
                    </span>
                    {customer ? (
                      <DateComponent value={customer.createDate} />
                    ) : (
                      ""
                    )}{" "}
                    <br />
                    <span className="fw-bold">Date Downlaoded: </span>{" "}
                    {dateDownloaded ? (
                      <DateComponent value={dateDownloaded} />
                    ) : (
                      ""
                    )}{" "}
                    <br />
                    <span className="fw-bold"> User Name: </span>{" "}
                    {User && User.name} <br />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="">Powered and presented by</div>
                  <p className="text-center justify-content-center">
                    <img
                      src="assets/images/LogoVixo.png"
                      alt="logo icon"
                      style={{
                        width: "350x",
                        height: "242px",
                        display: "flex",
                      }}
                    />
                  </p>
                </div>
                <div className="col-md-12">
                  <p>
                    This download is a static version of the full customer file
                    pulled through and presented through Vixo Link. For any new
                    updates to this customer file, you will have to refresh the
                    customer's information and then re-download it in order to
                    update this downloaded document.
                  </p>
                  <p className="mb-2 mt-2">
                    *Full ACH details must be presented only in Vixo Link Home
                    or Dashboard.
                  </p>
                </div>
              </div>
            </div>
            <h3 className="text-info" style={{ marginLeft: "25px" }}>
              Account Details
            </h3>
            <CustomerAccount
              custDownload={custDownload}
              globalSelectedAccountHandler={globalSelectedAccountHandler}
              SetGlobalSelectedAccount={selectedAccount}
              detailComponentID={1}
              forDownload={true}
            />
            <div style={{ pointerEvents: "None" }}>
              <Row className="page-break">
                <h3 className="text-info" style={{ marginLeft: "25px" }}>
                  Transaction
                </h3>
                <Transaction
                  custDownload={custDownload}
                  IsOpenedFromDownload={true}
                  forDownload={true}
                  tabClicked={true}
                />
              </Row>
            </div>
            <div style={{ pointerEvents: "None" }}>
              <Row className="mb-1">
                <h3 className="text-info" style={{ marginLeft: "25px" }}>
                  Income
                </h3>
                <Income
                  custDownload={custDownload}
                  IsOpenedFromDownload={true}
                  forDownload={true}
                  tabClicked={true}
                />
              </Row>
            </div>
            <div style={{ pointerEvents: "None" }}>
              <Row className="mb-1">
                <h3 className="text-info" style={{ marginLeft: "25px" }}>
                  Expense
                </h3>
                <Expense
                  custDownload={custDownload}
                  IsOpenedFromDownload={true}
                  forDownload={true}
                  tabClicked={true}
                />
              </Row>
            </div>
            <div style={{ pointerEvents: "None" }}>
              <Row className="mb-1">
                <h3 className="text-info" style={{ marginLeft: "25px" }}>
                  Balance
                </h3>
                <Balance
                  custDownload={custDownload}
                  IsOpenedFromDownload={true}
                  forDownload={true}
                  tabClicked={true}
                />
              </Row>
            </div>
            <div>
              <Row className="mb-1">
                <h3 className="text-info" style={{ marginLeft: "25px" }}>
                  Statements
                </h3>
                <Statment
                  IsDownload={true}
                  setDownloadStatements={handleDownloadStatements}
                />
              </Row>
              <div>
                {/* <embed src={margeurldata} type="application/pdf" /> */}
                {/* <embed
                  src={urldata}
                  type="application/pdf"
                  style={{ width: "100%", height: "700px" }}
                /> */}
              </div>
            </div>
            <Row>
              <div className="col-md-12  mt-4 mb-4">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 text-end">
                      <img src="assets/images/logoVixo.png" width={"235px"} />
                    </div>
                    <div className="col-md-4 mb-4 mt-5">
                      <p className="fw-bold"> Brysen Partridge</p>
                      <p>CEO & Founder</p>
                      <p> 801.808.1845</p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
          </div>
        
      </div>
    </>
  );
};

export default DownloadNow;
