import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import CustomerNavbar from "../Header/CustomerNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CommunicationTimeline.css";
import DateTimeComponent from "../../VixoFormats/DateTime";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import CommunicationTimelineAccount from "./CommunicationTimelineAccounts";
import CommunicationTimelineFiles from "./CommuincationTimelineFiles";
import CommunicationTimelineDocument from "./CommuicationTimelineDocument";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
const CommunicationTimeline = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [CustomerKey, setcustomerKey] = useState(null);
  const [customerid, setCustomerID] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [communicationTimelineData, setcommunicationTimelineData] = useState(
    []
  );
  const [editedNotes, setEditedNotes] = useState({});
  const [textAreaShows, setTextAreaShows] = useState(
    Array(communicationTimelineData.length).fill(false)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [EditNote, setEditNote] = useState(null);
  const navigate = useNavigate();

  const toggleShowArea = (index) => {
    const newTextAreas = [...textAreaShows];
    newTextAreas[index] = !newTextAreas[index];
    setTextAreaShows(newTextAreas);
  };
  function HasAccountsInTimeLine(CustomerTimlineRecord) {
    if (
      CustomerTimlineRecord != null &&
      CustomerTimlineRecord.customerAccounts != null &&
      CustomerTimlineRecord.customerAccounts.length > 0
    ) {
      return true;
    }
    return false;
  }
  function HasStatementInTimeLine(CustomerTimlineFile) {
    if (
      CustomerTimlineFile != null &&
      CustomerTimlineFile.customerStatements != null &&
      CustomerTimlineFile.customerStatements.length > 0
    ) {
      return true;
    }
    return false;
  }
  function HasDocInTimeLine(CustomerTimlineDoc) {
    if (
      CustomerTimlineDoc != null &&
      CustomerTimlineDoc.customerDocuments != null &&
      CustomerTimlineDoc.customerDocuments.length > 0
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var customerKey = params.get("Id");
    var custid = params.get("Id");
    var UserKey = params.get("U");
    if (customerKey && customerKey !== null) {
      setcustomerKey(customerKey);
    }
    if (UserKey && UserKey !== null) {
      setUserKey(UserKey);
    }
  }, [location]);
  function fetchCustomerTimeline() {
    if (!CustomerKey) return; // Don't fetch if customerKey is not available

    if (HasLoggedInUser()) {
      const config = APIHeader();

      const CustomerTimeline =
        VixoAPI.CustomerAPI + "getCustomerTimelineBykey?key=" + CustomerKey;
      setLoading(true);
      axios
        .get(CustomerTimeline, { headers: APIHeader().headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            setcommunicationTimelineData(responseData.data);
            {
              responseData.data.map((id) => {
                setCustomerID(id.customerId);
              });
            }
            toast.success(responseData.message);
          } else {
            toast.error(responseData.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }
  useEffect(() => {
    fetchCustomerTimeline();
  }, [CustomerKey]);
  const [formData, setFormData] = useState({
    noteid: "",
    UploadedDate: "",
    Location: "",
  });
  const handleNewNoteChange = (e) => setNewNote(e.target.value);
  const handleChange = (e, index) => {
    const updatedNotes = { ...editedNotes, [index]: e.target.value };
    setEditedNotes(updatedNotes);
  };
  const HandleUpdateNotes = async (CustomreTLdata) => {
    const data = {
      Id: CustomreTLdata.noteID,
      Notes: CustomreTLdata.description,
      UserKey: UserKey,
      CusTLId: CustomreTLdata.id,
    };

    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const UpdateNotes = VixoAPI.CustomerAPI + "updateCustomerTimelineNote";
        setLoading(true);
        axios
          .put(UpdateNotes, data, { headers: APIHeader().headers })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              const updatedData = communicationTimelineData.map((item) =>
                item.id === CustomreTLdata.id
                  ? { ...item, description: EditNote.description }
                  : item
              );

              setcommunicationTimelineData(updatedData);
              handleEditModalClose();

              toast.success(responseData.message);
            } else {
              toast(responseData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };
  ///////////////////////////////////////////////
  const handleAddSubmit = async (e) => {
    var id = decodeURIComponent(CustomerKey);
    const data = {
      UserKey: UserKey,
      CustomerID: customerid,
      Notes: newNote,
    };
    // editedNotes
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const timelinepath = `${VixoAPI.CustomerAPI}createCustomerTimelineEntryforNotes`;
        setLoading(true);
        axios
          .post(timelinepath, data, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setcommunicationTimelineData((prevData) => [
                response.data,
                ...prevData,
              ]);
              setNewNote("");
              closeCreateModal();
              toast.success(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };
  const [showEditModal, setEditModalShow] = useState(false);
  const handleEditModalOpen = (selectedTimeLine) => {
    if (selectedTimeLine) {
      setEditNote(selectedTimeLine);
    } else {
      setEditNote(selectedTimeLine);
    }
    setEditModalShow(true);
  };
  const handleEditModalClose = (editedNote) => {
    setEditModalShow(false);
  };
  const handleEditNoteChange = (e) => {
    const { value } = e.target;
    setEditNote((prevNote) => ({
      ...prevNote,
      description: value,
    }));
  };
  return (
    <>
      <div className="container-fluid">
        <Spinner show={loading} />
        <ToastContainer />
        <CustomerNavbar />

        <div className="row">
          <div className="col-md-6"></div>
        </div>
        <div className="row mt-4">
          <div className="card-container">
            <div className="mt-5">
              {communicationTimelineData &&
                communicationTimelineData.map((event, index) => (
                  <div className="" key={index}>
                    {event.byUser && event.byUser === true ? (
                      <>
                        <div className="d-flex align-items-center justify-content-end gap-3 ">
                          <div className="mt-1 shadow px-2 py-3 user-message position-relative">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                              <div className="d-flex align-items-center gap-2">
                                <span className="d-flex align-items-center fs-3 cursor-pointer text-secondary"></span>
                                <p className="">{event.description}</p>
                              </div>
                              <span className="">
                                <DateTimeComponent value={event.dateTime} />
                              </span>
                            </div>
                            <div className="d-flex items-center w-50 gap-2">
                              <span
                                className="d-flex align-items-center fs-3 cursor-pointer text-secondary"
                                onClick={() => handleEditModalOpen(event)}
                              >
                                {event.eventId === 9 && (
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    style={{ fontSize: "20px" }}
                                    title="Edit Your Note"
                                  />
                                )}
                              </span>

                              <Modal
                                show={showEditModal}
                                onHide={handleEditModalClose}
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Edit Note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <textarea
                                    className="w-100 px-2 py-1"
                                    placeholder="Enter your notes"
                                    value={EditNote?.description}
                                    onChange={handleEditNoteChange}
                                  ></textarea>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleEditModalClose}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() => HandleUpdateNotes(EditNote)}
                                  >
                                    Save changes
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>
                          <div className="user-timeline-name shadow mb-4">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <BootstrapTooltip id="tooltip">
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {event.userName}
                                  </span>
                                </BootstrapTooltip>
                              }
                            >
                              <p
                                className=""
                                style={{ textTransform: "capitalize" }}
                              >
                                {event.userName
                                  ? event.userName
                                      .split(" ")
                                      .map((word) => word.charAt(0))
                                      .join(" ")
                                  : null}
                              </p>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="align-items-center gap-5">
                        <div className="d-flex align-items-center justify-content-between mt-1 shadow px-2 py-3 customer-data position-relative">
                          <p className="">{event.description}</p>
                          <span className="date-time">
                            <DateTimeComponent value={event.dateTime} />
                          </span>
                        </div>
                        <div className="communication-timeline-account">
                          <div className="mb-1">
                            {HasAccountsInTimeLine(event) === true ? (
                              <CommunicationTimelineAccount
                                communicationTimelineData={
                                  event.customerAccounts
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="communication-timeline-files">
                          <div className="row">
                            <div className="mb-1">
                              {HasStatementInTimeLine(event) === true ? (
                                <CommunicationTimelineFiles
                                  communicationTimelineData={
                                    event.customerStatements
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="communication-timeline-files">
                          <div className="row">
                            <div className="mb-1">
                              {HasDocInTimeLine(event) === true ? (
                                <CommunicationTimelineDocument
                                  communicationTimelineData={
                                    event.customerDocuments
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              {/* Floating button for creating a new note */}
              <span
                className="floating-button-timeline d-flex align-items-center fs-3 cursor-pointer text-secondary justify-content-end"
                onClick={() => openCreateModal()}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ fontSize: "25px", marginRight: "1px" }}
                  title="Create Your Note"
                />
              </span>

              <Modal
                show={isCreateModalOpen}
                onHide={closeCreateModal}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <textarea
                    className="w-100 px-2 py-1"
                    placeholder="Enter your notes"
                    value={newNote}
                    onChange={handleNewNoteChange}
                    required
                  ></textarea>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeCreateModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleAddSubmit}>
                    Create Note
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CommunicationTimeline;
