import React, { useState } from "react";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../../APICallWrapper";
import { Modal } from "react-bootstrap";
import Spinner from "../../../Spinner";
import { VixoAPI } from "../../../settings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const DeleteCustomer = ({
  ShowPopup,
  SelectedCustomer,
  onActionCancelled,
  onActionCompleted,

}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeleteConfirm = async () => {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      setLoading(true);
      const DeleteCustomerAPIPath = VixoAPI.CustomerAPI + "DeleteCustomer";
      axios
        .delete(DeleteCustomerAPIPath, {
          headers:config.headers,
          params: {
            CustomerKey: SelectedCustomer.key,
          },
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            onActionCompleted(SelectedCustomer,1); //Customer is Deleted
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  const handleDeleteCancel = async () => {
    onActionCancelled(SelectedCustomer,1); 
  };
  return (
    <>
       <Spinner show={loading} Title="Delete Customer"/>
      {/* <ToastContainer /> */}
      <Modal show={ShowPopup} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Customer {" "}{SelectedCustomer.identity}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting Customer will result in permanent data loss. Are you sure
          you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleDeleteCancel} style={{borderRadius:"20px",width:"7pc"}}>
            No
          </button>
          <button className="btn btn-danger" onClick={handleDeleteConfirm} style={{borderRadius:"20px",width:"7pc"}}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteCustomer;
