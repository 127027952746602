import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VixoAPI } from "./settings";

function APIErrorComponent({ APIResponse }) {
  const handleAddCreditClick = () => {
    window.open(`${VixoAPI.DashBoard}Billing`, '_blank');
  };

  const [error, setError] = useState(null);

  useEffect(() => {
    if (APIResponse && APIResponse !== null && APIResponse.statusCode === 507) {
      setError(
        <div>
          {APIResponse.message}
          <button onClick={handleAddCreditClick} className="alert alert-danger" style={{padding:"0px 5px", border:"none", color:"blue" ,textDecoration:"underline"}}>Click to Add Credit</button>
        </div>
      );
    } else if (APIResponse && APIResponse !== null) {
      setError(<p>{APIResponse.message}</p>);
    } else if (APIResponse === null) {
      setError(null);
    }
  }, [APIResponse]);

  return (
    <>
      {error && error != null && (
        <div className="alert alert-danger" style={{ color: "red" }}>
          {error}
        </div>
      )}
    </>
  );
}

export default APIErrorComponent;
