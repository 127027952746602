import React, { useState, useEffect } from "react";
import Bankcheckbox from "./Bankcheckbox";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { Navigate, json, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";

import { Modal, Button, Dropdown, Row, Col } from "react-bootstrap";

const Statment = ({ IsDownload = false, setDownloadStatements }) => {
  const [loading, setloading] = useState(false);
  const [customerKey, setCustomerKey] = useState(null);
  const [userKey, setUserKey] = useState(null);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const [accountStatements, setAccountStatements] = useState(null);
  // const [downloadStatements, setDownloadStatements] = useState(null);
  const [statementData, setstatementData] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const location = useLocation();
  const navigate =useNavigate()
  const [user, setUser] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var CustomerId = params.get("Id");
    if (CustomerId) {
      setCustomerKey(JSON.stringify(CustomerId));
    }
    if (CustomerId != null) {
      setCustomerKey(CustomerId);
      LoadBankByCustomerId(CustomerId);
    }
    var userKey = params.get("U");
    if (userKey) {
      setUserKey(userKey);
    }
  }, [location]);

  useEffect(() => {
    if (userKey) {
      LoadUser();
    }
  }, [userKey]);

  function LoadUser() {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      //setloading(true);
      var Key = encodeURIComponent(userKey);
      const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
      //setloading(true);
      axios
        .get(GetUserByKeyAPI, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setUserDataFromAPI(response.data);
          } else {
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,customerKey);
  }
  }

  function setUserDataFromAPI(data) {
    try {
      //setloading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  function clearTransactions() {
    setstatementData(null);
  }

  const [show, setShow] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showNoProfileMessage, setShowNoProfileMessage] = useState(false);

  const handleAccountClick = (accountIds) => {
    if (accountIds != null && accountIds.length >= 0) {
    } else {
      clearTransactions();
      return;
    }
    const accountIdArray = Array.isArray(accountIds)
      ? accountIds
      : [accountIds];
    // Handle the selected account logic here
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      //setloading(true);
      const GetStatement = `${VixoAPI.StatmentAPI}getStatementByAccountId`;
      // var Headers=APIHeader().headers;
      //setloading(true);
      axios
        .post(GetStatement, accountIdArray, {
          headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setStatement(response.data);
            setAccountStatements(response.data);
            if (response.data) {
              response.data.map((data) => {
                console.log("url", data.fileSha1Hash);
              });
            }
          }
          if (response.data != null && response.data.length > 0) {
            setSelectedValue(response.data[0].fileSha1Hash);
          } else {
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          handleClose();
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate, customerKey);
        }
    }
  }
  else{
     ExpireToken(navigate,customerKey);
  }
  };

  function LoadStatementsForDownload() {
    if (bankdata != null && bankdata.length >= 0) {
    } else {
      clearTransactions();
      return;
    }
    var accountIdArray = [];
    bankdata.forEach((element) => {
      accountIdArray.push(element.id);
    });

    // Handle the selected account logic here
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      const GetStatement = `${VixoAPI.StatmentAPI}getStatementByAccountId`;
      // var Headers=APIHeader().headers;
      //setloading(true);
      axios
        .post(GetStatement, accountIdArray, {
          headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setDownloadStatements(response.data);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          handleClose();
        });
    } catch (error) {
      throw error;
    }
  }
  else{
     ExpireToken(navigate,customerKey);
  }
  }

  const [selectedValue, setSelectedValue] = useState(""); // Initialize selectedValue state

  const handleSelectChange = (event) => {
    const selectedOptionValue = event.target.value;
    //setloading(true);
    setSelectedValue(selectedOptionValue);
    setTimeout(() => {
      //setloading(false);
    }, 1000);
  };

  function handleAccountStatatement() {
    if (!user) return;

    try {
      var data = {
        accountId: selectedAccountNumber,
        userID: user.id,
        customerKey: customerKey,
        numberOfStatements: selectedTime,
      };
      setError("");
      const GetStatement = `${VixoAPI.FinicityAPI}getCustomerAccountStatement`;
      setloading(true);
      axios
        .post(GetStatement, data, {
          headers:APIHeader().headers
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            handleAccountClick(selectedAccountNumber);
            handleClose(false);

            if (responseData.data == null) {
              setSelectedValue("");
            }
          } else {
            let errorData = responseData.message;
            if (typeof errorData === "string") {
              try {
                errorData = JSON.parse(errorData);
              } catch (e) {}
            }
            if (
              errorData &&
              errorData.error_code === "PRODUCTS_NOT_SUPPORTED" &&
              errorData.error_message ===
                'the following products are not supported by this institution: ["statements"]'
            ) {
              setError(
                "This functionality is unavailable in this Plaid account. Please try this bank with Finicity."
              );
            } else {
              setError(errorData.message);
            }
          }
        })
        .catch((error) => {
          setError(error.message);
          setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          handleClose(false);
          setloading(false);
        });
    } catch (error) {
      setError(error.message);
      setloading(false);
    }
  }

  function setError(message) {
    setErrorType(message);
  }
  function setStatement(cuskdata) {
    try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cuskdata));
      setstatementData(cusBankdata);
      handleClose();
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  function LoadBankByCustomerId(customerKey) {
    if(HasLoggedInUser()){
      const config = APIHeader();
    try {
      //setloading(true);

      const GetCustomerBankId = `${VixoAPI.AccountAPI}getbyCustomerKey?Key=${customerKey}`;
      //setloading(true);
      axios
        .get(GetCustomerBankId, {
          headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setBankData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          //setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
     ExpireToken(navigate,customerKey);
  }
  }
  const [bankdata, setBankdata] = useState(null);
  function setBankData(cusbankdata) {
    try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }

  useEffect(() => {
    if (IsDownload && bankdata && bankdata.length > 0) {
      LoadStatementsForDownload();
    }
  }, [IsDownload, bankdata]);

  return (
    <>
      {IsDownload === true ? (
        <>
          {/* <div className="row mt-3">
            {bankdata &&
              bankdata.map((bank, bankIndex) => (
                <div key={bankIndex}>
                  <h4>{bank.accountTitle}</h4>
                  {downloadStatements && downloadStatements.length > 0 ? (
                    downloadStatements.some(
                      (statement) => statement.accountId === bank.id
                    ) ? (
                      downloadStatements
                        .filter((statement) => statement.accountId === bank.id)
                        .map((statement, statementIndex) => (
                          <div className="m-3" key={statementIndex}>
                            {statement.fileSha1Hash}
                          </div>
                        ))
                    ) : (
                      <div className="m-3">
                        No statements found for this account.
                      </div>
                    )
                  ) : (
                    <div className="m-3">
                      No statements found for this account.
                    </div>
                  )}
                </div>
              ))}
          </div> */}
        </>
      ) : (
        <>
          <Spinner show={loading} Title="Statement" />

          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton style={{ borderRadius: "20px" }}>
              <Modal.Title>Select Duration and Bank</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <select
                    className="form-control-lg"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select a Time Duration</option>
                    <option value={1}>One Month</option>
                    <option value={3}>Three Month</option>
                    <option value={4}>Four Month</option>
                    <option value={6}>Six Month</option>
                    <option value={9}>Nine Month</option>
                    <option value={12}>One Year</option>
                  </select>
                </Col>
                <Col>
                  <select
                    className="form-control-lg"
                    value={selectedAccountNumber}
                    onChange={(e) => setSelectedAccountNumber(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    <option value="">Select a Bank</option>
                    {bankdata != null &&
                      bankdata.map((bank, index) => {
                        if (bank.connectionType === 2) {
                          return (
                            <option key={index} value={bank.id}>
                              <p>{bank.instituteName}</p>
                              <p>
                                ({" "}
                                {`${bank.accountType}   ${bank.accountNumberlastFourDigits}`}
                                )
                              </p>
                            </option>
                          );
                        }
                        return null; // If connectionType is not 2, skip this option
                      })}
                  </select>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={handleClose}
                style={{ borderRadius: "20px", width: "7pc", color: "White" }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-info px-5"
                onClick={handleAccountStatatement}
                disabled={!selectedAccountNumber || !selectedTime}
                style={{ borderRadius: "20px", color: "White" }}
              >
                Fetch
              </button>
            </Modal.Footer>
          </Modal>
          <div className="row">
            <Bankcheckbox handleAccountClick={handleAccountClick} />
          </div>
          <div class="row">
            <div class="col-6">
              {errorType && (
                <div className="alert alert-danger" style={{ color: "red" }}>
                  {errorType}
                </div>
              )}
              <select
                className="form-control-lg"
                value={selectedValue}
                onChange={handleSelectChange}
                style={{ width: "50%" }}
              >
                <option value="">Select an option</option>
                {statementData
                  ? statementData.map((item, key) => (
                      <option key={key} value={item.fileSha1Hash}>
                        {item.statementTitle}
                      </option>
                    ))
                  : "  "}
              </select>
            </div>
            <div class="col-6 text-end pb-1">
              <button
                type="button"
                className="btn btn-primary px-5"
                onClick={handleShow}
                style={{ borderRadius: "20px" }}
              >
                Fetch
              </button>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 text-center  "
              style={{ width: "100%", height: "700px" }}
            >
              <embed
                src={selectedValue ? selectedValue : ""}
                type="application/pdf"
                style={{ width: "100%", height: "700px" }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Statment;
