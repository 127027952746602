import "./App.css";
import Home from "./Components/Home/Home";
import Fileindex from "./Components/Files/Fileindex";
import { Route, Routes } from "react-router-dom";
import Tab from "./Components/Home/Tab";
import AttachBank from "./Components/Home/AttachBank";
import Downloadindex from "./Components/DownloadNow/Downloadindex";
import DocumentOpen from "./Components/Files/DocumentOpen";
import StatementOpen from "./Components/Files/StatementOpen";
import ErrorPage from "./Components/Home/ErrorPage";
import Contractindex from "./Components/Docusign/Contractindex";
import CommunicationTimeline from "./Components/Home/CommunicationTimeline";
import React, { useEffect, useState } from "react";
import NotificationsHome from "./Components/Notification/NotificationsHome";
import { useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { VixoAPI } from "./settings";
import { addNotification } from "./Components/Notification/Slices/sendNotificationSlice";
import CustomerResendInvite from "./Components/Header/DropDownComponents/CustomerResendInvite";
import AddCustomerModal from "./Components/Header/DropDownComponents/CustomerAddModal";
import EditCustomer from "./Components/Header/DropDownComponents/EditCustomer";
// import SendingType from "./Components/Header/DropDownComponents/ResendInvite";
import DownloadHome from "./Components/Download/DownloadHome";
import DownloadNow from "./Components/DownloadNow/DownloadNow";
import customeridentityverification from "./Components/IdentityVerification/CustomerIdentityVerfication";
import AddBankAccountFileCustomer from "./Components/Header/DropDownComponents/AddBankAccountFileCustomer";
import TermsCondition from "./Components/Home/Terms";
import APIErrorComponent from "./APIErrorComponent";

const App = () => {
  // Signal R Connection is here
  const dispatch = useDispatch();
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      // sendNotificationToRedux(receivedNotification);
      const { organiationID, cutomerId, notificationType, referenceID } =
        receivedNotification;
      sendNotificationToRedux(receivedNotification);

      // Handle the notification data as needed in your React application
    });

    function sendNotificationToRedux(receivedNotification) {
      dispatch(addNotification(receivedNotification));
    }

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="/notification" element={<NotificationsHome />} />
        <Route path="/filesindex" element={<Fileindex />} />
        <Route path="/vixohome" Component={Home} />
        <Route path="/Home" Component={AttachBank} />
        <Route path="/downloadindex" element={<Downloadindex />} />
        <Route path="/tabs" Component={Tab} />
        <Route path="/downloadnow" Component={DownloadNow} />
        <Route path="/document" Component={DocumentOpen} />
        <Route path="/statement" Component={StatementOpen} />
        <Route path="/Contractindex" element={<Contractindex />} />
        <Route path="/" Component={ErrorPage} />
        <Route path="CommunicationTimeline" Component={CommunicationTimeline} />
        <Route path="/APIErrorComponent" Component={APIErrorComponent} />
        <Route path="/addcustomer" element={<AddCustomerModal />} />
        <Route path="/editcustomer" Component={EditCustomer} />
        <Route path="/resendinvite" Component={CustomerResendInvite} />
        <Route
          path="/AddBankAccountFileCustomer"
          Component={AddBankAccountFileCustomer}
        />
        <Route path="/terms" Component={TermsCondition} />
        <Route path="/DownloadHome" element={<DownloadHome />} />
        <Route
          path="/customeridentityverification"
          Component={customeridentityverification}
        />
      </Routes>
    </>
  );
};

export default App;
