import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faFilePen } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fileopen from "./Fileopen";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import Docdelete from "./Docdelete";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import "./file.css";
import DateComponent from "../../VixoFormats/Date";
import { useNavigate } from "react-router-dom";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";

const CustomerUploadDocumentList = ({
  customerkey,
  userkey,
  IsNewDocumentUploaded,
}) => {
  const [loading, setLoading] = useState(false);
  const childRef = useRef(null);
  const [showOcrPopup, setShowOcrPopup] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [showDeleteDoc, setShowDeleteDoc] = useState(false);
  const [showDeleteStatement, setShowDeleteStatement] = useState(false);
  const [RefreshChildComponent, setRefreshChildComponent] = useState(1);
  const [documentsCount, setDocumentsCount] = useState(0);
  const navigate= useNavigate()
 
  const handleDeleteClick = (Id, type) => { 
    if (type === "statement") {
      setselectedFiledata(Id, type);
      setShowDeleteStatement(true);
   
      
    } else {
      setselectedFiledata(Id, type);
      setShowDeleteDoc(true);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteStatement(false);
    setShowDeleteDoc(false);
  };

  const [selectedFiledata, setselectedFiledata] = useState({});
  const [selectAllOuter, setSelectAllOuter] = useState(false);
  const handleSelectAllCheckboxChange = () => {
    setSelectAllOuter(!selectAllOuter);
  };

  useEffect(() => {
    LoadFreshData();
  }, []);

  useEffect(() => {
    if(IsNewDocumentUploaded>0 && IsNewDocumentUploaded>documentsCount)
    {
      setDocumentsCount(IsNewDocumentUploaded);
      LoadFreshData();
    }
  }, [IsNewDocumentUploaded]);


  function LoadFreshData() {
    LoadDocByCustomerKey();
  
  }


  const [documentData, setDocumentData] = useState(null);

  function LoadDocByCustomerKey() {
  
    if(HasLoggedInUser())
      {
    try {
          const config= APIHeader();
      setLoading(true);
      const DocDataById = `${VixoAPI.DocumentAPI}getAnyDocumentByCustomerKey?Key=${customerkey}`;
      // setLoading(true);
      axios
        .get(DocDataById, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            setDocumentData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
  else{
    ExpireToken(navigate, customerkey);
  }
}

  function setData(docData) {
    try {
      setLoading(true);
      var documentsArray = JSON.parse(JSON.stringify(docData));
      setDocumentData(documentsArray);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);

  function handleViewClick(fileName) {
    const fileview = fileName;
    setFileView(fileview);
    setShowViewPopup(true);
  }

  const handleModalClose = () => {
    setShowOcrPopup(false);
  };

  const DocDeleteSuccess = () => {
    setLoading(true)
    LoadFreshData();
  };

  return (
    <>
      <Fileopen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        title={selectedFiledata?.name}
        pdfSrc={fileView}
      />
      <Docdelete
        show={showDeleteDoc}
        onHide={handleDeleteCancel}
        id={selectedFiledata}
        onDeleteCancel={handleDeleteCancel}
        onDeleteSuccess={DocDeleteSuccess}
      />
      <ToastContainer />
      <Spinner show={loading} Title="Customer Upload Document List" />
      <div className="card">
        <div className="card-header py-3 bg-transparent">
          <h5 className="mb-0">Uploaded Documents</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table id="example" className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Document Name</th>
                  <th>Location</th>
                  <th>Error</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {documentData != null &&
                  documentData.map((document, index) => (
                    <tr key={index}>
                      <td>
                        {document
                          ? 
                          <DateComponent value={document.uploadedDate} / >
                          : ""}{" "}
                      </td>

                      <td>{document.fileName}</td>
                      <td>{document.location}</td>
                      <td>No</td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="reload-tooltip">View Document</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ color: "green", marginLeft: "2px" }}
                            onClick={() =>
                              handleViewClick(document.fileSha1Hash)
                            }
                            icon={faEye}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="reload-tooltip">
                              Delete Document
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ color: "red", marginLeft: "2px" }}
                            onClick={() => handleDeleteClick(document.id)}
                            icon={faTrash}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerUploadDocumentList;
