import React, { useState, useEffect, useRef } from "react";
import "../Header/Sidebar.css";
import "../Home/Account.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Route, json, useLocation, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { faTrash, faEye, faFilePen } from "@fortawesome/free-solid-svg-icons";
import UpdateAccount from "./UpdateAccount";
import Money from "../../VixoFormats/Money";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faSync,
  faArrowRotateRight,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import CustomerAccountDetails from "./CustomerAccountDetails";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PaymentTabTwo from "./Payment2";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import AccountStatus from "../../VixoFormats/AccountStatus";
const CustomerAccount = ({
  custDownload,
  detailComponentID,
  globalSelectedAccountHandler,
  globalSelectedAccountId,
}) => {
  // All useState
  const navigate = useNavigate();
  const [CustomerKey, setCustomerKey] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [loading, setloading] = useState(false);
  const [bankdata, setBankdata] = useState([]);
  const [customer, setCustomers] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showInCustomer, setShowInCustomer] = useState(true);
  const [AccountID, setAccountId] = useState(null);
  const [accountbyaccountid, setaccountbyaccountid] = useState();
  const notification = useSelector(selectNotification);

  //TODO Adnan
  //Check if new New Notification is of Type 1 and is for this Custome using CustomerKey
  //Check if new New Notification is of Type 1 and is for this Custome using CustomerKey
  //Then Referesh Account List for this Customer using LoadBankByCustomerKey()

  function GotNewAlert(notification) {
    console.log("Customer accounts : " + notification.notificationType);

    if (
      // notification =null && CustomerKey!=null  &&
      notification.customerKey != null &&
      notification.notificationType == 1 &&
      notification.customerKey == customer.key
    ) {
      // setAlertCount((prevCount) => prevCount + 1);
      LoadBankByCustomerKey(notification.customerKey);
    }
  }

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function LoadMinAccountByAccountID(accountbyaccountid) {
    if(HasLoggedInUser())
      {
    try {
      //setloading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getMinimalAccountbyAccountID?Id=${accountbyaccountid}`;
      //setloading(true);
      axios
        .get(GetCustomerBankId, {headers:APIHeader().headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            UpdateBank(response.data);
          } else {
          }
        })
        .catch((error) => {
          //setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  const location = useLocation();
  // const hasBanks = bankdata.length > 0;

  //   card color
  const cardColors = ["#5f0a54", "#116fb9", "#910c1f"];
  const CardBodyColors = ["#af8ed3", "#0a426c", "#520810"];

  function UpdateBankAccount(customeraccount) {
    console.log("customeraccount:", customeraccount);
    const updatedArray = bankdata.map((obj) =>
      obj.id === customeraccount.id ? { ...obj, ...customeraccount } : obj
    );

    setBankdata(updatedArray);
    SetGlobalSelectedAccount(customeraccount.id);
  }
  function SetGlobalSelectedAccount(accountID) {
    if(selectedAccount!=accountID)
      {
        globalSelectedAccountHandler(accountID);
        setSelectedAccount(accountID);
      }
  }
  const customerAccountUpdated = (customeraccount) => {
    UpdateBankAccount(customeraccount);
  };

  // UseEffect Get queryString
  useEffect(() => {
    if (custDownload != null) {
      setShowInCustomer(false);
    }
    const params = new URLSearchParams(location.search);
    var CustomerId = params.get("Id");
    var UserID = params.get("U");

    if (CustomerId) {
      setCustomerKey(JSON.stringify(CustomerId));
    }
    if (UserID) {
      setUserKey(JSON.stringify(UserID));
    }
    if (CustomerId != null && UserID != null) {
      LoadBankByCustomerKey(CustomerId);
      LoadCustomer(CustomerId);
    }
  }, [location]);

  const [bankIds, setBankIds] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var bankId = params.get("AccountId");
    if (bankId) {
      setBankIds(bankId);
      // Automatically select the first bank if available
      if (bankdata!=null && bankdata.length > 0) {
        var SelectedGlobalBankAccountIndex =
          bankdata && bankdata.findIndex((p) => p.id === parseInt(bankId));
        if (SelectedGlobalBankAccountIndex > -1) {
          handleAccountClick(bankdata[SelectedGlobalBankAccountIndex]);
          setClickedCardIndex(SelectedGlobalBankAccountIndex);
        }
      }
    } else {
      if (bankdata != null) {
        // Automatically select the first bank if available
        if (bankdata.length > 0 && selectedAccount === null) {
          handleAccountClick(bankdata[0]);
          setClickedCardIndex(0);
        }
      }
    }
  }, [bankdata, bankIds]);

  useEffect(() => {
    if (globalSelectedAccountId != null) {
      // Automatically select the first bank if available
      if (bankdata!=null && bankdata.length > 0) {
        var SelectedGlobalBankAccountIndex =
          // var SelectedGlobalBankAccountIndex=bankdata.indexOf(p=>p.AccountID===globalSelectedAccountId);
          bankdata &&
          bankdata.findIndex((p) => p.id === globalSelectedAccountId);
        if (SelectedGlobalBankAccountIndex > -1) {
          handleAccountClick(bankdata[SelectedGlobalBankAccountIndex]);
          setClickedCardIndex(SelectedGlobalBankAccountIndex);
        }
      }
    }
  }, [bankdata, globalSelectedAccountId]);

  // Get All Bank Using CustomerKey

  function LoadBankByCustomerKey(customerKey) {
    if(HasLoggedInUser())
      {
        var config=APIHeader();
    try {
      setloading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getMinimalAccountsCustomerKey?Key=${customerKey}`      //setloading(true);
      axios
        .get(GetCustomerBankId,{headers:config.headers} )
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            BankData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      throw error;
    }
  }else
  {
    ExpireToken(navigate,CustomerKey);
  }
  }

  function LoadBankByCustomerKeySilent(customerKey) {
    if(HasLoggedInUser())
      {
        var config=APIHeader();
   
    try {
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getMinimalAccountsCustomerKey?Key=${customerKey}`;
      axios
        .get(GetCustomerBankId,{headers:config.headers} )
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            BankData(response.data);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate,CustomerKey);
            }

        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  function BankData(cusbankdata) {
    if(HasLoggedInUser())
    {
      try {
      //setloading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));
      setBankdata(cusBankdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
}

  function UpdateBank(updatedBank) {
    if(HasLoggedInUser()){

    try {
      var cusBankdata = JSON.parse(JSON.stringify(updatedBank));
      const updatedData = bankdata.map((item) =>
        item.id === cusBankdata.id ? { ...item, cusBankdata } : item
      );

      // Update the state with the new array
      setBankdata(updatedData);
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }
  // End Bank using customerKey

  // Get Customer

  function LoadCustomer(CustomerKey) {
    if(HasLoggedInUser()){
      const config = APIHeader();
    try {
      //setloading(true);
      var Key = encodeURIComponent(CustomerKey);
      const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
      //setloading(true);
      axios
        .get(GetCustomerId, {headers:config.headers} )
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            CusData(response.data);
          } else {
            // setError(response.message);
          }
        })
        .catch((error) => {
          //setloading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate, CustomerKey);
            }
        })
        .finally(() => {
          //setloading(false);
        });
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  function CusData(data) {
    if(HasLoggedInUser()){
    try {
      //setloading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setCustomers(customersdata);
      //setloading(false);
    } catch (error) {
      //setloading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  //   End Get Customer
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [paymentTabTwoData, setPaymentTabTwoData] = useState(null);
  // Account Click
  const handleAccountClick = (bankdata) => {
    if(selectedAccount!=bankdata.id)
      {
        SetGlobalSelectedAccount(bankdata.id);
      }
    setAccountId(bankdata.id);
    // Pass selected account details to PaymentTabTwo component
    const { accountNumberlastFourDigits, balance, accountTitle } = bankdata;
    const selectedAccountDetails = {
      accountNumber: accountNumberlastFourDigits,
      balance: balance,
      accountTitle: accountTitle,
    };
    setPaymentTabTwoData(selectedAccountDetails);
  };
  const handleButtonRefresh = () => {
    // Reload data without changing the selectedAccount state
    LoadCustomer(CustomerKey);
  };

  const [updateBank, setUpdateBank] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleModaUpdateOpen = (BankDetails) => {
    if (BankDetails) {
      setUpdateBank(BankDetails);
    } else {
      setUpdateBank(null);
    }
    setShowModal(true);
  };

  const handleModalClose = (account) => {
    setShowModal(false);
    if (account) {
      UpdateBankAccount(account);
      console.log("Account", account);
    } else {
      console.error("No account data received after closing the modal.");
    }
  };
  const selectedAccountRef = useRef(null);
  useEffect(() => {
    if (selectedAccountRef.current) {
      selectedAccountRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [selectedAccount]);
  return (
    <>
      <ToastContainer />
      <UpdateAccount
        showModal={showModal}
        updateBank={updateBank}
        handleModalClose={handleModalClose}
      />

      <Spinner show={loading} Title="Customer Account"/>

      <div className="row mb-2">
        {/* <div
          className="col-12 text-center"
          style={{
            marginLeft: "-50vh",
            marginTop: "16px",
          }}
        >
          <Button
            variant="primary"
            className="btn btn-light"
            onClick={handleButtonRefresh}
            style={{
              fontSize: "20px",
              backgroundColor: "white",
              color: "darkgreen",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowRotateRight}
              style={{ marginRight: "5px" }}
            />
          </Button>
        </div> */}
      </div>

      {showInCustomer && (
        <div className="containerFluid ShowinCustomer mt-3">
          <div className="row">
            <div className="col-md-3 customer-account">
              <div
                className="sidebar-container"
                style={{
                  padding: 10,
                }}
              >
                {bankdata!=null && bankdata.length > 0 ? (
                  // If there are banks, display them
                  bankdata.map((bank, index) => (
                    <div
                      className="row mt-2 bankcard"
                      style={{
                        borderBottom: "10px solid white ",
                        backgroundColor: "#004265",
                        borderRadius: "14px",
                        border: " 0.5px solid #BECDDB",
                        opacity: clickedCardIndex !== index ? "0.5" : "1",
                      }}
                      key={index}
                      ref={(ref) => {
                        if (selectedAccount === bank.id) {
                          selectedAccountRef.current = ref;
                        }
                      }}
                      onClick={() => {
                        handleAccountClick(bank);
                        setClickedCardIndex(index);
                      }}
                    >
                      <div className="col-md-12">
                        <Card
                          className="mb-2 mt-2 cardbank"
                          style={{
                            boxShadow:
                              "rgba(0,  0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(0 0 0 / 20%) 0px 0px 0px 0px",
                            backgroundColor: "white",
                            padding: "1px",
                            borderRadius: "15px",
                          }}
                          onClick={() => {
                            handleAccountClick(bank);
                            setClickedCardIndex(index);
                          }}
                        >
                          {bank.connectionType == 5 && (
                            <div className="text-end">
                              <a
                                href={bank.statementUrl}
                                target="_blank"
                                style={{ marginRight: "3%" }}
                              >
                                <FontAwesomeIcon
                                  style={{ color: "black" }}
                                  className="icon-bank-eye"
                                  icon={faEye}
                                />
                              </a>
                              {bank.hasErrors ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="reload-tooltip">
                                      {bank.errors.map((erroritem, indx) => (
                                        <div key={indx}>
                                          FieldName: {erroritem.errorField}
                                          <br />
                                          ErrorMessage: {erroritem.errorMessage}
                                        </div>
                                      ))}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="icon-bank"
                                    style={{
                                      color: "black",
                                     
                                    }}
                                    onClick={() => handleModaUpdateOpen(bank)}
                                  />
                                </OverlayTrigger>
                              ) : (
                                <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  className="icon-bank-square"
                                  style={{
                                    color: "black",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => handleModaUpdateOpen(bank)}
                                />
                              )}
                            </div>
                          )}
                          {
                          bank.instituteLogoUrl ? (
                            <Card.Img
                              className="col-md-12 bg-light text-center img-fluid mb-1 mt-2 instituteLogoUrl"
                              src={bank.instituteLogoUrl}
                              alt="Bank Image"
                              style={{ alignSelf: "center" }}
                            />
                          ) : (
                            <h3 className="text-center">
                              {/* {bank.instituteName} */}
                            </h3>
                          )}

                          <Card
                            className="bankborder"
                            style={{
                              background: bank.brandingSecColor
                                ? Array.isArray(bank.brandingSecColor)
                                  ? bank.brandingColor[
                                      index % bank.brandingSecColor.length
                                    ]
                                  : bank.brandingSecColor
                                : "#0B5ED7",
                                marginTop:"3px"
                            }}
                          ></Card>
                        </Card>
                        <Card.Title className="d-flex align-items-center justify-content-between p-2 rounded">
                          <div
                            className="text-light banktext"
                            style={{ fontSize: "15px", color: "white" }}
                          >
                            {bank ? bank.lastUpdatedAgo : ""}
                          </div>
                          <div
                            className="text-light banktext"
                            style={{
                              fontSize: "15px",
                              color:
                                bank.connectionId === 0
                                  ? "lime"
                                  : bank.connectionId === 1
                                    ? "yellow"
                                    : bank.connectionId === 2 ||
                                        bank.connectionId === 5 ||
                                        bank.connectionId === 4
                                      ? "lime"
                                      : bank.connectionId === 3 ||
                                          bank.connectionId === 6
                                        ? "red"
                                        : "#ffc107",
                            }}
                          >
                            <AccountStatus value={bank.connectionId} />
                          </div>
                        </Card.Title>
                        <div
                          style={{
                            borderTop: "2px solid  white",
                          }}
                        >
                          <p className="banktext"
                            style={{
                              color: "white",
                              fontSize: "15px",
                              marginTop: "-1px",
                              color: "white",
                              marginLeft: "8px",
                              // borderTop: "1px solid white",
                            }}
                          >
                            <br />
                            {bank.accountTitle}
                            &nbsp; &nbsp;
                            {bank.accountNumberlastFourDigits &&
                            bank.accountNumberlastFourDigits.length === 4
                              ? `(${bank.accountNumberlastFourDigits})`
                              : bank.accountNumberlastFourDigits}
                          </p>

                          {/* <div
                                className="form-check"
                                style={{ float: "right" }}
                              >
                                <label key={index}>{bank.label}</label>
                              </div> */}

                          <div
                            className="col-md-12  bankpayment bankpaymentfooter"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "white",
                              padding: "10px",
                              borderRadius: "2pc",
                              marginBottom: "1pc",
                              marginTop:"3px"
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPowerOff}
                              style={{
                                marginTop: "3px",
                                color:
                                  bank.connectionId === 0
                                    ? "lime"
                                    : bank.connectionId === 1
                                      ? "yellow"
                                      : bank.connectionId === 2
                                        ? "lime"
                                        : bank.connectionId === 3
                                          ? "red"
                                          : bank.connectionId === 6
                                            ? "gray"
                                            : "blue",
                              }}
                            />
                            <span
                              className="banktext"
                              style={{
                                color: "#004265",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              <span className="payment">
                              <Money value={bank.balance} />
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // If there are no banks, display the "Connect a bank" message
                  <div
                    className="text-center fw-bold fs-4 mb-5 "
                    style={{ marginTop: "177px" }}
                  >
                    Connect your bank to access the data
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 customer-account-details">
              {/* {detailComponentID != null && detailComponentID == 1 ? ( */}

              {detailComponentID === 1 ? (
                <CustomerAccountDetails
                  selectedCustomer={customer}
                  selectedAccount={selectedAccount}
                  AccountID={AccountID}
                  forDownload={true}
                  accountUpdated={customerAccountUpdated}
                />
              ) : (
                <PaymentTabTwo
                  detailComponentID={2}
                  customer={customer}
                  selectedAccount={selectedAccount}
                  AccountID={AccountID}
                  forDownload={true}
                  accountUpdated={customerAccountUpdated}
                  selectedAccountDetails={paymentTabTwoData}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {!showInCustomer && (
        <div className="container mt-3 showindownload">
          {bankdata != null &&
            bankdata.map((bankdetail, downloadindex) => {
              return (
                <div className="row mt-2" key={downloadindex}>
                  <div className="col-md-2">
                    <div className="sidebar-container">
                      <div className="row">
                        <div className="col-md-12">
                          <Card
                            className="mb-2 mt-2 "
                            style={{
                              boxShadow:
                                "rgba(0,  0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(0 0 0 / 20%) 0px 0px 0px 0px",
                              backgroundColor: "rgb(0, 66, 101)",
                              padding: "1px",
                              borderRadius: "15px",
                            }}
                          >
                            {/* <Card.Img
                              src={bankdetail.instituteLogoUrl}
                              alt="Bank Image"
                              className="col-md-12 bg-light text-center img-fluid mb-1 mt-2"
                              style={{ alignSelf: "center" }}
                            ></Card.Img> */}
                            <Card
                              className="m-2"
                              style={{
                                borderBottom: "10px solid white ",
                                backgroundColor: "#004265",
                                borderRadius: "14px",
                                border: " 0.5px solid #BECDDB",
                                // opacity: clickedCardIndex !== downloadindex ? "0.5" : "1",
                              }}
                            >
                              <Card.Title className="d-flex align-items-center justify-content-between p-2 rounded">
                                <div
                                  className="text-light"
                                  style={{ fontSize: "15px", color: "white" }}
                                >
                                  {bankdetail ? bankdetail.lastUpdatedAgo : ""}
                                </div>
                                <div
                                  className="text-light"
                                  style={{
                                    fontSize: "15px",
                                    color:
                                      bankdetail.connectionId === 0
                                        ? "lime"
                                        : bankdetail.connectionId === 1
                                          ? "yellow"
                                          : bankdetail.connectionId === 2
                                            ? "lime"
                                            : bankdetail.connectionId === 3
                                              ? "red"
                                              : "#ffc107",
                                  }}
                                >
                                  <AccountStatus
                                    value={bankdetail.connectionId}
                                  />
                                </div>
                              </Card.Title>
                              <div
                                style={{
                                  borderTop: "2px solid  white",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "15px",
                                    marginTop: "-1px",
                                    color: "white",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <br />
                                  {bankdetail.accountTitle}
                                  &nbsp; &nbsp;
                                  {bankdetail.accountNumberlastFourDigits &&
                                  bankdetail.accountNumberlastFourDigits
                                    .length === 4
                                    ? `(${bankdetail.accountNumberlastFourDigits})`
                                    : bankdetail.accountNumberlastFourDigits}
                                </p>
                                <div
                                  className="col-md-12 mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    background: "white",
                                    padding: "10px",
                                    borderRadius: "2pc",
                                    marginBottom: "1pc",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPowerOff}
                                    style={{
                                      marginTop: "3px",
                                      color:
                                        bankdetail.connectionType === 0
                                          ? "lime"
                                          : bankdetail.connectionType === 1
                                            ? "yellow"
                                            : bankdetail.connectionType === 2
                                              ? "lime"
                                              : bankdetail.connectionType === 3
                                                ? "red"
                                                : "blue",
                                    }}
                                  />
                                  <span
                                    className=""
                                    style={{
                                      color: "#004265",
                                      fontWeight: "600",
                                      fontSize: "15px",
                                    }}
                                  >
                                    ${bankdetail.balance}
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <CustomerAccountDetails
                      selectedCustomer={customer}
                      selectedAccount={selectedAccount}
                      AccountID={AccountID}
                      forDownload={false}
                      accountUpdated={customerAccountUpdated}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default CustomerAccount;
