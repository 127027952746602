import { VixoAPI } from '../../../settings';
import axios from 'axios';
import { APIHeader, ExpireToken, HasLoggedInUser } from '../../../APICallWrapper';

export async function UserRoleTypes() {
  const config = APIHeader();
  try {
    const APIPath = VixoAPI.LookupAPI + 'getUserRoles';
    const response = await axios.get(APIPath, { headers: config.headers });
    const apiResponse = response.data;
    if (apiResponse.statusCode === 200) {
      return apiResponse.data;
    } else {
      throw new Error('Failed to fetch user roles');
    }
  } catch (error) {
    throw error;
  }
}

export async function AdminUserRoleTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem('AdminUserRoleTypes');
    if (lookups === null) {
      const APIPath = VixoAPI.LookupAPI + 'getAdminRoles';
      const response = await axios.get(APIPath, { headers: config.headers });
      const apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        localStorage.setItem('AdminUserRoleTypes', JSON.stringify(apiResponse.data));
        return apiResponse.data;
      } else {
        throw new Error('Failed to fetch admin user roles');
      }
    } else {
      return JSON.parse(lookups);
    }
  } catch (error) {
    throw error;
  }
}

export async function InstituteTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem('InstituteTypes');
    if (lookups === null) {
      const APIPath = VixoAPI.LookupAPI + 'getInstituteTypes';
      const response = await axios.get(APIPath, { headers: config.headers });
      const apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        localStorage.setItem('InstituteTypes', JSON.stringify(apiResponse.data));
        return apiResponse.data;
      } else {
        throw new Error('Failed to fetch institute types');
      }
    } else {
      return JSON.parse(lookups);
    }
  } catch (error) {
    throw error;
  }
}

export async function ThresholdTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem('ThresholdTypes');
    if (lookups === null) {
      const APIPath = VixoAPI.LookupAPI + 'getThresholdType';
      const response = await axios.get(APIPath, { headers: config.headers });
      const apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        localStorage.setItem('ThresholdTypes', JSON.stringify(apiResponse.data));
        return apiResponse.data;
      } else {
        throw new Error('Failed to fetch threshold types');
      }
    } else {
      return JSON.parse(lookups);
    }
  } catch (error) {
    throw error;
  }
}

export async function OpenBankingType() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem('OpenBankingType');
    if (lookups === null) {
      const APIPath = VixoAPI.LookupAPI + 'getOpenBankingType';
      const response = await axios.get(APIPath, { headers: config.headers });
      const apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        localStorage.setItem('OpenBankingType', JSON.stringify(apiResponse.data));
        return apiResponse.data;
      } else {
        throw new Error('Failed to fetch open banking types');
      }
    } else {
      return JSON.parse(lookups);
    }
  } catch (error) {
    throw error;
  }
}

export async function PaymentStatusType() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem('PaymentStatusType');
    if (lookups === null) {
      const APIPath = VixoAPI.LookupAPI + 'getVixoPaymentStatusTypes';
      const response = await axios.get(APIPath, { headers: config.headers });
      const apiResponse = response.data;
      if (apiResponse.statusCode === 200) {
        localStorage.setItem('PaymentStatusType', JSON.stringify(apiResponse.data));
        return apiResponse.data;
      } else {
        throw new Error('Failed to fetch payment status types');
      }
    } else {
      return JSON.parse(lookups);
    }
  } catch (error) {
    throw error;
  }
}
