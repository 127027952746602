import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import StatementUploadPopup from "./StatementUploadPopup";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";

import "./file.css";
import { useNavigate } from "react-router-dom";

const CustomerUploadStatement = ({
  customerkey,
  userkey,
  NewStatementUploaded,
}) => {
  const [loading, setLoading] = useState(false);
  const documentInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [showOcrPopup, setShowOcrPopup] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const navigate =useNavigate()

  // ---------show delete popup-----------
  const [RefreshChildComponent, setRefreshChildComponent] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setShowOcrPopup(true);
    setFiles(Array.from(selectedFiles));
    setSelectedFiles([]);
    e.target.value = "";
  };



  const [statementData, setStatementData] = useState(null);

  function LoadMinimalStatementByCustomerKey() {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      const SetDataById = `${VixoAPI.StatmentAPI}getMinimalStatementByCustomerKey?Key=${customerkey}&OcrStatus=${false}`;
      axios
        .get(SetDataById, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setstatementData(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
  else{
    ExpireToken(navigate)
  }
  }

  function setstatementData(StatementData) {
    try {
      setLoading(true);
      var Statdata = JSON.parse(JSON.stringify(StatementData));
      setStatementData(Statdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const [documentData, setDocumentData] = useState(null);

  function LoadDocByCustomerKey() {
    if(HasLoggedInUser()){
      const config= APIHeader();
    try {
      // setLoading(true);
      const DocDataById = `${VixoAPI.DocumentAPI}getAnyDocumentByCustomerKey?Key=${customerkey}`;
      // setLoading(true);
      axios
        .get(DocDataById, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
            setDocumentData(response.data);
            // LoadMinimalStatementByCustomerKey();
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          throw error;
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      // setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
  else{
   ExpireToken(navigate)
  }
  }

  function setData(docData) {
    try {
      setLoading(true);
      var Docmentdata = JSON.parse(JSON.stringify(docData));
      setDocumentData(Docmentdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const handleModalClose = () => {
    LoadDocByCustomerKey();
    LoadMinimalStatementByCustomerKey();
    setShowOcrPopup(false);
  };

  const [fileUploaded, setFileUploaded] = useState(false);

  const StatementUploaded = () => {
    NewStatementUploaded();
  };

  return (
    <>
      <StatementUploadPopup
        show={showOcrPopup}
        handleModalClose={handleModalClose}
        files={files}
        selectedFiles={selectedFiles}
        customerkey={customerkey}
        NewStatementUploaded={StatementUploaded}
        
      />

      <ToastContainer />
      <Spinner show={loading} Title="Customer Upload Statement"/>

      <h6
        className="text-start"
        style={{ fontSize: "18px", color: "#004265", fontWeight: "400" }}
      >
        Upload Statement
      </h6>
      <fieldset
        className="upload_dropZone text-center mb-3 p-4"
        style={{ cursor: "pointer" }}
      >
        <legend className="visually-hidden">Document uploader</legend>
        <label
          // className="mb-3"
          htmlFor="formFileLg"
          style={{
            marginTop: "-2px",
            color: "#004265",
            fontSize: "20px",
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
            display: "inline-block",
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            fontWeight: "600",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            <input
              id="formFileLg"
              className="form-control"
              ref={documentInputRef}
              type="file"
              multiple
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "600",
              }}
              onChange={handleFileChange}
            />
          </div>
          <img src="assets/images/fileuploader.svg" alt="Document Uploader" />
          <br />
          <h2
            className="mt-3"
            style={{ fontSize: "20px", color: "#004265", fontWeight: "600" }}
          >
            Upload Statement
          </h2>

          <p
            className="mt-3"
            style={{
              color: "#004265",
              fontSize: "16px",
              cursor: "pointer",
              fontWeight: "300",
            }}
          >
            Drag and drop files here
          </p>
        </label>
      </fieldset>
    </>
  );
};

export default CustomerUploadStatement;
