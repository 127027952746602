import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faSync,
  faArrowRotateRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../Spinner";
import OCRStatements from "./OCRStatements";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import "./file.css";
import StatementUploadPopup from "./StatementUploadPopup";
import CustomerUploadStatement from "./CustomerUploadStatement";
import CustomerUploadStatementList from "./CustomerUploadStatementList";
import CustomerUploadDocument from "./CustomerUploadDocument";
import CustomerUploadDocumentList from "./CustomerUploadDocumentList";

const File = ({ customerkey, userkey }) => {
  const notification = useSelector(selectNotification);
  const NotificationReceived = notification;
  const [loading, setLoading] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [IsNewDocumentUploaded, setIsNewDocumetnUploaded] = useState(0);
  const [IsNewStatementUploaded, setIsNewStatementUploaded] = useState(0);
  const [IsNewStatementOcrUploaded, setIsNewStatementOcrUploaded] = useState(1);



  function LoadFreshData() {
    NewStatementUploaded();
    NewDocumentUploaded();
  }

  const NewDocumentUploaded = () => {
    var NewIsNewDocumentUploaded = IsNewDocumentUploaded + 1;
    setIsNewDocumetnUploaded(NewIsNewDocumentUploaded);
  };

  const NewStatementUploaded = () => {
    var NewIsNewStatementUploaded = IsNewStatementUploaded + 1;
    setIsNewStatementUploaded(NewIsNewStatementUploaded);
  };



  const handleRefreshClick = () => {
    LoadFreshData();
  };


  const deleteocr = () => {
    NewStatementUploaded();
  };

  const [fileUploaded, setFileUploaded] = useState(false);

  const StatementUploaded = () => {
    setFileUploaded(true);
  };


  useEffect(() => {
    if (NotificationReceived != null) {
      GotNewAlert(NotificationReceived);
    }
  }, [NotificationReceived]);
  

  function GotNewAlert(NotificationData) {
    if (
      NotificationData != null &&
      NotificationData.customerKey == customerkey &&
      NotificationData.notificationType == 2 ||   NotificationData.notificationType == 17
    ) {
      NewStatementUploaded();
    }


    if (
      NotificationData != null &&
      NotificationData.customerKey == customerkey &&
      NotificationData.notificationType == 3
    ) {
      NewDocumentUploaded();
    }
  }


  return (
    <>
      <ToastContainer />
      <Spinner show={loading} Title="File"/>

      <OCRStatements
        customerkey={customerkey}
        Refresh={deleteocr}
        fileUploaded={fileUploaded}
        userkey={userkey}
        IsNewStatementUploaded={IsNewStatementUploaded}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 shadow fileindexing">
            <div className="card m-3">
              <div className="text-end " style={{ marginRight: "39px" }}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="refresh-tooltip">
                      Refresh Document & Statement
                    </Tooltip>
                  }
                >
                  <button
                    className="btn btn-info"
                    style={{
                      borderRadius: "20px",
                      fontWeight: "500",
                      width: "7pc",
                      color: "white",
                      marginTop:"12px"
                    }}
                    onClick={handleRefreshClick}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faArrowRotateRight} /> Refresh
                  </button>
                </OverlayTrigger>
              </div>
              <div className="card-header bg-transparent"></div>
              <div className="row">
                <div className="col-md-2"></div>
                <div
                  className="col-md-9 files"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="col-8 fileindexing">
                    <div className="">
                      <div className=" py-3">
                        <h4
                          className="mb-0 text-start textupload"
                          style={{
                            color: "#00AADB",
                            fontWeight: 600,
                            fontSize: "25px",
                            marginLeft: "-280px",
                          }}
                        >
                          Upload Files
                        </h4>
                      </div>
                      <div className="card-body">
                        <CustomerUploadStatement
                          customerkey={customerkey}
                          NewStatementUploaded={NewStatementUploaded}
                        />
                        <CustomerUploadDocument
                          customerkey={customerkey}
                          NewDocumentUploaded={NewDocumentUploaded}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mx-auto mt-4 shadow ">
            <CustomerUploadStatementList
              customerkey={customerkey}
              IsNewStatementUploaded={IsNewStatementUploaded}
            />
            <CustomerUploadDocumentList
              customerkey={customerkey}
              IsNewDocumentUploaded={IsNewDocumentUploaded}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default File;
