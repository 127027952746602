import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { APIHeader,HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  faTrash,
  faEye,
  faFilePen,
  faExclamationTriangle,
  faDiamondTurnRight,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Fileopen from "./Fileopen";
import "./file.css";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import OcrStatementDelete from "./OcrStatementDelete";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
const OCRStatements = ({
  customerkey,
  Refresh,
  fileUploaded,
  userkey,
  IsNewStatementUploaded,
}) => {
  const [selectedFiledata, setselectedFiledata] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [statementCount, setStatementCount] = useState(0);
  const navigate=useNavigate()
  const handleDeleteClick = (Id) => {
    setselectedFiledata(Id);
    setShowDeletePopup(true);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  useEffect(() => {
    if (IsNewStatementUploaded > 0 && IsNewStatementUploaded > statementCount) {
      setStatementCount(IsNewStatementUploaded);
      LoadFreshData();
    }
  }, [IsNewStatementUploaded]);

  useEffect(() => {
    LoadFreshData();
  }, []);

  function LoadFreshData() {
    LoadMinimalStatementByCustomerKey();
  }
  const [loading, setLoading] = useState(false);
  const [statementData, setStatementData] = useState(null);

  function LoadMinimalStatementByCustomerKey() {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const SetDataById = `${
        VixoAPI.StatmentAPI
      }getMinimalStatementByCustomerKey?Key=${customerkey}&OcrStatus=${true}`;
      axios
        .get(SetDataById, { headers: config.headers })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setdata(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
}

  function LoadStatementByCustomerKey() {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      setLoading(true);
      const SetDataById = `${
        VixoAPI.StatmentAPI
      }getStatementByCustomerKey?Key=${customerkey}&OcrStatus=${true}`;
      setLoading(true);
      axios
        .get(SetDataById, { headers: APIHeader().headers })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setdata(response.data);
          } else {
            //setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate);
        }
    }
  }
  else{
    ExpireToken(navigate)
  }
}

  function setdata(Data) {
    try {
      setLoading(true);
      var data = JSON.parse(JSON.stringify(Data));
      setStatementData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);
  function handleViewClick(fileName) {
    const fileview = fileName;
    setFileView(fileview);
    setShowViewPopup(true);
  }

  const FileDeleteSuccess = () => {
    Refresh();
    // LoadStatementByCustomerKey();
    LoadMinimalStatementByCustomerKey();
  };

  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(
    location.pathname === "/vixohome" ? "banking" : "files"
  );

  const URlToAppened = "?Id=" + customerkey + "&U=" + userkey;

  useEffect(() => {
    if (location.pathname === "/vixohome") {
      setSelectedItem("banking" + URlToAppened);
    }
  }, [location.pathname]);

  return (
    <>
      {/* view file  */}
      <Fileopen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        title={selectedFiledata?.name}
        pdfSrc={fileView}
      />

      {/* delete file  */}
      <OcrStatementDelete
        show={showDeletePopup}
        onHide={handleDeleteCancel}
        id={selectedFiledata}
        onDeleteCancel={handleDeleteCancel}
        onDeleteSuccess={FileDeleteSuccess}
      />
      {/* end delete file  */}

      <div className="">
        <div className="row"></div>
        <div className="row">
          {statementData != null &&
            statementData.map((statement, index) => (
              <div
                key={index}
                className={`col-md-3 shadow text-center animated ${
                  /* Add a condition to apply animation only when a new statement is added */
                  statement.newlyAdded ? "fadeInUp" : ""
                }`}
              >
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-1 text-end">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="reload-tooltip">View File</Tooltip>}
                    >
                      <FontAwesomeIcon
                        className="p-2"
                        style={{
                          color: "green",
                          marginLeft: "-6px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewClick(statement.fileSha1Hash)}
                        icon={faEye}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="col-1 mt-1 text-end">
                    {statement.ocrtypeId === 4 || statement.ocrtypeId === 8 ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="reload-tooltip">
                            Redirect Account
                          </Tooltip>
                        }
                      >
                        <NavLink
                          className="nav-link"
                          to={`/vixohome${URlToAppened}&AccountId=${statement.accountId}`}
                          onClick={() => setSelectedItem("banking")}
                        >
                          <FontAwesomeIcon
                            className="p-1"
                            icon={faDiamondTurnRight}
                            flip="both"
                            style={{
                              color: "black",
                              marginTop: "5%",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                          />
                        </NavLink>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-1 text-end">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="reload-tooltip">Risk</Tooltip>}
                    >
                      <FontAwesomeIcon
                        className="p-2"
                        style={{
                          color: "red",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        icon={faExclamationTriangle}
                      />
                    </OverlayTrigger>
                  </div>

                  <div className="col-1 text-end">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="reload-tooltip">
                          Remove Transaction
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        className="p-2"
                        style={{
                          color: "red",
                          marginLeft: "2px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteClick(statement.id)}
                        icon={faXmark}
                      />
                    </OverlayTrigger>
                  </div>
                </div>

                {/* <p
                  className="text-center"
                  style={{ marginTop: "17px", cursor: "pointer" }}
                  onClick={() => handleViewClick(statement.fileSha1Hash)}
                > */}
                {/* <span
                    onClick={() => handleViewClick(statement.fileSha1Hash)}
                    style={{
                      width: "147px",
                      height: "140px",
                      overflow: "hidden !important",
                    }}
                  > */}
                <embed
                  src={statement.fileSha1Hash}
                  type="application/pdf"
                  width="200"
                  height="200"
                  onClick={() => handleViewClick(statement.fileSha1Hash)}
                  style={{ overflow: "hidden" }}
                />
                {/* </span> */}
                {/* </p> */}
                <div className="card-body m-3">
                  <h6 className="card-title">{statement.statementTitle}</h6>
                  <div>
                    <img
                      src={statement.ocrName}
                      alt="Logo 2"
                      style={{
                        width: "60px",
                        height: "20px",
                        marginTop: "-10px",
                        marginRight: "1rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default OCRStatements;
