import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import ErrorComponent from "../../ErrorComponent";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings"; // Import VixoAPI if needed
import PhoneNumber from "../../VixoFormats/Phone";
import Money from "../../VixoFormats/Money";
import DateComponent from "../../VixoFormats/Date";
import DollarSign from "../../VixoFormats/DollarSign";

const PaymentFund = ({
  showModal,
  handleModalClose,
  loanTerm,
  interestRate,
  debtCancellation,
  handleLoanTermChange,
  handleInterestRateChange,
  handleDebtCancellationChange,
  loanPurpose,
  customerFundingDetail,
  customer,
  selectedAccount,
  requestamount,
  bankAccount,
  selectedAccountDetails,
  PaymentRequestDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [organizationID, setorganizationID] = useState(0);
  const [userID, setUserID] = useState(0);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [customerKey, setcustomerKey] = useState("");
  const [User, setUser] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const navigate =useNavigate()
  const [successMessageStyle, setSuccessMessageStyle] = useState({
    color: "green",
  });
  const isDataValid = () => {
    return true;
  };
  const [errorType, setErrorType] = useState("");
  const handleInputChange = (e, setValue) => {
    const inputValue = e.target.value;
    const numbersOnly = inputValue.replace(/[^0-9.]/g, "");
    setValue(numbersOnly);
  };
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("Id");
    if (keyFromParams) {
      setcustomerKey(keyFromParams);
    }
     var CustomerToken = params.get("Id");
     var UserToken = params.get("U");
    if (UserToken != null) {
      setUserKey(UserToken);
      
     
    }
  }, [location]);
  var accountNumber = 0;
  const requestData = {
    id: 0,
    organizationId: organizationID,
    userId: userID,
    customerId: 0,
    amount: loanTerm,
    interest: interestRate,
    terms: debtCancellation,
    purpose: loanPurpose,
    AccountNumber: selectedAccountDetails?.accountNumber || null,
    CustomerFundingRequest: requestamount || null,
    paymentRequestDate: PaymentRequestDate || null,
    customerFundingDetail: customerFundingDetail || null,
  };

  // load user
   useEffect(() => {
     if (UserKey && UserKey != null) {
       LoadUser();
     }
   }, [UserKey]);

   function LoadUser() {
    if(HasLoggedInUser()){
      const config=APIHeader();
     try {
      //  setLoading(true);
       var Key = encodeURIComponent(UserKey);
       const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
      //  setLoading(true);
       axios
         .get(GetUserByKeyAPI, {headers:config.headers})
         .then((response) => {
           var response = response.data;
           if (response.statusCode === 200) {
             setUserDataFromAPI(response.data);
           } else {
           }
         })
         .catch((error) => {
          //  setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,customerKey);
              }
            })
            .finally(() => {
              //  setLoading(false);
            });
          } catch (error) {
            //  setLoading(false);
            throw error;
          }
        }
        else{
          ExpireToken(navigate,customerKey)
        }
   }
   function setUserDataFromAPI(data) {
     try {
      //  setLoading(true);
       var userObject = JSON.parse(JSON.stringify(data));
       setUserID(userObject.id);
       setorganizationID(userObject.organizationId);
       setUser(userObject);
      //  setLoading(false);
     } catch (error) {
      //  setLoading(false);
       throw error;
     }
   }
  // 
  const handleSubmit = async (e) => {
    e.preventDefault();
    var encodedKey = encodeURIComponent(customerKey);
    // Implement your validation logic here
    if (!isDataValid()) {
      return;
    }
    try {
      const UpdateCustomerFundingAPIPath =
        VixoAPI.FundingAPI + "UpdateCustomerFunding?CustomerKey=" + encodedKey;
      setLoading(true);
      const response = await axios.put(
        {headers:APIHeader().headers},
        UpdateCustomerFundingAPIPath,
        requestData
      );
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        setConfirmationSuccess(true);
        setSuccessMessage(responseData.message);
        setSuccessMessageStyle({ color: "green" });
        handleModalClose();
      } else {
        setErrorType(responseData.message);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  function formatAmount(amount) {
    return amount.toFixed(2); // Format to two decimal places
  }

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} Title="Payment Fund"/>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <p>
              <div
                className="col-md-12 col-sm-12  AccountInfoMediaOne"
                style={{
                  border: "0px solid #DFD1D1",
                  backgroundColor: "white",
                }}
              >
                <div className="row MediaOneRow">
                  <div className="col-md-6">
                    <span className="fw-bold">Name</span>
                    <br />
                    <span>{customer ? customer.name : ""}</span>
                  </div>
                  <div className="col-md-6">
                    <span className="fw-bold">Email</span>
                    <br />
                    <span style={{ wordBreak: "break-word" }}>
                      {customer ? customer.email : ""}
                    </span>
                  </div>
                </div>
                <div className="row MediaOneRow mt-2">
                  <div className="col-md-6">
                    <span className="fw-bold">Phone</span>
                    <br />
                    <span>
                      {customer ? <PhoneNumber number={customer.phone} /> : ""}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <span className="fw-bold">Account Number</span>
                    <br />

                    <span>
                      {selectedAccountDetails &&
                      selectedAccountDetails.accountNumber != null
                        ? selectedAccountDetails.accountNumber
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="row MediaOneRow mt-2">
                  <div className="col-md-6">
                    <span className="fw-bold">Account Type</span>
                    <br />

                    <span>
                      {selectedAccountDetails &&
                      selectedAccountDetails.accountTitle != null
                        ? selectedAccountDetails.accountTitle
                        : ""}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="fw-bold">Balance</span>
                    <br />
                    <span>
                      {selectedAccountDetails &&
                      selectedAccountDetails.balance != null ? (
                        <Money value={selectedAccountDetails.balance} />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="row MediaOneRow mt-2">
                  <div className="col-md-6">
                    <span className="fw-bold">Requested Date</span>
                    <br></br>

                    {PaymentRequestDate ? (
                      <span>
                        <DateComponent value={PaymentRequestDate} />
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <span className="fw-bold">Requested Amount</span>
                    <br />
                    <div className="input-group">
                      <span className=" input-group-text fs-5">
                        <DollarSign />
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        value={requestamount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row MediaOneRow mt-3">
                  <h6 className="fw-bold">Funding</h6>
                  <div className="col-md-4 col-sm-4">
                    <span>Amount</span>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <span>Interest Rate</span>
                  </div>
                  <div className="col-md-4 col-sm-4">Loan Terms</div>
                </div>
                <div className="row MediaOneRow mt-2">
                  <div className="col-md-4 col-sm-4">
                    <div className="input-group">
                      <span className=" input-group-text fs-5">
                        <DollarSign />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$ 5005 "
                        value={loanTerm}
                        onChange={(e) => handleLoanTermChange(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-group">
                      <span className=" input-group-text fs-5">%</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" % 7.25 "
                        value={interestRate}
                        onChange={(e) =>
                          handleInterestRateChange(e.target.value)
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <input
                      type="text"
                      Loan
                      Purpose
                      className="form-control"
                      placeholder="Month"
                      value={debtCancellation}
                      onChange={(e) =>
                        handleDebtCancellationChange(e.target.value)
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mt-3">
                  <label>
                    <b>Loan Purpose</b>
                  </label>
                  <textarea
                    rows={2}
                    type="text"
                    name="loanPurpose"
                    value={loanPurpose}
                    className="form-control mt-1"
                    placeholder="Debt"
                    readOnly // You might want to make this field readOnly in PaymentFund
                  />
                </div>
                <div className="row text-center mb-2">
                  <div className="col-md-12 col-sm-12 ">
                    <form onSubmit={handleSubmit}>
                      <button
                        className="btn btn-info w-50 mt-2"
                        id="paymentFundModalId"
                        style={{
                          borderRadius: "20px",
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        FUND
                      </button>
                    </form>
                  </div>
                  {confirmationSuccess && (
                    <div
                      className="alert alert-success"
                      style={{ color: "green" }}
                    >
                      {successMessage}
                    </div>
                  )}
                  {errorType && (
                    <div
                      className="alert alert-danger"
                      style={{ color: "red" }}
                    >
                      {errorType}
                    </div>
                  )}
                </div>
              </div>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PaymentFund;
