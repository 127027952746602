import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import TopnavBar2 from "../Header/CustomerNavbar";
import { CustomerAuthentication } from "../../customerAuthentication";
import { VixoAPI } from "../../settings";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import PlaidIdentityVerfication from "./PlaidIdentityVerfication";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";

const CustomerIdentityVerficationRequired = ({
  Customer,
  CustomerIdentityResponseData,
}) => {
  const [isIdentityVerified, setIsIdentityVerified] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startIdentity, setStartIdentity] = useState(false);
  const [customerIdentityResponseData, setCustomerIdentityResponseData] =
    useState(true);
  const [shareableUrl, setShareableURL] = useState(null);
  const [customerKey, setCustomerKey] = useState("");
  const [ErrorMessage, setErrorMessage] = useState(true);
  const [showRestartButton, setShowRestartButton] = useState(true);
  const navigate =useNavigate();

  function startIdentityRequired(ShareableURL) {
    if (Customer.shareableUrl != null) {
      setShareableURL(null);
      setShareableURL(Customer.shareableUrl);
      console.log("Customer", CustomerIdentityResponseData);
    } else {
      setStartIdentity(true);
      console.log("Customer", CustomerIdentityResponseData);
    }
  }
  useEffect(() => {
  
    if (shareableUrl !== null) {
      const newWindow = window.open(shareableUrl, "_blank");

      if (newWindow) {
        newWindow.focus();
      } else {
        console.error(
          "Unable to open new window for shareableUrl:",
          shareableUrl
        );
      }
    }
  }, [shareableUrl]);

  function RestartIdentityVerificationRequest() {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      setLoading(true);
      const PlaidIdentificationRequest = `${VixoAPI.FinicityAPI}resetidentityverification?CustomerKey=${customerKey}`;
      axios
        .post(PlaidIdentificationRequest,{headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            startIdentityRequired();
            setShowRestartButton(false);
          } else if (response) {
            console.log(response);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate);
  }
  }

  useEffect(() => {
    // Parse the URL and extract the 'id' parameter
    const searchParams = new URLSearchParams(window.location.search);
    const Key = searchParams.get("id");
    setCustomerKey(Key);
  }, []);

  return (
    <>
      <div
        className="col-md-12 linkstext"
        style={{
          background: "linear-gradient(0deg, #fff 10%, #EAF0F6 50%)",
          padding: "45px",
          borderRadius: "50px 50px 0px 0px",
          marginTop: "-3px",
        }}
      >
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 text-center">
            {/* show button and message after identity response  */}
            {CustomerIdentityResponseData.status == null ||
            CustomerIdentityResponseData.status === "active" ? (
              <>
                <button
                  id="myButton"
                  type="button"
                  className="btn btn-info custom-button"
                  onClick={startIdentityRequired}
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginBottom: "2rem",
                    fontSize: "20px",
                    borderRadius: "100px",
                    color: "white",
                    padding: "0.6rem",
                    background: "#00AADB",
                  }}
                >
                  Start Identity Verification
                </button>
                {startIdentity && (
                  <PlaidIdentityVerfication Customer={Customer} />
                )}
              </>
            ) : CustomerIdentityResponseData.status === "success" ? (
              <div>
                <p
                  style={{
                    color: "green",
                  }}
                >
                  Your ID was Verified.
                </p>
              </div>
            ) : CustomerIdentityResponseData.status === "failed" ? (
              <div>
                {ErrorMessage && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    Your ID Verification was not Verified. You may restart the
                    Process.
                  </p>
                )}
                {showRestartButton ?(
                  <button
                    id="myButton"
                    type="button"
                    className="btn btn-info custom-button"
                    onClick={() => {
                      RestartIdentityVerificationRequest();
                      setErrorMessage(false);
                    }}
                    style={{
                      color: "white",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: "white",
                      padding: "0.6rem",
                      background: "#00AADB",
                    }}
                  >
                    Restart Identity Verification
                  </button>
                ):(
                  <>
                  <button
                    id="myButton"
                    type="button"
                    className="btn btn-info custom-button"
                    onClick={RestartIdentityVerificationRequest}
                    style={{
                      color: "white",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: "white",
                      padding: "0.6rem",
                      background: "#00AADB",
                    }}
                  >
                    Start Identity Verification
                  </button>
                  {startIdentity && (
                    <PlaidIdentityVerfication Customer={Customer} />
                  )}
                </>
                )}
                {startIdentity && (
                  <PlaidIdentityVerfication Customer={Customer} />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerIdentityVerficationRequired;
