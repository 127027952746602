import React, { useState, useEffect } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { VixoAPI } from "../../settings";
import { config } from "@fortawesome/fontawesome-svg-core";

const DocumentOpen = () => {
  const [loading, setLoading] = useState(false);
    const location = useLocation();
    const[documentSrc, setDocumentSrc] = useState(null);
    const navigate= useNavigate();

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      var DocumentKey = params.get("I");
     if(DocumentKey!=null){
      getDocument(DocumentKey)
     }
    }, [location]);
    function getDocument(DocumentKey) {
      if(HasLoggedInUser())
        {
          const config= APIHeader();
        
      try {
        setLoading(true);
        const DocDataById = `${VixoAPI.DocumentAPI}getAnyDocumentByKey?key=${DocumentKey}`;
        setLoading(true);
        axios
          .get(DocDataById, {headers:config.headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var Docmentdata = JSON.parse(JSON.stringify(response.data));
              setDocumentSrc(Docmentdata.fileSha1Hash);
            } else {
              //setError(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        if(error.response && error.response.status && error.response.status==401)
          {
             ExpireToken(navigate);
          }
      }
    }
    else{
     ExpireToken(navigate); 
    }
    }
  return (
    <>
    <div className="containerFluid">
      <div className="row">
      <div className="col-md-12">
        <embed src={documentSrc?documentSrc:''} type="application/pdf" style={{ width: '100%', height: '70pc', overflowX: 'hidden'}} />
      </div>
      </div>
    </div>

    </>
  )
}

export default DocumentOpen