import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Money from "../../VixoFormats/Money";
import DateComponentate from "../../VixoFormats/Date";
import {
  faSync,
  faArrowRotateRight,
  faSpinner,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { VixoAPI } from "../../settings";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import Spinner from "../../Spinner";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./ProgressbarAnimation.css";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import DateComponent from "../../VixoFormats/Date";
import PhoneNumber from "../../VixoFormats/Phone";
import AccountStatus from "../../VixoFormats/AccountStatus";
import APIErrorComponent from "../../APIErrorComponent";
import { json, useLocation, useNavigate } from "react-router-dom";
import TooltipComponent from "../Tooltip/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CustomerAccountDetails = ({
  selectedCustomer,
  selectedAccount,
  AccountID,
  forDownload,
  accountUpdated,
}) => {
  const [bankAccount, setBankAccount] = useState({});
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [connectionChanging, setConnectionChanging] = useState(false);
  const [bankConnectMessage, setBankConnectMessage] = useState("");
  const [isLoadingOwner, setIsLoadingOwner] = useState(false);
  const [userId, setUserId] = useState(null);
  const [customer, setCustomer] = useState({});
  const location = useLocation();
  const [User, setUser] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [APIResponse, setAPIResponse] = useState(null);
  const [showNameTickIcon, setShowNameTickIcon] = useState(null);
  const [showEmailTickIcon, setShowEmailTickIcon] = useState(null);
  const [showPhoneTickIcon, setShowPhoneTickIcon] = useState(null);
  const [showAddressTickIcon, setShowAddressTickIcon] = useState(null);
  const [showVerificationStatus, setshowVerificationStatus] = useState(null);

  // end useState

  const [achDetailsProgressBar, setAchDetailsProgressBar] = useState(false);

  const [accountOwnerProgressBar, setAccountOwnerProgressBar] = useState(false);

  const [past90DaysProgressBar, setPast90DaysProgressBar] = useState(false);

  const [LongHistoryProgressBar, setLongHistoryProgressBar] = useState(false);
  const[Accounterror,setAccounterror] =useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [
    showTransactionLoadingPopup,
    setShowTransactionLoadingPopup,
  ] = useState(false);

  //TODO ALI call ReloadCustomerAccount if you are getting ReferenceID == AccountID and Event is 18

  const notification = useSelector(selectNotification);
  const [showData, setShowData] = useState(false);
  const [showAchDetails, setShowAchDetails] = useState(false);
  const [showPast90DaysData, setShowPast90DaysData] = useState(false);
  const [showLongHistoryData, setShowLongHistoryData] = useState(false);
  const [showInCustomer, setShowInCustomer] = useState(true);

  function GotNewAlert(notification) {
    if (
      notification != null &&
      selectedAccount != null &&
      notification.referenceID != null &&
      parseInt(notification.referenceID) == selectedAccount &&
      notification.notificationType == 18
    ) {
      // setAlertCount((prevCount) => prevCount + 1);
      ReloadCustomerAccount(selectedAccount);
    }

    if (
      notification != null &&
      notification.customerKey == customer.key &&
      notification.notificationType == 25 // Customer is Edited
    ) {
      GetCustomerByKey(notification.customerKey).then((customFromAPI) => {
        if (customFromAPI) {
          setCustomer(customFromAPI);
        }
      });
    }
  }

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  const startAnimation = () => {
    setIsAnimating(true);
    // Perform any other actions you want when starting the animation
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var CustomerToken = params.get("Id");
    var UserToken = params.get("U");
    if (UserToken) {
      setUserKey(UserToken);
    }
    if (selectedCustomer && selectedCustomer.key) {
      LoadCustomer(selectedCustomer.key);
    }
    if (selectedAccount) {
      ReloadCustomerAccount(selectedAccount);
    }
  }, [selectedAccount, selectedCustomer]);

  useEffect(() => {
    const resetProgressBars = () => {
      setAccountOwnerProgressBar(false);
      setAchDetailsProgressBar(false);
      setPast90DaysProgressBar(false);
      setLongHistoryProgressBar(false);
    };
    const animationTimeout = setTimeout(resetProgressBars, 6000);

    return () => clearTimeout(animationTimeout);
  }, [
    accountOwnerProgressBar,
    achDetailsProgressBar,
    past90DaysProgressBar,
    LongHistoryProgressBar,
    showTransactionLoadingPopup,
  ]);

  useEffect(() => {
    if (UserKey && UserKey != null) {
      LoadUser();
    }
  }, [UserKey]);

  function LoadUser() {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(UserKey);
        const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;
        // setLoading(true);
        axios
          .get(GetUserByKeyAPI, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setUserDataFromAPI(response.data);
            } else {
            }
          })
          .catch((error) => {
            // setLoading(false);
            if(error.response && error.response.status && error.response.status==401)
              {
                 ExpireToken(navigate,selectedCustomer.key);
              }
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  }

  function setUserDataFromAPI(data) {
    try {
      // setLoading(true);
      var userObject = JSON.parse(JSON.stringify(data));
      setUserId(userObject.id);
      setUser(userObject);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      throw error;
    }
  }

  //

  function LoadCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config= APIHeader();
      try {
        // setLoading(true);
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;
        // setLoading(true);
        axios
          .get(GetCustomerId, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              CusData(response.data);
              if (
                response.data &&
                response.data.identityRequired == true &&
                response.data.idVerificationStatus != 0
              ) {
                const {
                  isNameVerified,
                  isEmailVerified,
                  IsPhoneNumberVrified,
                  isAddressVerified,
                } = response.data;

                if (isNameVerified) {
                  setShowNameTickIcon(true);
                } else if (isEmailVerified) {
                  setShowEmailTickIcon(true);
                } else if (IsPhoneNumberVrified) {
                  setShowPhoneTickIcon(true);
                } else if (isAddressVerified) {
                  setShowAddressTickIcon(true);
                }
                setshowVerificationStatus(true);
              }
            } else {
              setAPIResponse(APIResponse);
            }
          })
          .catch((error) => {
            // setLoading(false);
            throw error;
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        // setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  }

  function CusData(data) {
    try {
      // setLoading(true);
      var customersdata = JSON.parse(JSON.stringify(data));
      setCustomer(customersdata);
      // setLoading(false);
      setIsLoadingOwner(false);
      setAchDetailsProgressBar(false);
      setLongHistoryProgressBar(false);
      setPast90DaysProgressBar(false);
    } catch (error) {
      // setLoading(false);
      throw error;
    }
  }
  function ReloadCustomerAccount(AccountID) {
    if (!AccountID) {
      AccountID = this.bankAccount.id;
    }
    if (HasLoggedInUser()) {
      try {
        const AccountAPI = `${VixoAPI.AccountAPI}get?ID=${AccountID}`;
        setLoading(true);
        axios
          .get(AccountAPI, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var account = JSON.parse(JSON.stringify(response.data));
              if (account.connectionStatus === "Ocr") {
                account.realAccountNumber = account.accountNumber;
              }
              setBankAccount(account);
              accountUpdated(account);
            } else if (response) {
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setLoading(false);

            throw error;
          })
          .finally(() => {
            setLoading(false);
          });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  }

  // start account disconent code
  const handleConnectOrDisconnectClick = () => {
    setBankConnectMessage("");
    if (bankAccount.connectionId == 2) {
      //Connected
      // Disconnect when already connected
      disconnectBankAccountWithOpenBanking(AccountID);
    } else {
      // Connect when not connected
      connectBankAccountWithOpenBanking(AccountID);
    }
  };
  // disconnect account api
  const disconnectBankAccountWithOpenBanking = async (AccountID) => {
    if (AccountID === null) {
      return;
    }
    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        const GetDisConnectBank = `${VixoAPI.AccountAPI}disconnectBankAccountWithOpenBanking?AccountID=${AccountID}`;
        const response = await axios.get(GetDisConnectBank, {
          headers: APIHeader().headers,
        });

        if (response.status === 200) {
          if (response.data.message) {
            setBankConnectMessage(response.data.message);
          }

          ReloadCustomerAccount(AccountID);
        } else if (response) {
          setAPIResponse(response);
        }
      } catch (error) {
        console.error("Error connecting account:", error);
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };
  // connect account api
  const connectBankAccountWithOpenBanking = async (AccountID) => {
    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        const GetConnectBank = `${VixoAPI.AccountAPI}connectBankAccountWithOpenBanking?AccountID=${AccountID}`;
        const response = await axios.get(GetConnectBank, {
          headers: APIHeader().headers,
        });

        if (response.status === 200) {
          ReloadCustomerAccount(AccountID);
          setBankConnectMessage(response.message);
        } else {
          setAPIResponse(response);
        }
      } catch (error) {
        console.error("Error connecting account:", error);
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };
  // end account disconnect and connect
  // Start APi owner Account Details
  const handleAccountowner = (accountid) => {
    setAccountOwnerProgressBar(true);
    setErrorType("");
    setAPIResponse(null);
    if (accountid != null && accountid.length >= 0) {
    }

    var data = {
      accountId: accountid,
      userID: userId,
      customerKey: customer.key,
      numberOfStatements: 0,
    };
    try {
      setIsLoadingOwner(true);
      const GetOwnerDetails = `${VixoAPI.FinicityAPI}getCustomerAccountOwnerDetail`;

      axios
        .post(GetOwnerDetails, data, { headers: APIHeader().headers })
        .then((response) => {
          var response = response.data;

          if (response.statusCode === 200) {
            ReloadCustomerAccount(accountid);
            LoadCustomer(selectedCustomer.key);
            setShowData(true);
          } else {
            setAPIResponse(response);
          }
        })
        .catch((error) => {
          setIsLoadingOwner(false);
          throw error;
        })
        .finally(() => {
          setIsLoadingOwner(false);
          setAccountOwnerProgressBar(false);
        });
    } catch (error) {
      setIsLoadingOwner(false);
      setAccountOwnerProgressBar(false);

      throw error;
    }
  };
  //  end owner Account api
  // start ACH api details
  const handleACHDetails = (accountid) => {
    setAchDetailsProgressBar(true);
    setAPIResponse(null);
    if (accountid != null && accountid.length >= 0) {
    }

    var data = {
      accountId: accountid,
      userID: userId,
      customerKey: customer.key,
      numberOfStatements: 0,
    };

    if (HasLoggedInUser()) {
      try {
        setIsLoadingOwner(true);
        const GetAchDetails = `${VixoAPI.FinicityAPI}getCustomerAccountACHDetails`;

        axios
          .post(GetAchDetails, data, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            setIsLoadingOwner(false);
            if (response.statusCode === 200) {
              ReloadCustomerAccount(accountid);
              setShowAchDetails(true);
            } else {
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setIsLoadingOwner(false);
            throw error;
          })
          .finally(() => {
            setIsLoadingOwner(false);
            setAchDetailsProgressBar(false);
          });
      } catch (error) {
        setIsLoadingOwner(false);
        setAchDetailsProgressBar(false);

        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };

  // end ACH api details
  // start trnsaction and transaction History
  const handleTransaction = (accountid) => {
    setPast90DaysProgressBar(true);
    setAPIResponse(null);
    setShowTransactionLoadingPopup(true);
    if (HasLoggedInUser()) {
      try {
        setIsLoadingOwner(true);
        var data = {
          accountId: accountid,
          userID: userId,
          customerKey: customer.key,
          numberOfStatements: 0,
        };
        const getTrnsaction = `${VixoAPI.FinicityAPI}getCustomerAccountPast90DaysTransactions`;
        axios
          .post(getTrnsaction, data, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              if (response.data != null) {
                ReloadCustomerAccount(bankAccount.id);
                setShowTransactionLoadingPopup(false);
                setShowPast90DaysData(true); // Set state to indicate progress is complete
              }
            } else if (response.statusCode === 507) {
              setShowTransactionLoadingPopup(false);
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setIsLoadingOwner(false);
            setShowTransactionLoadingPopup(false);

            throw error;
          })
          .finally(() => {
            setIsLoadingOwner(false);
            setPast90DaysProgressBar(false);
          });
      } catch (error) {
        setIsLoadingOwner(false);
        setPast90DaysProgressBar(false);

        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };

  function setError(message) {
    setErrorType(message);
  }

  const handleTransactionHistory = (accountid) => {
    setLongHistoryProgressBar(true);
    setShowTransactionLoadingPopup(true);
    setAPIResponse(null);

    if (accountid != null && accountid.length >= 0) {
    }
    var data = {
      accountId: accountid,
      userID: userId,
      customerKey: customer.key,
      numberOfStatements: 0,
    };

    if (HasLoggedInUser()) {
      try {
        setIsLoadingOwner(true);
        const getTrnHistory = `${VixoAPI.FinicityAPI}getCustomerAccountTranasctionsHistory`;

        axios
          .post(getTrnHistory, data, { headers: APIHeader().headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              if (response.data != null) {
                ReloadCustomerAccount(bankAccount.id);
                //setTransactionHistory(response.data.length);
                setShowLongHistoryData(true);
                setShowTransactionLoadingPopup(false);
                toast.error(response.message)

              }
            } else if (response.statusCode === 507) {
              setShowTransactionLoadingPopup(false);
              setAPIResponse(response);
            }
          })
          
          .catch((error) => {
            setIsLoadingOwner(false);
            setShowTransactionLoadingPopup(false);
            throw error;
          })
          .finally(() => {
            setIsLoadingOwner(false);
            setLongHistoryProgressBar(false);
          });
      } catch (error) {
        setIsLoadingOwner(false);
        setLongHistoryProgressBar(false);

        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };

  const handleButtonRefresh = () => {
    if (bankAccount.id != null) {
    }
    var data = {
      accountId: bankAccount.id,
      userID: userId,
      customerKey: customer.key,
      numberOfStatements: 0,
    };

    if (HasLoggedInUser()) {
      try {
        setLoading(true);
        setIsLoadingOwner(true);
        setAPIResponse(null);
        const getTrnHistory = `${VixoAPI.FinicityAPI}refreshBankAccount`;

        axios
          .post(
            getTrnHistory,
            data,
            { timeout: 600000 },
            { headers: APIHeader().headers }
          )
          .then((response) => {
            var response = response.data;
            setLoading(false);

            if (response.statusCode === 200) {
              if (response.data != null) {
                ReloadCustomerAccount(bankAccount.id);
                //setTransactionHistory(response.data.length);
              }
            } else {
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            setLoading(false);
            setIsLoadingOwner(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
            setIsLoadingOwner(false);
          });
      } catch (error) {
        setLoading(false);
        setIsLoadingOwner(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  };
  const navigate = useNavigate();
  var sendinvite = "/resendinvite?Id=" + selectedCustomer.key + "&U=" + UserKey;
  const handleResendInviteClick = () => {
    navigate(sendinvite);
  };

  async function GetCustomerByKey(CustomerKey) {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        setAPIResponse(null);
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;

        const response = await axios.get(GetCustomerId, {
          headers: config.headers,
        });
        var apiResponse = response.data;
        if (apiResponse.statusCode === 200) {
          const data = apiResponse.data;
          var customersdata = JSON.parse(JSON.stringify(data));
          return customersdata;
        } else {
          setAPIResponse(response);
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, selectedCustomer.key);
    }
  }
  //Calculate OWnerLast Billed Date
  const LastOwnerBilledDate =
    new Date() - new Date(bankAccount?.lastBilledOwnership);

  const LastOwnerBilledDays = Math.floor(
    LastOwnerBilledDate / (1000 * 60 * 60 * 24)
  );

  //Calculate lastBilledLongHistory Date
  const lastBilledLongHistoryDate =
    new Date() - new Date(bankAccount?.lastBilledLongHistory);

  const lastBilledLongHistoryDays = Math.floor(
    lastBilledLongHistoryDate / (1000 * 60 * 60 * 24)
  );

  //Calculate lastBilledAch Date

  const lastBilledAchDate = new Date() - new Date(bankAccount?.lastBilledAch);

  const lastBilledAchDays = Math.floor(
    lastBilledAchDate / (1000 * 60 * 60 * 24)
  );

  const [showFullAccountNumber, setShowFullAccountNumber] = useState(false);
  const [showFullRoutingNumber, setshowFullRoutingNumber] = useState(false);

  const toggleAccountNumberVisibility = () => {
    setShowFullAccountNumber(!showFullAccountNumber);
  };

  const maskAccountNumber = (accountNumber) => {
    if (accountNumber?.length <= 3) {
      return accountNumber;
    }
    accountNumber = accountNumber?.toString();
    const lastFourDigits = accountNumber?.slice(-4);
    const maskedDigits = "*".repeat(Math.max(accountNumber?.length - 4, 0));
    return maskedDigits + lastFourDigits;
  };

  const toggleRoutingNumberVisibility = () => {
    setshowFullRoutingNumber(!showFullRoutingNumber);
  };

  const maskRoutingNumber = (routingNumber) => {
    if (routingNumber?.length <= 3) {
      return routingNumber;
    }

    const lastFourDigits = routingNumber?.slice(-4);
    const maskedDigits = "*".repeat(Math.max(routingNumber?.length - 4, 0));
    return maskedDigits + lastFourDigits;
  };

  return (
    <>
      <ToastContainer />
     
      <APIErrorComponent APIResponse={APIResponse} />
      {/* <div className="alert alert-danger">
       {Accounterror&&Accounterror}
      </div> */}
      {forDownload ? <Spinner show={loading} /> : null}
      <div className="">
        <div className="row">
          {/* customer data */}
          <div
            className="col-md-4 col-sm-4 shadow AccountInfoMediaOne cardmobob"
            style={{
              // border: "1px solid #DFD1D1",
              backgroundColor: "white",
              marginLeft: "-6px",
            }}
          >
            <div
              className="row MediaOneRow"
              style={{
                borderBottom: "1px Solid #DFD1D1",
                background: "#D9E6F3",
                borderRadius: "10px 10px 0px 0px",
                border: "0.5px solid #BECDDB",
                padding: "0px",
                color: "#004265",
                height: "85px",
                fontSize: "20px",
                fontWeight: "600",
                textAlignLast: "center",
              }}
            >
              <div
                className="col-md-5 customer-text"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  fontSize: "17px",
                }}
              >
                Customer
              </div>
              <div
                className="col-md-7"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                {forDownload
                  ? showVerificationStatus && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="verification-tooltip">
                            {showNameTickIcon &&
                            showEmailTickIcon &&
                            showPhoneTickIcon &&
                            showAddressTickIcon
                              ? " Verified Profile"
                              : " Unverified Profile"}
                          </Tooltip>
                        }
                      >
                        <button
                          className={`verified-button ${
                            showNameTickIcon &&
                            showEmailTickIcon &&
                            showPhoneTickIcon &&
                            showAddressTickIcon
                              ? "verified"
                              : "unverified"
                          }`}
                        >
                          <p className="verified-text">
                            {showNameTickIcon &&
                            showEmailTickIcon &&
                            showPhoneTickIcon &&
                            showAddressTickIcon ? (
                              "Verified Profile"
                            ) : (
                              <>
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                Unverified Profile
                              </>
                            )}
                          </p>
                          {showNameTickIcon &&
                          showEmailTickIcon &&
                          showPhoneTickIcon &&
                          showAddressTickIcon ? (
                            <>
                              <img
                                className="verified-image-account"
                                src="assets/images/account-verify.gif"
                                alt="Verified Account"
                              />
                            </>
                          ) : (
                            <>
                              {/* <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="unverified-icon"
                          /> */}
                            </>
                          )}
                        </button>
                      </OverlayTrigger>
                    )
                  : null}
              </div>
            </div>

            <br />
            {/* <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Identity
                </span>
                <br />
                <span style={{ color: "#004265", fontSize: "15px" }}>
                <span style={{ color: "#004265", fontSize: "15px" }}>
                  {customer ? customer.identity : ""}
                </span>
              </div>
            </div> */}
            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Name
                </span>
                <br />
                <span style={{ color: "#004265", fontSize: "15px" }}>
                  {customer ? customer.name : ""}
                </span>
                {showNameTickIcon != null ? (
                  showNameTickIcon ? (
                    <img
                      className="verified-image"
                      src="assets/images/verified.png"
                    />
                  ) : (
                    <img src="assets/images/unverified.png" />
                  )
                ) : (
                  ""
                )}
              </div>
           
            </div>
            <br />
            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Email
                </span>
                <br />
                <span style={{ color: "#004265", fontSize: "15px" }}>
                  {customer ? customer.email : ""}
                </span>
                {showEmailTickIcon != null ? (
                  showEmailTickIcon ? (
                    <span>
                      {" "}
                      <img
                        className="verified-image"
                        src="assets/images/account-verify.gif"
                        alt="Verified Account"
                      />{" "}
                    </span>
                  ) : (
                    <span>❌ </span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />

            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Created at
                </span>
                <br />
                <DateComponent value={customer ? customer.createDate : ""} />
              </div>
            </div>
            <br />

            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Phone Number
                </span>
                <br />
                <span style={{ color: "#004265", fontSize: "15px" }}>
                  {customer ? <PhoneNumber number={customer.phone} /> : ""}
                </span>

                {showPhoneTickIcon != null ? (
                  showPhoneTickIcon ? (
                    <span>
                      {" "}
                      <img
                        className="verified-image"
                        src="assets/images/account-verify.gif"
                        alt="Verified Account"
                      />{" "}
                    </span>
                  ) : (
                    <span>❌ </span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <div className="row MediaOneRow">
              <div className="col-md-12">
                <span
                  className="fw-bold"
                  style={{ color: "#004265", fontSize: "15px" }}
                >
                  Address
                </span>
                <br />
                <span style={{ color: "#004265", fontSize: "15px" }}>
                  {customer ? customer.address : ""}
                </span>
                {showAddressTickIcon != null ? (
                  showAddressTickIcon ? (
                    <span>
                      {" "}
                      <img
                        className="verified-image"
                        src="assets/images/account-verify.gif"
                        alt="Verified Account"
                      />{" "}
                    </span>
                  ) : (
                    <span>❌ </span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
          </div>
          {/* End customer data */}

          {/* Bank Data */}
          <div
            className="col-md-8 col-sm-6 shadow AccountInfoMediaTwo  cardmobob carddetails"
            style={{
              backgroundColor: "white",
              height: "32pc",
              marginLeft: "6px",
            }}
          >
            <div className="bank-section">
              <div className="account-info">
                <div
                  className="row buttonsrefresh "
                  style={{
                    borderRadius: "10px 10px 0px 0px",
                    border: "0.5px solid  #BECDDB",
                    background: "#D9E6F4",
                    height: "-webkit-fill-available",
                  }}
                >
                  <div className="col-md-6 ">
                    {forDownload ? (
                      <img
                        src={bankAccount ? bankAccount.instituteLogoUrl : ""}
                        className="bank-image"
                        style={{ marginTop: "4%", width: "100%" }}
                      />
                    ) : null}
                  </div>

                  <div className="respbutton">
                    {/* Show Resend Invite button if bankAccount.connectionId is 6 */}
                    {bankAccount.connectionId === 6 ? (
                      <div className="col-md-3 mt-4 p-1">
                        {forDownload ? (
                          <>
                            <TooltipComponent
                              Status={bankAccount.connectionId}
                              Constant="ResendInvite"
                            />

                            <Button
                              variant="primary"
                              onClick={handleResendInviteClick}
                              style={{
                                fontSize: "14px",
                                backgroundColor: "#00AADB",
                                fontWeight: "500",
                                borderRadius: "20px",
                                width: "-webkit-fill-available",
                              }}
                            >
                              Resend Invite
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="col-md-3 mt-4 p-1 ">
                        {forDownload ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="refresh-tooltip">
                                Refresh Account
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="primary"
                              className="button-refresh"
                              disabled={
                                bankAccount.connectionId !== 2 || isLoadingOwner
                              }
                              onClick={handleButtonRefresh}
                              style={{
                                fontSize: "14px",
                                backgroundColor: "#00AADB",
                                fontWeight: "500",
                                borderRadius: "20px",
                                width: "-webkit-fill-available",
                                marginLeft: "5px !important",
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowRotateRight} />{" "}
                              Refresh
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="col-md-3  mt-4 p-1">
                      {forDownload ? (
                        <Button
                          variant={
                            bankAccount.connectionId == 2 ? "danger" : "primary"
                          }
                          onClick={handleConnectOrDisconnectClick}
                          hidden={
                            bankAccount.connectionId != 2 &&
                            bankAccount.connectionId != 3
                          }
                          className="ms-sm-2 ms-md-0 mb-3 btn connect-btn"
                          style={{
                            borderRadius: "18px",
                            width: "-webkit-fill-available",
                            marginLeft: "-5px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlugCircleCheck}
                            style={{
                              marginRight: "0px",

                              color:
                                bankAccount.connectionId == 2 ? "white" : "",
                            }}
                          />
                          <span
                            style={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {" "}
                            {bankAccount.connectionId == 2
                              ? "Disconnect"
                              : "Connect"}
                            {connectionChanging && (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                className="ms-2"
                              ></Spinner>
                            )}{" "}
                          </span>
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="mt-3"></div>
                  <div>
                    <>
                      {bankConnectMessage && (
                        <span style={{ position: "relative", top: "-8px" }}>
                          {bankConnectMessage}
                        </span>
                      )}
                    </>
                  </div>
                </div>
                <br></br>

                <div className="row MediaTwoRow">
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Institution
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.instituteName : ""}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      Status:
                    </span>{" "}
                    <span style={{ fontSize: "15px", color: "#004265" }}>
                      <br />
                      <AccountStatus value={bankAccount.connectionId} />
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Last Sync
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      <DateComponent
                        value={bankAccount ? bankAccount.lastUpdateDate : ""}
                      />
                    </span>
                  </div>
                </div>
                <div className="row MediaTwoRow mt-3">
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      {" "}
                      Account Label
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.accountType : ""}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Balance
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      <Money
                        value={bankAccount ? bankAccount.balance : "0.0"}
                      />
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Balance Date
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {" "}
                      <DateComponent
                        value={customer ? customer.createDate : ""}
                      />
                    </span>
                  </div>
                </div>
                <div className="row MediaTwoRow mt-3">
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      {" "}
                      Owner Name
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.ownerName : ""}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Routing Number
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.routingNumber : "0"}

                      {/* <div>
                        {showFullRoutingNumber ? (
                          <span style={{ color: "#004265", fontSize: "15px" }}>
                            {bankAccount ? bankAccount?.routingNumber : ""}
                          </span>
                        ) : (
                          <span style={{ color: "#004265", fontSize: "15px" }}>
                            {bankAccount ? maskRoutingNumber(bankAccount?.routingNumber) : ""}
                          </span>
                        )}
                        <FontAwesomeIcon
                          icon={showFullRoutingNumber ? faEyeSlash : faEye}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          onClick={toggleRoutingNumberVisibility}
                        />
                      </div> */}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Transactions
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.transactionsCount : "0"}
                    </span>
                  </div>
                </div>
                <div className="row MediaTwoRow mt-3">
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      {" "}
                      Address
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      {bankAccount ? bankAccount.ownerAddress : ""}
                    </span>
                  </div>
                  {/* <div className="col-md-4">
                    <span className="fw-bold">Account Number</span>
                    <br />
                    <span>
                      {selectedAccount ? selectedAccount.accountNumber : ""}
                      {achDetails ? achDetails.accountNumber : ""}
                    </span>
                  </div> */}
                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Account Number
                    </span>
                    <br />
                    {forDownload ? (
                      <div>
                        {showFullAccountNumber ? (
                          <span style={{ color: "#004265", fontSize: "15px" }}>
                            {bankAccount ? bankAccount?.realAccountNumber : ""}
                          </span>
                        ) : (
                          <span style={{ color: "#004265", fontSize: "15px" }}>
                            {bankAccount
                              ? maskAccountNumber(
                                  bankAccount?.realAccountNumber
                                )
                              : ""}
                          </span>
                        )}
                        <FontAwesomeIcon
                          icon={showFullAccountNumber ? faEyeSlash : faEye}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          onClick={toggleAccountNumberVisibility}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-4">
                    <span
                      className="fw-bold"
                      style={{ color: "#004265", fontSize: "15px" }}
                    >
                      Latest Transaction
                    </span>
                    <br />
                    <span style={{ color: "#004265", fontSize: "15px" }}>
                      <DateComponent
                        value={
                          bankAccount ? bankAccount.lastTransactionDate : ""
                        }
                      />
                    </span>
                  </div>
                </div>
                {forDownload ? (
                  <div className="row MediaTwoRow mt-4">
                    <div className="col-md-4 mb-1">
                      <TooltipComponent
                        Status={bankAccount.connectionId}
                        Constant="AccountOwner"
                      />
                      <button
                        className="btn accountBtn  position-relative w-100"
                        style={{
                          color: "white",
                          overflow: "hidden",
                          position: "relative",
                          borderRadius: "20px",
                        }}
                        disabled={
                          bankAccount.connectionId !== 2 ||
                          isLoadingOwner ||
                          LastOwnerBilledDays <= 30
                        }
                        onClick={() =>
                          handleAccountowner(bankAccount ? bankAccount.id : "")
                        }
                      >
                        ACCOUNT OWNER
                        {accountOwnerProgressBar && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <ProgressBar
                              variant="white"
                              now={100}
                              className="progress-with-animation"
                              style={{
                                height: "100%",
                                backgroundColor: "#00AADB",
                              }}
                            >
                              <div
                                className=" text-black "
                                style={{ marginLeft: "1pc", marginTop: "5px" }}
                              >
                                Retrieving...
                              </div>
                            </ProgressBar>
                          </div>
                        )}
                      </button>
                    </div>

                    <div className="col-md-4 mb-1">
                      <TooltipComponent
                        Status={bankAccount.connectionId}
                        Constant="ACHDetail"
                      />
                      <button
                        className="btn accountBtn position-relative  w-100"
                        style={{
                          color: "white",
                          overflow: "hidden",
                          position: "relative",
                          borderRadius: "20px",
                        }}
                        disabled={
                          bankAccount.connectionId !== 2 ||
                          isLoadingOwner ||
                          lastBilledAchDays <= 30
                        }
                        onClick={() =>
                          handleACHDetails(bankAccount ? bankAccount.id : "")
                        }
                      >
                        ACH DETAILS
                        {achDetailsProgressBar && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <ProgressBar
                              variant="white"
                              now={100}
                              className="progress-with-animation"
                              style={{
                                height: "100%",
                                backgroundColor: "#00AADB",
                              }}
                            >
                              <div
                                className=" text-black "
                                style={{ marginLeft: "1pc", marginTop: "5px" }}
                              >
                                Retrieving...
                              </div>
                            </ProgressBar>
                          </div>
                        )}
                      </button>
                    </div>

                    <div className="col-md-4 mb-1">
                      <TooltipComponent
                        Status={bankAccount.connectionId}
                        Constant="Days90"
                      />
                      <button
                        disabled={
                          bankAccount.connectionId !== 2 || isLoadingOwner
                        }
                        className="btn accountBtn position-relative  w-100"
                        style={{
                          color: "white",
                          overflow: "hidden",
                          position: "relative",
                          borderRadius: "20px",
                        }}
                        onClick={() =>
                          handleTransaction(bankAccount ? bankAccount.id : "")
                        }
                      >
                        PAST 90 DAYS
                        {past90DaysProgressBar && showTransactionLoadingPopup && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <ProgressBar
                              variant="white"
                              now={100}
                              className="progress-with-animation"
                              style={{
                                height: "100%",
                                backgroundColor: "#00AADB",
                              }}
                            >
                              <div
                                className=" text-black "
                                style={{
                                  marginLeft: "1pc",
                                  marginTop: "5px",
                                }}
                              >
                                Retrieving...
                              </div>
                            </ProgressBar>
                          </div>
                        )}
                      </button>
                    </div>

                    <br />
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <TooltipComponent
                        Status={bankAccount.connectionId}
                        Constant="LongHistory"
                      />
                      <button
                        disabled={
                          bankAccount.connectionId !== 2 ||
                          isLoadingOwner ||
                          lastBilledLongHistoryDays <= 30
                        }
                        className="btn accountBtn position-relative  w-100"
                        style={{
                          color: "white",
                          overflow: "hidden",
                          position: "relative",
                          borderRadius: "20px",
                        }}
                        onClick={() =>
                          handleTransactionHistory(
                            bankAccount ? bankAccount.id : ""
                          )
                        }
                      >
                        LONG HISTORY
                        {LongHistoryProgressBar && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <ProgressBar
                              variant="white"
                              now={100}
                              className="progress-with-animation"
                              style={{
                                height: "100%",
                                backgroundColor: "#00AADB",
                              }}
                            >
                              <div
                                className=" text-black "
                                style={{ marginLeft: "1pc", marginTop: "5px" }}
                              >
                                Retrieving...
                              </div>
                            </ProgressBar>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <br />
                <div className="row MediaTwoRow"></div>
              </div>
              {/* {error ? error : ""} */}
            </div>
          </div>

          {/* end Bank data */}
        </div>
      </div>
      {showTransactionLoadingPopup && (
        <Modal
          className=""
          show={showTransactionLoadingPopup}
          centered
          size="sm"
        >
          <Modal.Body className="text-center" style={{ margin: "30px" }}>
            <Spinner animation="border" variant="success" />
            <p>Transactions are being Fetched...</p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CustomerAccountDetails;
