import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import {
  usePlaidLink,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
} from "react-plaid-link";
import axios from "axios";

import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import { CustomerAuthentication } from "../../customerAuthentication";
const PlaidComponent = ({
  linkToken,
  CustomerKey,
  ReloadBanks,
  SelectedCustomer,
  ResetCustomerOpenBankingEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const [customerToken, setCustomerToken] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorType, setErrorType] = useState(null);
  const [publicToken, setPublicToken] = useState(null);
  const [isPublicTokenSaved, setPublicTokenSave] = useState(null);
  const [openBankingType, setOpenBankingType] = useState(null);
  const [openBankingCustomerID, setOpenBankingCustomerID] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const location = useLocation();
  const [OpenBankingData, setOpenBankingData] = useState(null);

  const navigate = useNavigate();
  const onSuccess = React.useCallback((public_token, metadata) => {
    try {
      setPublicToken(public_token);
      setMetaData(metadata);

      // Handle response ...
    } catch (error) {
      setErrorType(error);
    }
  }, []);
  // const onExit = React.useCallback( (error, metadata) => {
    
      
  //     if (metadata.accounts.length == 0) {
  //       if (
  //         SelectedCustomer != null &&
  //         (SelectedCustomer.customerOpenBankingTypeChoice == 0 ||
  //           SelectedCustomer.customerOpenBankingTypeChoice == null)
  //       ) {
  //         ResetCustomerOpenBanking();
  //       }
  //     }
  //   },
  //   [SelectedCustomer]
  // );

  const onExit = React.useCallback((error, metadata) => {
    ReturnCustomerBankingData().then((result)=>
    {

      var APIResponse = result.data;
      if (APIResponse.statusCode === 200) {
        var responseData = APIResponse.data;
        if (
          responseData != null) {
            var CustomerAccountsLength=0;
            if(responseData.accounts)
            {

              CustomerAccountsLength=responseData.accounts.length;
            }
            var CustomerOpenBankingTypeChoice=responseData.customer.customerOpenBankingTypeChoice;
            if(CustomerAccountsLength< 1 && (CustomerOpenBankingTypeChoice==null || CustomerOpenBankingTypeChoice==0))
            {
              // var localPLaidOpen = plaidOpen + 1;
              // setPlaidOpen(localPLaidOpen);
             ResetCustomerOpenBankingEvent();
              
              
            } 
            
        }
   
    }
    }).catch((error) => {


      alert(error);
      console.error("Error fetching data:", error);
    })  .finally(() => {
      setLoading(false);
    });
    
  }, [SelectedCustomer]);
  const onEvent = React.useCallback((eventName, metadata) => {}, []);

  const config = {
    onSuccess,
    onExit,
    onEvent,
    token: linkToken,
  };

  const { open, exit, ready } = usePlaidLink(config);
  function setError(message) {
    setErrorType(message);
  }


  async function ReturnCustomerBankingData(){
    try {
      setLoading(true);
      const data = {
        BankAccounts: null,
        Insititute: null,
        CustomerKey: SelectedCustomer.key,
      };
      const LoadFinicityAuthAPI = `${VixoAPI.FinicityAPI}syncCustomerOpenBankingData`;
      setLoading(true);
      const response = axios.post(LoadFinicityAuthAPI, data);
      
        return response;
       
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };


  useEffect(() => {
    var authData = CustomerAuthentication(location);
    if (authData.CustomerKey === "") {
      navigate("/");
    } else {
      var encodedCustomer = encodeURIComponent(authData.CustomerKey);
      setCustomerToken(encodedCustomer);
    }
  }, [CustomerKey]);

  useEffect(() => {
    if (linkToken != null && customerToken != null) {
      // localStorage.setItem(linkToken, "publicLinkToken");
      open();
    }
  }, [linkToken, CustomerKey, open, onSuccess]);

  useEffect(() => {
    if (publicToken != null && customerToken != null) {
      SavePublicToken();
    }
  }, [publicToken, CustomerKey]);

  useEffect(() => {
    if (
      metaData != null &&
      customerToken != null &&
      isPublicTokenSaved != null &&
      isPublicTokenSaved === true
    ) {
      SavePlaidBankAccounts();
    }
  }, [CustomerKey, metaData, isPublicTokenSaved]);


  function SavePublicToken() {
    setLoading(true);
    if (customerToken != null && publicToken != null) {
      const APIPath = `${VixoAPI.FinicityAPI}updatePublicToken?CustomerKey=${customerToken}&PublicToken=${publicToken}`;
      axios
        .get(APIPath)
        .then((response) => {
          var apiResponse = response.data;

          if (apiResponse.statusCode == 200) {
            setPublicTokenSave(true);
          } else {
            setError(apiResponse.message);
          }
        })
        .catch((error) => {
          
          setLoading(false);
        });
    }
    setLoading(false);
  }
  function SavePlaidBankAccounts() {
    setLoading(true);
    if (metaData && metaData != null) {
      var insitute = JSON.parse(JSON.stringify(metaData.institution));
      var bankAccounts = metaData.accounts;
      var accountsArrayJson = JSON.parse(JSON.stringify(bankAccounts));
      const accountsArray = Array.isArray(accountsArrayJson)
        ? accountsArrayJson
        : [accountsArray];

      if (CustomerKey != null && accountsArray) {
        const data = {
          BankAccounts: accountsArray,
          Insititute: insitute,
          CustomerKey: customerToken,
        };

        const syncCustomerBanksAPI = `${VixoAPI.FinicityAPI}syncCustomerOpenBankingData`;
        axios
          .post(syncCustomerBanksAPI, data)
          .then((response) => {
            var apiResponse = response.data;
            if (apiResponse.statusCode == 200) {
              setOpenBankingData(response.data);
              setSuccessMessage(apiResponse.message);
              ReloadBanks("Reload Banks");
            } else {
              setError(apiResponse.message);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }

    setLoading(false);
  }

  async function ResetCustomerOpenBanking() {
    ResetCustomerOpenBankingEvent();
  }

  useEffect(() => {
    if (SelectedCustomer && SelectedCustomer !== null) {
      setCustomerToken(SelectedCustomer.key);
      setOpenBankingType(SelectedCustomer.openBankingType);
      setOpenBankingCustomerID(SelectedCustomer.openBankingCustomerId);
    }
  }, [SelectedCustomer]);


  return (
    <>
      <Spinner show={loading} Title="Plaid Component"/>

      <br />
      <span>{errorType ? errorType : ""}</span>
      {successMessage ? (
        <span style={{ color: "green" }}>{successMessage}</span>
      ) : (
        ""
      )}
    </>
  );
};

export default PlaidComponent;
