// Breadcrumbs.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');

  return (
    <Breadcrumb>
      <Breadcrumb.Item
        className={paths.length === 0 ? 'active-component' : 'second-component'}
        linkAs={Link}
        linkProps={{ to: '/Home' }}
      >
        Home
      </Breadcrumb.Item>
      {paths.map((path, index) => (
        <Breadcrumb.Item
          key={index}
          className={index === paths.length - 1 ? 'active-component' : 'second-component'}
          linkAs={Link}
          linkProps={{ to: `/${paths.slice(0, index + 1).join('/')}` }}
          style={{TextDecoration:'none', color:'gray'}}
        >
          {path}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
