import React from "react";
import { Link } from "react-router-dom";
import TopnavBar2 from "../Header/CustomerNavbar";
import File from "./File";
import { json, useLocation } from "react-router-dom";

const Fileindex = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Customerkey = params.get("Id")?.replace(/^"|"$/g, "");;
  const Userkey = params.get("U")?.replace(/^"|"$/g, "");

  return (
    <>
      <main className="page-content">
        <div className="containerFluid">
          <div className=" fileindex1 row  shadow">
          <div className="col-12  fileindex2">
          <TopnavBar2 />
          </div>
           <File customerkey={Customerkey} userkey={Userkey} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Fileindex;
