import React, { useState, useEffect } from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUpload } from "@fortawesome/free-solid-svg-icons";
import "./CustomerNavbar.css";
import { Link } from "react-router-dom";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import { Overlay, Tooltip } from "react-bootstrap";

import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import Fileopen from "../Files/Fileopen";
import { useSelector } from "react-redux";
import CreateAlert from "../../alertFactory";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import DropdownButton from "react-bootstrap/DropdownButton";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCustomer from "./DropDownComponents/EditCustomer";
import DeleteCustomer from "./DropDownComponents/DeleteCustomer";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { CustomerAuthentication } from "../../customerAuthentication";
import CustomerIdentityVerfication from "../IdentityVerification/CustomerIdentityVerfication";
import CustomerTransferHome from "./CustomerTransferHome";
function CustomerNavbar(
  idVerificationProcessStatus,
  idVerificationStatus,
  onIconClick
) {
  const redDivStyle = {
    backgroundColor: "#FFDDDD",
    width: "100%",
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "#FF0000",
    fontWeight: "bold",
    zIndex: 9999,
    fontSize: "18px",
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleIconClick = () => {
    setShowModal(true);
  };

  const notification = useSelector(selectNotification);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [selectedItem, setSelectedItem] = useState(
    location.pathname === "/vixohome" ? "banking" : "files"
  );
  const [activeCustomerIndex, setActiveCustomerIndex] = useState(-1);
  const [SelectedCustomer, setSelectedCustomer] = useState({});
  const [customerKey, setCustomerKey] = useState();
  const [IsCustomerFound, setIsCustomerFound] = useState(true);
  const [IsCustomerModified, setIsCustomerModified] = useState(0);
  const [DownloadHome, setDownloadHome] = useState(null);
  const [userKey, setUserKey] = useState();
  const [customerCount, setCustomerCount] = useState(0);
  const [customerNotificaton, setcustomerNotificaton] = useState([]);
  const URlToAppened = "?Id=" + customerKey + "&U=" + userKey;
  const [User, setUser] = useState({});
  const [org, setOrg] = useState({});
  // const [customerArray, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ShowDeleteCustomer, setShowDeleteCustomer] = useState(false);
  const [ShowEditCustomer, setShowEditModal] = useState(false);
  const [CustomerToken, setCustomerToken] = useState(null);
  const [customerEdited, setCustomerEdited] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");

  const [showTickIcon, setShowTickIcon] = useState(false);
  const [showDoubleTickIcon, setShowDoubleTickIcon] = useState(false);
  const [showTripleTickIcon, setShowTripleTickIcon] = useState(false);
  const [showFourTickIcon, setShowFourTickIcon] = useState(false);
  const [showCrossIcon, setShowCrossIcon] = useState(false);
  const [ReloadCustomer, setReloadCustomer] = useState(0);
  const [showButton, setShowButton] = useState(false);

  function Initialize() {
    const params = new URLSearchParams(location.search);
    var Customerkey1 = params.get("Id");
    var UserID = params.get("U");
    setCustomerKey(Customerkey1);
    setUserKey(UserID);

    if (location.pathname === "/vixohome") {
      setSelectedItem("banking" + URlToAppened);
    } else if (location.pathname === "/filesindex") {
      setSelectedItem("files" + URlToAppened);
    }
    LoadCustomer(Customerkey1);
    LoadCustomerAlerts(Customerkey1);
    // DownloadURL();
  }

  useEffect(() => {
    Initialize();
  }, [location.pathname]);

  useEffect(() => {
    if (IsCustomerModified > 0) {
      Initialize();
    }
  }, [IsCustomerModified]);

  useEffect(() => {
    if (notification != null) {
      GotNewAlert(notification);
    }
  }, [notification]);

  useEffect(() => {
    LoadData();
  }, []);

  async function GetCustomerByKey(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getAnyCustomerBykey?key=${Key}`;
        var Headers = APIHeader().headers;

        const response = await axios.get(GetCustomerId, {
          headers: config.headers,
        });
        var apiResponse = response.data;
        if (apiResponse.statusCode === 200) {
          const data = apiResponse.data;
          var customersdata = JSON.parse(JSON.stringify(data));
          return customersdata;
        }
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function GotNewAlert(NotificationData) {
    if (
      NotificationData != null &&
      NotificationData.customerKey == customerKey &&
      NotificationData.notificationType < 8
    ) {
      var OldAlertCountsForCustomer = customerCount;
      OldAlertCountsForCustomer = OldAlertCountsForCustomer + 1;
      setCustomerCount(OldAlertCountsForCustomer);
      const newAlert = CreateAlert(NotificationData);
      const updatedListOfAlets = [...customerNotificaton, newAlert];
      setcustomerNotificaton(updatedListOfAlets);
      LoadCustomerAlerts(customerKey);
    } else if (
      notification != null &&
      notification.customerKey == customerKey &&
      notification.notificationType == 25
    ) {
      LoadCustomer(notification.customerKey);
    } else if (
      notification != null &&
      parseInt(notification.organiationID) == SelectedCustomer.organizationId &&
      notification.customerKey == SelectedCustomer.key &&
      notification.notificationType == 26 // Delete Customer
    ) {
      LoadCustomer(SelectedCustomer.key);
    }
  }

  function LoadCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();

      try {
        //setLoading(true);
        var Key = CustomerKey;
        const GetCustomerId = `${VixoAPI.CustomerAPI}getAnyCustomerBykey?key=${Key}`;

        //setLoading(true);
        axios
          .get(GetCustomerId, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var customerData = JSON.parse(JSON.stringify(response.data));

              if (customerData.identityRequired) {
                setShowTickIcon(false);
                setShowDoubleTickIcon(false);
                setShowTripleTickIcon(false);
                setShowFourTickIcon(false);
                setShowCrossIcon(false);

                if (customerData.idVerificationProcessStatus === 1) {
                  setShowTickIcon(true);
                } else if (customerData.idVerificationProcessStatus === 2) {
                  setShowDoubleTickIcon(true);
                } else if (customerData.idVerificationProcessStatus === 4) {
                  //setShowTripleTickIcon(true);

                  if (customerData.idVerificationStatus === 2) {
                    setShowFourTickIcon(true);
                  } else if (customerData.idVerificationStatus === 3) {
                    setShowCrossIcon(true);
                  }
                }
              }
              setSelectedCustomer(customerData);
            } else {
              setIsCustomerFound(false);
            }
          })
          .catch((error) => {
            //setLoading(false);
            throw error;
          })
          .finally(() => {
            //setLoading(false);
            DownloadURL();
          });
      } catch (error) {
        //setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function LoadCustomerAlerts(Customerkey) {
    if (HasLoggedInUser()) {
      var config = APIHeader();
      try {
        const GetCustomerId =
          VixoAPI.AlertsAPI + "getCustomerAlertsUsingSP?Key=" + Customerkey;

        axios
          .get(GetCustomerId, { headers: config.headers })
          .then((response) => {
            var responseData = response.data; // Renaming to avoid conflict with response variable
            if (responseData.statusCode === 200) {
              setCustomersData(responseData.data);

              console.log("responseData.data", responseData.data);
            } else {
              // Handle other status codes or responses if needed
            }
          })
          .catch((error) => {
            // Handle errors
            console.error("Error while fetching customer alerts:", error);
          })
          .finally(() => {
            //setLoading(false);
          });
      } catch (error) {
        //setLoading(false);
        console.error("Error in LoadCustomerAlerts:", error);
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function setCustomersData(data) {
    try {
      //setLoading(true);
      var customers = JSON.parse(JSON.stringify(data));
      console.log("customers", customers);
      setCustomerCount(customers.alertCount);
      setcustomerNotificaton(customers.cusAlerts);

      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      throw error;
    }
  }

  const handleAlertClick = (id) => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      const GetAlertAPIPath = VixoAPI.AlertsAPI + "viewAlerts?AlertID=" + id;
      //setLoading(true);
      axios
        .get(GetAlertAPIPath, { headers: config.headers })
        .then((response) => {
          var responseData = response.data;
          //setLoading(false);
          if (responseData.statusCode === 200) {
            if (responseData.data.eventTypeId === 3) {
              getAlertDocData(responseData.data.referenceId);
            } else if (responseData.data.eventTypeId === 2) {
              getAlertStatementData(responseData.data.referenceId);
            }
            LoadCustomerAlerts(customerKey);
            setShowViewPopup(true);
          }
        })
        .catch((error) => {
          //setLoading(false);
          if (
            error.response &&
            error.response.status &&
            error.response.status == 401
          ) {
            ExpireToken(navigate, SelectedCustomer);
          }
        });
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  };

  function getAlertDocData(Id) {
    if (HasLoggedInUser()) {
      var config = APIHeader();

      const GetAlertAPIPath =
        VixoAPI.DocumentAPI + "getDocumentById?DocumentId=" + Id;
      axios
        .get(GetAlertAPIPath, { headers: config.headers })
        .then((response) => {
          var response = response.data;
          //setLoading(false);
          if (response.statusCode === 200) {
            setFileView(response.data.fileSha1Hash);
            setShowViewPopup(true);
            //setLoading(false);
          }
        })
        .catch((error) => {
          //setLoading(false);
          throw error;
        })
        .finally(() => {
          //setLoading(false);
        });
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function getAlertStatementData(Id) {
    if (HasLoggedInUser()) {
      var config = APIHeader();

      const GetAlertAPIPath =
        VixoAPI.StatmentAPI + "getStatementById?StatementId=" + Id;
      //setLoading(true);
      axios
        .get(GetAlertAPIPath, { headers: config.headers })
        .then((response) => {
          var responseData = response.data;
          //setLoading(false);
          if (responseData.statusCode === 200) {
            setFileView(responseData.data.fileSha1Hash);
            setShowViewPopup(true);
          }
        })
        .catch((error) => {
          //setLoading(false);
          throw error;
        });
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }
  function formatTimeFromDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }
  const handleBankingClick = () => {
    const pathToNavigate = `/vixohome${URlToAppened}`;
    navigate(pathToNavigate);
  };
  const handleFileClick = () => {
    const pathToNavigate = `/filesindex${URlToAppened}`;
    navigate(pathToNavigate);
  };
  const handleDocusignClick = () => {
    const pathToNavigate = `/Contractindex${URlToAppened}`;
    navigate(pathToNavigate);
  };
  const handleIdentityVerification = () => {
    const pathToNavigate = `/customeridentityverification${URlToAppened}`;
    navigate(pathToNavigate);
  };
  const handleCommuncationClick = () => {
    const pathToNavigate = `/CommunicationTimeline${URlToAppened}`;
    navigate(pathToNavigate);
  };

  const handleCustomerTransfer = () => {
    const pathToNavigate = `/CustomerTransfer${URlToAppened}`;
    navigate(pathToNavigate);
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };
  const LoadData = () => {
    var authData = CustomerAuthentication(location);
    if (authData.CustomerKey === "" || authData.UserKey === "") {
      navigate("/");
    } else {
      var encodedCustomer = encodeURIComponent(authData.CustomerKey);
      setCustomerToken(encodedCustomer);
      LoadCustomer(encodedCustomer);
    }

    const userKey = authData.UserKey;
    setUserKey(userKey);
    const userObject = getUserByKey(userKey);
    setUser(userObject);
  };

  function getUserByKey(UserKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var encodedCustomer = encodeURIComponent(UserKey);
        const OrgAPIPath = `${VixoAPI.UserAPI}getAnyUserByKey?Key=${encodedCustomer}`;
        axios
          .get(OrgAPIPath, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              localStorage.setItem("User", JSON.stringify(response.data));
              getOrganizationById(response.data.organizationId);
              return response.data;
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, SelectedCustomer.key);
            }
          })
          .finally(() => {});
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate, SelectedCustomer.key);
        }
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function getOrganizationById(OrganizationID) {
    if (HasLoggedInUser()) {
      var config = APIHeader();
      try {
        const OrgAPIPath = `${VixoAPI.OrganizationAPI}get?ID=${OrganizationID}`;
        axios
          .get(OrgAPIPath, { headers: config.headers })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              localStorage.setItem("Org", JSON.stringify(response.data));
            }
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  function LoadOrganizationCustomers(OrganizationID) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        //setLoading(true);
        const GetCustomersAPIPath =
          VixoAPI.CustomerAPI + "GetCustomersByOrganization";
        axios
          .get(GetCustomersAPIPath, {
            headers: config.headers,
            params: {
              Id: OrganizationID,
            },
          })
          .then((response) => {
            var response = response.data;
            //setLoading(false);
            if (response.statusCode === 200) {
              console.log("response.data", response.data);
              setCustomersData(response.data);
            } else {
            }
            if (response.data == null) {
              navigate("/Welcome");
            }
          })
          .catch((error) => {
            //setLoading(false);
            throw error;
          })
          .finally(() => {
            //setLoading(false);
          });
      } catch (error) {
        //setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }
  const clearCustomerNotifications = async () => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      //setLoading(true);
      const ClearAPIPath =
        VixoAPI.AlertsAPI + "clearAlerts?CustomerID=" + SelectedCustomer.id;
      // var Headers=APIHeader().headers;

      axios
        .get(ClearAPIPath, { headers: config.headers })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            LoadCustomerAlerts(SelectedCustomer.key);
          } else {
          }
        })
        .catch((error) => {
          //setLoading(false);
          if (
            error.response &&
            error.response.status &&
            error.response.status == 401
          ) {
            ExpireToken(navigate, SelectedCustomer.key);
          }
        })
        .finally(() => {
          //setLoading(false);
        });
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  };
  const handleDeleteClick = () => {
    setShowDeleteCustomer(true);
  };
  const handleEditClick = () => {
    setShowEditModal(true);
    setCustomerEdited(true);
  };

  const ActionCompleted = (Customer, ActionID) => {
    if (ActionID == 1) {
      // Customer Deleted
      setShowDeleteCustomer(false);
    } else if (ActionID == 2) {
      // Customer Edited
      setShowEditModal(false);
    } else if (ActionID == 3) {
      //resend invite
    }
    var lIsCustomerModified = IsCustomerModified + 1;
    setIsCustomerModified(lIsCustomerModified);
  };

  const ActionCancelled = (Customer, ActionID) => {
    if (ActionID == 1) {
      // Customer Deleted
      setShowDeleteCustomer(false);
    } else if (ActionID == 2) {
      // Customer Edited
      setShowEditModal(false);
    } else if (ActionID == 3) {
      //resend invite page
    }
  };
  function DownloadURL() {
    if (HasLoggedInUser()) {
      try {
        var customerHomeLink =
          VixoAPI.CustomeDashboard +
          "downloadindex?Id=" +
          encodeURIComponent(SelectedCustomer.key) +
          "&U=" +
          encodeURIComponent(userKey);
        setDownloadHome(customerHomeLink);
      } catch (Exception) {}
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  var sendinvite = "/resendinvite?Id=" + SelectedCustomer.key + "&U=" + userKey;
  var downloadindex =
    "/downloadindex?Id=" + SelectedCustomer.key + "&U=" + userKey;

  var bankaccountfile = "/AddBankAccountFileCustomer";
  useEffect(() => {
    if (Object.keys(SelectedCustomer).length !== 0) {
      DownloadURL();
    }
  }, [SelectedCustomer]);
  // ClearAllNotificationAll
  function ClearAllNotificationAll(customerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      const clearAllAlerts =
        VixoAPI.AlertsAPI + "ClearAlertsByCustomerId?Key=" + customerKey;
      axios
        .get(clearAllAlerts, { headers: config.headers })
        .then((response) => {
          var responseData = response.data; // Renaming to avoid conflict with response variable
          if (responseData.status === 200) {
            setcustomerNotificaton([]);
            setCustomerCount(0);
            toast.success(responseData.message);
          } else {
            toast.error(responseData.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          //setLoading(false); // Set loading to false regardless of success or failure
        });
    } else {
      ExpireToken(navigate, SelectedCustomer.key);
    }
  }

  return (
    <>
      <Fileopen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        pdfSrc={fileView}
      />

      <Spinner show={loading} Title="Customer Upload customer trasfer"/>
      <h1 style={{ textAlign: "center" }}>
        <span className="iconSideBar p-1">
          <div className="container">
            <div className="row identifyMediaQury">
              <div className="col-md-3"></div>
              <div className="mt-5">
                <span
                  className="text-center"
                  style={{
                    color: "#004265",
                    fontSize: "16px",

                    fontWeight: "400",
                    background: "rgb(220, 230, 241)",
                    borderRadius: "20px",
                    padding: "10px",
                  }}
                >
                  &nbsp;{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="34"
                    viewBox="0 0 34 39"
                    fill="none"
                  >
                    <path
                      d="M33.4977 10.901L33.3518 7.98198L33.2772 6.48678L32.236 7.56034L17.8062 22.4734L12.9314 17.0018L12.5 16.5186L12.0168 16.9499L10.1259 18.6365L9.6426 19.0679L10.074 19.5511L17.2354 27.585L17.6992 28.1072L18.1857 27.6045L29.703 15.698C29.5603 18.1143 29.0835 20.4106 28.2824 22.5416C27.4391 24.786 26.239 26.8423 24.7211 28.6489C23.4302 30.1862 21.8961 31.5355 20.2939 32.5539C19.0971 33.3128 17.8613 33.8837 16.7878 34.1691C15.7175 33.8804 14.4785 33.3128 13.2817 32.5539C11.6762 31.5355 10.1453 30.1862 8.85445 28.6489C7.33655 26.8423 6.13649 24.786 5.29321 22.5416C4.3202 19.9533 3.8272 17.1186 3.8272 14.1185C3.8272 12.7173 3.87585 11.4913 3.93099 10.5897C6.2792 10.3853 8.65336 9.80801 10.8362 8.90635C12.0038 8.42308 13.1422 7.83927 14.2158 7.17438C15.1239 6.61003 15.9867 5.98405 16.791 5.30943C17.9197 6.25974 19.1619 7.10627 20.4885 7.82954C22.347 8.84148 24.3384 9.60043 26.4109 10.0805L27.0434 10.2264L27.1893 9.59394L27.7602 7.12573L27.9061 6.49326L27.2737 6.34731C25.5384 5.94513 23.8746 5.31267 22.321 4.46615C20.7577 3.61314 19.3533 2.56876 18.1468 1.35898L17.2516 0.460561L16.7943 0L16.3337 0.460561L15.4385 1.35898C13.7649 3.03257 11.6697 4.4175 9.37664 5.36457C7.07707 6.31488 4.57318 6.82409 2.13739 6.84031L1.00545 6.84679L0.424883 6.85004L0.36326 7.42736L0.246497 8.55606C0.236767 8.65336 0 10.94 0 14.1185C0 22.3405 3.22393 27.8996 5.92567 31.1138C6.76571 32.1128 7.68683 33.0436 8.65661 33.8772C9.54205 34.6362 10.4761 35.3237 11.4362 35.914C13.113 36.9487 14.8774 37.6947 16.4018 38.0158L16.6613 38.0709L16.7943 38.1001L16.9272 38.0709L17.1867 38.0158C18.7111 37.6947 20.4723 36.9487 22.1523 35.914C23.1091 35.3237 24.0465 34.6394 24.9319 33.8772C25.9017 33.0436 26.8228 32.1128 27.6629 31.1138C30.3679 27.8964 33.5885 22.3372 33.5885 14.1185C33.5788 13.0514 33.5529 11.9681 33.4977 10.901Z"
                      fill="url(#paint0_linear_103_6)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_103_6"
                        x1="16.7845"
                        y1="0.202131"
                        x2="16.7845"
                        y2="38.0203"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#30C1FF" />
                        <stop offset="1" stop-color="#4741BF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  &nbsp;{" "}
                  <span
                    style={{
                      color: "#004265",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontWeight: "400",
                    }}
                  ></span>
                  <span
                    style={{
                      color: "rgb(35 92 122)",
                      fontWeight: "700",
                      bottom: "-1px",
                      position: "relative",
                    }}
                  >
                    {SelectedCustomer && SelectedCustomer.identity}
                    <DropdownButton
                      id="myDropdownButton"
                      variant="rgb(234, 240, 246)"
                      style={{
                        display: "inline",
                        backgroundColor: "transparent",
                        color: "black",
                        fontSize: "10px",
                        border: "transparent",
                        borderColor: "#eaf0f6",
                      }}
                      title={
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faEllipsis}
                        />
                      }
                      className="mt-2"
                    >
                      <div
                        style={{
                          backgroundColor: "rgb(234, 240, 246)",
                        }}
                      >
                        <Dropdown.Header
                          id="myDropdown"
                          style={{ padding: "1pc" }}
                        >
                          <span>
                            {" "}
                            {SelectedCustomer.identity}
                            <FontAwesomeIcon
                              className="p-1"
                              style={{
                                backgroundColor: "transparent",
                                color: "deepskyblue",
                                float: "inline-end",
                                fontSize: "16px",
                                marginTop: "-10px",
                              }}
                              icon={faLink}
                            />{" "}
                          </span>{" "}
                          &nbsp; &nbsp; &nbsp;{" "}
                        </Dropdown.Header>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={handleEditClick}
                          style={{ marginTop: "15px" }}
                        >
                          Edit Customer
                        </Dropdown.Item>

                        <EditCustomer
                          ShowPopup={ShowEditCustomer}
                          onActionCancelled={ActionCancelled}
                          onActionCompleted={ActionCompleted}
                          SelectedCustomer={SelectedCustomer}
                        />

                        <Dropdown.Item style={{ marginTop: "2px" }}>
                          Refresh
                        </Dropdown.Item>
                        <Dropdown.Item style={{ marginTop: "2px" }}>
                          <Link
                            to={sendinvite}
                            style={{
                              padding: "unset",
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            Resend Invite
                          </Link>
                        </Dropdown.Item>
                        <div style={{ marginTop: "5px" }}>
                          <Link
                            target="_blank"
                            to={downloadindex}
                            style={{
                              textDecoration: "none",
                              marginLeft: "15px",
                              color: "black",
                              fontWeight: "100",
                            }}
                          >
                            Download
                          </Link>
                        </div>

                        <Dropdown.Item
                          onClick={clearCustomerNotifications}
                          style={{ marginTop: "6px" }}
                        >
                          {" "}
                          Clear Notification{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={handleDeleteClick}
                          style={{ marginTop: "2px" }}
                        >
                          {" "}
                          Delete Customer{" "}
                        </Dropdown.Item>
                        <DeleteCustomer
                          ShowPopup={ShowDeleteCustomer}
                          onActionCancelled={ActionCancelled}
                          onActionCompleted={ActionCompleted}
                          SelectedCustomer={SelectedCustomer}
                        />
                        <div style={{ marginTop: "6px", marginBottom: "10px" }}>
                          <Link
                            to={`${
                              VixoAPI.CustomeDashboard
                            }home?id=${encodeURIComponent(
                              SelectedCustomer.key
                            )}`}
                            target="_blank"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "black",
                              marginLeft: "15px",
                              fontWeight: "400",
                            }}
                          >
                            Customer Portal
                          </Link>
                        </div>

                        <div style={{ marginTop: "6px", marginBottom: "10px" }}>
                          <div
                            onClick={handleIconClick}
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "black",
                              marginLeft: "15px",
                              fontWeight: "400",
                            }}
                          >
                            Customer Transfer
                          </div>
                        </div>
                      </div>
                    </DropdownButton>
                  </span>
                </span>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </span>
      </h1>

      {IsCustomerFound ? (
        <header className="top-header">
          <nav
            className="navbar navbar-expand"
            style={{ paddingLeft: "26px", paddingTop: "18px" }}
          >
            <div className="top-navbar d-xl-block ">
              <ul className="navbar-nav align-items-center">
                <li className="tab-banking">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="175"
                    height="41"
                    viewBox="0 0 175 41"
                    onClick={handleBankingClick}
                  >
                    <path
                      d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                      fill={
                        location.pathname.includes("/vixohome")
                          ? "#EAF0F6"
                          : "white"
                      }
                      stroke="#BECDDB"
                    />
                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="#000"
                    >
                      Banking
                    </text>
                  </svg>
                </li>

                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="175"
                    height="41"
                    viewBox="0 0 175 41"
                    onClick={handleFileClick}
                    style={{ marginLeft: "-28px" }}
                  >
                    <path
                      d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                      fill={
                        location.pathname.includes("/filesindex")
                          ? "#EAF0F6"
                          : "white"
                      }
                      stroke="#BECDDB"
                    />

                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="#000"
                    >
                      Files
                    </text>
                  </svg>
                </li>

                <li className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="175"
                    height="41"
                    viewBox="0 0 175 41"
                    onClick={handleDocusignClick}
                    style={{ marginLeft: "-28px" }}
                  >
                    <path
                      d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                      fill={
                        location.pathname.includes("/Contractindex")
                          ? "#EAF0F6"
                          : "white"
                      }
                      stroke="#BECDDB"
                    />

                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="#000"
                    >
                      DocuSign &trade;
                    </text>
                  </svg>
                </li>

                <div className="d-none d-md-block">
                  <div className="  d-flex  align-items-center">
                    <li className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="175"
                        height="41"
                        viewBox="0 0 175 41"
                        onClick={handleIdentityVerification}
                        style={{ marginLeft: "-28px" }}
                      >
                        <path
                          d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                          fill={
                            location.pathname.includes(
                              "/customeridentityverification"
                            )
                              ? "#EAF0F6"
                              : "white"
                          }
                          stroke="#BECDDB"
                        />

                        <text
                          x="30%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="#000"
                        >
                          ID
                        </text>

                        {showTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() => document.getElementById("tickIcon")}
                            >
                              {(props) => (
                                <Tooltip id="tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="tickIcon"
                              x="40%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "35%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  " Identity Verification Request sent to Customer"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ✅
                            </text>
                          </>
                        )}

                        {showDoubleTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("doubleTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="double-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>

                            <text
                              id="doubleTickIcon"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "45%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Started Identity Verification Request"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ✅ ✅
                            </text>
                          </>
                        )}

                        {showTripleTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("tripleTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="triple-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="tripleTickIcon"
                              x="60%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "55%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Identity is Verified"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ✅ ✅ ✅
                            </text>
                          </>
                        )}

                        {showFourTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("fourTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="four-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="fourTickIcon"
                              x="40%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "65%" }}
                              onMouseEnter={() =>
                                setTooltipContent("Four Tick Icon Tooltip")
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ✅
                            </text>
                          </>
                        )}

                        {showCrossIcon && (
                          <>
                            <text
                              id="crossIcon"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="red"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "75%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Identity Verification Process Failed"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ❌
                            </text>

                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("crossIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="cross-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                          </>
                        )}
                      </svg>
                    </li>

                    <li className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="175"
                        height="41"
                        viewBox="0 0 175 41"
                        onClick={handleCommuncationClick}
                        style={{ marginLeft: "-28px" }}
                      >
                        <path
                          d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                          fill={
                            location.pathname.includes("/CommunicationTimeline")
                              ? "#EAF0F6"
                              : "white"
                          }
                          stroke="#BECDDB"
                        />

                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="#000"
                        >
                          History
                        </text>
                      </svg>
                    </li>
                  </div>
                </div>
                <li
                  className={`nav-item ${
                    location.pathname.includes("/downloadindex") ? "Active" : ""
                  }`}
                ></li>
              </ul>
            </div>
            <div className="top-navbar-right ms-3 ms-auto m-0">
              <div style={{ background: "white", borderRadius: "50%" }}></div>
              <Nav className="navbar-nav align-items-center">
                <div className="d-block d-md-none px-5">
                  <div className="  d-flex  align-items-center">
                    <li className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="175"
                        height="41"
                        viewBox="0 0 175 41"
                        onClick={handleIdentityVerification}
                        style={{ marginLeft: "-28px" }}
                      >
                        <path
                          d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                          fill={
                            location.pathname.includes(
                              "/customeridentityverification"
                            )
                              ? "#EAF0F6"
                              : "white"
                          }
                          stroke="#BECDDB"
                        />

                        <text
                          x="30%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="#000"
                        >
                          ID
                        </text>

                        {showTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() => document.getElementById("tickIcon")}
                            >
                              {(props) => (
                                <Tooltip id="tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="tickIcon"
                              x="40%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "35%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  " Identity Verification Request sent to Customer"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ?
                            </text>
                          </>
                        )}

                        {showDoubleTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("doubleTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="double-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>

                            <text
                              id="doubleTickIcon"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "45%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Started Identity Verification Request"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ??
                            </text>
                          </>
                        )}

                        {showTripleTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("tripleTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="triple-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="tripleTickIcon"
                              x="60%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "55%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Identity is Verified"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ???
                            </text>
                          </>
                        )}

                        {showFourTickIcon && (
                          <>
                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("fourTickIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="four-tick-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                            <text
                              id="fourTickIcon"
                              x="40%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="green"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "65%" }}
                              onMouseEnter={() =>
                                setTooltipContent("Four Tick Icon Tooltip")
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ?
                            </text>
                          </>
                        )}

                        {showCrossIcon && (
                          <>
                            <text
                              id="crossIcon"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="red"
                              fontSize="0.8em"
                              style={{ position: "absolute", left: "75%" }}
                              onMouseEnter={() =>
                                setTooltipContent(
                                  "Customer Identity Verification Process Failed"
                                )
                              }
                              onMouseLeave={() => setTooltipContent("")}
                            >
                              ?
                            </text>

                            <Overlay
                              trigger="hover"
                              placement="top"
                              show={!!tooltipContent}
                              target={() =>
                                document.getElementById("crossIcon")
                              }
                            >
                              {(props) => (
                                <Tooltip id="cross-tooltip" {...props}>
                                  {tooltipContent}
                                </Tooltip>
                              )}
                            </Overlay>
                          </>
                        )}
                      </svg>
                    </li>

                    <li className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="175"
                        height="41"
                        viewBox="0 0 175 41"
                        onClick={handleCommuncationClick}
                        style={{ marginLeft: "-28px" }}
                      >
                        <path
                          d="M14.3555 27.8641V13.1359C14.3555 6.1423 20.4008 0.5 27.7111 0.5H158.289C165.736 0.5 171.644 6.13912 171.644 13.1359V27.8641C171.644 33.6233 175.404 38.4826 180.621 40.5H5.3791C10.5957 38.4826 14.3555 33.6233 14.3555 27.8641Z"
                          fill={
                            location.pathname.includes("/CommunicationTimeline")
                              ? "#EAF0F6"
                              : "white"
                          }
                          stroke="#BECDDB"
                        />

                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="#000"
                        >
                          History
                        </text>
                      </svg>
                    </li>
                  </div>
                </div>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="notifications-dropdown"
                  style={{
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <div
                    className="notifications"
                    style={{ cursor: "pointer", marginRight: "54px" }}
                    onClick={handleIconClick}
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </div>
                </Dropdown.Toggle>
                {/* <Nav.Item
                  style={{ cursor: "pointer", marginRight: "4pc" }}
                  onClick={handleIconClick}
                >
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{
                      color: "#00AADB",
                      fontSize: "21px",
                      marginTop: "10px",
                    }}
                  />
                </Nav.Item> */}
                <CustomerTransferHome
                  showModal={showModal}
                  setShowModal={setShowModal}
                  SelectedCustomer={SelectedCustomer}
                />

                <Nav.Item>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={Nav.Link}
                      id="notifications-dropdown"
                      style={{
                        background: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <div className="notifications">
                        <span
                          className="notify-badge"
                          style={{ fontSize: "9px" }}
                        >
                          {customerCount}
                        </span>
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      id="myalert"
                      className="customer-alert"
                      style={{ marginRight: "6pc", marginTop: "10pc" }}
                    >
                      <div
                        className="alertmob"
                        style={{
                          maxWidth: "270px",
                          position: "absolute !important",
                          paddingLeft: "9px",
                          maxHeight: "465px",
                          overflow: "hidden",
                          width: "18pc",
                          backgroundColor: "white",
                        }}
                      >
                        <div className="p-2 border-bottom m-2 d-flex align-items-center justify-content-between">
                          <h5 className="h5 mb-0">Notifications</h5>
                          <button
                            onClick={() => ClearAllNotificationAll(customerKey)}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              fontSize: "14px",
                              color: "green",
                            }}
                          >
                            Clear All
                          </button>
                        </div>
                        <div
                          style={{
                            scrollbarWidth: "thin",
                            backgroundColor: "white",
                            msOverflowStyle: "thin",
                          }}
                          className="overflow-auto header-notifications-list p-2"
                        >
                          {customerNotificaton &&
                            customerNotificaton
                              .sort(
                                (a, b) =>
                                  new Date(b.createdDate) -
                                  new Date(a.createdDate)
                              )
                              .map((alert, index) => (
                                <div
                                  className="notification-scrol d-flex align-items-center mb-2 "
                                  key={index}
                                >
                                  <div
                                    className="ms-3 flex-grow-1"
                                    style={{ cursor: "Pointer" }}
                                  >
                                    <h6 className="mb-0 dropdown-msg-user">
                                      {alert.description}
                                    </h6>
                                    <div
                                      className={`d-flex align-items-center ${
                                        alert.eventTypeId === 3 ||
                                        alert.eventTypeId === 2
                                          ? "justify-content-between"
                                          : "justify-content-end"
                                      }`}
                                    >
                                      {alert.eventTypeId !== null &&
                                        (alert.eventTypeId == 3 ||
                                          (alert.eventTypeId == 2 && (
                                            <small
                                              className="mb-0 dropdown-msg-text text-secondary d-flex align-items-center cursor-pointer"
                                              onClick={() =>
                                                handleAlertClick(alert.id)
                                              }
                                            >
                                              View Details
                                            </small>
                                          )))}

                                      <p className="msg-time  text-secondary mb-0">
                                        {formatTimeFromDate(alert.createdDate)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    className="projects m-2 nav-item"
                    to={`${VixoAPI.DashBoard}General`}
                    target="_blank"
                    style={{marginLeft:"6px"}}
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </Link>
                </Nav.Item>
              </Nav>
            </div>
          </nav>
        </header>
      ) : (
        <div style={redDivStyle}>Customer Does not Exist</div>
      )}
    </>
  );
}

export default CustomerNavbar;
