// import React, { useState, useEffect } from "react";
// import Bankcheckbox from "./Bankcheckbox";
// import ReactApexChart from "react-apexcharts";
// import axios from "axios";
// import Spinner from "../../Spinner";
// import { VixoAPI } from "../../settings";

// const Transaction = () => {
//   const [chartData, setChartData] = useState({
//     series: [],
//     options: {
//       chart: {
//         type: 'line',
//         height: 350,
//       },
//       xaxis: {
//         type: 'datetime', // Set x-axis type to datetime
//         labels: {
//           datetimeFormatter: {
//             year: 'yyyy',
//             month: "MMM 'yy",
//             day: 'dd MMM',
//             hour: 'HH:mm',
//           },
//         },
//       },
//       stroke: {
//         width: 3,
//         dashArray: [5],
//       },
//     },
//   });

//   const [loading, setLoading] = useState(false);
//   const [trndata, setTrndata] = useState(null);

//   const clearTrn = () => {
//     setTrndata(null);
//     setChartData((prevChartData) => ({
//       series: [],
//       options: prevChartData.options, // Maintain the reference to the same options object
//     }));
//   };

//   const [accountIds, setAccountIds] = useState(null);

//   const handleAccountClick = (accountIds, name) => {
//     if (accountIds != null && accountIds.length >= 0) {
//       setAccountIds(accountIds);
//     } else {
//       clearTrn();
//       return;
//     }
//     const accountIdArray = Array.isArray(accountIds)
//       ? accountIds
//       : [accountIds];

//     try {
//       setLoading(true);
//       const GetTrn = `${VixoAPI.AccountAPI}getTranByAccount`;
//       setLoading(true);
//       axios
//         .post(GetTrn, accountIdArray)
//         .then((response) => {
//           var response = response.data;
//           if (response.statusCode === 200) {
//             setTransactionData(response.data);
//             if (response.data != null) {
//               const newData = {
//                 series: [
//                   {
//                     name: name,
//                     data: response.data.map((item) => ({
//                       x: new Date(item.transactionDate).getTime(), // Use transactionDate as x-axis
//                       y: item.amount, // Use amount as y-axis
//                     })),
//                   },
//                 ],
//                 options: {
//                   chart: {
//                     type: "line",
//                     height: 350,
//                   },
//                   // Add other chart options here
//                   stroke: {
//                     width: 3,
//                     dashArray: [5],
//                   },
//                   xaxis: {
//                     type: "datetime", // Set x-axis type to datetime
//                     labels: {
//                       datetimeFormatter: {
//                         year: "yyyy",
//                         month: "MMM 'yy",
//                         day: "dd MMM",
//                         hour: "HH:mm",
//                       },
//                     },
//                   },
//                 },
//               };
//               setChartData(newData);
//             }
//           } else {
//             clearTrn();
//             // setError(response.message);
//           }
//         })
//         .catch((error) => {
//           setLoading(false);
//           throw error;
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } catch (error) {
//       setLoading(false);
//       throw error;
//     }
//   };

//   const [inputValue, setInputValue] = useState('');
//   const handleInputChange = (e) => {
//     setInputValue(e.target.value); // Update the state with the input's value
//   };

//   const getTransactionMonth = (value) => {
//     const accountIdArray = Array.isArray(accountIds) ? accountIds : [accountIds];
//  const data = {
//   AccountId: accountIdArray,
//   months:value,
//   filter: inputValue
//  }

//   try {
//     setLoading(true);
//     const GetTrnMonth = `${VixoAPI.AccountAPI}getTranByAccountMonth`;
//     axios
//       .post(GetTrnMonth, data)
//         .then((response) => {
//           var response = response.data;
//           if (response.statusCode === 200) {
//             setTransactionData(response.data);
//             if (response.data != null) {
//               const newData = {
//                 series: [
//                   {
//                     //name: name,
//                     data: response.data.map((item) => ({
//                       x: new Date(item.transactionDate).getTime(), // Use transactionDate as x-axis
//                       y: item.amount, // Use amount as y-axis
//                     })),
//                   },
//                 ],
//                 options: {
//                   chart: {
//                     type: "line",
//                     height: 350,
//                   },
//                   // Add other chart options here
//                   stroke: {
//                     width: 3,
//                     dashArray: [5],
//                   },
//                   xaxis: {
//                     type: "datetime", // Set x-axis type to datetime
//                     labels: {
//                       datetimeFormatter: {
//                         year: "yyyy",
//                         month: "MMM 'yy",
//                         day: "dd MMM",
//                         hour: "HH:mm",
//                       },
//                     },
//                   },
//                 },
//               };
//               setChartData(newData);
//             }
//           } else {
//             clearTrn();
//             // setError(response.message);
//           }
//         })
//         .catch((error) => {
//           setLoading(false);
//           throw error;
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } catch (error) {
//       setLoading(false);
//       throw error;
//     }
//   };

//   function setTransactionData(cusTrndata) {
//     try {
//       setLoading(true);
//       var incomeData = JSON.parse(JSON.stringify(cusTrndata));
//       setTrndata(incomeData);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       throw error;
//     }
//   }

//   const [searchQuery, setSearchQuery] = useState("");

//   const handleSearchInputChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   return (
//     <>
//       <Spinner show={loading} />
//       <div className="row">
//         <Bankcheckbox handleAccountClick={handleAccountClick} />
//       </div>
//       <div className="row">
//         <div className="col-6">
//         <input
//         className="form-control w-50 searchbartabbmob"
//         placeholder="Search"
//         value={inputValue} // Make the input a controlled component
//         onChange={handleInputChange} // Handle input changes with an arrow function
//       />
//         </div>
//         <div className="col-6 text-end ">
//         <select
//         onChange={(e) => getTransactionMonth(e.target.value)}
//         className="dropdowntab dropdowntabmob w-50 h-100 rounded"
//       >
//         <option disabled>Total By</option>
//         <option value="365">Annually</option>
//         <option value="90">Quarterly</option>
//         <option value="30">Monthly</option>
//         <option value="7">Weekly</option>
//         <option value="1">Daily</option>
//       </select>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-12">
//           <div id="chart">
//           <ReactApexChart
//   options={chartData.options}
//   series={chartData.series}
//   type="line"
//   height={350}
// />
//           </div>
//         </div>
//       </div>
//       <div className="row mt-5">
//       <input
//                     className="form-control mb-3"
//                     type="text"
//                     placeholder="Type here to search"
//                     value={searchQuery}
//                     onChange={handleSearchInputChange}
//                   />
//         <div className="card">
//           <div className="card-body">
//             <div className="table-responsive">
//               <table
//                 id="example"
//                 className="table table-striped"
//                 style={{ width: "100%" }}
//               >
//                 <thead>
//                   <tr>
//                     <th>Date</th>
//                     <th>Description</th>
//                     {/* <th>RunningBalance</th> */}
//                     <th>Amount</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {trndata != null &&

//                     trndata
//                     .filter((trn) =>
//                     trn.description
//                         .toLowerCase()
//                         .includes(searchQuery.toLowerCase())
//                     ).map((trn, index) => (
//                       <tr>
//                         <td>{trn.transactionDate}</td>
//                         <td>{trn.description}</td>
//                         <td>{trn.amount}</td>
//                         {/* <td>$100</td> */}
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Transaction;

// import React, { useEffect, useState } from "react";
// import IncomeTransaction from "../IncomeExpenseTransactions/IncomeTransaction";

// const Transaction = ({custDownload}) => {
//   const [TransactionType, setTransactionType] = useState(0);
 
//   useEffect(() => {
   
//   }, []);
//   return (
//     <div>
//       <IncomeTransaction TransactionType={TransactionType} trngrpah={custDownload} />
//     </div>
//   );
// };
// export default Transaction;

import React, { useEffect, useState, lazy, Suspense } from "react";

// Use React.lazy to lazily load the IncomeTransaction component
const LazyIncomeTransaction = lazy(() => import("../IncomeExpenseTransactions/IncomeTransaction"));

const Transaction = ({custDownload,tabClicked,IsOpenedFromDownload}) => {
  // const [TransactionType, setTransactionType] = useState(0); // 0- Mean Transaction
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyIncomeTransaction TransactionType={0}  tabClicked={tabClicked} trngrpah={custDownload} IsOpenedFromDownload={IsOpenedFromDownload} />
      </Suspense>
    </div>
  );
};

export default Transaction;



