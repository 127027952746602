import React from "react";

const DollarSign = () => {
  return (
    <>
      <span>$</span>
    </>
  );
};

export default DollarSign;
