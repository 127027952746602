import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import {
  Form,
  Row,
  Col,
  Carousel,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Money from "../../VixoFormats/Money";
import { useSelector } from "react-redux";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import AccountStatus from "../../VixoFormats/AccountStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
const Bankcheckbox = ({ handleAccountClick }) => {
  const notification = useSelector(selectNotification);
  const [selectAll, setSelectAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [bankdata, setBankdata] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState(
    Array(bankdata?.length).fill(true)
  );

  const [CustomerKey, setCustomerKey] = useState(null);
  const [organizationid, setOrganizationId] = useState(null);
  const [UserKey, setUserKey] = useState(null);
  const [filesVisibility, setFilesVisibility] = useState({});
  const navigate =useNavigate();

  const location = useLocation();
  useEffect(() => {
    if(HasLoggedInUser())
    {
      fetchData();
    }
  }, [location, selectAll]);

  useEffect(() => {
    LoadCustomer(CustomerKey);
  }, []);

  /////////////////////////////////

  const fetchData = async () => {
    const config = APIHeader();
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const customerKey = params.get("Id");
      const UserID = params.get("U");
      if (customerKey && UserID) {
        setCustomerKey(customerKey);
        setUserKey(UserID);
      }
      if (customerKey != null && UserID != null && isDataLoaded == false) {
        const GetCustomerBankId = `${VixoAPI.AccountAPI}getbyCustomerKey?Key=${customerKey}`;
        const response = await axios.get(GetCustomerBankId, {headers:config.headers});

        setBankAPIData(response.data.data);
        // setCustomerId(response.data.data.customerId)
        // If selectAll is true, pass default data to handleAccountClick
        if (selectAll) {
          const defaultBankIds =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.map((bank) => bank.id);
          const defaultBankNames =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.map((bank) => bank.instituteName);
          const bankConnectionStatus =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.map((bank) => bank.ocrType);
          handleAccountClick(
            defaultBankIds,
            defaultBankNames,
            bankConnectionStatus
          );
        } else {
          // Handle error if needed
        }
      }
    } catch (error) {
      setLoading(false);
      if(error.response && error.response.status && error.response.status==401)
        {
           ExpireToken(navigate,CustomerKey);
        }
    } finally {
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  };

  function LoadCustomer(customerKey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      const LoadCustomerAPI =
        VixoAPI.CustomerAPI + "getCustomerBykey?Key=" + customerKey;
      setLoading(true);
      axios
        .get(LoadCustomerAPI, {headers:config.headers})
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            setOrganizationId(responseData.data.organizationId);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate, customerKey);
              }
            })
            .finally(() => {
              setLoading(false);
            });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  //////////////Notifications ///////////////

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function GotNewAlert(notification) {
    if (
      (notification != null &&
        // parseInt(notification.organiationID) === organizationid &&
        notification.customerKey === CustomerKey &&
        (notification.notificationType === 1 ||
      notification.notificationType === 25))
    ) {
      fetchData();
      const link = `${VixoAPI.CustomeDashboard}vixohome?Id=${UserKey}&U=${CustomerKey}#transaction`;
      window.location.reload(link);
    }
  }

  useEffect(() => {
    // Update checkboxStates when selectAll or bankdata changes
    setCheckboxStates(
      selectAll
        ? Array(bankdata && bankdata.length).fill(true)
        : Array(bankdata && bankdata.length).fill(false)
    );
  }, [selectAll, bankdata]);

  function LoadBankByCustomerId(Customerkey) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      const GetCustomerBankId = `${VixoAPI.AccountAPI}getbyCustomerKeylastfourdigits?Key=${Customerkey}`;
      setLoading(true);
      axios
        .get(GetCustomerBankId, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setBankAPIData(response.data);
            // Initialize checkboxStates with the same length as bankdata
            setCheckboxStates(Array(response.data.length).fill(false));
          } else {
            // Handle error if needed
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate,CustomerKey);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate,CustomerKey);
  }
  }

  function setBankAPIData(cusbankdata) {
    try {
      setLoading(true);
      var cusBankdata = JSON.parse(JSON.stringify(cusbankdata));

      const modifiedData =
        cusBankdata &&
        cusBankdata.map((item) => ({
          ...item,
          isChecked: true,
        }));
      if (modifiedData != null && modifiedData.length > 0) {
        setIsDataLoaded(true);
      }
      setBankdata(modifiedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const newSelectAll = !selectAll;

    // Update selectAll state
    setSelectAll(isChecked);

    const updatedBankData = bankdata.map((item) => ({
      ...item,
      isChecked: newSelectAll,
    }));

    setBankdata(updatedBankData);

    var selectedBanks = updatedBankData.filter(
      (item) => item.isChecked === true
    );
    const defaultBankIds = selectedBanks.map((bank) => bank.id);
    const defaultBankNames = selectedBanks.map((bank) => bank.instituteName);
    handleAccountClick(defaultBankIds, defaultBankNames);
  };

  const handleCheckboxChange = (index) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[index] = !newCheckboxStates[index];
    setCheckboxStates(newCheckboxStates);

    // Check if all checkboxes are checked
    const allChecked = newCheckboxStates.every((isChecked) => isChecked);

    // Update selectAll state
    setSelectAll(allChecked);
  };

  const carouselRef = useRef();

  const handleBankItemClick = (id, name) => {
    if (id && id != null) {
      // Make sure to check if 'carouselRef.current' exists before calling 'next'
      var selectedBankIndex1 = bankdata.findIndex((item) => item.id === id);
      const bankdata1 = [...bankdata];
      bankdata1[selectedBankIndex1].isChecked =
        !bankdata1[selectedBankIndex1].isChecked;
      if (bankdata1 != null && bankdata1.length > 0) {
        setIsDataLoaded(true);
      }
      setBankdata(bankdata1);

      var selectedBanks = bankdata.filter((item) => item.isChecked == true);
      const defaultBankIds =
        selectedBanks && selectedBanks.map((bank) => bank.id);
      const defaultBankNames =
        selectedBanks && selectedBanks.map((bank) => bank.instituteName);
      handleAccountClick(defaultBankIds, defaultBankNames);
    }
  };

  if (bankdata && bankdata.length === 0) {
    // No banks available
    return (
      <div className="container m-2">
        <div className="row">
          <Form>
            <Row className="d-flex justify-content-start align-items-start">
              <Col md={12} className="justify-content-start"></Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  ///////////////////////// capitalizeFirstLetter /////////////
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <Spinner show={loading} Title="Bank Checkbox" />
      <div className="container m-2">
        <div className="row">
          <Form>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={1}>
                <div className="d-flex justify-content-center">
                  <Form.Check
                    type="checkbox"
                    label="All"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </div>
              </Col>
              <Col md={10}>
                <Carousel ref={carouselRef}>
                  {Array.from({
                    length: Math.ceil(bankdata && bankdata.length / 3),
                  }).map((_, rowIndex) => (
                    <Carousel.Item key={rowIndex}>
                      <Row className="justify-content-center p-5">
                        {bankdata &&
                          bankdata
                            .slice(rowIndex * 3, (rowIndex + 1) * 3)
                            .map((bank, index) => (
                              <Col
                                md={4}
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "inherit",
                                }}
                                key={bank.id}
                                onClick={() =>
                                  handleBankItemClick(
                                    bank.id,
                                    bank.instituteName
                                  )
                                }
                              >
                                <div
                                  className="flex-row"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    className="checkboxstyle"
                                    style={{
                                      paddingLeft: "10px",
                                      marginRight: "9px",
                                    }}
                                    checked={bank.isChecked}
                                  />

                                  {bank.instituteLogoUrl ? (
                                    <img
                                      className="text-center"
                                      src={bank.instituteLogoUrl}
                                      alt="Logo 2"
                                      style={{
                                        width: "auto", // Set the width to 100%
                                        height: "30px", // Set the height to the desired fixed value
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <h3 className="text-center">
                                      {bank.instituteName}
                                    </h3>
                                  )}
                                </div>

                                <div
                                  className="row m-0"
                                  style={{
                                    borderRadius: "5px",
                                    height: "7pc",
                                    background: bank.brandingSecColor
                                      ? Array.isArray(bank.brandingSecColor)
                                        ? bank.brandingColor[
                                            index % bank.brandingSecColor.length
                                          ]
                                        : bank.brandingSecColor
                                      : "#0B5ED7",
                                  }}
                                >
                                  <div className="col-md-12">
                                    <span
                                      className="d-flex"
                                      style={{
                                        color: "white",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span style={{ alignSelf: "flex-start" }}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {bank.accountTitle}
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {capitalizeFirstLetter(
                                              bank.accountTitle.substring(0, 20)
                                            )}
                                          </span>
                                        </OverlayTrigger>
                                      </span>

                                      {/* Other existing code */}
                                      <span style={{ alignSelf: "flex-end" }}>
                                        {bank.accountNumberlastFourDigits &&
                                        bank.accountNumberlastFourDigits
                                          .length === 4
                                          ? `(${bank.accountNumberlastFourDigits})`
                                          : bank.accountNumberlastFourDigits}
                                      </span>

                                      {/* Other existing code */}
                                    </span>
                                    <img
                                      src={bank.ocrLogoUrl}
                                      alt="Logo 2"
                                      style={{
                                        width: "95px",
                                        height: "45px",
                                        marginTop: "4px",
                                        marginRight: "10rem",
                                      }}
                                    />
                                    <div
                                      className="mt-2"
                                      style={{ color: "White",fontSize:"13px" }}
                                    >
                                      <AccountStatus
                                        value={bank.connectionId}
                                      />
                                    </div>
                                  </div>

                                  <FontAwesomeIcon
                                    icon={faPowerOff}
                                    style={{
                                      marginTop: "-20px",
                                      marginLeft:"10px",
                                      color:
                                        bank.connectionType === 0
                                          ? "lime"
                                          : bank.connectionType === 1
                                            ? "yellow"
                                            : bank.connectionType === 2
                                              ? "lime"
                                              : bank.connectionType === 3
                                                ? "red"
                                                : "blue",
                                    }}
                                  />

                                  <div className="text-end">
                                    <span
                                      style={{
                                        color: "white",

                                        // marginTop: "50px",
                                      }}
                                    >
                                      <div style={{ marginTop: "-25px" }}>
                                        <Money value={bank.balance} />
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            ))}
                      </Row>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Bankcheckbox;
