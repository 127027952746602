import React from "react";
import { Modal} from "react-bootstrap";
import { RingLoader } from "react-spinners";
//import './Spinner.css';


const Spinner = ({show,Title}) => {
  if (!show) {
    return null;
  }

  return (
    <>
        <Modal show={show} className="d-flex align-items-center modelcolor spinner-mobile" backdrop="static" keyboard={false}>
          <Modal.Body className="d-flex justify-content-center"  >
          <img src="assets/images/spinner.gif" style={{width:"150px"}} />
          </Modal.Body>
          {/* <div style={{margin:'10px'}}>Loading {Title && Title!=null ?Title:""}</div> */}
        </Modal>
    </>
  );
};

export default Spinner;
